import { userAgent } from '@seiue/ua'
import { uniq } from '@seiue/util'
import { useEffect, useMemo, useRef } from 'react'

import {
  CounterSubjectEnum,
  useCounters,
} from 'packages/features/counter/hooks'
import { useLoadEvaluation } from 'packages/features/evaluations/utils/apis'
import { useIsApolloLogin } from 'packages/features/sessions/hooks'

import { RootStackNavigationProp } from '@go/router'

/**
 * 强制评教
 *
 * @param navigation - navigation
 */
export const useForceEvaluation = (
  navigation: RootStackNavigationProp<'Main'>,
) => {
  const { isDone, loading } = useForceEvaluationSettings()

  const isApolloLogin = useIsApolloLogin()

  const openForceEvaluation =
    !isDone && !loading && !isApolloLogin && !userAgent.isC3AppWebView

  useEffect(() => {
    if (openForceEvaluation) {
      navigation.replace('ForceEvaluationModal')
    }
  }, [openForceEvaluation, navigation])
}

/**
 * 获取强制评教设置
 *
 * @returns setings
 */
export const useForceEvaluationSettings = () => {
  const { counterSubjectIds, loading, refetching, refresh, skip } = useCounters(
    CounterSubjectEnum.ForcedEvaluations,
  )

  // 记录当下强制评教的 id，以判断显示的重要通知总数
  const importantIdsRef = useRef<string[]>([])

  // why idsRef，ids 需要立即响应 counterSubjectIds 的变化
  const idsRef = useRef<string[]>([])

  /*
   * why useMemo？
   * useMemo useMemo 会立即调用，即 counterSubjectIds 变化能立即变更 idsRef （而 useEfect 会在本次执行的最后调用）
   */
  useMemo(() => {
    idsRef.current = counterSubjectIds

    if (counterSubjectIds.length === 0) {
      importantIdsRef.current = []
    } else {
      importantIdsRef.current = uniq([
        ...importantIdsRef.current,
        ...counterSubjectIds,
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counterSubjectIds.join(',')])

  const currentEvaluationId = idsRef.current[0]

  const { data: evaluation, loading: loadingEvaluation } = useLoadEvaluation(
    { id: Number(currentEvaluationId) },
    {
      disable: Number.isNaN(Number(currentEvaluationId)),
    },
  )

  useEffect(() => {
    if (
      evaluation?.resultSent &&
      evaluation?.id === Number(currentEvaluationId)
    ) {
      skip(CounterSubjectEnum.ForcedEvaluations, currentEvaluationId)

      idsRef.current.shift()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluation?.resultSent, evaluation?.id, currentEvaluationId])

  const isDone = !idsRef.current.length && !loading && !loadingEvaluation

  return {
    currentEvaluationId,
    isDone,
    loading,
    refetching,
    refresh,
  }
}

import { PlatformEnum } from './platform-enum'
import { Change, ChangeInterface } from './change'

export interface NewestAppverInterface {
  isBc: boolean
  updatable: boolean
  version: string
  releasedAt: string
  releasedStores: string[] | null
  platform: PlatformEnum
  order: number
  changelog: ChangeInterface[]
  tags: string[]
  apk?: string | null
  app?: string
  clusterName?: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class NewestAppver implements NewestAppverInterface {
  isBc: boolean
  updatable: boolean
  version: string
  releasedAt: string
  releasedStores: string[] | null
  platform: PlatformEnum
  order: number
  changelog: Change[]
  tags: string[]
  apk?: string | null
  app?: string
  clusterName?: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: NewestAppverInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      isBc: _isBc,
      updatable: _updatable,
      version: _version,
      releasedAt: _releasedAt,
      releasedStores: _releasedStores,
      platform: _platform,
      order: _order,
      changelog: _changelog,
      tags: _tags,
      apk: _apk,
      app: _app,
      clusterName: _clusterName,
      id: _id,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.isBc = _isBc
    this.updatable = _updatable
    this.version = _version
    this.releasedAt = _releasedAt
    this.releasedStores = _releasedStores
    this.platform = _platform
    this.order = _order
    this.changelog = _changelog
      ? _changelog.map(i => new Change(i))
      : _changelog
    this.tags = _tags
    this.apk = _apk
    this.app = _app
    this.clusterName = _clusterName
    this.id = _id
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'isBc',
    'updatable',
    'version',
    'releasedAt',
    'releasedStores',
    'platform',
    'order',
    'changelog',
    'tags',
    'apk',
    'app',
    'clusterName',
    'id',
    'createdAt',
    'updatedAt',
  ]
}

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'SubGroupList',
    Component: lazy(() =>
      import('./screens/SubGroupList').then(m => ({
        default: m.SubGroupList,
      })),
    ),
    getHeaderTitle: () => $t('子群组'),
  },
  {
    name: 'AttendanceDetail',
    Component: lazy(() =>
      import('./screens/Attendance/Detail').then(m => ({
        default: m.AttendanceDetail,
      })),
    ),
    getHeaderTitle: () => $t('子群组 - 考勤'),
  },
  {
    name: 'SubGroupHome',
    Component: lazy(() =>
      import('./screens/SubGroupHome').then(m => ({
        default: m.SubGroupHome,
      })),
    ),
  },
  {
    name: 'AttendanceInput',
    Component: lazy(() =>
      import('./screens/Attendance/Input').then(m => ({
        default: m.AttendanceInput,
      })),
    ),
    getHeaderTitle: () => $t('录入考勤'),
  },
  {
    name: 'CustomizedGroupsSignin',
    Component: lazy(() =>
      import('./screens/Signin/index').then(m => ({
        default: m.SigninScreen,
      })),
    ),
  },
  {
    name: 'CustomizedGroupsSigninSetting',
    Component: lazy(() =>
      import('./screens/SignInSetting/index').then(m => ({
        default: m.SignInSetting,
      })),
    ),
    getHeaderTitle: () => $t('扫码签到'),
  },
]

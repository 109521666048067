/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { ChannelEnum } from './channel-enum'
import {
  OnlineClassLessonReq,
  OnlineClassLessonReqInterface,
} from './online-class-lesson-req'
import {
  OnlineClassLesson,
  OnlineClassLessonInterface,
} from './online-class-lesson'

const buildConfig = createConfigBuilder('scms')

export const onlineClassApi$batchPatchOnlineLessons = {
  config(
    onlineClassId: number,
    channel: ChannelEnum,
    onlineClassLessonReq: OnlineClassLessonReqInterface[],
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'patch',
      '/online-class/online-classes/{onlineClassId}/lessons/$batch',
      {
        onlineClassId,
        channel,
      },
      null,
      onlineClassLessonReq,
      {
        format: 'json',
        isArray: true,
        defaults: {
          lessonAssistantRid: (v: any) => (v === 0 ? v : v || null),
          seatNum: (v: any) => v || 0,
          record: (v: any) => v || false,
          recordScene: (v: any) => v || false,
          live: (v: any) => v || false,
          replay: (v: any) => v || false,
        },
      },
    )
  },

  /**
   * @summary 批量修改线上课节
   * @param onlineClassId onlineClass 主键id
   * @param channel
   * @param onlineClassLessonReq
   * @param [options]
   * @return AxiosResponsePromise<OnlineClassLesson[]>
   */

  api(
    onlineClassId: number,
    channel: ChannelEnum,
    onlineClassLessonReq: OnlineClassLessonReqInterface[],
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<OnlineClassLesson[]> {
    return axios(
      onlineClassApi$batchPatchOnlineLessons.config(
        onlineClassId,
        channel,
        onlineClassLessonReq,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new OnlineClassLesson(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({
        onlineClassId,
        channel,
        payload,
      }: {
        onlineClassId: number
        channel: ChannelEnum
        payload: OnlineClassLessonReqInterface[]
      }) =>
        onlineClassApi$batchPatchOnlineLessons.api(
          onlineClassId,
          channel,
          payload,
        ),
      mutationOptions,
    )
  },
}

// @ts-ignore
onlineClassApi$batchPatchOnlineLessons.api._name_ = `OnlineClass.batchPatchOnlineLessons`

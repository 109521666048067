/**
 * @file 初始化环境变量
 */

/* eslint-disable no-process-env */

import { isWeb } from '@seiue/rn-util'
import Config from 'react-native-config'

// 和 react 应用一样统一使用 process.env，方便代码复用
Object.assign(process.env, Config)

/**
 * __DEV__ 是 rn 特殊变量，当运行本地 rn 服务时，该值为 true
 * 因为我们会同时编译 web 端，有些 rn 库会依赖该变量，因此我们在初始化时声明该变量
 */
if (isWeb) {
  ;(global as any).__DEV__ = process.env.NODE_ENV === 'development'
}

import React from 'react'

import { EnabledPlugin, PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'

import { LogoRender } from '@go/components/Logo/Render'
import { Menu } from '@go/features/applications/types'

/**
 * 获取约谈菜单
 *
 * @param plugin - 约谈插件
 * @returns 约谈菜单
 */
export const getChatMenu =
  (plugin: EnabledPlugin) => async (): Promise<Menu[] | null> => {
    return [
      {
        key: PluginEnum.Chat,
        pluginName: PluginEnum.Chat,
        icon: ({ size } = {}) => <LogoRender size={size} logo={plugin.icon} />,
        getTitle: () => $t('约谈'),
        screen: {
          name: 'PluginUserChats' as const,
          params: undefined,
        },
      },
    ]
  }

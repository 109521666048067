/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { LoginLink, LoginLinkInterface } from './login-link'
import type { GetClassInLoginLinkQuery } from './get-class-in-login-link-query'

const buildConfig = createConfigBuilder('scms')

export const classinApi$getClassInLoginLink = {
  config(
    reflectionId: number,
    classinCourseId: number,
    classinLessonId: number,
    getClassInLoginLinkQuery?: GetClassInLoginLinkQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/classin/login/links',
      {
        reflectionId,
        classinCourseId,
        classinLessonId,
      },
      getClassInLoginLinkQuery,
    )
  },

  /**
   * @summary 获取可以唤起 classin 客户端的链接
   * @param reflectionId
   * @param classinCourseId
   * @param classinLessonId
   * @param [getClassInLoginLinkQuery]
   * @param [classinQuery.deviceType] 设备类型 默认是 1
   * 1 代表 Windows/Mac OS 端
   * 2 代表 iOS 移动端
   * 3 代表 Android
   * @param [options]
   * @return AxiosResponsePromise<LoginLink>
   */

  api(
    reflectionId: number,
    classinCourseId: number,
    classinLessonId: number,
    getClassInLoginLinkQuery?: GetClassInLoginLinkQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<LoginLink> {
    return axios(
      classinApi$getClassInLoginLink.config(
        reflectionId,
        classinCourseId,
        classinLessonId,
        getClassInLoginLinkQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new LoginLink(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = LoginLink>(
    {
      reflectionId,
      classinCourseId,
      classinLessonId,
      query,
    }: {
      reflectionId: number
      classinCourseId: number
      classinLessonId: number
      query?: GetClassInLoginLinkQuery
    },
    queryOptions?: QueryOptionsWithSelect<LoginLink, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<LoginLink, TSelected>(
      classinApi$getClassInLoginLink.api,
      'Classin.getClassInLoginLink',
      queryOptions,
      reflectionId,
      classinCourseId,
      classinLessonId,
      query,
    )
  },
}

// @ts-ignore
classinApi$getClassInLoginLink.api._name_ = `Classin.getClassInLoginLink`

import {
  createBottomTabNavigator,
  BottomTabBarProps,
} from '@react-navigation/bottom-tabs'
import { Text } from '@seiue/rn-ui'
import { getSafeDistanceToBottom } from '@seiue/rn-util'
import { useAtomValue } from 'jotai'
import React, { FC, useRef, useEffect, useMemo, Suspense } from 'react'
import { Animated, TouchableHighlight, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

import { CounterSubjectEnum } from 'packages/features/counter'
import { useIsLoggedIn } from 'packages/features/sessions/hooks'
import { counterApi$listMyCounters } from 'packages/sdks-next/chalk'

import { Badge } from '@go/components/Badge'
import { ErrorBoundary } from '@go/components/ErrorBoundary'
import { TabRoute } from '@go/router'

import { tabRoutes } from '../tab-routes'

import { ShortcutModal } from './ShortcutModal'
import { showShortcutAtom } from './atoms'

const Tab = createBottomTabNavigator()

/**
 * 底部导航栏
 *
 * @returns component
 */
export const BottomTabsNavigator = () => (
  <Tab.Navigator
    tabBar={(props: BottomTabBarProps) => <MyTabBar {...props} />}
    screenOptions={{ lazy: true }}
  >
    {tabRoutes.map(({ name, Component, getTitle }) => (
      <Tab.Screen
        key={name}
        name={name}
        options={{
          header: () => null,
          title: getTitle(),
        }}
      >
        {props => (
          <ErrorBoundary>
            <Suspense fallback={<></>}>
              <Component {...props} />
            </Suspense>
          </ErrorBoundary>
        )}
      </Tab.Screen>
    ))}
  </Tab.Navigator>
)

/**
 * 自定义标签栏
 *
 * @param props - tab bar props
 * @returns component
 */
const MyTabBar: FC<BottomTabBarProps> = props => {
  const theme = useTheme()

  const { state, descriptors, navigation } = props

  const isLoggedIn = useIsLoggedIn()

  const { data: myCounters } = counterApi$listMyCounters.useApi({
    staleTime: 5, // 缓存一个短时间
    disable: !isLoggedIn,
  })

  const allCount = useMemo(
    () =>
      myCounters?.reduce((result, counter) => {
        if (
          [
            CounterSubjectEnum.PendingTodo,
            CounterSubjectEnum.UnreadMessage,
            CounterSubjectEnum.UnreadNotifications,
          ].find(name => counter.subject.includes(name))
        ) {
          return result + counter.count
        }

        return result
      }, 0),
    [myCounters],
  )

  // 是否显示快速创建入口
  const showShortcut = useAtomValue(showShortcutAtom)

  // 快速创建弹窗
  const [shortcutModalVisible, setShortcutModalVisible] = React.useState(false)

  const filteredRoutes = React.useMemo(() => {
    return showShortcut
      ? state.routes
      : state.routes.filter(route => route.name !== 'Shortcut')
  }, [state.routes, showShortcut])

  return (
    <TabBarWrapper>
      {filteredRoutes.map(route => {
        const tabRouteIndex = tabRoutes.findIndex(tr => tr.name === route.name)
        const tabRoute = tabRoutes[tabRouteIndex]
        if (!tabRoute) return null

        const { options } = descriptors[route.key]

        const { getTitle, name, Icon: TabIcon } = tabRoute

        const isFocused = state.index === tabRouteIndex

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          })

          if (!isFocused && !event.defaultPrevented) {
            // The `merge: true` option makes sure that the params inside the tab screen are preserved
            navigation.navigate({ name: route.name, merge: true, params: {} })
          }
        }

        const tabBarProps = {
          key: route.key,
          accessibilityRole: 'button' as const,
          accessibilityState: isFocused ? { selected: true } : {},
          accessibilityLabel: options.tabBarAccessibilityLabel,
          testID: options.tabBarTestID,
        }

        return name === 'Shortcut' ? (
          // 快速发起按钮
          <View {...tabBarProps} style={{ flex: 1, alignItems: 'center' }}>
            <ShortcutButton
              underlayColor={theme.brand.dark1}
              onPress={() => setShortcutModalVisible(true)}
            >
              <TabIcon focused={isFocused} />
            </ShortcutButton>
            {/* 快速发起弹窗 */}
            <ShortcutModal
              visible={shortcutModalVisible}
              onDismiss={() => setShortcutModalVisible(false)}
            />
          </View>
        ) : (
          <TabBarButton {...tabBarProps} activeOpacity={1} onPress={onPress}>
            {/* 首页消息数量 */}
            {name === 'Home' && allCount ? (
              <StyledBadge count={allCount} fontSize={12} />
            ) : null}

            <AnimatedImage focused={isFocused} TabIcon={TabIcon} />
            <TabBarLabel focused={isFocused}>{getTitle()}</TabBarLabel>
          </TabBarButton>
        )
      })}
    </TabBarWrapper>
  )
}

/**
 * 标签栏图标
 *
 * @param props - props
 * @param props.focused - 是否选中
 * @param props.TabIcon - 图标
 * @returns component
 */
const AnimatedImage: FC<{
  focused: boolean
  TabIcon: TabRoute['Icon']
}> = ({ focused, TabIcon }) => {
  const scaleValue = useRef(new Animated.Value(1)).current

  useEffect(() => {
    if (focused) {
      Animated.sequence([
        Animated.timing(scaleValue, {
          toValue: 0.84,
          duration: 100,
          useNativeDriver: true,
        }),
        Animated.timing(scaleValue, {
          toValue: 1,
          duration: 100,
          useNativeDriver: true,
        }),
      ]).start()
    }
  }, [focused, scaleValue])

  return !TabIcon ? null : (
    <Animated.View
      style={[
        {
          transform: [
            {
              scale: scaleValue,
            },
          ],
        },
      ]}
    >
      <TabIcon focused={focused} />
    </Animated.View>
  )
}

const TabBarWrapper = styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.background._0};
  border-top-width: 0.5px;
  border-top-color: ${props => props.theme.border.greyLight};
  padding-bottom: ${getSafeDistanceToBottom()}px;
`

const TabBarButton = styled.TouchableOpacity`
  flex: 1;
  height: 54px;
  align-items: center;
  justify-content: center;
  position: relative;
`

const StyledBadge = styled(Badge)`
  left: 50%;
  right: auto;
  z-index: 1;
  height: 18px;
  min-width: 18px;
  border-radius: 9px;
`

const TabBarLabel = styled(Text)<{ focused: boolean }>`
  margin-top: 4px;
  color: ${props =>
    props.focused ? props.theme.text.brand : props.theme.text._1};
  font-size: 10px;
  font-weight: 400;
`

const ShortcutButton = styled(TouchableHighlight)`
  width: 42px;
  height: 42px;
  background-color: ${p => p.theme.brand._1};
  border-radius: 30px;
  align-items: center;
  justify-content: center;
`

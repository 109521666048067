/**
 * @file 初始化 userAgent（如需要）
 */
;(function anoy() {
  if (!window.navigator.userAgent) {
    // @ts-expect-error 在某些测试环境下，userAgent 可能不存在，所以在此处复写一个默认值
    window.navigator.userAgent = 'react-native'
  }
})()

export {}

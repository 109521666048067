import { initAccounts } from './accounts'
import { initAdminClass } from './adminclass'
import { initApplications } from './applications'
import { initAttendances } from './attendances'
import { initCalendar } from './calendar'
import { initClasses } from './classes'
import { initEvaluations } from './evaluations'
import { initExams } from './exams'
import { initGoals } from './goals'
import { initGrades } from './grades'
import { initGzAcademicCredit } from './gz-academic-credit'
import { initHome } from './home'
import { initMessages } from './messages'
import { initNoCode } from './no-code'
import { initNotices } from './notices'
import { initPlugins } from './plugins'
import { initSchedule } from './schedules'
import { initSchoolCalendars } from './school-calendars'
import { initSemesterReview } from './semester-review'
import { initSessions } from './sessions'
import { initShares } from './shares'
import { initSubjectSelection } from './subject-selection'
import { initSzHomeworkSurvey } from './sz-homework-survey'
import { initTasks } from './tasks'
import { initTodos } from './todos'
import { InitFeatureParams } from './types'
import { initVenues } from './venues'
import { initWorkflows } from './workflows'

/**
 * 初始化基础模块
 *
 * @param params - 各种初始化方法
 */
export const initFeatures = (params: InitFeatureParams) => {
  initAttendances(params)
  initAdminClass(params)
  initEvaluations(params)
  initGoals(params)
  initHome(params)
  initCalendar(params)
  initMessages(params)
  initNotices(params)
  initPlugins(params)
  initSchedule(params)
  initSchoolCalendars(params)
  initSessions(params)
  initSubjectSelection(params)
  initTasks(params)
  initNoCode(params)
  initTodos(params)
  initClasses(params)
  initAccounts(params)
  initGrades(params)
  initVenues(params)
  initShares(params)
  initWorkflows(params)
  initExams(params)
  initSzHomeworkSurvey(params)
  initGzAcademicCredit(params)
  initApplications(params)
  initSemesterReview(params)
}

/**
 * @file 注册审批渲染器
 */
import React from 'react'

import { RegisterWorkflowRenderType } from 'packages/features/workflows/register'

export const workflowRenders: RegisterWorkflowRenderType = {
  bizType: 'approval',
  render: {
    FlowCard: React.lazy(() =>
      import('@go/features/approval').then(m => ({
        default: m.ApprovalFlowCard,
      })),
    ),
    NewFlowModal: React.lazy(() =>
      import('@go/features/approval').then(m => ({
        default: m.NewApprovalModal,
      })),
    ),
    ShowFlowModal: React.lazy(() =>
      import('@go/features/approval').then(m => ({
        default: m.ShowApprovalModal,
      })),
    ),
    EditFlowModal: React.lazy(() =>
      import('@go/features/approval').then(m => ({
        default: m.EditApprovalModal,
      })),
    ),
  },
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import type { DeleteFileQuery } from './delete-file-query'

const buildConfig = createConfigBuilder('chalk')

export const netdiskApi$deleteFile = {
  config(
    id: number,
    deleteFileQuery?: DeleteFileQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'delete',
      '/netdisk/files/{id}',
      {
        id,
      },
      deleteFileQuery,
    )
  },

  /**
   * @summary 删除文件
   * @param id
   * @param [deleteFileQuery]
   * @param [netdiskQuery.byAdmin] 管理员删除文件
   * @param [netdiskQuery.force] 强制删除（从回收站中删除）
   * @param [options]
   * @return AxiosResponsePromise<null>
   */

  api(
    id: number,
    deleteFileQuery?: DeleteFileQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<null> {
    return axios(
      netdiskApi$deleteFile.config(id, deleteFileQuery, options),
    ) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({ id, query }: { id: number; query?: DeleteFileQuery }) =>
        netdiskApi$deleteFile.api(id, query),
      mutationOptions,
    )
  },
}

// @ts-ignore
netdiskApi$deleteFile.api._name_ = `Netdisk.deleteFile`

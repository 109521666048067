/**
 * @file 通知待办
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

const NoticeDiscussionTodoCard = lazy(() =>
  import('./NoticeDiscussionTodoCard').then(m => ({
    default: m.NoticeDiscussionTodoCard,
  })),
)

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.NotificationDiscussionReplied,
    Render: NoticeDiscussionTodoCard,
  },
]

/**
 * @file main App component
 */

import { LinkingOptions, NavigationContainer } from '@react-navigation/native'
import { NavigationState } from '@react-navigation/routers'
import { getPersistor } from '@rematch/persist'
import { isAxiosError } from '@seiue/axios'
import { isWeb } from '@seiue/rn-util'
import { reportToSentry } from '@seiue/sentry'
import { createDeferred, isReactNative, useOnMount } from '@seiue/util'
import { useAtomValue } from 'jotai'
import React, { useEffect } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Alert, StatusBar } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { patchFlatListProps } from 'react-native-web-refresh-control'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import {
  ModuleEnum,
  useHasEnabledParentPlugin,
} from 'packages/feature-utils/plugins'
import { registerCalendarEvents } from 'packages/features/calendars/utils/register'
import { useDestorySessionWhenTimeout } from 'packages/features/sessions/hooks'
import {
  getTencentAuthParamsFromUrl,
  getTokenAuthParamsFromUrl,
} from 'packages/features/sessions/utils/data'
import { isPublicShareURL } from 'packages/features/shares/utils'
import { registerTodos } from 'packages/features/todos/register'
import { registerWorkflowRenders } from 'packages/features/workflows/register'
import { $t, LocaleProvider } from 'packages/locale/LocaleProvider'
import { useLocaleInited } from 'packages/locale/hooks'
import { PASSPORT_SETUP_FAILED } from 'packages/passport'

import { PushProcessor } from '@go/components/globals/PushProcessor'
import { VersionUpdatesChecker } from '@go/components/globals/VersionUpdatesChecker'
import { initFeatures } from '@go/features'
import { registerMessageNavigator } from '@go/features/messages/utils'
import { userAgreementCheckedAtom } from '@go/features/sessions/screens/Login/atoms'
import { initPlugins } from '@go/plugins'
import { addRoutes, linking, MainStackParams } from '@go/router'
import { useShouldShowShortcut } from '@go/router/BottomTabsNavigator/hooks'
import { RootNavigator } from '@go/router/RootNavigator'
import { store, useDispatch, useSelector } from '@go/stores'
import { isMetaError, isNetworkError } from '@go/utils/error-handler'
import { useInitSeiueFile } from '@go/utils/file'
import {
  getActiveRouteName,
  navigate,
  navigationRef,
  routeQueue,
  setIsNavigationReady,
} from '@go/utils/navigator'

import { ConfigProvider } from './ConfigProvider'
import { Portal } from './Portal'
import { TrackingProvider } from './TrackingProvider'
import { useRegisterErrorHandlers } from './error-handler'
import { usePluginRegister } from './hooks'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const global: { HermesInternal: null | {} }

// patchFlatListProps is a function that you'll want to call at some point, while loading your app. It replaces the default value of the refreshControl prop of FlatList
patchFlatListProps()

// 注册插件的一些静态信息
initPlugins({
  registerTodos,
  registerMessageNavigator,
  registerCalendarEvents,
  registerWorkflowRenders,
})

initFeatures({
  addRoutes,
  registerTodos,
  registerMessageNavigator,
  registerCalendarEvents,
  registerWorkflowRenders,
})

/**
 * Main App component
 *
 * @param props - props
 * @param props.pushCustomContent - 推送的自定义参数
 * @returns Main App component
 */
const AppInner: React.FC<{
  pushCustomContent?: string
}> = ({ pushCustomContent }) => {
  const dispatch = useDispatch()
  const sessionStatus = useSelector(state => state.session.state)

  const userAgreementChecked = useAtomValue(userAgreementCheckedAtom)

  const isPublicShare = isPublicShareURL()
  const localeInited = useLocaleInited()

  // 注册全局错误处理
  useRegisterErrorHandlers()

  const sessionDone = sessionStatus !== 'pending'
  const sessionCreated = sessionStatus === 'created'
  const sessionFailed = sessionStatus === 'none'

  const pluginsRegistered = usePluginRegister(sessionCreated)

  useDestorySessionWhenTimeout(sessionDone, () => {
    // 使用基本的提示 API，因为整个 App 尚未初始化完成。
    if (isReactNative) {
      const { promise, resolve } = createDeferred<boolean>()

      Alert.alert(
        // eslint-disable-next-line seiue/missing-formatted-message
        '登录超时，请重新登录',
        '',
        [
          {
            // eslint-disable-next-line seiue/missing-formatted-message
            text: '好的',
            onPress: () => {
              resolve(true)
            },
          },
        ],
      )

      return promise
    }

    // eslint-disable-next-line seiue/missing-formatted-message,no-alert
    alert('登录超时，请重新登录')

    return true
  })

  useOnMount(() => {
    // 支持用 url token、腾讯校园或 passport 登录
    const tencentAuthParams = isWeb ? getTencentAuthParamsFromUrl() : null
    const tokenAuthParams = isWeb ? getTokenAuthParamsFromUrl() : null

    dispatch.session
      .init(tencentAuthParams ?? tokenAuthParams ?? undefined)
      .catch(async (err: any) => {
        if (isMetaError(err)) throw err

        /**
         * 这儿的错误会导致用户登陆不进去,
         * 所以非 401 错误和腾讯登录失败 (除非是用户自身网络问题) 都要上报 (腾讯登录理论上不会失败)
         */
        if (
          (!isAxiosError(err, 401) || tencentAuthParams) &&
          !isNetworkError(err) &&
          err !== PASSPORT_SETUP_FAILED
        ) {
          reportToSentry(err, {
            ExceptionType: 'SessionCreationFailure',
          })
        }

        if (tencentAuthParams) {
          // eslint-disable-next-line
          window.alert(
            // eslint-disable-next-line
            `登录失败，请退出重试${err?.message ? `（${err.message}）` : ''}`,
          )
        } else if (!isPublicShare) {
          await dispatch.session.destroy({
            setPassportRedirect: true,
          })
        }
      })
  })

  // Go web 判断移除初始化 loader 逻辑
  useEffect(() => {
    if ((sessionCreated || isPublicShare) && isWeb && localeInited) {
      const loader = document.getElementById('app-loader')
      if (loader) {
        loader.style.opacity = '0'

        // 透明度 css 动画持续 300ms，在用户操作前移除元素
        setTimeout(() => loader.remove(), 360)
      }
    }
  }, [sessionCreated, localeInited, isPublicShare])

  const statusBarStyle = useSelector(state => state.app.statusBarStyle)

  const [activeRouteName, setActiveRouteName] = React.useState('')

  const onStateChange = (state?: NavigationState) => {
    const activeName = getActiveRouteName(state)
    setActiveRouteName(activeName)
  }

  React.useEffect(
    () => () => {
      setIsNavigationReady(false)
    },
    [],
  )

  /**
   * NavigationContainer 挂载完成事件
   */
  const onReady = () => {
    setIsNavigationReady(true)
    setTimeout(() => {
      // 检查队列里是否有未跳转的路由
      if (routeQueue.length) {
        navigate(...routeQueue[0])
      }
      // FIXME 大延迟以等待路由注册完毕
    }, 500)
  }

  useInitSeiueFile()

  const i18nEnabled = useHasEnabledParentPlugin(ModuleEnum.I18n)

  useEffect(() => {
    /*
     * 当 session 完成时，重新初始化一次多语言
     * 因为 GO 的特殊情况，Locale 首次 init 时，session 永远处于未确定的状态，所以那次的初始化无法加载正确的数据
     */
    if (sessionDone) {
      dispatch.locale.inited(i18nEnabled)
    }
  }, [dispatch.locale, i18nEnabled, sessionDone])

  // 判断是否显示底部导航栏的「快速创建」入口
  const { loading: loadingShortcut } = useShouldShowShortcut(sessionCreated)

  const rootApp = (
    <TrackingProvider>
      <SafeAreaProvider>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <Portal
            loading={
              // 正在初始化
              !activeRouteName ||
              // 登录成功，但插件尚未加载完毕
              (sessionCreated && !pluginsRegistered) ||
              // 正在进入过渡页面
              ['ReflectionTransferStation'].includes(activeRouteName) ||
              // 正在判断是否应该显示「快速创建」入口
              loadingShortcut
            }
          >
            {isPublicShare ||
            sessionFailed ||
            activeRouteName === 'LoginScreen' ||
            pluginsRegistered ? (
              <>
                <StatusBar
                  barStyle={statusBarStyle}
                  translucent={true}
                  backgroundColor="transparent"
                />
                {
                  /*
                   * 登录成功或同意了隐私政策，再检测版本
                   * 1. 防止用户在未同意隐私策略的情况下，进行设备信息的获取
                   * 2. 统一交互，除隐私策略以外的弹窗型内容登录后展示
                   */
                  sessionCreated || userAgreementChecked ? (
                    <VersionUpdatesChecker />
                  ) : null
                }

                {/* 推送点击处理器 */}
                <PushProcessor pushCustomContent={pushCustomContent} />

                <NavigationContainer
                  linking={linking as LinkingOptions<MainStackParams>}
                  ref={navigationRef}
                  onReady={onReady}
                  onStateChange={onStateChange}
                  documentTitle={{
                    formatter: options => {
                      const title = options?.['title'] || $t('希悦校园')

                      /**
                       * @hack ReactNavigation 存在一个 Bug，当从某个页面离开时，标题仍然会被设置为上一个页面的标题
                       * 所以这里强制刷新标题
                       */
                      if (isWeb) {
                        requestAnimationFrame(() => {
                          document.title = ''
                          document.title = title
                        })
                      }

                      return title
                    },
                  }}
                >
                  <RootNavigator />
                </NavigationContainer>
              </>
            ) : null}
          </Portal>
        </GestureHandlerRootView>
      </SafeAreaProvider>
    </TrackingProvider>
  )

  return <ConfigProvider>{rootApp}</ConfigProvider>
}

const queryClient = new QueryClient()

/**
 * App 入口
 *
 * @param props - props
 * @param props.pushCustomContent - 推送的自定义参数
 * @returns component
 */
export const App: React.FC<{
  pushCustomContent?: string
}> = ({ pushCustomContent }) => {
  return (
    <ReduxProvider store={store}>
      <PersistGate persistor={getPersistor()}>
        <QueryClientProvider client={queryClient}>
          {/* 始终为 true，因为 GO 的结构需要子组件渲染才能完成 App 的初始化 */}
          <LocaleProvider featuresInited={true}>
            <AppInner pushCustomContent={pushCustomContent} />
          </LocaleProvider>
        </QueryClientProvider>
      </PersistGate>
    </ReduxProvider>
  )
}

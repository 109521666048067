/**
 * @file 版本检查更新 store
 */

import { AppState } from '@go/stores/app'

interface State {
  // 版本更新弹窗上次出现的时间戳
  lastTimestamp: number
}

const state: State = {
  lastTimestamp: 0,
}

const reducers = {
  setLastTimestamp(appState: AppState, lastTimestamp: number) {
    return { ...appState, lastTimestamp }
  },
}

export const versionUpdates = {
  state,
  reducers,
}

/**
 * @file 心理档案待办
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.PluginPsyStudentSubmitAssessment,
    Render: lazy(() =>
      import('./StudentSubmitAssessment').then(m => ({
        default: m.StudentSubmitAssessment,
      })),
    ),
  },
  {
    type: TodoTypeEnum.PsycoProblemReport,
    Render: lazy(() =>
      import('./ProblemReportTodo').then(m => ({
        default: m.ProblemReportTodo,
      })),
    ),
  },
  {
    type: TodoTypeEnum.PluginPsyChatSubmitRecord,
    Render: lazy(() =>
      import('./SubmitPsyChatRecordTodo').then(m => ({
        default: m.SubmitPsyChatRecordTodo,
      })),
    ),
  },
]

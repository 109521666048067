/**
 * @file 学生心理档案
 */

import { InitPluginFeature } from '@go/plugins/types'

import { calendarEvents } from './calendar-events'
import { todos } from './todos'
import { workflowRenders } from './workflow-renders'

export const psychologicalFile: InitPluginFeature = {
  name: 'psychological-file',
  calendarEvents,
  todos,
  workflowRenders,
}

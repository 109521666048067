import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'ShareEventDetails',
    path: 'share/:schoolId/events/:pid/info/:eid',
    getTitle: () => $t('活动详情'),
    isShare: true,
    Component: lazy(() =>
      import('./screens/Events/ShareEventInfo').then(m => ({
        default: m.ShareEventDetails,
      })),
    ),
  },
  {
    name: 'ShareEventInvitation',
    path: 'share/:schoolId/event-invited/:code',
    getTitle: () => $t('活动邀请'),
    isShare: true,
    Component: lazy(() =>
      import('./screens/Events/ShareEventInvitation').then(m => ({
        default: m.EventInvitation,
      })),
    ),
  },
  {
    name: 'EventExcellentRecordsList',
    path: 'share/:schoolId/events/:pid/info/:eid/excellent-details',
    isShare: true,
    Component: lazy(() =>
      import('./screens/Events/ExcellentRecordsList').then(m => ({
        default: m.ExcellentRecordsList,
      })),
    ),
  },
  {
    name: 'EventExcellentRecordDetails',
    isShare: true,
    Component: lazy(() =>
      import('./screens/Events/ExcellentRecordDetails').then(m => ({
        default: m.ExcellentRecordDetails,
      })),
    ),
  },
  {
    name: 'PublicShareQuestionnaire',
    getTitle: () => $t('问卷填写'),
    path: 'share/questionnaire/:qid',
    isShare: true,
    Component: lazy(() =>
      import('./screens/questionnaire/FillQuestionnaire').then(m => ({
        default: m.PublicQuestionnaireShare,
      })),
    ),
  },
  {
    name: 'SytemShareQuestionnaire',
    getTitle: () => $t('问卷填写'),
    path: 'share/ra/:schoolId/questionnaire/:qid',
    isShare: true,
    Component: lazy(() =>
      import('./screens/questionnaire/FillQuestionnaire').then(m => ({
        default: m.SystemQuestionnaireShare,
      })),
    ),
  },
  {
    name: 'ShareVisitor',
    getTitle: () => $t('校外访客'),
    getHeaderTitle: () => $t('校外访客'),
    path: 'share/visitors/:identityId',
    isShare: true,
    Component: lazy(() =>
      import('../../plugins/features/eems/screens/ShareVisitor').then(m => ({
        default: m.ShareVisitor,
      })),
    ),
  },
  {
    name: 'GzzxAnniversary',
    getTitle: () => $t('广州中学校友会'),
    getHeaderTitle: () => $t('广州中学校友会'),
    isShare: true,
    Component: lazy(() =>
      import('./screens/questionnaire/gzzx-anniversary/GzzxAnniversary').then(
        m => ({
          default: m.GzzxAnniversary,
        }),
      ),
    ),
  },
  {
    name: 'ShareNotification',
    getTitle: () => $t('通知详情'),
    getHeaderTitle: () => $t('通知详情'),
    path: 'share/ra/:schoolId/ntfs/:notificationId',
    isShare: true,
    Component: lazy(() =>
      import('./screens/Notification').then(m => ({
        default: m.Notification,
      })),
    ),
  },
  {
    name: 'Plugin.VisitorCenter.FillInvitation',
    getHeaderTitle: () => $t('访客信息填报'),
    getTitle: () => $t('访客信息填报'),
    path: 'share/visitor-center/fill-invitation/:hash',
    isShare: true,
    Component: lazy(() =>
      import('./screens/VisitorCenter/pages/FillInvitation').then(m => ({
        default: m.FillInvitation,
      })),
    ),
  },
  {
    name: 'Plugin.VisitorCenter.SubmitFeedback',
    getHeaderTitle: () => $t('访客信息填报'),
    getTitle: () => $t('访客信息填报'),
    path: 'share/visitor-center/submit-feedback/:hash',
    isShare: true,
    Component: lazy(() =>
      import('./screens/VisitorCenter/pages/SubmitFeedback').then(m => ({
        default: m.SubmitFeedback,
      })),
    ),
  },
  {
    name: 'Plugin.Certifications.GrantByCodeDemo',
    getHeaderTitle: () => $t('扫码授予'),
    getTitle: () => $t('扫码授予'),
    path: 'share/cd/c/:certId',
    isShare: true,
    Component: lazy(() =>
      import('./screens/certifications/screens/GrantByCodeDemo').then(m => ({
        default: m.GrantByCodeDemo,
      })),
    ),
  },
  {
    name: 'Plugin.Certifications.WallDemo',
    getHeaderTitle: () => $t('徽章墙'),
    getTitle: () => $t('徽章墙'),
    path: 'share/cd/wall',
    isShare: true,
    Component: lazy(() =>
      import('./screens/certifications/screens/WallDemo').then(m => ({
        default: m.WallDemo,
      })),
    ),
  },
  {
    name: 'Plugin.Certifications.ApplicationLogsDemo',
    getHeaderTitle: () => $t('全部获得记录'),
    getTitle: () => $t('全部获得记录'),
    path: 'share/cd/application-logs',
    isShare: true,
    Component: lazy(() =>
      import('./screens/certifications/screens/ApplicationLogsDemo').then(
        m => ({
          default: m.ApplicationLogsDemo,
        }),
      ),
    ),
  },
  {
    name: 'Plugin.Certifications.CertifiedDemo',
    getHeaderTitle: () => $t('获得详情'),
    getTitle: () => $t('获得详情'),
    path: 'share/cd/certified/:certId',
    isShare: true,
    Component: lazy(() =>
      import('./screens/certifications/screens/CertifiedDemo').then(m => ({
        default: m.CertifiedDemo,
      })),
    ),
  },
]

import { PluginEnum } from 'packages/feature-utils/plugins'

import { InitPluginFeature } from '@go/plugins/types'

import { routes } from './routes'

export const visitorCenter: InitPluginFeature = {
  name: PluginEnum.VisitorCenter,
  register: () => ({
    routes,
  }),
}

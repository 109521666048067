/**
 * @file 学期评语待办
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos'

export const semesterReviewTodos: TodoExecution[] = [
  {
    type: TodoTypeEnum.SemesterReviewSubmit,
    Render: lazy(() =>
      import('./ReviewSubmit').then(m => ({
        default: m.ReviewSubmit,
      })),
    ),
  },
]

import { File } from '@seiue/rn-icons'
import React from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'

import { LogoRender } from '@go/components/Logo/Render'
import { Menu } from '@go/features/applications/types'

/**
 * 阅卷 menu
 *
 * @returns Menu
 */
export const getOnlineScoringMenu = () => async (): Promise<Menu[] | null> => {
  return [
    {
      key: PluginEnum.OnlineScoring,
      pluginName: PluginEnum.OnlineScoring,
      icon: ({ size } = {}) => (
        <LogoRender
          size={size}
          logo={'File+17A0DE'}
          icons={[
            {
              name: 'File',
              icon: File,
            },
          ]}
        />
      ),
      getTitle: () => $t('阅卷'),
      screen: {
        name: 'Plugin.OnlineScoring.ExamList' as const,
        params: undefined,
      },
    },
  ]
}

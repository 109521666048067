import { MoralPolicyEnum } from 'packages/sdks-next/vnas'

export type MoralAssessmentsScreenParams = {
  'Plugin.MoralAssessments'?: {
    defaultTabKey?: CenterTabKeyEnum
  }
  'Plugin.SearchMoralAssessments': {
    policy: CenterTabKeyEnum.Evaluated | CenterTabKeyEnum.TypeIn
  }
  'Plugin.MoralAssessmentEntryStructures': {
    id: number
    from: CenterTabKeyEnum.Evaluated | CenterTabKeyEnum.TypeIn
  }
  'Plugin.MultiEvaluateTargetGradeEntry': {
    id: number
    itemId?: number
    from: CenterTabKeyEnum.Evaluated | CenterTabKeyEnum.TypeIn
  }
  'Plugin.SingleEvaluateTargetGradeEntry': {
    id: number
  }
  'Plugin.MoralAssessment.AnalysisStructures': {
    id: number
  }
  'Plugin.MoralAssessment.Analysis': {
    id: number
    rid: number
    itemId?: number
    showName?: string
  }
  'Plugin.SearchMoralTrends': {}
  'Plugin.MoralAssessmentShortcutEntry': {
    /**
     * 评价 id
     */
    id: number
    /**
     * 评价项 id
     */
    itemId: number
  }
}

export enum CenterTabKeyEnum {
  /**
   * 参与录入
   */
  TypeIn = MoralPolicyEnum.Evaluator,
  /**
   * 我的评价
   */
  Evaluated = MoralPolicyEnum.Evaluated,
  /**
   * 动态
   */
  Trends = 'trends',
}

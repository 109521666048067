/**
 * @file 网盘下载池 - Web 实现，实际上由空数据和空方法填满，无用。
 */
import { noop } from '@seiue/util'

import { Reducers, State, Effects, NetdiskDownloadPollModel } from './types'

const state: State = {
  files: [],
}

const reducers: Reducers = {
  setFiles(prevState) {
    return prevState
  },

  addFile(prevState) {
    return prevState
  },

  changeFile(prevState) {
    return prevState
  },

  removeFile(prevState) {
    return prevState
  },
}

const effects: Effects = () => ({
  async init() {
    return noop()
  },

  async download() {
    return noop()
  },
})

export const netdiskDownloadPoll: NetdiskDownloadPollModel = {
  state,
  reducers,
  effects,
}

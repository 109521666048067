import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'Plugin.OnlineScoring.ExamList',
    getHeaderTitle: () => $t('阅卷'),
    Component: lazy(() =>
      import('./screens/ExamList').then(m => ({
        default: m.ExamList,
      })),
    ),
  },
  {
    name: 'Plugin.OnlineScoring.TeacherReview',
    getHeaderTitle: () => $t('阅卷'),
    Component: lazy(() =>
      import('./screens/TeacherReview').then(m => ({
        default: m.TeacherReview,
      })),
    ),
  },
]

/**
 * @file 网盘上传池 Store
 */
import { Toast } from '@seiue/rn-ui'
import { isEqual, isReactNative, omit, throttle } from '@seiue/util'

import { $t } from 'packages/locale'
import {
  Folder,
  UploadFile,
  upload,
  convertFolderToKey,
} from 'packages/plugins/features/net-disk'
import {
  getFolderPath,
  getNetdiskFileTypeFromRawFile,
} from 'packages/plugins/features/net-disk/utils'
import { netdiskApi$ensureDirs } from 'packages/sdks-next/chalk'

import { FileRaw, getFileHash, Status, seiueFiles } from '@go/utils/file'

import { AddFileUploadedListener, RemoveFileUploadedListener } from './types'

// 自增的临时记录 ID
let _fileKey = 0

// 上传计数
let _uploadingCount = 0

// 最大同时上传数量
const MAX_UPLOADING_COUNT = 5

// 等待上传队列
let _watingUploadQueue: UploadFile[] = []

// 上传后的监听器
let _uploadedListener: { [key: string]: Function[] } = {}

/**
 * 添加文件到上传池的监听器
 *
 * @param folder - 文件夹
 * @param callback - 回调函数
 */
export const addFileUploadedListener: AddFileUploadedListener = (
  folder,
  callback,
) => {
  const key = convertFolderToKey(folder)

  if (!_uploadedListener[key]) {
    _uploadedListener[key] = []
  }

  _uploadedListener[key].push(
    throttle(callback, 1000, { leading: false, trailing: true }),
  )
}

/**
 * 移除文件上传后的监听器
 *
 * @param folder - 文件夹
 */
export const removeFileUploadedListener: RemoveFileUploadedListener =
  folder => {
    if (folder) {
      const key = convertFolderToKey(folder)

      _uploadedListener[key] = []
    } else {
      _uploadedListener = {}
    }
  }

const triggerFileUploadedListener = (folder: Folder) => {
  // 不仅触发文件夹本身的刷新，父文件夹也需要刷新
  const key = convertFolderToKey(folder)
  const listenerKeys = Object.keys(_uploadedListener)

  listenerKeys.forEach(_key => {
    if (key.includes(_key)) {
      _uploadedListener[_key]?.forEach(callback => {
        callback()
      })
    }
  })
}

interface State {
  files: UploadFile[]
}

const state: State = {
  files: [],
}

const reducers = {
  addFile(prevState: State, file: UploadFile) {
    return {
      ...prevState,
      files: [file, ...prevState.files],
    }
  },

  changeFile(
    prevState: State,
    fileData: Partial<UploadFile> & { key: number },
  ) {
    const nextFiles = [...prevState.files]

    const targetFileIndex = nextFiles.findIndex(
      ({ key: _key }) => _key === fileData.key,
    )

    if (targetFileIndex >= 0) {
      const targetFile = nextFiles[targetFileIndex]
      const nextFile = {
        ...targetFile,
        ...fileData,
      }

      nextFiles[targetFileIndex] = nextFile

      if (nextFile.status === Status.Uploaded) {
        triggerFileUploadedListener(nextFile.folder)
      }

      if (
        fileData.status &&
        [Status.Uploaded, Status.Fail].includes(fileData.status)
      ) {
        _uploadingCount -= 1

        // 如果有在等待上传的文件
        const nextInitFile = _watingUploadQueue.pop()

        nextInitFile?.upload()
      }
    }

    return {
      ...prevState,
      files: nextFiles,
    }
  },

  removeFile(prevState: State, key: number) {
    const nextFiles = [...prevState.files]
    const targetFileIndex = nextFiles.findIndex(({ key: _key }) => _key === key)

    if (targetFileIndex >= 0) {
      const targetFile = nextFiles[targetFileIndex]

      targetFile.cancelUpload?.()

      if (targetFile.status === Status.Uploading) {
        _uploadingCount -= 1
      }

      nextFiles.splice(targetFileIndex, 1)
    }

    _watingUploadQueue = _watingUploadQueue.filter(file => file.key !== key)

    return {
      ...prevState,
      files: nextFiles,
    }
  },

  clearFiles(prevState: State) {
    prevState.files.forEach(file => file.cancelUpload?.())

    return {
      ...prevState,
      files: [],
    }
  },
}

const effects = (dispatch: any) => ({
  async appendFile(
    {
      file: rawFile,
      folder: curFolder,
      fileRelativePath,
    }: {
      file: FileRaw
      folder: Folder
      fileRelativePath?: string
    },
    {
      netdiskUploadPoll,
    }: {
      netdiskUploadPoll: State
    },
  ) {
    const newFileHash = await getFileHash(rawFile)

    if (
      // 如果上传栈里没有一样的文件
      !netdiskUploadPoll.files.find(
        file =>
          file.hash === newFileHash &&
          isEqual(file.folder, curFolder) &&
          file.name === rawFile.name,
      )
    ) {
      const newFileKey = (_fileKey += 1)
      let folder = curFolder

      // 如果能获取到文件的路径，那么去创建对应的文件夹
      if (fileRelativePath) {
        const folderPath = fileRelativePath.replace(`/${rawFile.name}`, '')

        const folderNames = folderPath.split('/')

        let _path = curFolder.path
        const ensureDirsData = folderNames.map(folderName => {
          const data = {
            name: folderName,
            path: `${_path}`,
          }

          if (_path === '/') {
            _path += folderName
          } else {
            _path += `/${folderName}`
          }

          return data
        })

        const {
          data: [newFolder],
        } = await netdiskApi$ensureDirs.api(
          curFolder.netdiskOwnerId,
          ensureDirsData,
        )

        folder = {
          id: newFolder.id,
          path: getFolderPath({ path: newFolder.path, name: newFolder.name }),
          netdiskOwnerId: newFolder.netdiskOwnerId,
        }
      }

      const newFile: UploadFile = {
        key: newFileKey,
        name: rawFile.name,
        hash: newFileHash,
        rawFile,
        type: getNetdiskFileTypeFromRawFile(rawFile),
        progress: 0,
        status: Status.Init,
        folder,
        upload: async () => {
          if (_uploadingCount >= MAX_UPLOADING_COUNT) {
            return
          }

          _uploadingCount += 1

          if (isReactNative) {
            const file = seiueFiles.uploaderFactory(rawFile, props => {
              dispatch.netdiskUploadPoll.changeFile({
                key: newFileKey,
                ...omit(props, ['cancel']),
                cancelUpload: props.cancel,
              })
            })

            file.upload?.({
              netdisk: {
                ownerId: curFolder.netdiskOwnerId,
                folderId: curFolder.id,
                folderPath: curFolder.path,
              },
            })
          } else {
            await upload({
              key: newFileKey,
              rawFile,
              hash: newFileHash,
              folder,
              changeFile: dispatch.netdiskUploadPoll.changeFile,
            })
          }
        },
        cancel: () => {
          dispatch.netdiskUploadPoll.removeFile(newFileKey)
        },
      }

      dispatch.netdiskUploadPoll.addFile(newFile)

      // 如果上传可以触发，则立刻触发
      if (_uploadingCount < MAX_UPLOADING_COUNT) {
        await newFile.upload()
      } else {
        _watingUploadQueue.push(newFile)
      }
    } else {
      Toast.show(
        $t('「{name}」文件已存在', {
          name: rawFile.name,
        }),
      )
    }
  },

  reset() {
    _fileKey = 0
    _uploadingCount = 0
    _watingUploadQueue = []

    dispatch.netdiskUploadPoll.clearFiles()
  },
})

export const netdiskUploadPoll = {
  state,
  reducers,
  effects,
}

export type NetdiskUploadPollModel = typeof netdiskUploadPoll

/**
 * @file 搜索缓存存储
 *
 * WIP
 */

interface State {
  netdiskSearchHistory: string[]
}

const state: State = {
  netdiskSearchHistory: [],
}

const reducers = {
  setNetdiskSearchHistory(appState: State, netdiskSearchHistory: string[]) {
    return { ...appState, netdiskSearchHistory }
  },
}

export const searchUpdates = {
  state,
  reducers,
}

/**
 * @file 网盘相关工具函数
 */

import {
  faFile,
  faFileImage,
  faFilePowerpoint,
  faFileMusic,
  faFileVideo,
  faFileExcel,
  faFileAlt,
  faBookOpen,
  faBookReader,
  faUser,
  faUsers,
} from '@fortawesome/pro-solid-svg-icons'
import { axios } from '@seiue/axios'
import { env } from '@seiue/env'
import type { FileRaw } from '@seiue/file'
import {
  catchUploadError,
  Config,
  isRawFile,
  SeiueFiles,
  Status,
  uploadFileOnLocalService,
  uploadFileWithPolicy,
} from '@seiue/file'
import { moment } from '@seiue/moment'
import { createEnumHelper, getExtName, partition } from '@seiue/util'
import { RcFile } from 'antd/lib/upload'

import { $t } from 'packages/locale'
import { isFileHashExistOnNetDisk } from 'packages/plugins/features/net-disk/apis'
import {
  NetdiskFile,
  NetdiskFileStatusEnum,
  NetdiskFileTypeEnum,
  NetdiskFileWithoutOwner,
  NetdiskOwner,
  RoleEnum,
  netdiskApi$getFilePolicy,
  netdiskApi$createFile,
  netdiskApi$deleteFile,
} from 'packages/sdks-next/chalk'

import { SHARED_NET_DISK } from './const'
import { Folder, UploadFile } from './types'

/**
 *  根据文件类型返回网盘文件类型
 *
 * @param name - 文件类型
 * @returns 网盘文件类型
 */
const getFileType = (name: string) => {
  const doc = ['pdf', 'doc', 'docx', 'txt', 'wps', 'rtf']
  const photo = ['bmp', 'gif', 'jpg', 'png', 'tif', 'jpeg', 'heic']
  const audio = ['wav', 'aif', 'au', 'mp3', 'ram']
  const video = ['avi', 'mov', 'swf', 'mp4', '3gp', 'mpg', 'flv', 'wmv']
  const slide = ['ppt', 'pptx', 'pptm']
  const sheet = ['xls', 'xlsx', 'csv']
  const type = name.split('.').pop()
  if (type) {
    switch (true) {
      case doc.includes(type):
        return NetdiskFileTypeEnum.Doc
      case photo.includes(type):
        return NetdiskFileTypeEnum.Photo
      case audio.includes(type):
        return NetdiskFileTypeEnum.Audio
      case video.includes(type):
        return NetdiskFileTypeEnum.Video
      case slide.includes(type):
        return NetdiskFileTypeEnum.Slide
      case sheet.includes(type):
        return NetdiskFileTypeEnum.Sheet
      default:
        return NetdiskFileTypeEnum.Other
    }
  }

  return NetdiskFileTypeEnum.Other
}

/**
 * 从文件数据中，获取其对应的网盘文件类型
 *
 * @param file - 文件
 * @returns 网盘文件类型
 */
export const getNetdiskFileTypeFromRawFile = (file: Pick<FileRaw, 'name'>) =>
  getFileType(file.name)

/**
 * 是否是根目录
 *
 * @param path - 文件夹路径
 * @returns 是否
 */
export const isRootFolder = (path: string) => path === '/'

/**
 * 获取网盘文件的文件夹路径
 *
 * @param folder - 文件夹
 * @returns 路径
 */
export const getFolderPath = (folder: Pick<NetdiskFile, 'path' | 'name'>) =>
  `${isRootFolder(folder.path) ? '' : folder.path}/${folder.name}`

/**
 * 是否是用户盘
 *
 * @param owner - 网盘
 * @returns 是否
 */
export const isUserDisk = (owner: NetdiskOwner) =>
  !!Object.values(RoleEnum).find(role => owner.type === role)

/**
 * 是否是自定义群组网盘
 *
 * @param owner - 网盘
 * @returns 是否
 */
export const isCustomizedDisk = (owner: NetdiskOwner) =>
  owner.type
    ? !['seiue.class_group', 'seiue.course_group', SHARED_NET_DISK].includes(
        owner.type,
      )
    : false

/**
 * 获取群组网盘的类型
 *
 * @param type - 网盘初始类型
 * @returns 翻译后的网盘类型
 */
export const getCourseType = (type: string) => {
  switch (true) {
    case type === 'seiue.course_group':
      return 'Course'
    case type === 'seiue.class_group':
      return 'Class'
    default:
      return type
  }
}

/**
 * 网盘类型转化为显示数据
 */
export const fileTypeEnumToData = createEnumHelper(() => ({
  [NetdiskFileTypeEnum.Doc]: {
    icon: faFileAlt,
    color: '#17a0de',
    label: $t('文本'),
  },
  [NetdiskFileTypeEnum.Photo]: {
    icon: faFileImage,
    color: '#60c381',
    label: $t('图像'),
  },
  [NetdiskFileTypeEnum.Audio]: {
    icon: faFileMusic,
    color: '#d45586',
    label: $t('声音'),
  },
  [NetdiskFileTypeEnum.Sheet]: {
    icon: faFileExcel,
    color: '#21a18e',
    label: $t('表格'),
  },
  [NetdiskFileTypeEnum.Slide]: {
    icon: faFilePowerpoint,
    color: '#ea7b25',
    label: $t('演示文稿'),
  },
  [NetdiskFileTypeEnum.Video]: {
    icon: faFileVideo,
    color: '#8e6bf1',
    label: $t('视频'),
  },
  [NetdiskFileTypeEnum.Other]: {
    icon: faFile,
    color: 'rgba(6 ,12, 25, 0.25)',
    label: $t('未知'),
  },
}))

/**
 * 根据过期时间给出不同显示
 *
 * @param expiredAt - 时间
 * @returns 显示
 */
export const getExpiredLabel = (expiredAt: string | null) => {
  const isExpired = expiredAt
    ? Number(moment(expiredAt)) < Number(moment())
    : false

  const count = expiredAt
    ? Math.floor((Number(moment(expiredAt)) - Number(moment())) / 3600000 / 24)
    : null

  switch (true) {
    case isExpired && !!count:
      return $t('已过期')
    case !isExpired && count && count > 1:
      return $t('{count} 天后过期', { count })
    case !isExpired && count && count < 1:
      return $t('即将过期')
    case !isExpired && !count:
      return $t('永久有效')
    default:
      return $t('永久有效')
  }
}

/**
 * 拼接完整的分享链接
 *
 * @param param - 参数
 * @param param.schoolId - 学校 id
 * @param param.shareId - 分享 id
 * @returns 分享链接
 */
export const getNetdiskShareUrl = ({
  schoolId,
  shareId,
}: {
  schoolId: number
  shareId: number
}) => `${env('CLIENT_CHALK_3')}/share/net-disk/navigator/${schoolId}/${shareId}`

/**
 * 获取网盘根目录的图标
 *
 * @param type - 根网盘类型
 * @returns 图标
 */
export const getNetdiskOwnerFolderInnerIcon = (type: string) => {
  switch (true) {
    case ['teacher', 'student'].includes(type):
      return faUser
    case type === 'seiue.class_group':
      return faBookOpen
    case type === 'seiue.course_group':
      return faBookReader
    default:
      return faUsers
  }
}

/**
 * 获取默认的 folder query
 * 共享网盘没有 ownerName，所以列表里面展示了 topFileName，所以详情处需要传递默认的 folder query，即 topFile 的信息
 *
 * @param file - 文件
 * @returns 默认的 folder query
 */
export const getDefaultFolderQuery = (
  file?: NetdiskFileWithoutOwner | null,
) => {
  if (!file) return undefined

  return {
    folderId: file.id,
    folderPath: `${file.path}${file.name}`,
  }
}

const netDiskUploadService = new SeiueFiles<
  Config<{ id: number; file: FileRaw }>,
  { id: number; file: FileRaw }
>({
  autoUpload: true,
  uploaderFactory: (file, setFile, uploadConfig) => {
    if (file.id && isRawFile(file.file)) {
      return {
        upload: async () =>
          catchUploadError(async () => {
            const { data: policy } = await netdiskApi$getFilePolicy.api(file.id)

            if (uploadConfig.isLocalService) {
              return uploadFileOnLocalService({
                netdiskFileId: file.id,
                rawFile: file.file,
                host: policy.host,
                onChange: setFile,
              })
            }

            return uploadFileWithPolicy({
              file: file.file,
              policy,
              onChange: setFile,
            })
          }, setFile),
        status: Status.Init,
      }
    }

    return {
      status: Status.Uploaded,
    }
  },
})

/**
 * 上传文件
 *
 * @param props - 参数
 * @param props.key - 文件 key
 * @param props.rawFile - 文件
 * @param props.hash - 文件 hash
 * @param props.changeFile - 上传后的回调
 * @param props.folder - 上传操作所在文件夹
 * @returns 上传结果
 */
export const upload = async ({
  key,
  rawFile,
  hash,
  changeFile,
  folder,
}: {
  key: number
  rawFile: FileRaw
  hash: string
  changeFile: (fileData: Partial<UploadFile> & { key: number }) => void
  folder: Folder
}) => {
  if (!rawFile) {
    changeFile({
      key,
      status: Status.Fail,
      progress: 0,
    })

    return
  }

  let cancelSign = false
  const cancelTokenSource = axios.CancelToken.source()

  try {
    // 获取 hash 的过程中，可以改变标志位来停止上传
    changeFile({
      key,
      status: Status.Uploading,
      progress: 0,
      cancelUpload: () => {
        cancelSign = true
      },
    })

    const isFileExist = await isFileHashExistOnNetDisk(hash)

    if (cancelSign) return

    // 构建网盘文件的过程中，可以通过 cancelTokenSource 来停止上传
    changeFile({
      key,
      status: Status.Uploading,
      progress: 0,
      cancelUpload: () => {
        cancelTokenSource.cancel()
      },
    })

    const basicReq = {
      netdiskOwnerId: folder.netdiskOwnerId,
      parentId: folder.id,
      name: rawFile.name,
      path: `${folder.path}`,
      mime: rawFile.type,
      type: getNetdiskFileTypeFromRawFile(rawFile),
      size: rawFile.size,
      hash,
    }

    if (isFileExist) {
      // 在对应的文件夹路径下，创建对应的值
      const { data: createFile } = await netdiskApi$createFile.api(
        {
          ...basicReq,
          status: NetdiskFileStatusEnum.Normal,
        },
        {},
        {
          cancelToken: cancelTokenSource.token,
        },
      )

      changeFile({
        key,
        status: Status.Uploaded,
        progress: 100,
        result: createFile,
      })

      return
    }

    if (cancelSign) return

    const { data: createFile } = await netdiskApi$createFile.api(
      {
        ...basicReq,
        status: NetdiskFileStatusEnum.Uploading,
      },
      {},
      {
        cancelToken: cancelTokenSource.token,
      },
    )

    changeFile({
      key,
      status: Status.Uploading,
      progress: 0,
      result: createFile,
    })

    const uploadableFile = netDiskUploadService.uploaderFactory(
      {
        id: createFile.id,
        file: rawFile,
      },
      file => {
        if (file.status === Status.Init) return

        if (file.status === Status.Uploading) {
          changeFile({
            key,
            status: file.status,
            progress:
              file.progress instanceof ProgressEvent
                ? Math.round((file.progress.loaded / file.progress.total) * 100)
                : 0,
            cancelUpload: () => {
              file.cancel?.()

              // 异步的将上传中的文件删除，防止容量占用
              netdiskApi$deleteFile.api(createFile.id, {
                force: true,
              })
            },
          })

          return
        }

        if (file.status === Status.Fail) {
          // 上传失败
          changeFile({
            key,
            status: file.status,
          })

          return
        }

        // 上传完成
        changeFile({
          key,
          status: file.status,
          progress: 100,
        })
      },
    )

    uploadableFile.upload?.()
  } catch (e) {
    // 任何环节出错，即失败
    changeFile({
      key,
      status: Status.Fail,
    })

    throw e
  }
}

/**
 * 转换文件夹名称为 key
 * 用于上传文件时文件的比较
 *
 * @param folder - 文件夹
 * @returns key
 */
export const convertFolderToKey = (folder: Folder) =>
  `${folder.netdiskOwnerId}/${folder.path}`

/**
 * 切去文件后缀
 *
 * @param fileName - 文件名
 * @returns 文件名和后缀
 */
export const removeFileSuffix = (fileName: string) => {
  const lastIndex = fileName.lastIndexOf('.')
  if (lastIndex === -1) {
    return { name: fileName, suffix: '' }
  }

  const name = fileName.substring(0, lastIndex)
  const suffix = fileName.substring(lastIndex + 1)
  return { name, suffix }
}

/**
 * 拼接文件后缀名
 *
 * @param props - 参数
 * @param props.fileName - 文件名
 * @param props.suffix - 后缀名
 * @returns 文件名
 */
export const appendFileSuffix = ({
  fileName,
  suffix,
}: {
  fileName: string
  suffix: string
}) => {
  return `${fileName}.${suffix}`
}

/**
 * 检查文件扩展名是否在允许列表中
 *
 * @param file - 文件
 * @param allowedExtensions - 允许的文件扩展名列表, 如 ['.jpg', '.png']
 * @returns 是否允许
 */
export const isAllowedExtension = (
  file: Pick<RcFile, 'name'>,
  allowedExtensions?: string[],
) => {
  if (!allowedExtensions) return true
  const ext = getExtName(file.name)
  return allowedExtensions.includes(`.${ext}`)
}

/**
 * 过滤文件列表，分离出允许和不允许的文件
 *
 * @param files - 文件列表
 * @param allowedExtensions - 允许的文件扩展名列表, 如 ['.jpg', '.png']
 * @returns 允许和不允许的文件列表
 */
export const separateAllowedAndDisallowedFiles = <
  T extends Pick<RcFile, 'name'>,
>(
  files: T[],
  allowedExtensions?: string[],
) => {
  if (!allowedExtensions) return { allowedFiles: files, disallowedFiles: [] }

  const [allowedFiles, disallowedFiles] = partition(files, file =>
    isAllowedExtension(file, allowedExtensions),
  )

  return { allowedFiles, disallowedFiles }
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { UserDeviceReq, UserDeviceReqInterface } from './user-device-req'

const buildConfig = createConfigBuilder('chalk')

export const userApi$syncUserDevice = {
  config(
    id: string,
    userDeviceReq: UserDeviceReqInterface,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'put',
      '/me/devices/{id}',
      {
        id,
      },
      null,
      userDeviceReq,
      {
        format: 'json',
        isArray: false,
        defaults: {
          platform: (v: any) => v || '',
          browser: (v: any) => v || '',
          deviceName: (v: any) => v || '',
          deviceOs: (v: any) => v || '',
          deviceExtra: (v: any) => v || null,
        },
      },
    )
  },

  /**
   * @summary Class CreateUserDevice
   * @param id
   * @param userDeviceReq
   * @param [options]
   * @return AxiosResponsePromise<null>
   */

  api(
    id: string,
    userDeviceReq: UserDeviceReqInterface,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<null> {
    return axios(
      userApi$syncUserDevice.config(id, userDeviceReq, options),
    ) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({ id, payload }: { id: string; payload: UserDeviceReqInterface }) =>
        userApi$syncUserDevice.api(id, payload),
      mutationOptions,
    )
  },
}

// @ts-ignore
userApi$syncUserDevice.api._name_ = `User.syncUserDevice`

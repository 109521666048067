import { FileRaw, Status } from '@seiue/file'

import {
  NetdiskFile,
  NetdiskFileTypeEnum,
  NetdiskOwner,
  NetdiskPermissionPermissionsEnum,
} from 'packages/sdks-next/chalk'

export type NetdiskFileWithSelfPermission = NetdiskFile & {
  selfPermission?: NetdiskPermissionPermissionsEnum
  netdiskOwner: NetdiskOwner
}

// 上传相关的文件夹设置
export type Folder = {
  netdiskOwnerId: NetdiskFile['netdiskOwnerId']
  id: NetdiskFile['id']
  path: NetdiskFile['path']
}

// 上传池中的文件
export interface UploadFile {
  key: number
  name: string
  hash: string
  type: NetdiskFileTypeEnum
  rawFile: FileRaw
  status: Status
  progress: number
  folder: Folder
  result?: NetdiskFile
  upload: () => Promise<void> | void
  cancel: () => void
  cancelUpload?: () => void
}

export enum NetDiskUploadLimit {
  None = 'none',
  Part = 'part',
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  ClassinReflection,
  ClassinReflectionInterface,
} from './classin-reflection'
import type { QueryClassinReflectionsQuery } from './query-classin-reflections-query'

const buildConfig = createConfigBuilder('chalk')

export const classinApi$queryClassinReflections = {
  config(
    queryClassinReflectionsQuery?: QueryClassinReflectionsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/classin/reflections',
      {},
      queryClassinReflectionsQuery,
    )
  },

  /**
   * @summary 查询用户（带有 classin 账号绑定状态）
   * 如果没有传 role 参数，则不支持自定义字段的查询、获取
   *
   * 1. 管理员查询所有用户
   * 2. 普通用户查他人是否绑定 classin
   * @param [queryClassinReflectionsQuery]
   * @param [classinQuery.registered] 是否已注册
   * @param [classinQuery.classinAccount.accountType] 账号类型
   * @param [classinQuery.classinAccount.accountIn] classin 账号
   * @param [classinQuery.expand]
   * @param [classinQuery.account]
   * @param [classinQuery.usin]
   * @param [classinQuery.idcard]
   * @param [classinQuery.outerId]
   * @param [classinQuery.idIn]
   * @param [classinQuery.nameIn]
   * @param [classinQuery.nameLike]
   * @param [classinQuery.enameIn]
   * @param [classinQuery.enameLike]
   * @param [classinQuery.identity] name, usin, ename, pinyin 的模糊搜索
   * @param [classinQuery.accountLike]
   * @param [classinQuery.accountIn]
   * @param [classinQuery.usinLike]
   * @param [classinQuery.usinIn]
   * @param [classinQuery.genderIn]
   * @param [classinQuery.statusIn]
   * @param [classinQuery.archivedTypeIdIn]
   * @param [classinQuery.phoneIn]
   * @param [classinQuery.phoneLike]
   * @param [classinQuery.emailIn]
   * @param [classinQuery.emailLike]
   * @param [classinQuery.idcardIn]
   * @param [classinQuery.idcardLike]
   * @param [classinQuery.enteredOnElt]
   * @param [classinQuery.enteredOnEgt]
   * @param [classinQuery.workStartOnElt]
   * @param [classinQuery.workStartOnEgt]
   * @param [classinQuery.enteredYearsElt]
   * @param [classinQuery.enteredYearsEgt]
   * @param [classinQuery.workYearsElt]
   * @param [classinQuery.workYearsEgt]
   * @param [classinQuery.adminClassesContains]
   * @param [classinQuery.adminClassesOverlaps]
   * @param [classinQuery.adminClassFullnamesOverlaps]
   * @param [classinQuery.adminClassesLike]
   * @param [classinQuery.dorm]
   * @param [classinQuery.dormLike]
   * @param [classinQuery.dormIn]
   * @param [classinQuery.dormAdminsContains]
   * @param [classinQuery.dormAdminsOverlaps]
   * @param [classinQuery.dormAdminsLike]
   * @param [classinQuery.role]
   * @param [classinQuery.roleIn]
   * @param [classinQuery.fields] 自定义需要导出的字段
   * @param [classinQuery.paginated] 是否分页，默认 1
   * @param [classinQuery.page] 显示的数据页，默认 1
   * @param [classinQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<ClassinReflection[]>
   */

  api<const TQuery extends QueryClassinReflectionsQuery>(
    queryClassinReflectionsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<ClassinReflection, TQuery['expand']>[]> {
    return axios(
      classinApi$queryClassinReflections.config(
        queryClassinReflectionsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new ClassinReflection(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryClassinReflectionsQuery,
    TSelected = Expand<ClassinReflection, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<ClassinReflection, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<
      Expand<ClassinReflection, TQuery['expand']>[],
      TSelected
    >(
      classinApi$queryClassinReflections.api,
      'Classin.queryClassinReflections',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
classinApi$queryClassinReflections.api._name_ = `Classin.queryClassinReflections`

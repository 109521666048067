import { InitFeatureParams } from '../types'

import { calendarEvents } from './calendar-events'
import { routes } from './routes'
import { todos } from './todos'

/**
 * 初始化日程
 *
 * @param param0 - 参数
 */
export const initSchedule = (param0: InitFeatureParams) => {
  const { addRoutes, registerTodos, registerCalendarEvents } = param0
  addRoutes(routes)
  registerTodos(todos)
  registerCalendarEvents(calendarEvents)
}

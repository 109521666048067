/**
 * 审批中心，汇集了所有审批流业务，包括像标准审批流、请假、出入校等
 * ⚠️ 接入审批服务前请阅读 README
 */

import {
  WorkflowMessageAttributes,
  WorkflowMessageType,
} from 'packages/features/workflows/message/message-type'
import { WorkflowExtensionTypeEnum } from 'packages/features/workflows/types'
import { getWorkflowBizType } from 'packages/features/workflows/utils/data'

import { InitFeatureParams } from '../types'

import { routes } from './routes'
import { todos } from './todos'

/**
 * 初始化审批流
 *
 * @param param0 - 参数
 */
export const initWorkflows = (param0: InitFeatureParams) => {
  const { addRoutes, registerMessageNavigator, registerTodos } = param0
  addRoutes(routes)
  registerTodos(todos)
  registerMessageNavigator(
    [
      WorkflowMessageType.Reminder,
      WorkflowMessageType.Updated,
      WorkflowMessageType.StageUpdated,
      WorkflowMessageType.CommentRemind,
    ],
    ({ attributes }) => {
      const { flow, workflow, relatedData, commentId } =
        attributes as WorkflowMessageAttributes

      if (!flow?.id) return null

      if (workflow?.extensionType === WorkflowExtensionTypeEnum.NoCode) {
        const { pluginName, pageName, formId } = relatedData || {}

        return [
          'NoCodePage',
          {
            pluginName,
            pageName,
            id: formId,
          },
        ]
      }

      return [
        'ShowFlowModal',
        {
          workflowBizType: getWorkflowBizType(workflow?.extensionType),
          flowId: flow.id,
          commentId,
        },
      ]
    },
  )
}

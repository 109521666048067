/**
 * @file slot
 */

import { IconLookup } from '@fortawesome/fontawesome-svg-core'
import { stringifyURLQuery } from '@seiue/util'
import React, { useEffect, useState } from 'react'

import { EnabledPlugin } from 'packages/feature-utils/plugins'
import { GradeFieldsVisible } from 'packages/features/grades/hooks'
import { CustomScheduleEventSource } from 'packages/features/schedules/types'
import {
  Reflection,
  Schedule,
  ScheduleMember,
  SectionMember,
} from 'packages/sdks-next/chalk'
import { Setting } from 'packages/sdks-next/vnas'

import { Menu } from '@go/features/applications/types'
import { Dispatch } from '@go/stores'

export interface SyncSlotType {
  demo: () => React.ReactNode

  /*
   * 自定义日程卡片按钮
   */
  ownerScheduleEventCardAction: (props: {
    reflection: Reflection
    source: CustomScheduleEventSource
    /**
     * ClassIn 插件
     */
    classInPlugin?: EnabledPlugin
    /**
     * 登录的 token
     */
    accessToken?: string
  }) => {
    label: string
    onPress: () => Promise<void> | void
  }[]
  /**
   * 课程班成绩 - 字段展示状态重写
   */
  classGradeVisibilityOverride: (props: {
    /**
     * 成绩设置
     */
    setting?: Setting | null
    /**
     * 成绩字段可见性状态
     */
    gradeVisibilityRaw: GradeFieldsVisible
  }) => GradeFieldsVisible
}

export interface AsyncSlotTypes {
  asyncDemo: (props: any) => Promise<any>
  /**
   * 注册应用中心菜单，受插件使用范围影响（即插件开启，用户不在使用范围，菜单将不可见）
   *
   * @returns 菜单
   */
  asyncAppMenuItems: () => Promise<Menu[]>
  /*
   * 自定义日程详情详情信息
   */
  asyncOwnerScheduleDetail: (props: {
    dispatch: Dispatch
    currentReflection: Reflection
    schedule: Schedule | null
    members?: (SectionMember | ScheduleMember)[]
    playbackUrl?: string | null
    classinLessonId?: number | null
    classinCourseId?: number | null
    classInPlugin?: EnabledPlugin
    accessToken?: string
  }) => Promise<
    {
      icon: IconLookup
      iconColor?: string
      title: string
      value: React.ReactNode
      display?: boolean
    }[]
  >
}

export type SlotTypes = SyncSlotType & AsyncSlotTypes

// map of slot name to list of slot functions
const _map: { [slotName: string]: any[] } = {}

/**
 * 注册 slot
 *
 * @param name - slot 名称
 * @param slot - slot
 */
export const registerToSlot = <SlotName extends keyof SlotTypes>(
  name: SlotName,
  slot: SlotTypes[SlotName],
) => {
  _map[name] = _map[name] ?? []
  _map[name].push(slot)
}

/**
 * 移除 slot
 *
 * @param name - slot 名称
 * @param slot - slot
 */
export const removeSlot = <SlotName extends keyof SlotTypes>(
  name: SlotName,
  slot: SlotTypes[SlotName],
) => {
  _map[name] = _map[name]?.filter(slotSlot => slotSlot !== slot)
}

/**
 * 获取 slot
 *
 * @param name - slot 名称
 * @returns slot
 */
export const getSlots = <SlotName extends keyof SlotTypes>(
  name: SlotName,
): SlotTypes[SlotName][] => _map[name] || []

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T

/**
 * 获取异步 slots 结果的 Hooks
 *
 * @param name - slot 名称
 * @param params - slot 所需参数
 * @returns 异步 slots 的执行结果
 */
export const useAsyncSlots = <SlotName extends keyof AsyncSlotTypes>(
  name: SlotName,
  params: Parameters<AsyncSlotTypes[SlotName]>[0],
) => {
  const slots = _map[name] as AsyncSlotTypes[SlotName][]

  const [asyncSlotResult, setAsyncSlotResult] = useState<
    Awaited<ReturnType<AsyncSlotTypes[SlotName]>>[]
  >([])

  const paramsString = stringifyURLQuery(params || {})

  useEffect(() => {
    setAsyncSlotResult([])

    slots?.forEach((slot, index) =>
      slot(params).then(res => {
        setAsyncSlotResult(oldArr => {
          const newArr = [...oldArr]

          newArr[index] = res

          return newArr
        })
      }),
    )

    // eslint-disable-next-line
  }, [slots, paramsString])

  return asyncSlotResult
}

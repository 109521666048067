/**
 * @file 公开分享页 stack
 */
import {
  createStackNavigator,
  StackNavigationOptions,
} from '@react-navigation/stack'
import { isWeb } from '@seiue/rn-util'
import React, { Suspense, useMemo, useState } from 'react'

import { ErrorBoundary } from '@go/components/ErrorBoundary'
import { useRootNavigation } from '@go/router/hooks'
import { Route, MainStackParams, RootStackComponent } from '@go/router/types'
import { addRoutes } from '@go/router/utils'
import { useDispatch, useSelector } from '@go/stores'

import { Header } from './Layout/Header'
import { ShareNavigationContext } from './context'
import { isCertificationsDemo } from './utils'

const routes: Route[] = []

/**
 * 添加路由到分享导航
 *
 * @param rts - 路由
 */
export const addSharesRoutes = (rts: Route[]) => {
  addRoutes(routes, rts)
}

const ShareStack = createStackNavigator<MainStackParams>()

/**
 * 分享导航器
 *
 * @param props - props
 * @param props.navigation - 导航
 * @returns React.ReactElement
 */
export const SharesNavigator: RootStackComponent<'Share'> = ({
  navigation,
}) => {
  const dispatch = useDispatch()
  const isLoggedIn: boolean = useSelector(({ session }) => !!session.oAuthToken)

  const defaultOptions = useMemo(() => {
    const toHome = () => {
      if (isLoggedIn) {
        requestAnimationFrame(() => {
          navigation.navigate('Main')
        })
      } else if (isWeb) {
        dispatch.session.destroy()
      } else {
        requestAnimationFrame(() => {
          navigation.navigate('LoginScreen', {})
        })
      }
    }

    if (isCertificationsDemo()) {
      return {}
    }

    return {
      header: () => <Header isLoggedIn={isLoggedIn} onPress={toHome} />,
    }
  }, [dispatch.session, isLoggedIn, navigation])

  const [options, setOptions] = useState<StackNavigationOptions>()

  useRootNavigation(options || defaultOptions, [options, defaultOptions])

  return (
    <ShareNavigationContext.Provider
      value={{
        setOptions,
      }}
    >
      <ShareStack.Navigator screenOptions={{ headerShown: false }}>
        {routes
          .filter(r => r.isShare)
          .map(({ name, Component, getTitle, getHeaderTitle }) => (
            <ShareStack.Screen
              key={name}
              name={name as keyof MainStackParams}
              options={{
                title: getTitle?.() ?? '',
                headerTitle: getHeaderTitle?.() ?? '',
              }}
            >
              {props => (
                <ErrorBoundary>
                  <Suspense fallback={<></>}>
                    <Component {...props} />
                  </Suspense>
                </ErrorBoundary>
              )}
            </ShareStack.Screen>
          ))}
      </ShareStack.Navigator>
    </ShareNavigationContext.Provider>
  )
}

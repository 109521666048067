import { LiveInfo, LiveInfoInterface } from './live-info'
import { LessonStatusEnum } from './lesson-status-enum'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import { ClassLesson, ClassLessonInterface } from './class-lesson'

export interface OnlineClassLessonInterface {
  classId: number
  lessonId: number
  teacherRid?: number | null
  lessonAssistantRid?: number | null
  channelLessonId?: number | null
  channelLessonName?: string
  startTime?: string
  endTime?: string
  liveInfo?: LiveInfoInterface | null
  replayUrl?: string | null
  seatNum?: number
  /**
   * 录课
   */
  record?: boolean
  /**
   * 录制现场
   */
  recordScene?: boolean
  /**
   * 直播
   */
  live?: boolean
  /**
   * 回放
   */
  replay?: boolean
  /**
   * 课节状态
   */
  status?: LessonStatusEnum
  /**
   * 同步状态
   */
  needSynced?: boolean
  /**
   * 任课教师 需要expand
   */
  teacher?: SecuredReflectionInterface | null
  /**
   * 助教 需要expand
   */
  assistantTeacher?: SecuredReflectionInterface | null
  /**
   * 课节信息 需要expand,查询在线课列表必须expand
   */
  classLesson?: ClassLessonInterface | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
}

export class OnlineClassLesson implements OnlineClassLessonInterface {
  classId: number
  lessonId: number
  teacherRid?: number | null
  lessonAssistantRid?: number | null
  channelLessonId?: number | null
  channelLessonName?: string
  startTime?: string
  endTime?: string
  liveInfo?: LiveInfo | null
  replayUrl?: string | null
  seatNum?: number
  /**
   * 录课
   */
  record?: boolean
  /**
   * 录制现场
   */
  recordScene?: boolean
  /**
   * 直播
   */
  live?: boolean
  /**
   * 回放
   */
  replay?: boolean
  /**
   * 课节状态
   */
  status?: LessonStatusEnum
  /**
   * 同步状态
   */
  needSynced?: boolean
  /**
   * 任课教师 需要expand
   */
  teacher?: SecuredReflection | null
  /**
   * 助教 需要expand
   */
  assistantTeacher?: SecuredReflection | null
  /**
   * 课节信息 需要expand,查询在线课列表必须expand
   */
  classLesson?: ClassLesson | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number

  constructor(props: OnlineClassLessonInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      classId: _classId,
      lessonId: _lessonId,
      teacherRid: _teacherRid,
      lessonAssistantRid: _lessonAssistantRid,
      channelLessonId: _channelLessonId,
      channelLessonName: _channelLessonName,
      startTime: _startTime,
      endTime: _endTime,
      liveInfo: _liveInfo,
      replayUrl: _replayUrl,
      seatNum: _seatNum,
      record: _record,
      recordScene: _recordScene,
      live: _live,
      replay: _replay,
      status: _status,
      needSynced: _needSynced,
      teacher: _teacher,
      assistantTeacher: _assistantTeacher,
      classLesson: _classLesson,
      id: _id,
      schoolId: _schoolId,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.classId = _classId
    this.lessonId = _lessonId
    this.teacherRid = _teacherRid
    this.lessonAssistantRid = _lessonAssistantRid
    this.channelLessonId = _channelLessonId
    this.channelLessonName = _channelLessonName
    this.startTime = _startTime
    this.endTime = _endTime
    this.liveInfo = _liveInfo ? new LiveInfo(_liveInfo) : _liveInfo
    this.replayUrl = _replayUrl
    this.seatNum = _seatNum
    this.record = _record
    this.recordScene = _recordScene
    this.live = _live
    this.replay = _replay
    this.status = _status
    this.needSynced = _needSynced
    this.teacher = _teacher ? new SecuredReflection(_teacher) : _teacher
    this.assistantTeacher = _assistantTeacher
      ? new SecuredReflection(_assistantTeacher)
      : _assistantTeacher
    this.classLesson = _classLesson
      ? new ClassLesson(_classLesson)
      : _classLesson
    this.id = _id
    this.schoolId = _schoolId
  }

  static propKeys = [
    'classId',
    'lessonId',
    'teacherRid',
    'lessonAssistantRid',
    'channelLessonId',
    'channelLessonName',
    'startTime',
    'endTime',
    'liveInfo',
    'replayUrl',
    'seatNum',
    'record',
    'recordScene',
    'live',
    'replay',
    'status',
    'needSynced',
    'teacher',
    'assistantTeacher',
    'classLesson',
    'id',
    'schoolId',
  ]
}

/**
 * @file useLogout for go
 */
import { isReactNative } from '@seiue/util'
import { useCallback } from 'react'

import { useLogout as useLogoutOrigin } from 'packages/features/sessions/utils/apis'

import { useDispatch } from '@go/stores'

export const useLogout = () => {
  const logout = useLogoutOrigin()
  const dispatch = useDispatch()

  return useCallback(() => {
    if (isReactNative) {
      dispatch.todos.resetCurrentTodos([])
    }

    logout()
  }, [logout, dispatch])
}

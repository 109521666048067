import { reportToEngineer } from '@seiue/sentry'
import React from 'react'

import { IconType, LogoType } from 'packages/components/Logo/utils'
import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { newcapecApi$getSsoUrl } from 'packages/sdks-next/chalk'

import { LogoRender } from '@go/components/Logo/Render'
import { Menu } from '@go/features/applications/types'

import newcapecIcon from './assets/newcapec.png'

/**
 * 新开普菜单
 *
 * @returns 菜单
 */
export const getNewcapecOrderMenu = async (): Promise<Menu[] | null> => {
  let uri = ''

  try {
    const { data } = await newcapecApi$getSsoUrl.api()

    uri = data
  } catch (e) {
    reportToEngineer(e)
  }

  if (!uri) return null

  const title = $t('食堂订餐')

  return [
    {
      key: PluginEnum.NewcapecOrder,
      pluginName: PluginEnum.NewcapecOrder,
      icon: ({ size } = {}) => (
        <LogoRender
          size={size}
          logo={newcapecIcon}
          iconType={IconType.Image}
          logoType={LogoType.Comb}
        />
      ),
      getTitle: () => title,
      screen: {
        name: 'WebviewModal' as const,
        params: {
          uri,
          title,
        },
      },
      /**
       * 安卓手机 webview 无法设置 Referer 信息，导致唤起微信支付的界面会出错
       * 故针对安卓端采取唤起手机内置浏览器打开网页的方式
       */
      openInExternalBrowserInAndroid: true,
    },
  ]
}

/**
 * @file 公开分享页 header 的 logo，支持点击跳转
 */
import { Image } from '@seiue/rn-ui'
import React from 'react'
import { StyleProp, ViewStyle, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'

import { useSchoolLogo, useSchool } from 'packages/feature-utils/schools'

import { useDispatch } from '@go/stores'

import seiueLogoCN from '../assets/seiue_logo_cn.png'
import seiueLogoEn from '../assets/seiue_logo_en.png'

import { getSchoolIdFromURL } from './utils'

/**
 * 公开分享页 header 的 logo，支持点击跳转
 *
 * @param param0 - 参数
 * @param param0.onPress - 点击 logo 的回调
 * @param param0.style - 样式
 * @returns 公开分享页 header 的 logo，支持点击跳转
 */
export const SiderLogo: React.FC<{
  onPress?: () => void
  style?: StyleProp<ViewStyle>
}> = ({ style, onPress }) => {
  const dispatch = useDispatch()
  const schoolId = getSchoolIdFromURL()

  const school = useSchool(schoolId)

  const { isCnLocale, height, logo } = useSchoolLogo({ school })

  React.useEffect(() => {
    if (school) dispatch.session.setCurrentSchool(school)
  }, [school, dispatch.session])

  return (
    <Logo style={style} onPress={onPress}>
      <StyledImage
        style={{ height: height || 24 }}
        source={logo || (isCnLocale ? seiueLogoCN : seiueLogoEn)}
        resizeMode="contain"
      />
    </Logo>
  )
}

const Logo = styled(TouchableOpacity)``

const StyledImage = styled(Image)``

/**
 * @file 自定义群组
 */

import { InitPluginFeature } from '@go/plugins/types'

import { calendarEvents } from './calendar-events'
import { routes } from './routes'
import { todos } from './todos'

export const customizedGroups: InitPluginFeature = {
  name: 'customized-group',
  todos,
  calendarEvents,
  register: () => ({
    routes,
  }),
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { NetdiskFile, NetdiskFileInterface } from './netdisk-file'
import type { QueryUnorderedFilesQuery } from './query-unordered-files-query'

const buildConfig = createConfigBuilder('chalk')

export const netdiskApi$queryUnorderedFiles = {
  config(
    queryUnorderedFilesQuery?: QueryUnorderedFilesQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/netdisk/files/unordered',
      {},
      queryUnorderedFilesQuery,
    )
  },

  /**
   * @summary 查询所有无序的文件
   * 回收站-我删除的文件
   * 搜索文件名
   * @param [queryUnorderedFilesQuery]
   * @param [netdiskQuery.expand]
   * @param [netdiskQuery.fields]
   * @param [netdiskQuery.validatingHash] 如果只是验证文件是否存在，则传该参数为 true 。且 fields 参数必须等于 'hash' 一个字段。
   * @param [netdiskQuery.isExport] 是否下载列表文件
   * @param [netdiskQuery.withCurrentReflection] 当 expand netdisk_stars 时，只查询当前登录用户的收藏
   * @param [netdiskQuery.paginated] 是否分页，默认 1
   * @param [netdiskQuery.page] 显示的数据页，默认 1
   * @param [netdiskQuery.perPage] 每页显示数量，默认 20
   * @param [netdiskQuery.id]
   * @param [netdiskQuery.idIn]
   * @param [netdiskQuery.isDir]
   * @param [netdiskQuery.nameLike]
   * @param [netdiskQuery.nameIn]
   * @param [netdiskQuery.name]
   * @param [netdiskQuery.path]
   * @param [netdiskQuery.pathLike]
   * @param [netdiskQuery.pathPrefix] 指定 path 前缀，可用于查询某目录下的所有文件
   * @param [netdiskQuery.parentId]
   * @param [netdiskQuery.parentIdIn]
   * @param [netdiskQuery.typeIn]
   * @param [netdiskQuery.type]
   * @param [netdiskQuery.mime]
   * @param [netdiskQuery.mimeIn]
   * @param [netdiskQuery.sizeEgt]
   * @param [netdiskQuery.sizeElt]
   * @param [netdiskQuery.netdiskOwnerId]
   * @param [netdiskQuery.netdiskOwnerIdIn]
   * @param [netdiskQuery.hash]
   * @param [netdiskQuery.hashIn]
   * @param [netdiskQuery.shareSumElt]
   * @param [netdiskQuery.shareSumEgt]
   * @param [netdiskQuery.downloadSumElt]
   * @param [netdiskQuery.downloadSumEgt]
   * @param [netdiskQuery.createdAtElt]
   * @param [netdiskQuery.createdAtEgt]
   * @param [netdiskQuery.status] 默认查询正常，使用 trashed 查询回收站内容
   * @param [netdiskQuery.creatorRid]
   * @param [netdiskQuery.creatorRidIn]
   * @param [netdiskQuery.creator.account]
   * @param [netdiskQuery.creator.usin]
   * @param [netdiskQuery.creator.idcard]
   * @param [netdiskQuery.creator.outerId]
   * @param [netdiskQuery.creator.idIn]
   * @param [netdiskQuery.creator.nameIn]
   * @param [netdiskQuery.creator.nameLike]
   * @param [netdiskQuery.creator.enameIn]
   * @param [netdiskQuery.creator.enameLike]
   * @param [netdiskQuery.creator.identity] name, usin, ename, pinyin 的模糊搜索
   * @param [netdiskQuery.creator.accountLike]
   * @param [netdiskQuery.creator.accountIn]
   * @param [netdiskQuery.creator.usinLike]
   * @param [netdiskQuery.creator.usinIn]
   * @param [netdiskQuery.creator.genderIn]
   * @param [netdiskQuery.creator.statusIn]
   * @param [netdiskQuery.creator.archivedTypeIdIn]
   * @param [netdiskQuery.creator.phoneIn]
   * @param [netdiskQuery.creator.phoneLike]
   * @param [netdiskQuery.creator.emailIn]
   * @param [netdiskQuery.creator.emailLike]
   * @param [netdiskQuery.creator.idcardIn]
   * @param [netdiskQuery.creator.idcardLike]
   * @param [netdiskQuery.creator.enteredOnElt]
   * @param [netdiskQuery.creator.enteredOnEgt]
   * @param [netdiskQuery.creator.workStartOnElt]
   * @param [netdiskQuery.creator.workStartOnEgt]
   * @param [netdiskQuery.creator.enteredYearsElt]
   * @param [netdiskQuery.creator.enteredYearsEgt]
   * @param [netdiskQuery.creator.workYearsElt]
   * @param [netdiskQuery.creator.workYearsEgt]
   * @param [netdiskQuery.creator.adminClassesContains]
   * @param [netdiskQuery.creator.adminClassesOverlaps]
   * @param [netdiskQuery.creator.adminClassFullnamesOverlaps]
   * @param [netdiskQuery.creator.adminClassesLike]
   * @param [netdiskQuery.creator.dorm]
   * @param [netdiskQuery.creator.dormLike]
   * @param [netdiskQuery.creator.dormIn]
   * @param [netdiskQuery.creator.dormAdminsContains]
   * @param [netdiskQuery.creator.dormAdminsOverlaps]
   * @param [netdiskQuery.creator.dormAdminsLike]
   * @param [netdiskQuery.creator.role]
   * @param [netdiskQuery.creator.roleIn]
   * @param [netdiskQuery.sort]
   * @param [options]
   * @return AxiosResponsePromise<NetdiskFile[]>
   */

  api<const TQuery extends QueryUnorderedFilesQuery>(
    queryUnorderedFilesQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<NetdiskFile, TQuery['expand']>[]> {
    return axios(
      netdiskApi$queryUnorderedFiles.config(queryUnorderedFilesQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new NetdiskFile(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryUnorderedFilesQuery,
    TSelected = Expand<NetdiskFile, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<NetdiskFile, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<NetdiskFile, TQuery['expand']>[], TSelected>(
      netdiskApi$queryUnorderedFiles.api,
      'Netdisk.queryUnorderedFiles',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
netdiskApi$queryUnorderedFiles.api._name_ = `Netdisk.queryUnorderedFiles`

/**
 * @file 任务待办注册
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.OnlineScoringTeacherReview,
    Render: lazy(() =>
      import('./TeacherReview').then(m => ({ default: m.TeacherReviewTodo })),
    ),
  },
  {
    type: TodoTypeEnum.OnlineScoringProblemPage,
    Render: lazy(() =>
      import('./ProblemPage').then(m => ({
        default: m.ScoringProblemPageTodo,
      })),
    ),
  },
]

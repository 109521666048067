import { InitFeatureParams } from '../types'

import { routes } from './routes'

/**
 * 初始化校历
 *
 * @param param0 - 参数
 */
export const initSchoolCalendars = (param0: InitFeatureParams) => {
  const { addRoutes, registerMessageNavigator } = param0

  addRoutes(routes)

  registerMessageNavigator('schcal', ({ attributes }) => {
    const startAt = (attributes as any)?.startAt || ''
    const activeDay = startAt.split(' ')[0]

    return [
      'SchoolCalendarEvent',
      {
        id: attributes?.id,
        day: activeDay,
        semesterId: (attributes as any)?.semesterId,
      },
    ]
  })
}

import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface GuardianReadStatusInterface {
  /**
   * 家长信息
   */
  reflection?: SecuredReflectionInterface | null
  /**
   * 已读状态
   */
  readed: boolean
  /**
   * 回执状态
   */
  receipted: boolean | null
  /**
   * 已回执的签名图片 Hash
   */
  receiptedImage?: string | null
}

export class GuardianReadStatus implements GuardianReadStatusInterface {
  /**
   * 家长信息
   */
  reflection?: SecuredReflection | null
  /**
   * 已读状态
   */
  readed: boolean
  /**
   * 回执状态
   */
  receipted: boolean | null
  /**
   * 已回执的签名图片 Hash
   */
  receiptedImage?: string | null

  constructor(props: GuardianReadStatusInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      reflection: _reflection,
      readed: _readed,
      receipted: _receipted,
      receiptedImage: _receiptedImage,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.reflection = _reflection
      ? new SecuredReflection(_reflection)
      : _reflection
    this.readed = _readed
    this.receipted = _receipted
    this.receiptedImage = _receiptedImage
  }

  static propKeys = ['reflection', 'readed', 'receipted', 'receiptedImage']
}

export * from './common'

const Error = '#E4524B'
const Red = '#E03437'
const Yellow = '#FCA700'
const Green = '#16C89E'

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const Color = {
  Theme: '#447AE6',
  Black: '#323842',
  DeepGray: '#5E636E',
  Gray: '#9398A1',
  LightGray: '#C6C9CF',
  White: '#ffffff',
  Red,
  Yellow,
  Green,
  Error,
  Warning: Yellow,
  Success: Green,
  ElRed: '#E4524B',
  ElYellow: '#FCA700',
  ElGreen: '#16C89E',
  ElTheme: '#669fff',
}

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const BorderWidth = {
  Light: 0.5,
  Regular: 1,
}

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const TitleFontSize = {
  XLarge: 24,
  Large: 18,
  Regular: 16,
  Small: 14,
}

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const TextFontSize = {
  Article: 15,
  Regular: 14,
  Comment: 13,
  Label: 12,
  Tag: 11,
}

/**
 * @deprecated 改用 packages/theme 中的变量. 如无定义, 请使用原始值.
 */
export const FontWeight = {
  Regular: '400',
  Medium: '500',
  Bold: 'bold',
} as const

import React from 'react'

/**
 * go-web 监听 ScrollView 的滚动，当停止滚动时，执行 handleIndexChange 方法
 *
 * @param props - props
 * @param props.scrollViewRef - scrollView 的 ref
 * @param props.widthRef - scrollView 的宽度 ref
 * @param props.handleIndexChange - 当停止滚动时，执行的回调函数
 */
export const useSwiperScrollEnd = ({
  scrollViewRef,
  widthRef,
  handleIndexChange,
}: {
  scrollViewRef: React.MutableRefObject<HTMLDivElement>
  widthRef: React.MutableRefObject<number>
  handleIndexChange: (index: number) => void
}) => {
  React.useEffect(() => {
    let timer: NodeJS.Timeout | null = null
    const currentScrollView = scrollViewRef.current as HTMLDivElement | null

    const handleScroll = () => {
      if (timer) {
        clearTimeout(timer)
      }

      timer = setTimeout(() => {
        // 无滚动事件触发，可以认为停止滚动了
        const { scrollLeft } = currentScrollView as HTMLDivElement
        handleIndexChange(Math.round(scrollLeft / widthRef.current))
      }, 100)
    }

    if (currentScrollView) {
      currentScrollView.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (currentScrollView) {
        currentScrollView.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleIndexChange, scrollViewRef, widthRef])
}

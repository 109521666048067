/**
 * @file 认证
 */

import { CertificationPluginKind } from 'packages/feature-utils/certifications'
import { PluginNameEnum } from 'packages/plugins/types'
import { RoleEnum } from 'packages/sdks-next/chalk'
import {
  CertRelatedPolicyEnum,
  CertificationReflectionStatusEnum,
} from 'packages/sdks-next/sgms'

import { InitPluginFeature } from '@go/plugins/types'

import { routes } from './routes'
import { todos } from './todos'

export const certification: InitPluginFeature = {
  name: PluginNameEnum.Certification,
  todos,
  register: () => {
    return {
      routes,
    }
  },
  messageNavigators: [
    {
      type: 'certification.certification_reflection_update',
      navigator: (message, reflection) => {
        const { id, kind, newStatus, certId, certPluginId } =
          message.attributes as {
            // 认证获得记录 id
            id?: number
            // 认证插件类型
            kind?: CertificationPluginKind
            // 认证获得记录状态
            newStatus?: CertificationReflectionStatusEnum
            // 认证 id
            certId?: number
            // 认证插件 id
            certPluginId?: number
          }

        const isIncentive = !kind
          ? false
          : [
              CertificationPluginKind.OfflineIncentive,
              CertificationPluginKind.OnlineIncentive,
            ].includes(kind)

        // 激励类的认证跳转到移动端详情页（仅支持学生）
        if (
          isIncentive &&
          newStatus === CertificationReflectionStatusEnum.Passed &&
          certId &&
          certPluginId &&
          reflection.role === RoleEnum.Student
        ) {
          return [
            'Plugin.Certifications.Certified',
            {
              certificationId: certId,
              certificationPluginId: certPluginId,
            },
          ]
        }

        const ownerId = (message.owner as any)?.rid
        if (ownerId && id) {
          return {
            modalName: 'Plugin.CertificationReflectionModal',
            options: {
              rid: ownerId,
              certReflectionId: id,
              policy:
                reflection.role === RoleEnum.Guardian
                  ? CertRelatedPolicyEnum.ProfileRelated
                  : CertRelatedPolicyEnum.Owner,
            },
          }
        }

        return null
      },
    },
  ],
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'

const buildConfig = createConfigBuilder('chalk')

export const kaopuApi$getToolLoginUrl = {
  config(options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(options, 'get', '/kaopu/tool-login-url', {})
  },

  /**
   * @summary 获取工具登录地址
   *  - 需要安装插件 kaopu-ai-tool
   *  - 目前限制仅有教师可获取
   * @param [options]
   * @return AxiosResponsePromise<string>
   */

  api(options?: AxiosRequestExtraConfig): AxiosResponsePromise<string> {
    return axios(kaopuApi$getToolLoginUrl.config(options)) as any
  },

  useApi<TSelected = string>(
    queryOptions?: QueryOptionsWithSelect<string, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<string, TSelected>(
      kaopuApi$getToolLoginUrl.api,
      'Kaopu.getToolLoginUrl',
      queryOptions,
    )
  },
}

// @ts-ignore
kaopuApi$getToolLoginUrl.api._name_ = `Kaopu.getToolLoginUrl`

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'Plugin.Copilot.Entry',
    getTitle: () => 'Chalk AI',
    Component: lazy(() =>
      import('./screens/Entry').then(m => ({
        default: m.CopilotEntry,
      })),
    ),
  },
  {
    name: 'Plugin.Copilot.Chat',
    getTitle: () => 'Chalk AI',
    Component: lazy(() =>
      import('./screens/Chat').then(m => ({
        default: m.CopilotChat,
      })),
    ),
  },
  {
    name: 'Plugin.Copilot.Intent.StudentProfile',
    getTitle: () => $t('查看学生详情'),
    Component: lazy(() =>
      import('./screens/Intents/StudentProfile').then(m => ({
        default: m.CopilotStudentProfile,
      })),
    ),
  },
  {
    name: 'Plugin.Copilot.Intent.ScheduleQuery',
    getTitle: () => $t('课表查询'),
    Component: lazy(() =>
      import('./screens/Intents/ScheduleQuery').then(m => ({
        default: m.CopilotScheduleQuery,
      })),
    ),
  },
]

import { SlotTypes } from '@go/plugins/slots'

/**
 * 课程班成绩 - 字段展示状态重写
 *
 * @param props - SlotTypes['classGradeVisibilityOverride']
 * @returns GradeFieldsVisible
 */
export const getClassGradeVisibilityOverride: SlotTypes['classGradeVisibilityOverride'] =
  props => {
    const { setting, gradeVisibilityRaw } = props

    return {
      ...gradeVisibilityRaw,
      /**
       * 深中定制： 当开启了「教师不可查看发布前总成绩」配置时，不管用户是不是管理员，只要有教师或班主任身份就不可见
       * 移动端目前只有教师身份的用户拥有「查看发布前的总成绩」的入口，所以不用判断, 直接返回「disabledTeacherReviewGrade」值
       */
      unpublishedFinalGradesVisible: !setting?.disabledTeacherReviewGrade,
    }
  }

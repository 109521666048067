export interface LiveInfoInterface {
  fLV?: string
  hLS?: string
  rTMP?: string
}

export class LiveInfo implements LiveInfoInterface {
  fLV?: string
  hLS?: string
  rTMP?: string

  constructor(props: LiveInfoInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      fLV: _fLV,
      hLS: _hLS,
      rTMP: _rTMP,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.fLV = _fLV
    this.hLS = _hLS
    this.rTMP = _rTMP
  }

  static propKeys = ['fLV', 'hLS', 'rTMP']
}

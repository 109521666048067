import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { getDormMenu } from './menu-slot'
import { routes } from './routes'
import { todos } from './todos'

/**
 * 初始化宿舍
 */
export const dorms: InitPluginFeature = {
  name: PluginNameEnum.Dorm,
  todos,
  register: plugins => {
    const plugin = plugins[0]

    return {
      routes,
      slots: [
        {
          name: 'asyncAppMenuItems',
          slot: getDormMenu(plugin),
        },
      ],
    }
  },
}

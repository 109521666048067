import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'applications/added-list',
    name: 'ApplicationAddedList',
    getHeaderTitle: () => $t('已添加应用'),
    Component: lazy(() =>
      import('./screens/AddedList').then(m => ({ default: m.AddedList })),
    ),
  },
  {
    path: 'applications/common-settings',
    name: 'ApplicationCommonSettings',
    getHeaderTitle: () => $t('设置常用应用'),
    Component: lazy(() =>
      import('./screens/CommonSettings').then(m => ({
        default: m.CommonSettings,
      })),
    ),
  },
]

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import type { GetFileUrlQuery } from './get-file-url-query'

const buildConfig = createConfigBuilder('chalk')

export const netdiskApi$getFileUrl = {
  config(
    id: string,
    getFileUrlQuery?: GetFileUrlQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/netdisk/files/{id}/url',
      {
        id,
      },
      getFileUrlQuery,
    )
  },

  /**
   * @summary 获取文件下载地址或直接下载文件
   * @param id 如果是网盘文件，该值为 NetdiskFile.id ；如果为附件文件（如任务附件），该值为 NetdiskFile.hash
   * @param [getFileUrlQuery]
   * @param [netdiskQuery.convertFileType] 转换文件类型，如转换为 pdf, 目前仅支持 pdf
   * @param [netdiskQuery.redirect] 重定向，自动下载文件，默认为是。否为获取下载地址
   * @param [netdiskQuery.download] 下载模式，默认为否（表示在线预览）
   * @param [netdiskQuery.netdiskShareId] 分享 id ，如果是通过分享获取的下载权限，该参数必传
   * @param [netdiskQuery.processor] 文件处理器，目前仅支持图片处理。使用 `-` 连接处理器与相关参数，使用 `_` 连接多个处理器。
   * quality-Q-80
   * zoom-fill-h-200-w-100
   * avatar-100-100
   * quality-Q-80_zoom-fill-h-200-w-100
   * @param [netdiskQuery.filename] 自定义文件名
   * @param [netdiskQuery.internal]
   * @param [options]
   * @return AxiosResponsePromise<string>
   */

  api(
    id: string,
    getFileUrlQuery?: GetFileUrlQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<string> {
    return axios(
      netdiskApi$getFileUrl.config(id, getFileUrlQuery, options),
    ) as any
  },

  useApi<TSelected = string>(
    {
      id,
      query,
    }: {
      id: string
      query?: GetFileUrlQuery
    },
    queryOptions?: QueryOptionsWithSelect<string, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<string, TSelected>(
      netdiskApi$getFileUrl.api,
      'Netdisk.getFileUrl',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
netdiskApi$getFileUrl.api._name_ = `Netdisk.getFileUrl`

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'schedule/task/:id',
    name: 'TaskScheduleDetail',
    getTitle: () => $t('日程详情'),
    Component: lazy(() =>
      import('./screens/TaskScheduleDetail').then(m => ({
        default: m.TaskScheduleDetail,
      })),
    ),
  },
  {
    name: 'TaskList',
    Component: lazy(() =>
      import('./screens/TaskList').then(m => ({
        default: m.TaskList,
      })),
    ),
    getHeaderTitle: () => $t('任务'),
  },
  {
    name: 'TaskSearch',
    Component: lazy(() =>
      import('./screens/TaskSearch').then(m => ({
        default: m.TaskSearch,
      })),
    ),
  },
  {
    name: 'StudentTaskView',
    getTitle: () => $t('任务详情'),
    Component: lazy(() =>
      import('./screens/StudentTaskView').then(m => ({
        default: m.StudentTaskView,
      })),
    ),
  },
  {
    name: 'StudentSubmissionView',
    getTitle: () => $t('查看任务提交'),
    Component: lazy(() =>
      import('./screens/StudentSubmissionView').then(m => ({
        default: m.StudentSubmissionView,
      })),
    ),
  },
  {
    name: 'StudentSubmissionEdit',
    getTitle: () => $t('编辑任务成果'),
    Component: lazy(() =>
      import('./screens/StudentSubmissionEdit').then(m => ({
        default: m.StudentSubmissionEdit,
      })),
    ),
  },
  {
    name: 'TeacherTaskView',
    Component: lazy(() =>
      import('./screens/TeacherTaskView').then(m => ({
        default: m.TeacherTaskView,
      })),
    ),
  },
  {
    name: 'TeacherTaskReview',
    Component: lazy(() =>
      import('./screens/TeacherTaskReview').then(m => ({
        default: m.TeacherTaskReview,
      })),
    ),
  },
  {
    name: 'TaskTimeline',
    getHeaderTitle: () => $t('任务动态'),
    Component: lazy(() =>
      import('./screens/TaskTimeline').then(m => ({
        default: m.TaskTimeline,
      })),
    ),
  },
  {
    name: 'TaskPrivateDiscussion',
    getHeaderTitle: () => $t('私聊'),
    Component: lazy(() =>
      import('./screens/TaskPrivateDiscussion').then(m => ({
        default: m.TaskPrivateDiscussion,
      })),
    ),
  },
]

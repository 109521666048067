/**
 * @file 底部导航栏路由
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'

import {
  ApplicationIcon,
  CalendarIcon,
  HomeIcon,
  MineIcon,
  ShortcutIcon,
} from './BottomTabsNavigator/Icons'
import { TabRoute } from './types'

export const tabRoutes: TabRoute[] = [
  {
    name: 'Home',
    Icon: HomeIcon,
    Component: lazy(() =>
      import('@go/features/home/screens/Home').then(m => ({
        default: m.Home,
      })),
    ),
    getTitle: () => $t('首页'),
  },
  {
    name: 'Calendar',
    Icon: CalendarIcon,
    Component: lazy(() =>
      import('@go/features/calendar/screens/Calendar').then(m => ({
        default: m.CalendarScreen,
      })),
    ),
    getTitle: () => $t('日程'),
  },
  /**
   * 为了追求创建效率，一些跨业务、即时性强的业务可以添加到快速发起入口中，
   * 例如德育、通知、活动、成长记录等
   */
  {
    name: 'Shortcut',
    Icon: ShortcutIcon,
    // @ts-expect-error 快速发起入口不需要组件
    Component: () => null,
    getTitle: () => $t('快速发起'),
  },
  {
    name: 'Applications',
    Icon: ApplicationIcon,
    Component: lazy(() =>
      import('@go/features/applications/screens/Center').then(m => ({
        default: m.Application,
      })),
    ),
    getTitle: () => $t('应用'),
  },
  {
    name: 'Mine',
    Icon: MineIcon,
    Component: lazy(() =>
      import('@go/features/accounts/screens/Mine').then(m => ({
        default: m.Mine,
      })),
    ),
    getTitle: () => $t('我的'),
  },
]

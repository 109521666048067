/**
 * @file 教师考勤
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { routes } from './routes'
import { getTeacherAttendanceMenu } from './slots'

export const teacherAttendance: InitPluginFeature = {
  name: PluginNameEnum.TeacherAttendance,
  register: plugins => {
    return {
      routes,
      slots: [
        {
          name: 'asyncAppMenuItems',
          slot: getTeacherAttendanceMenu(plugins[0]),
        },
      ],
    }
  },
}

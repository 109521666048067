import React from 'react'

import { EnabledPlugin, getPluginIcon } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'

import { LogoRender } from '@go/components/Logo/Render'
import { Menu } from '@go/features/applications/types'

/**
 * 生成宿舍菜单
 *
 * @param plugin - 宿舍插件
 * @returns menu
 */
export const getDormMenu =
  (plugin: EnabledPlugin) => async (): Promise<Menu[] | null> => {
    return [
      {
        key: PluginNameEnum.Dorm,
        pluginName: PluginNameEnum.Dorm,
        icon: ({ size } = {}) => (
          <LogoRender size={size} logo={getPluginIcon(plugin)} />
        ),
        getTitle: () => $t('宿舍'),
        screen: {
          name: 'Plugin.Dorm',
          params: undefined,
        },
      },
    ]
  }

/**
 * @file utils
 */

import { upperFirst } from '@seiue/util'

import {
  Grade,
  Score,
  ScoreReq,
  TrendGrade,
  TrendScore,
} from 'packages/sdks-next/vnas'

type ScoreType = ScoreReq | Grade | Score | TrendScore | TrendGrade
type ScoreKeyType =
  | keyof ScoreReq
  | keyof Grade
  | keyof Score
  | keyof TrendScore
  | keyof TrendGrade
type GetResitkey<T extends ScoreKeyType> = T extends `resit${string}`
  ? T
  : never
type GetNoResitkey<T extends ScoreKeyType> = T extends `resit${string}`
  ? never
  : T

type NoResitScoreKeyType = GetNoResitkey<ScoreKeyType>
type ResitScoreKeyType = GetResitkey<ScoreKeyType>

/**
 * @utils
 * 根据补考成绩的状态获取最终补考成绩
 */
export const getResitScore = (
  score: Grade | Score | TrendScore | TrendGrade | undefined,
) => {
  const { resitScoreStatus, resitScore } = score || {}
  return resitScoreStatus !== null ? '-' : resitScore
}

/**
 * @utils
 * 根据补考成绩的状态获取最终等级
 */
export const getResitLevel = (
  { resitScoreStatus, resitInvalidReason, resitLevel }: Grade | TrendGrade,
  hideInvalidReason?: boolean,
) =>
  resitScoreStatus === null
    ? resitLevel
    : hideInvalidReason
    ? '-'
    : resitInvalidReason

/**
 * @utils
 * 检查是否存在补考
 */
export const isResit = (score?: ScoreType | null) => {
  if (!score) return false
  return 'resitScoreStatus' in score
    ? 'resitScoreId' in score
      ? !!score.resitScoreId
      : 'resitGradeId' in score
      ? !!score.resitGradeId
      : false
    : false
}

/**
 * @utils
 * 选取补考成绩数据，可以指定原始 key 和补考 key
 */
export const pickResitGrade = (
  score: ScoreType,
  keys: (NoResitScoreKeyType | [NoResitScoreKeyType, ResitScoreKeyType])[],
): Partial<ScoreType> => {
  const newScore: { [param: string]: ScoreType[keyof ScoreType] } = {}
  keys.forEach(key => {
    let originKey: NoResitScoreKeyType
    let resitKey: ResitScoreKeyType
    if (typeof key === 'string') {
      originKey = key
      resitKey = `resit${upperFirst(key)}` as ResitScoreKeyType
    } else {
      ;[originKey, resitKey] = key
    }

    if (originKey in score) {
      // @ts-ignore
      newScore[originKey] = score[resitKey] || score[originKey]
    }
  })

  return newScore
}

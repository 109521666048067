/**
 * @file 学案
 */

import { InitPluginFeature } from '@go/plugins/types'

import { todos } from './todos'

export const handout: InitPluginFeature = {
  name: 'handout',
  todos,
}

import { lazy } from 'react'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'SelectSchoolModal',
    isModal: true,
    Component: lazy(() =>
      import('./screens/SchoolSelect/SelectSchool').then(m => ({
        default: m.SelectSchoolModal,
      })),
    ),
  },
  {
    name: 'SearchSchoolModal',
    isModal: true,
    Component: lazy(() =>
      import('./screens/SchoolSelect/SearchSchool').then(m => ({
        default: m.SearchSchoolModal,
      })),
    ),
  },
  {
    name: 'SelectDistrictModal',
    isModal: true,
    Component: lazy(() =>
      import('./screens/SchoolSelect/SelectDistrict').then(m => ({
        default: m.SelectDistrictModal,
      })),
    ),
  },
  {
    name: 'SelectReflectionModal',
    isModal: true,
    Component: lazy(() =>
      import('./screens/SelectReflection').then(m => ({
        default: m.SelectReflectionModal,
      })),
    ),
  },
  {
    name: 'DefaultReflectionSelectorModal',
    isModal: true,
    Component: lazy(() =>
      import('./screens/SelectReflection/DefaultReflectionSelector').then(
        m => ({ default: m.DefaultReflectionSelectorModal }),
      ),
    ),
  },
]

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'Plugin.MoralAssessments',
    Component: lazy(() =>
      import('./screens/Center').then(m => ({ default: m.AssessmentCenter })),
    ),
  },
  {
    name: 'Plugin.SearchMoralAssessments',
    Component: lazy(() =>
      import('./screens/SearchAssessments').then(m => ({
        default: m.SearchAssessments,
      })),
    ),
    getHeaderTitle: () => $t('搜索评价'),
  },
  {
    name: 'Plugin.MoralAssessmentEntryStructures',
    Component: lazy(() =>
      import('./screens/GradeEntry/Structures').then(m => ({
        default: m.MoralGradeEntryStructures,
      })),
    ),
  },
  {
    name: 'Plugin.MultiEvaluateTargetGradeEntry',
    Component: lazy(() =>
      import('./screens/GradeEntry/MultiEvaluateTarget').then(m => ({
        default: m.MoralAssessmentGradeEntry,
      })),
    ),
  },
  {
    name: 'Plugin.SingleEvaluateTargetGradeEntry',
    Component: lazy(() =>
      import('./screens/GradeEntry/SingleEvaluateTarget').then(m => ({
        default: m.MoralAssessmentGradeEntry,
      })),
    ),
  },
  {
    name: 'Plugin.MoralAssessment.AnalysisStructures',
    Component: lazy(() =>
      import('./screens/GradeView/Structures').then(m => ({
        default: m.MoralAssessmentAnalysisStructures,
      })),
    ),
  },
  {
    name: 'Plugin.MoralAssessment.Analysis',
    Component: lazy(() =>
      import('./screens/GradeAnalyses').then(m => ({
        default: m.MoralAssessmentAnalyses,
      })),
    ),
  },
  {
    name: 'Plugin.SearchMoralTrends',
    Component: lazy(() =>
      import('./screens/SearchTrends').then(m => ({
        default: m.SearchTrends,
      })),
    ),
    getHeaderTitle: () => $t('搜索动态'),
  },
  {
    path: 'plugins/moral-assessments/:id/entry/:itemId',
    name: 'Plugin.MoralAssessmentShortcutEntry',
    getHeaderTitle: () => $t('随手评'),
    Component: lazy(() =>
      import('./screens/ShortcutEntry/index').then(m => ({
        default: m.MoralAssessmentEntry,
      })),
    ),
  },
]

/**
 * @file Webview 路由弹窗头部左侧的组件
 */
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { ButtonIcon, Layout, Flex } from '@seiue/rn-ui'
import React from 'react'

export const WebviewModalHeaderLeft: React.FC<{
  onClose: () => void
  onBack?: () => void
  canGoBack: boolean
}> = ({ onClose, canGoBack, onBack }) => (
  <Flex>
    <ButtonIcon icon={faTimes} size={22} onPress={onClose} />
    {!canGoBack ? null : <Layout.HeaderLeft size={18} onPress={onBack} />}
  </Flex>
)

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { getClassGradeVisibilityOverride } from './slots/get-class-grade-visibility-override'

/**
 * 深圳中学定制补考规则
 */
export const szCustomResitRule: InitPluginFeature = {
  name: PluginNameEnum.SZCustomResitRule,
  register: () => ({
    slots: [
      {
        name: 'classGradeVisibilityOverride',
        slot: getClassGradeVisibilityOverride,
      },
    ],
  }),
}

/**
 * @file hooks
 */

import {
  useHasPluginWithAnyPermission,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import {
  ResitStatus,
  TrendEvent,
  TrendGrade,
  TrendScore,
} from 'packages/sdks-next/vnas'

import { getResitLevel, getResitScore, isResit, pickResitGrade } from './utils'

const checkResit = (grade: TrendScore | TrendGrade) =>
  isResit(grade) && grade.resitStatus === ResitStatus.Published

const dealResitScore = ({
  score,
  grade,
}: {
  score?: TrendScore
  grade?: TrendGrade
}) => {
  if (score) return score.resitScoreId ? getResitScore(score) : score.score
  if (grade)
    return grade.resitScoreStatus === null && grade.resitGradeId
      ? getResitScore(grade)
      : grade.realScore
  return null
}

/**
 * 处理学生成绩动态数据学生补考成绩只能已发布的补考成绩
 *
 * @param events - 事件
 * @returns 处理后的数据
 */
export const useResitTrends = (events: TrendEvent[] | null) => {
  const resitPluginEnable = useHasPluginWithAnyPermission(PluginEnum.ExamResit)
  return resitPluginEnable
    ? events?.map(trend => {
        const { oldScore, newScore, oldGrade, newGrade } = trend
        const getScore = (_score: TrendScore | null | undefined) =>
          _score
            ? checkResit(_score)
              ? {
                  ..._score,
                  score: dealResitScore({ score: _score }),
                  ...pickResitGrade(_score, ['invalidReason']),
                }
              : _score
            : null

        const getGrade = (_grade: TrendGrade | null | undefined) =>
          _grade
            ? checkResit(_grade)
              ? {
                  ..._grade,
                  realScore: dealResitScore({ grade: _grade }),
                  level: getResitLevel(_grade) || _grade.level,
                  ...pickResitGrade(_grade, ['credit', 'gpa']),
                }
              : _grade
            : null

        return {
          ...trend,
          oldScore: getScore(oldScore),
          newScore: getScore(newScore),
          oldGrade: getGrade(oldGrade),
          newGrade: getGrade(newGrade),
        } as TrendEvent
      })
    : null
}

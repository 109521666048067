import { moment } from '@seiue/moment'

import { InitFeatureParams } from '../types'

import { routes } from './routes'
import { todos } from './todos'

/**
 * 初始化考勤
 *
 * @param param0 - 参数
 */
export const initAttendances = (param0: InitFeatureParams) => {
  const { addRoutes, registerTodos, registerMessageNavigator } = param0

  addRoutes(routes)
  registerTodos(todos)

  registerMessageNavigator('miss_attendance', (message: any) => [
    'AttendanceEntryScreen',
    {
      lessonId: message.attributes.lessonId,
      classId: message.attributes.classId,
      lessonDate: moment(message.createdAt).format('YYYY-MM-DD'),
    },
  ])

  registerMessageNavigator('abnormal_attendance', message => [
    'AttendMessageScreen',
    { id: message.id },
  ])
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { NetdiskDirReq, NetdiskDirReqInterface } from './netdisk-dir-req'
import { NetdiskFile, NetdiskFileInterface } from './netdisk-file'
import type { EnsureDirsQuery } from './ensure-dirs-query'

const buildConfig = createConfigBuilder('chalk')

export const netdiskApi$ensureDirs = {
  config(
    ownerId: number,
    netdiskDirReq: NetdiskDirReqInterface[],
    ensureDirsQuery?: EnsureDirsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'put',
      '/netdisk/owners/{ownerId}/files/dirs',
      {
        ownerId,
      },
      ensureDirsQuery,
      netdiskDirReq,
      {
        format: 'json',
        isArray: true,
        defaults: {},
      },
    )
  },

  /**
   * @summary 批量创建文件夹，如果不存在将直接跳过
   * @param ownerId NetdiskOwner.id （网盘的 ID ，非用户或班级 ID）
   * @param netdiskDirReq
   * @param [ensureDirsQuery]
   * @param [netdiskQuery.fileId] 当前file的ID
   * @param [options]
   * @return AxiosResponsePromise<NetdiskFile[]>
   */

  api(
    ownerId: number,
    netdiskDirReq: NetdiskDirReqInterface[],
    ensureDirsQuery?: EnsureDirsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<NetdiskFile[]> {
    return axios(
      netdiskApi$ensureDirs.config(
        ownerId,
        netdiskDirReq,
        ensureDirsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new NetdiskFile(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({
        ownerId,
        payload,
        query,
      }: {
        ownerId: number
        payload: NetdiskDirReqInterface[]
        query?: EnsureDirsQuery
      }) => netdiskApi$ensureDirs.api(ownerId, payload, query),
      mutationOptions,
    )
  },
}

// @ts-ignore
netdiskApi$ensureDirs.api._name_ = `Netdisk.ensureDirs`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  ReceivedNotification,
  ReceivedNotificationInterface,
} from './received-notification'
import type { GetReceivedNotificationsQuery } from './get-received-notifications-query'

const buildConfig = createConfigBuilder('chalk')

export const notificationApi$getReceivedNotifications = {
  config(
    idIn: string,
    getReceivedNotificationsQuery?: GetReceivedNotificationsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/notification/received-notifications',
      {
        idIn,
      },
      getReceivedNotificationsQuery,
    )
  },

  /**
   * @summary 获取通知接收者详情
   * 以接收人的视角返回通知的基础信息及接收人的统计信息。
   * @param idIn
   * @param [getReceivedNotificationsQuery]
   * @param [notificationQuery.actingAsSender]
   * @param [notificationQuery.expand] 支持 receiver
   * @param [options]
   * @return AxiosResponsePromise<ReceivedNotification[]>
   */

  api<const TQuery extends GetReceivedNotificationsQuery>(
    idIn: string,
    getReceivedNotificationsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<ReceivedNotification, TQuery['expand']>[]> {
    return axios(
      notificationApi$getReceivedNotifications.config(
        idIn,
        getReceivedNotificationsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new ReceivedNotification(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends GetReceivedNotificationsQuery,
    TSelected = Expand<ReceivedNotification, TQuery['expand']>[],
  >(
    {
      idIn,
      query,
    }: {
      idIn: string
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<ReceivedNotification, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<
      Expand<ReceivedNotification, TQuery['expand']>[],
      TSelected
    >(
      notificationApi$getReceivedNotifications.api,
      'Notification.getReceivedNotifications',
      queryOptions,
      idIn,
      query,
    )
  },
}

// @ts-ignore
notificationApi$getReceivedNotifications.api._name_ = `Notification.getReceivedNotifications`

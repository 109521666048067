/**
 * @file 版本更新检查
 *
 * 更新方式
 * iOS：跳转到 AppStore
 * Android：跳转到 apk 的下载地址，下载完成安装即可
 */

import { getPureAxios } from '@seiue/axios'
import { env, isProduction } from '@seiue/env'
import { moment } from '@seiue/moment'
import { Text, Button, Image, Modal, Toast } from '@seiue/rn-ui'
import { useOnAppState, Clipboard } from '@seiue/rn-util'
import { isReactNative } from '@seiue/util'
import React, { useImperativeHandle } from 'react'
import { Platform, Linking, View } from 'react-native'
import styled from 'styled-components/native'

import { useIsAppStoreSchool } from 'packages/features/schools/hooks'
import { $t } from 'packages/locale'
import {
  PlatformEnum,
  NewestAppver,
  appverApi$checkNewest,
} from 'packages/sdks-next/chalk'
import { BackgroundColor } from 'packages/themed/native'

import { useDispatch, useSelector } from '@go/stores'
import {
  getVersion,
  isHuawei,
} from '@go/utils/native-modules/device-info-module'

import bannerIcon from './version_banner.png'

const currentVersion = getVersion()
const isIOS = Platform.OS === 'ios'
const platform = isIOS ? PlatformEnum.Ios : PlatformEnum.Android
const itunesID = env('CLIENT_APP_ITUNES_ID')

/**
 * 版本检查的时间间隔
 * 生产环境：24 小时
 * 测试环境：5 分钟
 */
const interval = (
  isProduction() ? moment.duration(24, 'hours') : moment.duration(5, 'minutes')
).valueOf() as number

type VersionUpdatesCheckerProps = {
  /**
   * 检查更新的方式是否为手动触发，默认自动触发。两者区别为：
   * 自动触发：启动 App 时，判断当前时间与上次检查的时间间隔，超出间隔则触发。生产环境间隔为 8 小时，测试环境为 5 分钟。
   * 手动触发：由外层手动调用 checkVersion 检查更新。
   */
  manually?: boolean
}

export type VersionUpdatesCheckerRef = {
  /**
   * 检查新版本
   *
   * @returns 是否存在新版本
   */
  checkVersion: () => Promise<boolean>
}

/**
 * 版本更新检查
 *
 * @param props - {@link VersionUpdatesCheckerProps}
 * @returns 版本更新检查
 */
export const VersionUpdatesChecker = React.forwardRef<
  VersionUpdatesCheckerRef,
  VersionUpdatesCheckerProps
>((props, ref) => {
  const { manually } = props

  const dispatch = useDispatch()

  // 上次显示版本更新弹窗的时间戳
  const lastTimestamp = useSelector(state => state.versionUpdates.lastTimestamp)

  const [modalVisible, setModalVisible] = React.useState(false)
  const [latestVersion, setLatestVersion] = React.useState<NewestAppver>()

  const isAppStoreSchool = useIsAppStoreSchool()

  const checkVersion = React.useCallback(async (): Promise<boolean> => {
    if (!isReactNative) return false

    // 因为华为商店的隐私政策问题，安装在华为设备上并登录测试审核学校时，不主动弹出升级提示框
    if (!manually && (await isHuawei()) && isAppStoreSchool) return false

    if (isIOS) {
      // 如果在 iOS 下，商店版本与当前版本号相同，说明没有可更新的版本
      const appStoreVersion = (
        await getPureAxios().get(
          `https://itunes.apple.com/cn/lookup?id=${itunesID}`,
        )
      ).data.results[0]?.version

      if (appStoreVersion === currentVersion) return false
    }

    const { data } = await appverApi$checkNewest.api(
      platform,
      currentVersion,
      {
        // 后端规定，chalkapp 表示 c3app
        app: 'chalkapp',
        clusterName: env('CLUSTER_NAME'),
      },
      {
        omitAuthorizationHeader: true,
      },
    )

    setLatestVersion(data)

    const showModal = () => {
      dispatch.versionUpdates.setLastTimestamp(Date.now())
      setModalVisible(true)
    }

    const shouldShowModal = Date.now() - lastTimestamp >= interval

    if (data.updatable) {
      // 手动触发直接打开更新弹窗
      if (manually) {
        showModal()
        return true
      }

      // 不管强制还是非强制更新，都判断一下上次显示时间
      if (shouldShowModal) {
        showModal()
      }

      return true
    }

    return false
  }, [dispatch.versionUpdates, isAppStoreSchool, lastTimestamp, manually])

  const handleOnAppState = React.useCallback(() => {
    // 仅自动触发时才进行检测
    if (!manually) {
      checkVersion()
    }
  }, [checkVersion, manually])

  useOnAppState('active', handleOnAppState)

  useImperativeHandle(ref, () => ({
    checkVersion,
  }))

  if (!latestVersion || !isReactNative) return null

  const isForced = latestVersion.isBc

  const gotoUpdate = async () => {
    const url = isIOS
      ? `https://itunes.apple.com/cn/app/xi-yue-xiao-yuan/id${itunesID}`
      : latestVersion.apk

    if (!url) {
      setModalVisible(false)
      Modal.alert({ title: $t('下载失败'), message: $t('找不到安装包') })
      return
    }

    Linking.openURL(url).catch(() => {
      setModalVisible(false)
      Modal.alert({
        title: $t('下载失败'),
        message: $t(
          '因系统设置原因，无法自动打开浏览器。请复制安装包地址，在浏览器中手动粘贴并访问。',
        ),
        okText: $t('复制链接'),
        onOk: () => {
          Clipboard.setString(url)
          Toast.show($t('已复制'))
        },
      })
    })
  }

  return (
    <Modal
      visible={modalVisible}
      maskClosable={false}
      contentStyle={{
        overflow: 'hidden',
      }}
      maskStyle={{ backgroundColor: BackgroundColor.Backdrop }}
    >
      <View>
        <Image
          source={bannerIcon}
          style={{ height: 128, width: '100%' }}
          resizeMode="cover"
        />
      </View>
      <Title>
        {$t('发现新版本 v{version}', { version: latestVersion.version })}
      </Title>
      <Notice $isForced={isForced}>
        {isForced
          ? $t('请更新到最新版本，本次更新包含重要改动，旧版本可能无法正常使用')
          : $t('建议更新到最新版本，使用更稳定，体验更顺畅')}
      </Notice>
      <Footer>
        <Actions>
          <Action
            type="primary"
            label={$t('去下载')}
            style={{ borderRadius: 18 }}
            onPress={gotoUpdate}
          />
          <Action
            type="text-primary"
            size="small"
            label={$t('以后再说')}
            onPress={() => setModalVisible(false)}
          />
        </Actions>
      </Footer>
    </Modal>
  )
})

const Title = styled(Text)`
  color: ${p => p.theme.text._1};
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  margin-vertical: 16px;
`

const Footer = styled(View)`
  padding: 16px 24px;
`

const Actions = styled(View)`
  margin-top: 24px;
  margin-bottom: 4px;
`

const Action = styled(Button)`
  margin-bottom: 8px;
`

const Notice = styled(Text)<{ $isForced: boolean }>`
  color: ${p =>
    p.$isForced ? p.theme.status.warning.background : p.theme.text._3};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding-horizontal: 44px;
  text-align: center;
`

/**
 * @file 友盟
 */

import { NativeModules } from 'react-native'

const { UMAnalyticsModule } = NativeModules

/**
 * 注册友盟
 */
export function register() {
  UMAnalyticsModule.register()
}

import { useNavigation } from '@react-navigation/native'
import { Layout, Button } from '@seiue/rn-ui'
import React from 'react'
import styled from 'styled-components/native'

import { $t } from 'packages/locale'

import { MainStackNavigationProp } from '@go/router'

/**
 * 404 错误显示
 *
 * @returns JSX.Element
 */
export const NotFound: React.FC = () => {
  const navigation = useNavigation<MainStackNavigationProp<'Tabs'>>()

  return (
    <Layout.Container hasHeader={false}>
      <Layout.Content>
        <Content>
          <Label>{$t('当前页面不存在')}</Label>
          <Button
            type="primary"
            onPress={() => navigation.goBack()}
            label={$t('点击返回')}
          />
        </Content>
      </Layout.Content>
    </Layout.Container>
  )
}

const Content = styled.View`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0 48px;
`

const Label = styled.Text`
  margin-bottom: 24px;
  color: #9398a1;
  font-size: 18px;
`

/**
 * @file 无代码审批待办
 */
import React from 'react'

import { RegisterWorkflowRenderType } from 'packages/features/workflows/register'

export const workflowRenders: RegisterWorkflowRenderType[] = [
  {
    bizType: 'no-code',
    render: {
      TodoCard: React.lazy(() =>
        import('./TodoCard').then(m => ({
          default: m.NoCodeTodoCard,
        })),
      ),
    },
  },
]

/**
 * @file 消息相关函数
 */

import { Toast } from '@seiue/rn-ui'
import { isWeb } from '@seiue/rn-util'
import { isFunction } from '@seiue/util'
import { useCallback } from 'react'

import { useReadMessage } from 'packages/features/messages/hooks'
import {
  registerMessageNavigator as originRegister,
  NativeNavigator,
  NativeNavigatorRes,
  WebNavigatorRes,
  getMessageNavigatorResult,
  isWebNavigatorRes,
  getGoHijackMessageClickFn,
} from 'packages/features/messages/register'
import { useCurrentReflection } from 'packages/features/sessions'
import { $t } from 'packages/locale'
import {
  Message,
  SecuredReflection,
  Reflection,
} from 'packages/sdks-next/chalk'

import { MainStackParams, useMainNavigation } from '@go/router'

/**
 * 获取目标消息的导航器
 *
 * @param message - 消息
 * @param currentReflection - 当前登录用户
 * @returns 消息的导航器
 */
export const getMessageNavigator = (
  message: Message,
  currentReflection: SecuredReflection | Reflection,
) =>
  getMessageNavigatorResult(message, currentReflection) as
    | NativeNavigatorRes
    | WebNavigatorRes
    | null

export type MessageNavigatorParams = {
  type: string | string[]
  navigator: NativeNavigator
}

/**
 * 注册消息的导航器
 *
 * @param type - 消息类型
 * @param navigator - 消息导航器
 */
export const registerMessageNavigator = (
  type: MessageNavigatorParams['type'],
  navigator: MessageNavigatorParams['navigator'],
) => {
  originRegister(type, navigator)
}

/**
 * 获取消息的导航器
 *
 * @param msg - 消息
 * @returns 导航器
 */
export const useMessageNavigator = (
  msg: Message & {
    attributes?: {
      actionType?: 'admin_class'
      adminClassId?: number
      // message 携带 url，那么点击消息时直接跳转到 url
      url?: string
    }
  },
) => {
  const currentReflection = useCurrentReflection()

  const navigatorProps = getMessageNavigator(msg, currentReflection)

  const navigation = useMainNavigation()

  const read = useReadMessage()

  const navigate = useCallback(async () => {
    const hijackClick = getGoHijackMessageClickFn(msg.type)

    if (hijackClick) {
      hijackClick(msg)
      return
    }

    if (!msg.readed) {
      read(msg.id)
    }

    const { url } = msg.attributes || {}

    if (url) {
      if (isWeb) {
        window.open(url)
        return
      }

      navigation.navigate('WebviewModal', {
        title: msg.title || $t('链接'),
        uri: url,
      })

      return
    }

    const handleNavigator = (
      navigatorResult: NativeNavigatorRes | WebNavigatorRes | null,
    ) => {
      if (!navigatorResult) {
        Toast.show($t('此消息无更多内容'))
        return
      }

      // 现在 go 上注册了 Web 的导航，以此来获取 Web 的导航信息
      if (isWebNavigatorRes(navigatorResult)) {
        navigation.navigate('MessageDetail', {
          id: msg.id,
          webNavigator: JSON.stringify(navigatorResult),
        })

        return
      }

      const [routeName, routeParams] = navigatorResult
      navigation.navigate(routeName as keyof MainStackParams, routeParams)
    }

    if (isFunction(navigatorProps)) {
      const result = await navigatorProps()
      handleNavigator(result)
    } else {
      handleNavigator(navigatorProps)
    }
  }, [msg, navigation, navigatorProps, read])

  return navigate
}

/**
 * @file 启动页
 * 同时调度 session 各状态的路由跳转以及启动图的显示/隐藏
 */
import { isReactNative } from '@seiue/rn-util'
import { userAgent } from '@seiue/ua'
import React from 'react'

import {
  useForceBindPhone,
  useForceResetInitPassword,
} from 'packages/features/reflections'
import {
  useIsApolloLogin,
  useIsNoLogged,
  useIsLoggedIn,
} from 'packages/features/sessions/hooks'
import { useCurrentReflection } from 'packages/features/sessions/utils'
import { isPublicShareURL } from 'packages/features/shares/utils'

import { useSelector } from '@go/stores'
import { getRouteNameByInitialPath } from '@go/utils/navigator'
import Splash from '@go/utils/react-native-splash-screen'

import { useRootNavigation } from './hooks'

// 是否已导航到过初始路由
let initialPathNavigated = false

/**
 * 启动页
 */
export const useSessionNavigationTransfer = () => {
  const navigation = useRootNavigation()

  const isLoggedIn = useIsLoggedIn()
  const isNoLogged = useIsNoLogged()

  const { role, passwordChangeNeeded } = useCurrentReflection()

  const { data: forceBindSetting, loading: loadingForceBindSign } =
    useForceBindPhone({
      role,
      disable: !isLoggedIn,
    })

  const skipResetPassword = useSelector(
    state => state.session.skipResetPassword,
  )

  const { data: forceResetInitSetting, loading: loadingForceResetSign } =
    useForceResetInitPassword({ role, disable: !isLoggedIn })

  const forceResetInit =
    passwordChangeNeeded && (forceResetInitSetting || !skipResetPassword)

  const isApolloLogin = useIsApolloLogin()

  const forceBind =
    forceBindSetting && !isApolloLogin && !userAgent.isC3AppWebView

  const isUserBindPhone = useSelector(
    ({ session }) => !!session.currentUser && !!session.currentUser.phone,
  )

  const skipBindPhone = useSelector(({ session }) => session.skipBindPhone)

  const bindingAccount = useSelector(({ app }) => app.bindingAccount)

  // 多语言未初始化时不跳转，避免出现「绑定手机/修改初始密码页」 和用户设置语言不一致的情况
  const localeInited = useSelector(({ locale }) => locale.inited)

  const loading =
    bindingAccount ||
    loadingForceBindSign ||
    loadingForceResetSign ||
    (!isLoggedIn && !isNoLogged) ||
    !localeInited

  const showDefaultReflectionModal = useSelector(
    ({ session }) =>
      !!session.currentUser?.reflections?.length &&
      !session.currentUser.reflections.find(
        reflection => !!reflection.isDefault,
      ),
  )

  React.useEffect(() => {
    // 如果正在绑定账号，那么不要因为绑定账号的过程中，session 发生什么变化就开始切换路由
    if (loading) return

    if (isPublicShareURL()) {
      Splash.hide()

      return
    }

    if (isNoLogged) {
      if (isReactNative) {
        /*
         * 登录失败时
         * 登出时，立刻切换回 LoginScreen，以防止 reflection 的消失导致原已登录页面显示错误
         */
        navigation.navigate('LoginScreen', {})
      } else {
        return
      }
    } else if (
      !isUserBindPhone &&
      (forceBind || !skipBindPhone) &&
      !isApolloLogin &&
      !userAgent.isC3AppWebView &&
      isLoggedIn
    ) {
      /*
       * 未绑定手机
       */
      navigation.navigate('BindPhoneModal')
    } else if (
      showDefaultReflectionModal &&
      !isApolloLogin &&
      !userAgent.isC3AppWebView &&
      isLoggedIn
    ) {
      /*
       * 没有默认身份时
       * 弹出默认身份选择器
       */
      navigation.navigate('DefaultReflectionSelectorModal')
    } else if (
      forceResetInit &&
      !isApolloLogin &&
      !userAgent.isC3AppWebView &&
      isLoggedIn
    ) {
      /*
       * 未重置初始密码
       */
      navigation.navigate('ResetPasswordModal')
    } else {
      // 已选择身份，延迟进入 Main，以保证 Main 已渲染完毕
      requestAnimationFrame(() => {
        // 如果是 RN 环境，那么直接导航到主页
        if (isReactNative) {
          navigation.navigate('Main')

          // 如果已经导航过初始路由，那么不再导航，避免 initialPath 所对应的页面里的 navigate 和此 Hook 的 navigate 形成循环导航
        } else if (!initialPathNavigated) {
          // go web 要先根据 pathname 获取到路由才能跳转
          const { routeName, params } = getRouteNameByInitialPath()

          // 如果此时初始路由为中转路由，那么直接导航到主页，否则就一直位于当前页面了
          if (
            [
              // 绑定手机
              'BindPhoneModal',
              // 重置密码
              'ResetPasswordModal',
            ].includes(routeName)
          ) {
            navigation.navigate('Main')
          } else {
            // @ts-expect-error 无法确定类型
            navigation.navigate(routeName || 'Main', params)
          }

          initialPathNavigated = true
        }
      })
    }

    Splash.hide()
  }, [
    isUserBindPhone,
    showDefaultReflectionModal,
    navigation,
    loading,
    isApolloLogin,
    forceBind,
    forceResetInit,
    isNoLogged,
    isLoggedIn,
    skipBindPhone,
  ])
}

/**
 * @file 走马灯（轮播展示的区域）
 *
 * TODO 由于 @seiue/rn-ui README 未跑通该组件，暂时放在这里
 */

import { Swiper as SwiperRaw } from './Swiper'
import { SwiperItem } from './SwiperItem'

export * from './types'

type MergedSwiper = typeof SwiperRaw & {
  Item: typeof SwiperItem
}

export const Swiper = SwiperRaw as MergedSwiper
Swiper.Item = SwiperItem

import { InitFeatureParams } from '../types'

import { routes } from './routes'

/**
 * 初始化账号相关
 *
 * @param param0 - 初始化账号
 */
export const initAccounts = (param0: InitFeatureParams) => {
  const { addRoutes } = param0
  addRoutes(routes)
}

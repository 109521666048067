import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'adminclass/:id',
    name: 'AdminClass',
    getTitle: () => $t('行政班详情'),
    getHeaderTitle: () => $t('行政班详情'),
    Component: lazy(() =>
      import('./screens/AdminClass').then(m => ({
        default: m.AdminClassScreen,
      })),
    ),
  },
  {
    name: 'AdminClassInputAttendance',
    getTitle: () => $t('录入考勤'),
    getHeaderTitle: () => $t('行政班考勤'),
    Component: lazy(() =>
      import('./screens/InputAttendance').then(m => ({
        default: m.AdminClassInputAttendanceModal,
      })),
    ),
  },
]

/**
 * @file 腾讯移动推送 store
 */
import { env } from '@seiue/env'
import { PhonePermission } from '@seiue/rn-ui'
import { isIOS } from '@seiue/rn-util'
import retry from 'async-retry'
import { Platform } from 'react-native'

import {
  userApi$syncUserDevice,
  userApi$deleteUserDevice,
} from 'packages/sdks-next/chalk'

import { filterAndReportError } from '@go/utils/error-handler'
import {
  isEmulator,
  getDeviceName,
  getUniqueId,
} from '@go/utils/native-modules/device-info-module'
import {
  setDebugMode,
  register as registerPush,
  unregister as unregisterPush,
} from '@go/utils/native-modules/tpush-native-module'

import { RootState } from '.'

interface State {
  pushToken?: string
}

const state: State = {}

const reducers = {
  setPushToken(prevState: State, pushToken: string) {
    return { ...prevState, pushToken }
  },
}

const effects = (dispatch: any) => ({
  async register() {
    // iOS 模拟器不支持注册推送
    if (isIOS && (await isEmulator())) return

    // 请求授予通知权限
    const results = await PhonePermission.requestNotifications([
      'alert',
      'sound',
    ])

    // 如果未获得授予通知权限，将不会注册推送服务
    if (results.status !== 'granted') return

    const isProduction = env('ENV') === 'production'

    // 测试环境开启 Debug 模式
    setDebugMode(!isProduction)

    /**
     * 注册推送服务。
     * 在特定网络下（比如 pkuschool），有一定概率超时，
     * 所以加入重试机制（每次超时等待 300ms 后重试，最多重试 20 次）
     */
    try {
      await retry(
        async () => {
          const pushToken = await registerPush()
          dispatch.push.setPushToken(pushToken)

          const [uniqueId, deviceName] = await Promise.all([
            getUniqueId(),
            getDeviceName(),
          ])

          // 绑定 token 和 user
          await userApi$syncUserDevice.api(uniqueId, {
            pushToken,
            platform: Platform.OS,
            deviceName,
          })
        },
        {
          retries: 20,
          factor: 1,
          randomize: false,
          minTimeout: 300,
        },
      )
    } catch (err) {
      // 推送服务注册静默失败
    }
  },

  async unregister(payload: void, { session }: RootState) {
    /**
     * 1. App 首次加载也会调用该方法，但此时还未有 session 信息
     * 2. 同时解决 Android 的合规问题，getUniqueId 需在用户同意隐私政策后调用
     */
    if (!session.oAuthToken) return

    // iOS 模拟器不支持注册推送
    if (isIOS && (await isEmulator())) return

    try {
      await userApi$deleteUserDevice.api(await getUniqueId())
    } catch (e) {
      filterAndReportError(e, {
        ExceptionType: 'DeleteUserDevice',
      })
    }

    unregisterPush()
  },
})

export const push = {
  state,
  reducers,
  effects,
}

/**
 * @file Entry file of app for web
 */

import './config'

import 'react-app-polyfill/stable'
import '@seiue/polyfill'
import '@seiue/ui/build/module/global-styles.less'

import { initAxios } from '@seiue/axios'
import { initSentry } from '@seiue/sentry'
import React from 'react'
import { AppRegistry } from 'react-native'
import { BrowserRouter as Router } from 'react-router-dom'

import { getOAuthInfoFn, getReflectionFn } from 'packages/features/sessions'

import { App } from '@go/App'
import { store } from '@go/stores'

import packageJSON from '../package.json'

initSentry({
  dsn: 'https://0f0167938e5d471198cf50ccf6fb0707@sentry.seiue.com/51',
  release: packageJSON.version,
})

initAxios({
  getOAuthInfo: getOAuthInfoFn(store),
  getReflection: getReflectionFn(store),
  shouldReRequestWhen401: true,
  clearOAuthInfo: () => {
    store.dispatch['session'].clearOAuthToken()
  },
})

const WebApp: React.FC = () => {
  return (
    /**
     * FIXME
     * 纯粹是为了兼容报表中心的表格组件（依赖 DataTable -> react-router-dom）
     * 解耦之后就可以移除了
     */
    <Router>
      <App />
    </Router>
  )
}

AppRegistry.registerComponent('c3app', () => WebApp)

AppRegistry.runApplication('c3app', {
  rootTag: document.getElementById('root'),
})

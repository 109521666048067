import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { routes } from './routes'

export const copilot: InitPluginFeature = {
  name: PluginNameEnum.Copilot,
  register: () => ({
    routes,
  }),
}

/**
 * @file 约谈模块路由
 */
import { lazy } from 'react'

import { $t } from 'packages/locale/LocaleProvider'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'PluginUserChats',
    getHeaderTitle: () => $t('约谈'),
    Component: lazy(() =>
      import('./screens/Chats').then(m => ({
        default: m.Chats,
      })),
    ),
  },
  {
    name: 'PluginChatDetail',
    getTitle: () => $t('约谈详情'),
    Component: lazy(() =>
      import('./screens/ChatDetail').then(m => ({
        default: m.ChatDetail,
      })),
    ),
  },
  {
    name: 'PluginChatSubmitRecord',
    getHeaderTitle: () => $t('约谈记录'),
    Component: lazy(() =>
      import('./screens/ChatSubmitRecord').then(m => ({
        default: m.ChatSubmitRecord,
      })),
    ),
  },
  {
    name: 'PluginChatRemarks',
    getHeaderTitle: () => $t('约谈备注'),
    Component: lazy(() =>
      import('./screens/ChatRemarks').then(m => ({
        default: m.ChatRemarks,
      })),
    ),
  },
  {
    name: 'PluginChatWithTeacher',
    getHeaderTitle: () => $t('约谈教师'),
    Component: lazy(() =>
      import('./screens/ChatWithTeacher').then(m => ({
        default: m.ChatWithTeacher,
      })),
    ),
  },
]

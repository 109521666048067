/**
 * @file 设备信息
 * 源码来自 react-native-device-info
 * API 见：https://github.com/react-native-device-info/react-native-device-info#api
 */

import { isReactNative } from '@seiue/rn-util'
import { NativeModules, Platform } from 'react-native'

const { DeviceInfoModule } = NativeModules

let uniqueId: string

/**
 * 获取设备唯一标识
 *
 * @returns 标识
 */
export async function getUniqueId() {
  if (!uniqueId) {
    if (Platform.OS === 'ios') {
      uniqueId = await DeviceInfoModule.syncUniqueId()
    } else if (Platform.OS === 'android') {
      uniqueId = await DeviceInfoModule.getUniqueIdSync()
    }
  }

  return uniqueId
}

let version: string

/**
 * 获取版本号
 *
 * @returns 版本号
 */
export function getVersion() {
  if (!version) {
    if (
      Platform.OS === 'android' ||
      Platform.OS === 'ios' ||
      Platform.OS === 'windows'
    ) {
      version = DeviceInfoModule.appVersion
    } else {
      version = 'unknown'
    }
  }

  return version
}

let buildNumber: string

/**
 * 获取构建号
 *
 * @returns 构建号
 */
export function getBuildNumber() {
  if (!buildNumber) {
    if (
      Platform.OS === 'android' ||
      Platform.OS === 'ios' ||
      Platform.OS === 'windows'
    ) {
      buildNumber = DeviceInfoModule.buildNumber
    } else {
      buildNumber = 'unknown'
    }
  }

  return buildNumber
}

/**
 * 获取可读版本号
 *
 * @returns 可读版本号
 */
export function getReadableVersion() {
  return `${getVersion()}.${getBuildNumber()}`
}

let userAgent: string

/**
 * 获取 UA
 *
 * @returns UA
 */
export async function getUserAgent() {
  if (!userAgent) {
    if (
      Platform.OS === 'android' ||
      Platform.OS === 'ios' ||
      Platform.OS === 'web'
    ) {
      userAgent = await DeviceInfoModule.getUserAgent()
    } else {
      userAgent = 'unknown'
    }
  }

  return userAgent
}

let emulator: boolean

/**
 * 是否是模拟器
 *
 * @returns 是否是模拟器
 */
export async function isEmulator() {
  if (emulator === undefined) {
    if (
      Platform.OS === 'android' ||
      Platform.OS === 'ios' ||
      Platform.OS === 'windows'
    ) {
      emulator = await DeviceInfoModule.isEmulator()
    } else {
      emulator = false
    }
  }

  return emulator
}

let deviceName: string

/**
 * 获取设备名称
 *
 * @returns 设备名称
 */
export async function getDeviceName() {
  if (!deviceName) {
    if (
      Platform.OS === 'android' ||
      Platform.OS === 'ios' ||
      Platform.OS === 'windows'
    ) {
      deviceName = await DeviceInfoModule.getDeviceName()
    } else {
      deviceName = 'unknown'
    }
  }

  return deviceName
}

/**
 * 检测手机厂商
 *
 * @returns 手机厂商
 */
export async function getManufacturer() {
  if (Platform.OS === 'android') {
    const result: string = await DeviceInfoModule.getSystemManufacturer()

    return (result || '').toLowerCase()
  }

  if (Platform.OS === 'ios') {
    return 'apple'
  }

  return 'unknown'
}

/**
 * 是否是华为手机
 *
 * @returns boolean
 */
export async function isHuawei() {
  return (await getManufacturer()) === 'huawei'
}

/**
 * 获取 App 应用名称
 *
 * @returns 应用名称
 */
export function getAppName() {
  // eslint-disable-next-line seiue/missing-formatted-message
  return isReactNative ? DeviceInfoModule.appName : '希悦校园'
}

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { routes } from './routes'

export const smsPlatform: InitPluginFeature = {
  name: PluginNameEnum.SmsPlatform,
  register: () => ({
    routes,
  }),
}

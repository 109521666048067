/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { FilePolicy, FilePolicyInterface } from './file-policy'
import type { GetFilePolicyQuery } from './get-file-policy-query'

const buildConfig = createConfigBuilder('chalk')

export const netdiskApi$getFilePolicy = {
  config(
    id: number,
    getFilePolicyQuery?: GetFilePolicyQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/netdisk/files/{id}/policy',
      {
        id,
      },
      getFilePolicyQuery,
    )
  },

  /**
   * @summary 获取指定文件的上传 policy
   * @param id 文件 ID
   * @param [getFilePolicyQuery]
   * @param [netdiskQuery.bizSigHash] 业务签名 hash（短时效，可覆盖并手动控制，场景：移动端扫码上传图片）
   * @param [netdiskQuery.bizSig] 业务签名（长失效，有效期前不可手动控制，场景：匿名上传文件，解析行程码）
   * @param [options]
   * @return AxiosResponsePromise<FilePolicy>
   */

  api(
    id: number,
    getFilePolicyQuery?: GetFilePolicyQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<FilePolicy> {
    return axios(
      netdiskApi$getFilePolicy.config(id, getFilePolicyQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new FilePolicy(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = FilePolicy>(
    {
      id,
      query,
    }: {
      id: number
      query?: GetFilePolicyQuery
    },
    queryOptions?: QueryOptionsWithSelect<FilePolicy, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<FilePolicy, TSelected>(
      netdiskApi$getFilePolicy.api,
      'Netdisk.getFilePolicy',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
netdiskApi$getFilePolicy.api._name_ = `Netdisk.getFilePolicy`

import { MessageAttributes } from 'packages/sdks-next/chalk'

import { InitFeatureParams } from '../types'

import { calendarEvents } from './calendar-events'
import { routes } from './routes'
import { todos } from './todos'

/**
 * 初始化 venue
 *
 * @param param0 - 参数
 */
export const initVenues = (param0: InitFeatureParams) => {
  const {
    addRoutes,
    registerCalendarEvents,
    registerTodos,
    registerMessageNavigator,
  } = param0

  addRoutes(routes)
  registerCalendarEvents(calendarEvents)
  registerTodos(todos)
  registerMessageNavigator(
    ['venue.order_canceled', 'venue.order_time_canceled'],
    ({ attributes }) => {
      const { orderId } = attributes as MessageAttributes & {
        orderId?: number
      }

      if (!orderId) return null

      return [
        'OrderDetail',
        {
          id: orderId,
          isApproval: true,
        },
      ]
    },
  )

  registerMessageNavigator(
    ['venue.order_violation', 'venue.order_approved', 'venue.order_rejected'],
    ({ attributes }) => {
      const { orderId } = attributes as MessageAttributes & {
        orderId?: number
      }

      if (!orderId) return null

      return [
        'OrderDetail',
        {
          id: orderId,
          isApproval: false,
        },
      ]
    },
  )

  registerMessageNavigator(['venue.follower_trigger'], ({ attributes }) => {
    const { orderId } = attributes as MessageAttributes & {
      orderId?: number
    }

    if (!orderId) return null

    return ['VenuesCenter', {}]
  })
}

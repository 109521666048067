/**
 * @file 登录状态下的页面 stack
 */

import { createStackNavigator } from '@react-navigation/stack'
import React, { Suspense, lazy } from 'react'
import { Platform } from 'react-native'

import { useSemesters } from 'packages/feature-utils/semesters'
import { $t } from 'packages/locale'

import { ErrorBoundary } from '@go/components/ErrorBoundary'
import { useRefreshUser } from '@go/features/accounts/hooks/use-refresh-user'
import { useForceEvaluation } from '@go/features/evaluations/screens/ForceEvaluationScreen/use-force-evaluation'
import { useCheckImportantNotice } from '@go/features/notices/screens/ImportantNoticesModal/check-important-notices'

import { BottomTabsNavigator } from './BottomTabsNavigator'
import { Route, MainStackParams, RootStackComponent } from './types'
import { addRoutes, defaultHeaderOptions } from './utils'

let routes: Route[] = [
  {
    path: '403',
    name: '403',
    getHeaderTitle: () => $t('希悦'),
    Component: lazy(() =>
      import('../components/NotAllowed').then(m => ({ default: m.NotAllowed })),
    ),
  },
]

/**
 * 获取 Main 导航器注册的路由
 *
 * @returns 路由列表
 */
export const getMainRoutes = () => routes

/**
 * 将路由加入 Main 导航器中
 *
 * @param rts - 路由列表
 */
export const addMainRoutes = (rts: Route[]) => {
  addRoutes(routes, rts)
}

/**
 * 将路由从 Main 导航器中移除
 *
 * @param rtsName - 路由名称
 */
export const removeMainRoutes = (rtsName: string[]) => {
  routes = routes.filter(route => !rtsName.includes(route.name))
}

const MainStack = createStackNavigator<MainStackParams>()

/**
 * Main 路由导航器
 *
 * @param navigatorProps - navigatorProps
 * @returns component
 */
export const MainNavigator: RootStackComponent<'Main'> = navigatorProps => {
  const { navigation } = navigatorProps

  useCheckImportantNotice(navigation)
  useForceEvaluation(navigation)
  useRefreshUser()
  useSemesters()

  return <MainNavigatorMemo />
}

const _MainNavigator = () => {
  return (
    <MainStack.Navigator
      screenOptions={defaultHeaderOptions}
      initialRouteName="Tabs"
    >
      {/* 底部导航栏 */}
      <MainStack.Screen
        name="Tabs"
        component={BottomTabsNavigator}
        options={{
          headerShown: false,
          gestureEnabled: false,
        }}
      />

      {routes.map(({ name, Component, getTitle, getHeaderTitle }) => {
        const headerTitle = getHeaderTitle?.() || ''
        const title = getTitle?.() || ''

        return (
          <MainStack.Screen
            key={name}
            name={name as keyof MainStackParams}
            options={{
              title: title || headerTitle,
              headerTitle,
              // FIXME 升级 react-native-webview 后遇到一个 Android crash 问题，等待官方修正
              // https://github.com/react-native-webview/react-native-webview/issues/1915
              // https://github.com/react-native-webview/react-native-webview/pull/2874
              animationEnabled: Platform.select({
                ios: true,
                android: false,
              }),
            }}
          >
            {props => (
              <ErrorBoundary>
                <Suspense fallback={<></>}>
                  <Component {...props} />
                </Suspense>
              </ErrorBoundary>
            )}
          </MainStack.Screen>
        )
      })}
    </MainStack.Navigator>
  )
}

const MainNavigatorMemo = React.memo(_MainNavigator)

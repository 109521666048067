import { InitFeatureParams } from '../types'

import { routes } from './routes'
import { workflowRenders } from './workflow-renders'

/**
 * 初始化无代码模块
 *
 * @param param0 - 参数
 */
export const initNoCode = (param0: InitFeatureParams) => {
  const { addRoutes, registerWorkflowRenders } = param0
  addRoutes(routes)
  registerWorkflowRenders(workflowRenders)
}

/**
 * @file 网盘 - 文件夹相关
 */

import { netdiskApi$queryUnorderedFiles } from 'packages/sdks-next/chalk'

import { getFolderPath, isRootFolder } from './utils'

export type NetdiskFolder = {
  path: string
  id: number
  name: string
}

export interface GoToNetdiskFolder {
  (folder: { path: string; name: string; id?: number }): void
}

/**
 * 统筹计算当前文件夹信息的 hooks
 *
 * @param params - 参数
 * @param params.netdiskOwnerId - 网盘拥有者 id
 * @param params.folderPath - 文件夹路径
 * @param params.folderId - 文件夹 id
 * @param onChange - 当文件夹发生变化时的回调
 * @returns 当前文件夹信息
 */
export const useNetdiskFolder = (
  params: {
    netdiskOwnerId: number
    folderPath?: string
    folderId?: number
  },
  onChange: (params: { folderPath: string; folderId?: number }) => void,
): [NetdiskFolder, GoToNetdiskFolder, () => void] => {
  const { netdiskOwnerId, folderPath = '/', folderId } = params

  const isRoot = isRootFolder(folderPath)
  const queryId = isRoot ? 0 : Number(folderId)

  const pathItems = folderPath.split('/')
  const folderName = pathItems.pop()

  const { data: folders } = netdiskApi$queryUnorderedFiles.useApi(
    {
      name: folderName,
      path: pathItems.length > 1 ? pathItems.join('/') : '/',
      validatingHash: false,
      netdiskOwnerId,
    },
    {
      disable: !!queryId || isRoot,
    },
  )

  const id = queryId ? Number(queryId) : folders?.[0]?.id || 0

  const goToFolder: GoToNetdiskFolder = folder => {
    onChange({
      folderPath: getFolderPath(folder),
      folderId: folder.id,
    })
  }

  // 返回当前文件的上级
  const back = () => {
    if (isRoot) {
      onChange({
        folderPath: '',
      })
    } else {
      const nextItems = [...pathItems]

      onChange({
        folderPath: nextItems.join('/') || '/',
        folderId: undefined,
      })
    }
  }

  return [
    {
      id,
      path: folderPath,
      name: folderName || '',
    },
    goToFolder,
    back,
  ]
}

/**
 * @file 初始化 features
 */

import { reportToSentry } from '@seiue/sentry'

import { hasUsablePlugin, EnabledPlugin } from 'packages/feature-utils/plugins'

import { registerToSlot, removeSlot } from '@go/plugins/slots'
import {
  InitPluginParams,
  InitPluginFeature,
  PluginDynamicConfig,
} from '@go/plugins/types'
import { removeRoutes, addRoutes } from '@go/router'
import { store as goRootStore } from '@go/stores'

import { absence } from './absence'
import { academicGoals } from './academic-goal'
import { adjustment } from './adjustment'
import { approval } from './approval'
import { bjMonitorPlatform } from './bj-monitor-platform'
import { certification } from './certification'
import { certificationCredit } from './certification-credit'
import { chat } from './chat'
import { classDeclaration } from './class-declarations'
import { classIn } from './class-in'
import { classReview } from './class-review'
import { copilot } from './copilot'
import { customizedGroups } from './customized-groups'
import { dorms } from './dorms'
import { eems } from './eems'
import { election } from './election'
import { event } from './events'
import { examRooms } from './exam-rooms'
import { handout } from './handout'
import { kaopuAiTool } from './kaopu-ai-tool'
import { moralAssessment } from './moral-assessments'
import { netdisk } from './netdisk'
import { newcapecOrder } from './newcapec-order'
import { onlineScoring } from './online-scoring'
import { psychologicalFile } from './psychological-file'
import { questionnaire } from './questionnaire'
import { reportCenter } from './report-center'
import { resit } from './resit'
import { smsPlatform } from './sms-platform'
import { szCustomResitRule } from './sz-custom-resit-rule'
import { teacherAttendance } from './teacher-attendance'
import { teacherArchives } from './teacher-profile'
import { visitorCenter } from './visitor-center'

const plugins: InitPluginFeature[] = [
  moralAssessment,
  classDeclaration,
  election,
  newcapecOrder,
  event,
  resit,
  questionnaire,
  classIn,
  eems,
  bjMonitorPlatform,
  approval,
  absence,
  academicGoals,
  certification,
  certificationCredit,
  chat,
  customizedGroups,
  psychologicalFile,
  teacherArchives,
  handout,
  netdisk,
  smsPlatform,
  szCustomResitRule,
  dorms,
  reportCenter,
  examRooms,
  adjustment,
  visitorCenter,
  kaopuAiTool,
  onlineScoring,
  classReview,
  teacherAttendance,
  copilot,
]

/**
 * 初始化插件
 *
 * @param params - 初始化插件的参数
 */
export const initPlugins = (params: InitPluginParams) => {
  plugins.forEach(
    ({ calendarEvents, todos, messageNavigators, workflowRenders }) => {
      if (calendarEvents?.length) {
        params.registerCalendarEvents(calendarEvents)
      }

      if (todos?.length) {
        params.registerTodos(todos)
      }

      if (workflowRenders) {
        params.registerWorkflowRenders(workflowRenders)
      }

      if (messageNavigators?.length) {
        messageNavigators.forEach(navigator => {
          params.registerMessageNavigator(navigator.type, navigator.navigator)
        })
      }
    },
  )
}

// 注册信息的 Cache，用于
const registerCache: {
  [pluginName: string]: PluginDynamicConfig | undefined
} = {}

const unregisterPlugins = (name: string) => {
  const cache = registerCache[name]
  if (cache) {
    const { routes, slots, stores } = cache
    // 移除 route
    if (routes) removeRoutes(routes)

    // 移除 slot
    if (slots) slots.forEach(slot => removeSlot(slot.name, slot.slot))

    // 移除 stores
    if (stores) {
      Object.entries(stores).forEach(([storeName]) => {
        goRootStore.dispatch({
          type: `${storeName}/reset`,
        })
      })
    }
  }
}

/**
 * 注册插件
 *
 * @param enabledPlugins - 启用的插件列表
 */
export const registerPlugins = (enabledPlugins: EnabledPlugin[]) => {
  plugins.forEach(({ name, register }) => {
    /*
     * 隔离 Plugin 初始化可能出现的崩溃问题
     * 使其不再影响主系统的渲染
     */
    try {
      // 注册前，先清空之前的注册
      unregisterPlugins(name)

      const _plugins = enabledPlugins.filter(p => p.pluginName === name)
      const config = register?.(_plugins)

      const isPluginEnabled = !!_plugins.length
      const isPluginVisible = hasUsablePlugin(_plugins)

      registerCache[name] = config

      if (!config) return

      if (isPluginEnabled && config.routes?.length) {
        addRoutes(config.routes)
      }

      if (isPluginEnabled && config.slots?.length) {
        config.slots.forEach(slot => {
          // 应用中心，只有可见的插件才能查看入口
          if (slot.name === 'asyncAppMenuItems') {
            if (isPluginVisible) {
              registerToSlot(slot.name, slot.slot)
            }

            return
          }

          registerToSlot(slot.name, slot.slot)
        })
      }

      if (isPluginEnabled && config.stores) {
        Object.entries(config.stores).forEach(([storeName, store]) => {
          goRootStore.model({
            name: storeName,
            reducers: {},
            ...store,
          })
        })
      }
    } catch (e) {
      console.error(`Seiue Plugin ${name} init failed!`)

      reportToSentry(e, {
        ExceptionType: 'PluginInitFailed',
      })
    }
  })
}

import { PluginEnum } from 'packages/feature-utils/plugins'

import { InitPluginFeature } from '@go/plugins/types'

import { getQuestionnaireMenu } from './menu-slot'
import { routes } from './routes'
import { todos } from './todos'

export const questionnaire: InitPluginFeature = {
  name: PluginEnum.Questionnaire,
  todos,
  register: () => ({
    routes,
    slots: [
      {
        name: 'asyncAppMenuItems',
        slot: getQuestionnaireMenu,
      },
    ],
  }),
}

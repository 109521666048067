import { Portal as PortalNative } from '@seiue/rn-ui'
import { Portal as PortalDesktop } from '@seiue/ui'
import React, { FC } from 'react'

/**
 * Portal
 *
 * @param param0 - 参数
 * @param param0.children - 子组件
 * @returns portal
 */
export const Portal: FC<{ loading: boolean }> = ({ children }) => {
  return (
    <>
      <PortalDesktop.Host />
      <PortalNative.Host>{children}</PortalNative.Host>
    </>
  )
}

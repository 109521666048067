import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { workflowRenders } from './workflow-renders'

export const approval: InitPluginFeature = {
  name: PluginNameEnum.Approval,
  workflowRenders,
}

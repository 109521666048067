/**
 * @file Navigating without the navigation prop
 * https://reactnavigation.org/docs/navigating-without-navigation-prop
 */
import {
  NavigationContainerRef,
  NavigationState,
  useNavigationState,
} from '@react-navigation/core'
import { isWeb } from '@seiue/rn-util'
import { parseURLQuery } from '@seiue/util'
import React from 'react'
import { matchPath } from 'react-router-dom'

import { MainStackParams, linking } from '@go/router'

// NavigationContainer 是否已挂载完成，只有完成后才可以进行跳转
let isNavigationReady = false
export const setIsNavigationReady = (isReady: boolean) => {
  isNavigationReady = isReady
}

type RouteName = keyof MainStackParams
type RouteParams = MainStackParams[RouteName]
type Route = [RouteName, RouteParams]

/**
 * 待跳转路由队列，当 App 进程被杀死时点击推送，此时 NavigationContainer 可能尚未挂载完成，跳转并不可用，
 * 所以我们会将该推送加进队列，等待 NavigationContainer ready 后手动触发跳转
 */
export const routeQueue: Route[] = []

export const navigationRef =
  React.createRef<NavigationContainerRef<MainStackParams>>()

export function navigate(name: RouteName, params?: RouteParams) {
  if (isNavigationReady && navigationRef.current) {
    navigationRef.current.navigate(name, params)

    // Clear routes in queue
    routeQueue.splice(0, routeQueue.length)
  } else {
    routeQueue.push([name, params])
  }
}

export const getActiveRouteName = (
  navigationState?: NavigationState,
): string => {
  if (!navigationState) return ''

  const route = navigationState.routes[navigationState.index]

  // dive into nested navigators
  if (route.state?.routes) {
    return getActiveRouteName(route.state as any)
  }

  return route.name
}

/**
 * 检查 navigation 中是否存在目标路由
 */
export const useHasRoute = () => {
  const allRouteNames = useNavigationState(state => state.routeNames)
  return (routeName: string) => allRouteNames.includes(routeName)
}

const initialPathname = isWeb ? window.location.pathname : ''
const initialSearch = isWeb ? window.location.search : ''

/**
 * 获取初始 pathname 对应的路由（only for Web）
 */
export const getRouteNameByInitialPath = () =>
  getRouteNameByPathName({
    pathname: initialPathname,
    search: initialSearch,
  })

/**
 * 根据 pathname 获取对应的路由（only for Web）
 */
export const getRouteNameByPathName = ({
  pathname,
  search,
}: {
  pathname: string
  search: string
}) => {
  let routeParentName = ''
  let routeName = ''

  let params = parseURLQuery(search)

  Object.entries(linking.config.screens).some(([screenName, screenPath]) => {
    if (typeof screenPath === 'object') {
      return Object.entries(screenPath.screens).some(
        ([childScreenName, childScreenPath]) => {
          if (pathname === `/${childScreenPath}`) {
            routeParentName = screenName
            routeName = childScreenName
            return true
          }

          const matchResult = matchPath(pathname, {
            path: `/${childScreenPath}`,
            exact: true,
            strict: true,
          })

          if (matchResult?.path) {
            routeParentName = screenName
            routeName = childScreenName

            params = {
              ...params,
              ...matchResult.params,
            }

            return true
          }

          return false
        },
      )
    }

    if (pathname === `/${screenPath}`) {
      routeName = screenName
      return true
    }

    return false
  })

  return {
    routeParentName,
    routeName,
    params: Object.keys(params).length
      ? {
          ...params,
        }
      : undefined,
  }
}

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'attendances/entry',
    name: 'AttendanceEntryScreen',
    getTitle: () => $t('录入考勤'),
    getHeaderTitle: () => $t('录入考勤'),
    Component: lazy(() =>
      import('./screens/Entry').then(m => ({
        default: m.AttendanceEntryScreen,
      })),
    ),
  },
  {
    path: 'attendance/message',
    name: 'AttendMessageScreen',
    getTitle: () => $t('通知详情'),
    getHeaderTitle: () => $t('通知详情'),
    Component: lazy(() =>
      import('./screens/AttendMessage').then(m => ({
        default: m.AttendMessage,
      })),
    ),
  },
]

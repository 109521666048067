/**
 * @file 网盘模块路由
 */
import { lazy } from 'react'

import { $t } from 'packages/locale/LocaleProvider'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'NetdiskHome',
    getTitle: () => $t('网盘'),
    getHeaderTitle: () => $t('网盘'),
    Component: lazy(() =>
      import('./screens/NetdiskHome').then(m => ({
        default: m.NetdiskHomeScreen,
      })),
    ),
  },
  {
    name: 'NetdiskFileList',
    getTitle: () => $t('文件列表'),
    getHeaderTitle: () => $t('文件列表'),
    Component: lazy(() =>
      import('./screens/NetdiskFileList').then(m => ({
        default: m.NetdiskFileList,
      })),
    ),
  },
  {
    name: 'NetdiskShared',
    getTitle: () => $t('分享历史'),
    getHeaderTitle: () => $t('分享历史'),
    Component: lazy(() =>
      import('./screens/NetdiskShared').then(m => ({
        default: m.NetdiskShared,
      })),
    ),
  },
  {
    name: 'NetdiskLogs',
    getTitle: () => $t('操作历史'),
    getHeaderTitle: () => $t('操作历史'),
    Component: lazy(() =>
      import('./screens/NetdiskLogs').then(m => ({
        default: m.NetdiskLogs,
      })),
    ),
  },
  {
    name: 'NetdiskTrash',
    getTitle: () => $t('回收站'),
    getHeaderTitle: () => $t('回收站'),
    Component: lazy(() =>
      import('./screens/NetdiskTrash').then(m => ({
        default: m.NetdiskTrash,
      })),
    ),
  },
  {
    name: 'NetdiskTransmission',
    getTitle: () => $t('传输列表'),
    getHeaderTitle: () => $t('传输列表'),
    Component: lazy(() =>
      import('./screens/NetdiskTransmission').then(m => ({
        default: m.NetdiskTransmissionScreen,
      })),
    ),
  },
  {
    name: 'NetdiskFileSearch',
    getTitle: () => $t('文件搜索'),
    getHeaderTitle: () => $t('文件搜索'),
    Component: lazy(() =>
      import('./screens/Search').then(m => ({
        default: m.NetdiskFileSearch,
      })),
    ),
  },
]

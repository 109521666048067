import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'Plugin.ExamRooms.ExamScheduleView',
    getHeaderTitle: () => '',
    getTitle: () => $t('考试详情'),
    Component: lazy(() =>
      import('./screens/ExamScheduleView').then(m => ({
        default: m.ExamScheduleView,
      })),
    ),
  },
  {
    name: 'Plugin.ExamRooms.PreviewExaminee',
    getHeaderTitle: () => '',
    getTitle: () => $t('准考证'),
    Component: lazy(() =>
      import('./screens/PreviewExaminee').then(m => ({
        default: m.PreviewExaminee,
      })),
    ),
  },
  {
    name: 'Plugin.ExamRooms.PreviewExaminer',
    getHeaderTitle: () => '',
    getTitle: () => $t('监考安排'),
    Component: lazy(() =>
      import('./screens/PreviewExaminer').then(m => ({
        default: m.PreviewExaminer,
      })),
    ),
  },
]

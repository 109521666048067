/**
 * @file 日程 data helpers
 */

import { moment } from '@seiue/moment'

/**
 * 是否已结束
 *
 * @param endTime - 结束时间
 * @returns 是否已结束
 */
export const getIsEnded = (endTime?: string | null) =>
  endTime && moment(endTime).isBefore(moment())

/**
 * 是否已开始
 *
 * @param startTime - 开始时间
 * @returns 是否已开始
 */
export const getIsStarted = (startTime: string) =>
  moment(startTime).isBefore(moment())

/**
 * @file 活动模块路由
 */
import { lazy } from 'react'

import { $t } from 'packages/locale/LocaleProvider'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'EventList',
    getTitle: () => $t('活动列表'),
    Component: lazy(() =>
      import('./screens/Entry').then(m => ({ default: m.Entry })),
    ),
  },
  {
    name: 'SignUpApplicationList',
    getHeaderTitle: () => $t('审批列表'),
    Component: lazy(() =>
      import('./screens/SignUpList').then(m => ({
        default: m.SignUpApplicationList,
      })),
    ),
  },
  {
    name: 'ReasonUpload',
    Component: lazy(() =>
      import('./screens/ReasonUpload').then(m => ({ default: m.ReasonUpload })),
    ),
  },
  {
    name: 'EventDetails',
    path: '/event-details/:pid/home/:eid',
    getTitle: () => $t('活动详情'),
    Component: lazy(() =>
      import('./screens/EventDetails').then(m => ({ default: m.EventDetails })),
    ),
  },
  {
    name: 'EventDiscussion',
    path: '/event-details/:pid/home/:eid/discussion',
    Component: lazy(() =>
      import('./screens/EventDetails/EventDiscussion').then(m => ({
        default: m.EventDiscussion,
      })),
    ),
  },
  {
    name: 'EventSignin',
    path: '/events/:pid/:eid/signin/:code/:timeId',
    Component: lazy(() =>
      import('./screens/Signin').then(m => ({ default: m.SigninScreen })),
    ),
  },
  {
    name: 'EventDiscussionDetail',
    Component: lazy(() =>
      import('./screens/DiscussionDetails').then(m => ({
        default: m.EventDiscussionDetails,
      })),
    ),
  },
  {
    name: 'EventShareError',
    Component: lazy(() =>
      import('./screens/EventShareError').then(m => ({
        default: m.EventShareError,
      })),
    ),
  },
]

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'Plugin.ReportCenter.ReceivedList',
    getHeaderTitle: () => $t('报表中心'),
    Component: lazy(() =>
      import('./screens/ReceivedList').then(m => ({
        default: m.ReceivedReportList,
      })),
    ),
  },
  {
    name: 'Plugin.ReportCenter.ReceivedView',
    getHeaderTitle: () => $t('报表详情'),
    Component: lazy(() =>
      import('./screens/ReceivedView').then(m => ({
        default: m.ReceivedReportView,
      })),
    ),
  },
]

/**
 * @file 网盘插件相关接口
 */
import { FileRaw, getFileHash } from '@seiue/file'
import { omit } from '@seiue/util'

import { useCurrentReflection } from 'packages/features/sessions/utils'
import { Query } from 'packages/hooks'
import { SHARED_NET_DISK } from 'packages/plugins/features/net-disk/const'
import { NetdiskFolder } from 'packages/plugins/features/net-disk/folder'
import { Expand } from 'packages/sdks-next'
import {
  GetPersonalNetdiskOwnersQuery,
  NetdiskFile,
  NetdiskPermission,
  NetdiskPermissionPermissionsEnum,
  QueryFilesQuery,
  netdiskApi$queryFiles,
  netdiskApi$getPersonalNetdiskOwners,
  netdiskApi$queryPersonalNetdiskPermissions,
  netdiskApi$queryUnorderedFiles,
} from 'packages/sdks-next/chalk'

/**
 * 文件是否存在于网盘中
 *
 * @param file - 文件
 * @returns - 网盘
 */
export const isFileExistOnNetDisk = async (file: FileRaw) => {
  const hash = await getFileHash(file)

  if (!hash) return [false, ''] as const

  const isExist = await isFileHashExistOnNetDisk(hash)

  return [isExist, hash] as const
}

/**
 * 文件是否存在于网盘中
 *
 * @param hash - 文件 Hash
 * @returns - 是否
 */
export const isFileHashExistOnNetDisk = async (hash: string) => {
  if (!hash) return false

  const { data } = await netdiskApi$queryFiles.api({
    hash,
    validatingHash: true,
    fields: 'hash',
  })

  return !!data.length
}

/**
 * 获取个人网盘数据，默认不分页
 *
 * @param query - 查询参数
 * @param rid - 人员 ID
 * @returns - 个人网盘数据
 */
export const usePersonalNetdiskOwners = (
  query?: GetPersonalNetdiskOwnersQuery,
  rid?: number,
) => {
  const { id: crid } = useCurrentReflection()

  return netdiskApi$getPersonalNetdiskOwners.useApi({
    reflectionId: rid || crid,
    query: {
      expand: ['ownerName', 'ownerManagers'],
      tryExpand: ['topFile'],
      paginated: 0,
      ...query,
    },
  })
}

interface FilesWithSelfPermissionProps {
  query: QueryFilesQuery
  id: number
  path: string
  isSharedType: boolean
  ownerId: number
  rid: number
  isInAdmin?: boolean
}

const getContainSelfPermissionData = <T extends NetdiskFile>({
  isSharedType,
  data,
  isInAdmin,
  selfPermissions,
}: {
  isSharedType: boolean
  data: T[]
  isInAdmin: boolean
  selfPermissions: NetdiskPermission[]
}) => {
  return isSharedType
    ? data?.map(item => {
        const matchedPermission = selfPermissions?.find(
          (permission: NetdiskPermission) => permission.fileId === item.id,
        )

        const permissionFlag = isInAdmin
          ? NetdiskPermissionPermissionsEnum.Manage
          : matchedPermission?.permissionFlag ||
            NetdiskPermissionPermissionsEnum.Read

        return {
          ...item,
          selfPermission: permissionFlag,
        }
      })
    : data
}

/**
 * 查询文件列表, 并包含当前用户的权限
 *
 * @param props - 参数
 * @param props.query - 查询参数
 * @param props.id - 文件夹 id
 * @param props.path - 文件夹路径
 * @param props.isSharedType - 是否是共享网盘
 * @param props.rid - 人员 id
 * @param props.ownerId - 网盘拥有者 id
 * @param props.isInAdmin - 是否是在管理端
 * @returns 包含当前用户的权限的文件列表
 */
export const queryFilesWithSelfPermission = async ({
  query,
  id,
  path,
  isSharedType,
  rid,
  ownerId,
  isInAdmin = false,
}: FilesWithSelfPermissionProps) => {
  const result = await netdiskApi$queryFiles.api({
    ...omit(query, ['folderId', 'folderPath']),
    tryExpand: ['creator'],
    expand: ['netdiskStars', 'netdiskOwner', ['netdiskOwner', 'ownerManagers']],
    parentId: id,
    path,
    netdiskOwnerId: ownerId,
    withCurrentReflection: true,
  })

  const { data } = result

  const selfPermissions: NetdiskPermission[] = []
  if (isSharedType && data?.length) {
    const { data: permissions } =
      await netdiskApi$queryPersonalNetdiskPermissions.api(
        rid,
        data.map(d => d.id).join(','),
      )

    selfPermissions.push(...permissions)
  }

  const containSelfPermissionData = getContainSelfPermissionData({
    isSharedType,
    data,
    isInAdmin,
    selfPermissions,
  })

  return {
    ...result,
    data: containSelfPermissionData,
  }
}

interface SearchFilesWithSelfPermissionProps {
  query?: Query
  isSharedType: boolean
  ownerId: number
  rid: number
  isInAdmin?: boolean
  folder: NetdiskFolder
  netdiskOwnerIdIn?: string
}

/**
 * 查询文件列表, 并包含当前用户的权限
 * 用于搜索
 *
 * @param props - 参数
 * @param props.query - 查询参数
 * @param props.folder - 文件夹
 * @param props.isSharedType - 是否是共享网盘
 * @param props.ownerId - 网盘拥有者 id
 * @param props.rid - 人员 id
 * @param props.isInAdmin - 是否是在管理端
 * @param props.netdiskOwnerIdIn - 网盘拥有者 ids，go 搜索全部网盘时使用
 * @returns 用于搜索, 包含当前用户的权限的文件列表
 */
export const searchFilesWithSelfPermission = async ({
  query,
  folder,
  isSharedType,
  ownerId,
  rid,
  isInAdmin = false,
  netdiskOwnerIdIn,
}: SearchFilesWithSelfPermissionProps) => {
  const result = await netdiskApi$queryUnorderedFiles.api({
    ...query,
    isDir: false,
    parentId: netdiskOwnerIdIn ? undefined : folder.id,
    pathPrefix: netdiskOwnerIdIn ? undefined : folder.path,
    // go 搜索全部网盘时使用 netdiskOwnerIdIn
    netdiskOwnerId: netdiskOwnerIdIn ? undefined : ownerId,
    expand: [
      'netdiskOwner',
      ['netdiskOwner', 'ownerName'],
      ['netdiskOwner', 'ownerManagers'],
    ],
    netdiskOwnerIdIn,
    tryExpand: ['creator'],
  })

  const { data } = result

  const selfPermissions: NetdiskPermission[] = []
  if (isSharedType && data?.length) {
    const { data: permissions } =
      await netdiskApi$queryPersonalNetdiskPermissions.api(
        rid,
        data
          .filter(d => d.netdiskOwner.type === SHARED_NET_DISK)
          .map(d => d.id)
          .join(','),
      )

    selfPermissions.push(...permissions)
  }

  const containSelfPermissionData = getContainSelfPermissionData({
    isSharedType,
    data,
    isInAdmin,
    selfPermissions,
  })

  return {
    ...result,
    data: containSelfPermissionData as Expand<NetdiskFile, ['netdiskOwner']>[],
  }
}

import { InitPluginFeature } from '@go/plugins/types'

import { getNetdiskMenu } from './menu-slot'
import { routes } from './routes'
import { netdiskDownloadPoll } from './stores/netdisk-download-poll'
import { netdiskUploadPoll } from './stores/netdisk-upload-poll'

export const netdisk: InitPluginFeature = {
  name: 'netdisk',
  register: () => ({
    routes,
    stores: {
      netdiskUploadPoll,
      netdiskDownloadPoll,
    },
    slots: [
      {
        name: 'asyncAppMenuItems',
        slot: getNetdiskMenu,
      },
    ],
  }),
}

/**
 * @file styles
 */
import { Icon, Text } from '@seiue/rn-ui'
import { View } from 'react-native'
import styled from 'styled-components/native'

import { TextColor } from 'packages/theme/colors.native'

const Card = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 16px;
  height: 16px;
`

const ResitIcon = styled(Icon)`
  position: relative;
  top: 0;
  left: 0;

  &&& {
    color: #fca700;
  }
`

const TipWrapper = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  padding: 4px 0 10px 0;
`

const Tip = styled(Text)`
  font-size: 14px;
  color: ${TextColor._3};
  margin-left: 8px;
`

const LabelBox = styled(View)`
  width: 32px;
  height: 16px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d45586;
`

const Label = styled(Text)`
  color: #fff;
  font-size: 12px;
`

export { TipWrapper, LabelBox, Tip, Label, Card, ResitIcon }

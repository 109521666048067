export interface NotificationScopeInterface {
  /**
   * 分组ID
   */
  scopeId: string
  /**
   * 分组名称
   */
  scopeName: string
}

export class NotificationScope implements NotificationScopeInterface {
  /**
   * 分组ID
   */
  scopeId: string
  /**
   * 分组名称
   */
  scopeName: string

  constructor(props: NotificationScopeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      scopeId: _scopeId,
      scopeName: _scopeName,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.scopeId = _scopeId
    this.scopeName = _scopeName
  }

  static propKeys = ['scopeId', 'scopeName']
}

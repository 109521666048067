/**
 * @file class-in data helpers
 */

import { EnabledPlugin } from 'packages/feature-utils/plugins/types'

// classin 中间页链接
export const baseClassinUrl = 'https://www.eeo.cn/client/invoke/index.html'

/**
 * 获取 classin 单点登录链接
 *
 * @param schoolId - 学校 ID
 * @param token - 登录 token
 * @returns classin 单点登录链接
 */
export const getClassinSsoUrl = (schoolId: number, token?: string) => {
  return `https://passport.seiue.com/authorize?response_type=token&client_id=LCEzquNW8vCEmukSQGrFGs&school_id=${schoolId}&redirect_uri=https://www.eeo.cn/client/partner/accountbind/index.html?platform=24${
    token ? `&_access_token=${token}` : ''
  }`
}

/**
 * 获取 ClassIn 插件设置
 *
 * @param classInPlugin - ClassIn 插件
 * @returns ClassIn 插件设置
 */
export const getClassInSettions = (classInPlugin?: EnabledPlugin) => {
  return classInPlugin?.settings as
    | {
        enableSsoLogin: boolean
      }
    | undefined
}

import { env } from '@seiue/env'
import { isWeb } from '@seiue/rn-util'
import { TrackingProvider as TrackingProviderRaw } from '@seiue/tracking'
import React from 'react'

import {
  useSetTrackingUserId,
  useEnableTracking,
} from 'packages/features/tracking/utils'

const Inner: React.FC = ({ children }) => {
  // 设置埋点用户 id
  useSetTrackingUserId()

  // TODO: Track page views

  return <>{children}</>
}

/**
 * Matomo tracking provider
 *
 * @param params - params
 * @param params.children - children nodes
 * @returns react node
 */
export const TrackingProvider: React.FC = ({ children }) => {
  const enabledTracking = useEnableTracking()

  return (
    <TrackingProviderRaw
      enabled={enabledTracking}
      siteId={
        /* 暂不支持 native 所以在 native 下用 siteId=0 来 disable */
        isWeb ? +env('THIRD_PARTY_MATOMO_GO_ID') : null
      }
    >
      <Inner>{children}</Inner>
    </TrackingProviderRaw>
  )
}

import { lazy } from 'react'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'NoCodePage',
    Component: lazy(() =>
      import('./screens/Page').then(m => ({
        default: m.Page,
      })),
    ),
  },
]

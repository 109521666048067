/**
 * @file 报表中心
 */

import { reportPublishPublishedNavigator } from 'packages/plugins/features/report/chalk-navigator'
import { PluginNameEnum } from 'packages/plugins/types'
import { ReportPublishByEnum } from 'packages/sdks-next/nuwa'

import { InitPluginFeature } from '@go/plugins/types'

import { routes } from './routes'

export const reportCenter: InitPluginFeature = {
  name: PluginNameEnum.ReportCenter,
  register: () => {
    return {
      routes,
    }
  },

  messageNavigators: [
    // 数据看板的报表发布
    {
      type: ['report_dashboard.report_publish_published'],
      navigator: (message, currentReflection) => {
        const { attributes } = message
        const { reportPublishId, reportPublishBy, results } =
          (attributes as {
            reportPublishId?: number
            reportPublishBy: ReportPublishByEnum
            results: {
              id: number
              receiverIds: number[]
              ccIds: number[]
              managerIds: number[]
            }[]
          }) || {}

        // 移动端目前仅支持查看按人发布
        if (reportPublishBy === ReportPublishByEnum.Filter) return null

        const ownerResults = results.filter(result =>
          result.receiverIds?.includes(currentReflection.id),
        )

        // 只支持查看「接收人是我的」报表
        if (ownerResults.length === 1) {
          return [
            'Plugin.ReportCenter.ReceivedView',
            {
              publishingId: reportPublishId,
              resultId: ownerResults[0].id,
            },
          ]
        }

        // 其他情况跳转到桌面端的详情页
        return reportPublishPublishedNavigator(message, currentReflection)
      },
    },
    // 报告单的报表发布
    {
      type: ['report_report.report_publish_published'],
      navigator: (message, currentReflection) => {
        const { attributes } = message
        const { reportPublishId, reportPublishPublishBy, results } =
          (attributes as {
            reportPublishId?: number
            reportPublishPublishBy: ReportPublishByEnum
            results: {
              id: number
              receiverIds: number[]
              ccIds: number[]
              managerIds: number[]
            }[]
          }) || {}

        // 移动端目前仅支持查看按人发布
        if (
          reportPublishPublishBy === ReportPublishByEnum.Reflection &&
          // 仅支持查看一条发布结果，多条时没入口无法切换
          results.length === 1
        ) {
          return [
            'Plugin.ReportCenter.ReceivedView',
            {
              publishingId: reportPublishId,
              resultId: results[0].id,
              receiverId: results[0].receiverIds?.[0],
            },
          ]
        }

        // 其他情况（比如接收人不是当前用户的或者当前用户有多条发布结果的）均不支持，跳转到桌面端的详情页
        return reportPublishPublishedNavigator(message, currentReflection)
      },
    },
  ],
}

/**
 * @file 网盘 - 应用中心入口
 */
import { NetDiskSolid } from '@seiue/rn-icons'
import React from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'

import { LogoRender } from '@go/components/Logo/Render'
import { Menu } from '@go/features/applications/types'

/**
 * 获取网盘菜单
 *
 * @returns 网盘菜单
 */
export const getNetdiskMenu = async (): Promise<Menu[] | null> => [
  {
    key: PluginEnum.NetDisk,
    pluginName: PluginEnum.NetDisk,
    icon: ({ size } = {}) => (
      <LogoRender
        size={size}
        logo={'NetDiskSolid+D45586'}
        icons={[
          {
            name: 'NetDiskSolid',
            icon: NetDiskSolid,
          },
        ]}
      />
    ),
    getTitle: () => $t('网盘'),
    screen: {
      name: 'NetdiskHome' as const,
      params: undefined,
    },
  },
]

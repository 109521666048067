/**
 * @file 日程浮窗公用组件
 */

import { Button, Text } from '@seiue/rn-ui'
import { isWeb } from '@seiue/rn-util'
import React, { FC } from 'react'
import { TouchableOpacity, View } from 'react-native'
import styled, { css, useTheme } from 'styled-components/native'

import { navigateHybrid } from '@go/components/webview'
import { MainStackParamsUnion } from '@go/router'

/**
 * 日程卡片容器（主要包含左侧的部分如标题、辅助信息等，右侧的按钮如录入考勤，进入直播间）
 *
 * @param param0 - 参数
 * @param param0.oneLine - 是否单行显示
 * @param param0.showActions - 是否显示右侧的按钮
 * @param param0.children - React.ReactNode
 * @param param0.detailScreen - 点击卡片跳转的页面
 * @param param0.onPress - 点击卡片的回调
 * @param param0.activeOpacity - 点击卡片的透明度
 * @returns React.ReactNode
 */
export const EventCardWrapper: React.FC<{
  oneLine: boolean
  showActions: boolean
  detailScreen?: MainStackParamsUnion
  onPress?: () => void
  activeOpacity?: number
}> = ({
  oneLine,
  showActions,
  children,
  detailScreen,
  activeOpacity,
  onPress,
}) => (
  <TouchableOpacityStyled
    activeOpacity={activeOpacity}
    oneLine={oneLine}
    showActions={showActions}
    onPress={() => {
      if (detailScreen) {
        navigateHybrid(detailScreen)

        return
      }

      onPress?.()
    }}
  >
    {children}
  </TouchableOpacityStyled>
)

// 日程卡片标题
export const EventCardTitle = styled(Text)<{
  color: string
  invalid?: boolean
}>`
  flex-shrink: 0;
  margin-right: 5px;
  overflow: hidden;
  color: ${props => props.color};
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  text-decoration-line: ${props => (props.invalid ? 'line-through' : 'none')};
`

// 日程卡片左侧部分容器，包含标题、辅助信息等
export const EventCardLeft = styled(View)<{
  oneLine: boolean
}>`
  display: flex;
  flex: 1;
  align-self: flex-start;
  flex-basis: auto;
  flex-direction: ${props => (props.oneLine ? 'row' : 'column')};
  align-items: ${props => (props.oneLine ? 'center' : 'flex-start')};
`

// 日程卡片辅助信息容器
export const EventCardContent = styled(View)<{
  oneLine: boolean
}>`
  display: flex;
  flex-direction: ${props => (props.oneLine ? 'row' : 'column')};
  align-items: ${props => (props.oneLine ? 'center' : 'flex-start')};
  margin-top: ${props => (props.oneLine ? 0 : 2)}px;
`

// 日程卡片辅助信息 item
export const EventSupplement = styled(Text)<{
  oneLine: boolean
  view: string
  color: string
  invalid?: boolean
}>`
  flex-shrink: 0;
  overflow: hidden;
  color: ${props => props.color};
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: ${props => (props.invalid ? 'line-through' : 'none')};
`

const TouchableOpacityStyled = styled(TouchableOpacity)<{
  oneLine: boolean
  showActions: boolean
}>`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;

  ${isWeb &&
  css`
    flex-basis: auto;
  `}
`

/**
 * 事件标签
 *
 * @param param0 - 参数
 * @param param0.color - 文字颜色
 * @param param0.backgroundColor - 背景颜色
 * @param param0.children - React.ReactNode
 * @returns React.ReactNode
 */
export const EventTag: React.FC<{
  color: string
  backgroundColor: string
}> = ({ color, backgroundColor, children }) => (
  <EventTagWrapper
    backgroundColor={backgroundColor}
    style={{ transform: [{ translateY: 6 }] }}
  >
    <EventTagText color={color}>{children}</EventTagText>
  </EventTagWrapper>
)

const EventTagWrapper = styled(View)<{
  backgroundColor: string
}>`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  height: 18px;
  padding: 0 7px;
  background-color: ${props => props.backgroundColor};
  border-radius: 9px;
`

const EventTagText = styled(Text)<{
  color: string
}>`
  color: ${props => props.color};
  font-weight: bold;
  font-size: 12px;
`

/**
 * 日程卡片右侧的按钮
 *
 * @param param0 - 参数
 * @param param0.label - 按钮文字
 * @param param0.textColor - 文字颜色
 * @param param0.onPress - 点击按钮的回调
 * @param param0.oneLine - 是否单行显示
 * @returns React.ReactNode
 */
export const ActionBtn: FC<{
  label: string
  textColor?: string
  onPress: () => void
  oneLine?: boolean
}> = ({ label, textColor, onPress, oneLine = true }) => {
  const theme = useTheme()

  return (
    <ActionBtnWrapper key={label} $oneLine={oneLine} onPress={onPress}>
      <ActionBtnText color={textColor || theme.text.brand}>
        {label}
      </ActionBtnText>
    </ActionBtnWrapper>
  )
}

const ActionBtnWrapper = styled(Button)<{ $oneLine: boolean }>`
  position: absolute;
  top: ${props => (props.$oneLine ? '2px' : '6px')};
  right: 5px;
  max-width: 80px;
  height: 24px;
  padding: 0 8px;
  line-height: 24px;
  background: ${props => props.theme.background._0};
  border-radius: 4px;
`

const ActionBtnText = styled(Text)<{ color: string }>`
  color: ${props => props.color};
  font-weight: bold;
  font-size: 12px;
`

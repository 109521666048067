import {
  NotificationReceiversReadStatus,
  NotificationReceiversReadStatusInterface,
} from './notification-receivers-read-status'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface ReceivedNotificationInterface {
  id: string
  /**
   * 通知正文
   * 模板也通过这个参数传
   */
  content: string
  /**
   * 已读状态
   */
  readStatuses: NotificationReceiversReadStatusInterface[]
  receiver?: SecuredReflectionInterface | null
}

export class ReceivedNotification implements ReceivedNotificationInterface {
  id: string
  /**
   * 通知正文
   * 模板也通过这个参数传
   */
  content: string
  /**
   * 已读状态
   */
  readStatuses: NotificationReceiversReadStatus[]
  receiver?: SecuredReflection | null

  constructor(props: ReceivedNotificationInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      content: _content,
      readStatuses: _readStatuses,
      receiver: _receiver,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.content = _content
    this.readStatuses = _readStatuses
      ? _readStatuses.map(i => new NotificationReceiversReadStatus(i))
      : _readStatuses
    this.receiver = _receiver ? new SecuredReflection(_receiver) : _receiver
  }

  static propKeys = ['id', 'content', 'readStatuses', 'receiver']
}

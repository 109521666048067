import { InitFeatureParams } from '../types'

import { todos } from './todos'

/**
 * 初始化目标
 *
 * @param param0 - 参数
 */
export const initGoals = (param0: InitFeatureParams) => {
  const { registerTodos } = param0
  registerTodos(todos)
}

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { routes } from './routes'
import { workflowRenders } from './workflow-renders'

export const absence: InitPluginFeature = {
  name: PluginNameEnum.Absence,
  workflowRenders,
  register: () => ({
    routes,
  }),
  messageNavigators: [
    {
      type: 'leave_flow',
      navigator: message => {
        const attrs = message.attributes as {
          absenceId?: number
          flowId?: number
          schoolPluginId?: number
          flow?: {
            id: number
            workflowId: number
          }
        }

        const flowId = attrs.flowId || attrs.flow?.id

        if (!flowId) return null

        return [
          'ShowFlowModal',
          {
            workflowBizType: 'absence',
            flowId,
          },
        ]
      },
    },
    {
      type: 'leave_flow.agented_absence_approved',
      navigator: ({ attributes, owner }) => {
        const attrs = attributes as {
          absenceId: number
          flowId: number
          schoolPluginId: number
        }

        const { flowId } = attrs || {}

        if (!flowId) return null

        return [
          'PluginAbsencePersonalStatistics',
          {
            // @ts-expect-error owner id
            reflectionId: owner?.rid,
            flowId,
          },
        ]
      },
    },
    {
      type: 'absence.early_ended_for_initiator',
      navigator: ({ attributes }) => {
        const attrs = attributes as {
          flowId: number
        }

        const { flowId } = attrs || {}

        if (!flowId) return null

        return [
          'ShowFlowModal',
          {
            workflowBizType: 'absence',
            flowId,
          },
        ]
      },
    },
    {
      type: 'absence.flow_cc_node',
      navigator: ({ attributes }) => {
        const attrs = attributes as {
          flowId: number
        }

        const { flowId } = attrs || {}

        if (!flowId) return null

        return [
          'ShowFlowModal',
          {
            workflowBizType: 'absence',
            flowId,
          },
        ]
      },
    },
  ],
}

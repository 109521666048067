/**
 * @file ErrorBoundary
 */

import { faPageBreak } from '@fortawesome/pro-duotone-svg-icons'
import { useRoute } from '@react-navigation/core'
import { Text } from '@seiue/rn-ui'
import { reportToSentry } from '@seiue/sentry'
import { isWebpack4ChunkLoadError } from '@seiue/util'
import React from 'react'
import { ErrorBoundary as ErrorBoundaryRaw } from 'react-error-boundary'
import { View } from 'react-native'
import styled from 'styled-components/native'

import { $t } from 'packages/locale'

import { Icon } from '@go/components/Icon'

interface FallbackProps {
  error: Error
  resetErrorBoundary: () => void
}

const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  // 因为网络不稳定等原因, 下载 js/css 资源时可能会失败
  const message = isWebpack4ChunkLoadError(error)
    ? $t('网络状况不佳，可尝试刷新页面')
    : $t('错误已自动上报，我们将尽快处理')

  return (
    <ErrorHintContainer>
      <Icon icon={faPageBreak} size={48} />
      <ErrorTitle>{$t('页面加载失败了')}</ErrorTitle>
      <ErrorSubtitle>{message}</ErrorSubtitle>
    </ErrorHintContainer>
  )
}

export const ErrorBoundary: React.FC = props => {
  const route = useRoute()

  return (
    <ErrorBoundaryRaw
      FallbackComponent={ErrorFallback}
      resetKeys={[route]}
      onError={e => {
        if (isWebpack4ChunkLoadError(e)) return

        reportToSentry(e, {
          // ErrorBoundary 中的错误都会导致页面渲染失败
          ExceptionType: 'PageCrash',
        })
      }}
    >
      {props.children}
    </ErrorBoundaryRaw>
  )
}

const ErrorTitle = styled(Text)`
  margin: 30px 0 10px 0;
  color: #323842;
  font-weight: bold;
  font-size: 18px;
`

const ErrorSubtitle = styled(Text)`
  color: #9398a1;
  font-size: 14px;
`

const ErrorHintContainer = styled(View)`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* 调整视觉重心 */
  margin-top: -5%;
`

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'WorkflowsCenter',
    Component: lazy(() =>
      import('./screens/Center').then(m => ({ default: m.WorkflowsCenter })),
    ),
    getHeaderTitle: () => $t('审批中心'),
  },
  {
    name: 'NewFlowModal',
    getHeaderTitle: () => $t('发起审批'),
    Component: lazy(() =>
      import('./screens/New').then(m => ({
        default: m.NewFlowRouteModal,
      })),
    ),
  },
  {
    name: 'EditFlowModal',
    getHeaderTitle: () => $t('编辑审批'),
    Component: lazy(() =>
      import('./screens/Edit').then(m => ({
        default: m.EditFlowRouteModal,
      })),
    ),
  },
  {
    name: 'ShowFlowModal',
    getHeaderTitle: () => $t('审批单详情'),
    Component: lazy(() =>
      import('./screens/View').then(m => ({
        default: m.ShowFlowRouteModal,
      })),
    ),
  },
  {
    name: 'WorkflowApprovalModal',
    isModal: true,
    Component: lazy(() =>
      import('./screens/Approval').then(m => ({
        default: m.WorkflowApprovalModal,
      })),
    ),
  },
]

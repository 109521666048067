import { WorkflowMessageAttributes } from 'packages/features/workflows/message/message-type'
import { getWorkflowBizType } from 'packages/features/workflows/utils/data'
import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { routes } from './routes'
import { workflowRenders } from './workflow-renders'

export const eems: InitPluginFeature = {
  name: PluginNameEnum.Eems,
  register: () => ({
    routes,
  }),
  messageNavigators: [
    {
      type: 'visitor.delayed_leave',
      navigator: ({ attributes }) => {
        const { flow, workflow } = attributes as WorkflowMessageAttributes
        if (!flow?.id) return null

        return [
          'ShowFlowModal',
          {
            workflowBizType: getWorkflowBizType(workflow?.extensionType),
            flowId: flow.id,
          },
        ]
      },
    },
    {
      type: 'visitor.access_approved',
      navigator: message => {
        return [
          'PluginVisitorQRCodes',
          {
            defaultFlowId: (message.attributes as { flowId: number })?.flowId,
          },
        ]
      },
    },
  ],
  workflowRenders,
}

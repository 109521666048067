import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { Icon, Layout } from '@seiue/rn-ui'
import React from 'react'
import { Modal, TouchableOpacity, StyleSheet, View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'

import { BlurView } from './BlurView'
import { QuickStart } from './QuickStart'
import { Shortcut } from './Shortcut'

/**
 * 快速创建的弹窗
 *
 * @param props - props
 * @param props.visible - 是否显示弹窗
 * @param props.onDismiss - 关闭弹窗的回调
 * @returns component
 */
export const ShortcutModal: React.FC<{
  visible: boolean
  onDismiss: () => void
}> = ({ visible, onDismiss }) => {
  const theme = useTheme()

  return (
    <Modal
      visible={visible}
      animationType="slide"
      transparent={true}
      onDismiss={onDismiss}
      onRequestClose={() => onDismiss()}
    >
      <BlurView
        style={StyleSheet.absoluteFill}
        blurType="light"
        blurAmount={80}
        reducedTransparencyFallbackColor="white"
      />
      <View
        style={[
          StyleSheet.absoluteFill,
          { backgroundColor: '#fff', opacity: 0.8 },
        ]}
      />

      <Layout.Container
        style={{ backgroundColor: 'transparent' }}
        hasHeader={false}
      >
        <Content>
          {/* 随手评 */}
          <Shortcut onDismiss={onDismiss} />

          {/* 快速发起 */}
          <QuickStart onDismiss={onDismiss} />
        </Content>
        <Layout.Footer
          style={{
            backgroundColor: 'transparent',
            marginTop: 24,
            marginBottom: 24,
            alignItems: 'center',
          }}
          slotStyle={{
            flex: 1,
            alignItems: 'center',
          }}
        >
          <CloseButton activeOpacity={0.8} onPress={onDismiss}>
            <Icon icon={faTimes} color={theme.text._2} size={18} />
          </CloseButton>
        </Layout.Footer>
      </Layout.Container>
    </Modal>
  )
}

const Content = styled(View)`
  padding-horizontal: 20px;
  padding-top: 50px;
  justify-content: flex-end;
  flex: 1;
`

const CloseButton = styled(TouchableOpacity)`
  width: 48px;
  height: 48px;
  background-color: #edeff2;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
`

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isArray, isNumber } from '@seiue/util'
import React from 'react'
import { StyleSheet } from 'react-native'

import { IconProps } from './types'

export * from './types'

/**
 * @deprecated use Icon from '@seiue/rn-ui' instead
 */
export const Icon: React.FC<IconProps> = ({
  size,
  icon,
  color,
  secondaryColor,
  secondaryOpacity,
  marginLeft,
  marginRight,
  style,
}) => {
  // 当 Icon 的 style 是通过 StyleSheet.create 生成（通过类型是 array 或 number 判断），需要转换为 css
  const _style =
    isArray(style) || isNumber(style)
      ? StyleSheet.flatten(([] as number[]).concat(style as number | number[]))
      : style

  return (
    <FontAwesomeIcon
      style={{
        width: size,
        height: size,
        fontSize: size,
        // @ts-ignore
        '--fa-primary-color': color,
        '--fa-secondary-color': secondaryColor,
        '--fa-secondary-opacity': secondaryOpacity,
        marginLeft,
        marginRight,
        ...(_style as React.CSSProperties),
      }}
      icon={icon}
      color={color}
    />
  )
}

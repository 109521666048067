import { State, Toast } from '@seiue/rn-ui'
import { isAndroid } from '@seiue/rn-util'
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
} from 'react'
import { View } from 'react-native'
import {
  WebView as WebviewOriginal,
  WebViewMessageEvent,
  WebViewNavigation,
  WebViewProps as WebviewOriginalProps,
} from 'react-native-webview'
import { useQueryClient } from 'react-query'

import { NotFound } from '@go/components/NotFound'
import { filterAndReportError } from '@go/utils/error-handler'
import {
  getReadableVersion,
  getUserAgent,
} from '@go/utils/native-modules/device-info-module'
import { navigationRef } from '@go/utils/navigator'

import { createWebviewUri } from '../utils'

export type WebviewRef = { canGoBack: boolean; goBack?: () => void }

export interface WebviewProps {
  uri?: string
  source?: any
  originWhitelist?: string[]
  allowFileAccess?: boolean
  injectedJavaScript?: string
  javaScriptEnabled?: boolean
  onMessage?: (params: { type: string | null; data: any }) => void

  onNavigationStateChange?: WebviewOriginalProps['onNavigationStateChange']

  /**
   * 渲染 go web 页面时，通常由 native 来提供 navigation bar，
   * 所以隐藏 web 页面中的 navigation bar
   */
  hideNavBarOfGoWeb?: boolean

  /**
   * 获得 user-agent 之前是否显示 loading，默认 true
   */
  showLoading?: boolean
}

export const Webview = forwardRef<WebviewRef, WebviewProps>(
  (
    {
      uri,
      source,
      originWhitelist,
      allowFileAccess,
      injectedJavaScript,
      javaScriptEnabled,
      onMessage,
      onNavigationStateChange: onNavigationStateChangeOuter,
      hideNavBarOfGoWeb = false,
      showLoading = true,
    },
    ref,
  ) => {
    // get user agent
    const [userAgent, setUserAgent] = React.useState('')

    React.useEffect(() => {
      getUserAgent().then(ua => {
        setUserAgent(`${ua} c3app/${getReadableVersion()}`)
      })
    }, [])

    // build ref with canGoBack and goBack
    const webviewRef = React.useRef<WebviewOriginal | null>(null)
    const [canGoBack, setCanGoBack] = React.useState(false)

    /*
     * canGoBack 需要被 innerOnMessage 调用，但 innerOnMessage 不可刷新
     * 因此使用 ref 来传递新的 canGOBack
     */
    const canGoBackRef = React.useRef(false)
    canGoBackRef.current = canGoBack

    // useCallback 以避免 webview 不必要的重渲染，会导致 webview 重新加载初始 url
    const onNavigationStateChange = useCallback(
      (navState: WebViewNavigation) => {
        setCanGoBack(navState.canGoBack)
      },
      [],
    )

    const goBack = React.useMemo(
      () => (canGoBack ? webviewRef.current?.goBack : undefined),
      [canGoBack],
    )

    useImperativeHandle(
      ref,
      () => ({
        canGoBack,
        goBack,
      }),
      [canGoBack, goBack],
    )

    /*
     * HACK For Android
     * 现在 Android Webview 的 onNavigationStateChange 无法被正常的触发
     * 因此我们需要注入 js，修改 history api 令其主动通过 postMessage 来发送 navigationStateChange 事件
     */
    const innerInjectedJavaScript = isAndroid
      ? `
        (function() {
          function wrap(fn) {
            return function wrapper() {
              var res = fn.apply(this, arguments);
              window.ReactNativeWebView.postMessage('navigationStateChange');
              return res;
            }
          }
          history.pushState = wrap(history.pushState);
          history.replaceState = wrap(history.replaceState);
          window.addEventListener('popstate', function() {
            window.ReactNativeWebView.postMessage('navigationStateChange');
          });
        })();
        true;
        ${injectedJavaScript || ''}
      `
      : injectedJavaScript

    const queryClient = useQueryClient()

    // useCallback 以避免 webview 不必要的重渲染，会导致 webview 重新加载初始 url
    const innerOnMessage = useCallback(
      ({ nativeEvent }: WebViewMessageEvent) => {
        if (isAndroid && nativeEvent.data === 'navigationStateChange') {
          setCanGoBack(nativeEvent.canGoBack)
          return
        }

        // 我们规定的通信规范是 nativeEvent.data = { type, data }
        let params: { type: any; data: any } = { type: null, data: null }
        try {
          params = JSON.parse(nativeEvent.data)
        } catch (e) {
          // silently fail but report to sentry
          filterAndReportError(
            new Error(`[Webview] Failed to JSON.parse "${nativeEvent.data}"`),
            {
              ExceptionType: 'WebviewOnMessage',
            },
          )
        }

        /**
         * 这儿有一些跨业务通用的 type 支持,
         * 特定业务需要的 type 可以在业务代码中使用 webview 时,
         * 定义一个 onMessage prop 来处理
         */
        switch (params.type) {
          case 'log':
            // Mainly for debugging
            // eslint-disable-next-line no-console
            console.log('[Webview log]', params.data)
            break

          case 'navigate':
            // 跳转 native screen
            navigationRef.current?.navigate(
              params.data.name,
              params.data.params,
            )

            break

          case 'reloadQuery':
            // 刷新 query
            queryClient.invalidateQueries({ predicate: params.data.predicate })
            break

          case 'back':
            // 在 native 中返回

            if (canGoBackRef.current && webviewRef.current?.goBack) {
              webviewRef.current.goBack()

              return
            }

            navigationRef.current?.goBack()
            break

          case 'toast':
            // 在 native 中 toast, 这样后退时 toast 才不会随 webview 一起滑走
            Toast.show(params.data.message, params.data.duration)
            break

          default:
            break
        }

        onMessage?.(params)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    )

    // useMemo 以避免 webview 不必要的重渲染，会导致 webview 重新加载初始 url
    const webviewSource = useMemo(
      () => (uri ? { uri: createWebviewUri(uri) } : source),
      [uri, source],
    )

    if (!webviewSource) {
      return <NotFound />
    }

    return (
      <View style={{ flex: 1, marginTop: hideNavBarOfGoWeb ? -44 : 0 }}>
        {userAgent ? (
          <WebviewOriginal
            ref={webviewRef}
            source={webviewSource}
            userAgent={userAgent}
            originWhitelist={originWhitelist}
            allowFileAccess={allowFileAccess}
            injectedJavaScript={innerInjectedJavaScript}
            onMessage={innerOnMessage}
            javaScriptEnabled={isAndroid || javaScriptEnabled}
            onNavigationStateChange={state => {
              onNavigationStateChange(state)
              onNavigationStateChangeOuter?.(state)
            }}
          />
        ) : showLoading ? (
          <State.LoadingGlobal />
        ) : null}
      </View>
    )
  },
)

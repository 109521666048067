import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'schedule/lesson/:id/:classInCourseId/:classInLessonId',
    name: 'LessonScheduleDetail',
    getTitle: () => $t('日程详情'),
    Component: lazy(() =>
      import('./screens/LessonScheduleDetail').then(m => ({
        default: m.LessonScheduleDetail,
      })),
    ),
  },
  {
    path: 'classes/:classId/group',
    name: 'ClassGroup',
    getTitle: () => $t('课程班群组'),
    Component: lazy(() =>
      import('./screens/Group').then(m => ({
        default: m.ClassGroupIndex,
      })),
    ),
  },
  {
    path: 'classes/:classId/entry/:itemId',
    name: 'ClassGradeEntry',
    getTitle: () => $t('成绩录入'),
    Component: lazy(() =>
      import('./screens/Group/GradeEntry').then(m => ({
        default: m.ClassGradeEntry,
      })),
    ),
  },
  {
    path: 'classes/:classId/analyses',
    name: 'ClassGradeAnalyses',
    getTitle: () => $t('成绩分析'),
    Component: lazy(() =>
      import('./screens/Group/GradeAnalyses').then(m => ({
        default: m.ClassGradeAnalyses,
      })),
    ),
  },
  {
    path: 'schedule/classes/:reflectionId/:classId/:date/:timeranges',
    name: 'MultiClassInfo',
    getTitle: () => $t('多班同时上课'),
    Component: lazy(() =>
      import('./screens/MultiClassInfo').then(m => ({
        default: m.MultiClassInfo,
      })),
    ),
  },
  {
    path: 'classes/dailyGrades/:id',
    name: 'DailyGrades',
    getTitle: () => $t('成绩日报'),
    Component: lazy(() =>
      import('./screens/DailyGrades').then(m => ({
        default: m.DailyGrades,
      })),
    ),
  },
]

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'PluginAbsenceReflection',
    path: 'plugin/absence/reflection',
    getTitle: () => $t('个人资料'),
    Component: lazy(() =>
      import('./screens/AbsenceReflection').then(m => ({
        default: m.PluginAbsenceReflection,
      })),
    ),
  },
  {
    name: 'PluginAbsencePersonalStatistics',
    path: 'plugin/absence/personal-stat',
    getHeaderTitle: () => $t('请假记录'),
    Component: lazy(() =>
      import('./screens/AbsencePersonalStatistics').then(m => ({
        default: m.PluginAbsencePersonalStatistics,
      })),
    ),
  },
  {
    name: 'PluginAbsenceAgentDetail',
    path: 'plugin/absence/agent-detail',
    getHeaderTitle: () => $t('代请假详情'),
    Component: lazy(() =>
      import('./screens/AbsenceAgentDetail').then(m => ({
        default: m.PluginAbsenceAgentDetail,
      })),
    ),
  },
  {
    name: 'PluginAbsenceEarlyClosure',
    path: 'plugin/absence/early-closure',
    getHeaderTitle: () => $t('提前结束请假'),
    Component: lazy(() =>
      import('./screens/AbsenceEarlyClosure').then(m => ({
        default: m.AbsenceEarlyClosure,
      })),
    ),
  },
]

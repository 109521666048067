import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'messages',
    name: 'Messages',
    getTitle: () => $t('消息'),
    getHeaderTitle: () => $t('消息'),
    Component: lazy(() =>
      import('./screens/List').then(m => ({ default: m.MessageList })),
    ),
  },
  {
    path: 'messages/:id',
    name: 'MessageDetail',
    getTitle: () => $t('消息'),
    getHeaderTitle: () => $t('消息'),
    Component: lazy(() =>
      import('./screens/Detail').then(m => ({ default: m.MessageDetail })),
    ),
  },
  {
    path: 'messages/search',
    name: 'SearchMessages',
    getTitle: () => $t('搜索消息'),
    Component: lazy(() =>
      import('./screens/List/SearchModal').then(m => ({
        default: m.SearchModal,
      })),
    ),
  },
]

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { AccountTypeEnum } from './account-type-enum'
import { ClassinAccount, ClassinAccountInterface } from './classin-account'

const buildConfig = createConfigBuilder('chalk')

export const classinApi$getAccount = {
  config(
    reflectionType: string,
    accountType: AccountTypeEnum,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(options, 'get', '/classin/account-info', {
      reflectionType,
      accountType,
    })
  },

  /**
   * @summary 查询当前用户的关联账户信息
   * @param reflectionType 筛选当前用户指定身份所绑定的账号信息
   * @param accountType 筛选当前用户某一类型的绑定账户
   * @param [options]
   * @return AxiosResponsePromise<ClassinAccount>
   */

  api(
    reflectionType: string,
    accountType: AccountTypeEnum,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<ClassinAccount> {
    return axios(
      classinApi$getAccount.config(reflectionType, accountType, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new ClassinAccount(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = ClassinAccount>(
    {
      reflectionType,
      accountType,
    }: {
      reflectionType: string
      accountType: AccountTypeEnum
    },
    queryOptions?: QueryOptionsWithSelect<ClassinAccount, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<ClassinAccount, TSelected>(
      classinApi$getAccount.api,
      'Classin.getAccount',
      queryOptions,
      reflectionType,
      accountType,
    )
  },
}

// @ts-ignore
classinApi$getAccount.api._name_ = `Classin.getAccount`

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { getOnlineScoringMenu } from './menu'
import { routes } from './routes'
import { todos } from './todos'

export const onlineScoring: InitPluginFeature = {
  name: PluginNameEnum.OnlineScoring,
  todos,
  register: () => ({
    routes,
    slots: [
      {
        name: 'asyncAppMenuItems',
        slot: getOnlineScoringMenu(),
      },
    ],
  }),
}

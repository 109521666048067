import { MessageDomainEnum } from 'packages/features/messages/types'
import { NoticeTypes } from 'packages/plugins/features/chat/message/message-type'
import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { calendarEvents } from './calendar-events'
import { getChatMenu } from './menu-slot'
import { routes } from './routes'
import { todos } from './todos'

export const chat: InitPluginFeature = {
  name: PluginNameEnum.Chat,
  todos,
  calendarEvents,
  messageNavigators: [
    {
      type: [
        `${MessageDomainEnum.Chat}.${NoticeTypes.Created}`,
        `${MessageDomainEnum.Chat}.${NoticeTypes.Changed}`,
        `${MessageDomainEnum.Chat}.${NoticeTypes.Apply}`,
        `${MessageDomainEnum.Chat}.${NoticeTypes.GuardianSubmitted}`,
      ],
      navigator: message => {
        const attributes = message.attributes as {
          chatId: number
          instanceId?: number
        }

        const chatId = attributes?.chatId
        const instanceId = attributes?.instanceId

        if (!chatId || !instanceId) return null

        return ['PluginChatDetail', { id: chatId, chatInstanceId: instanceId }]
      },
    },
  ],
  register: plugins => {
    const parentPlugin = plugins.find(plugin => !plugin.parentId)

    return {
      routes,
      slots: parentPlugin
        ? [
            {
              name: 'asyncAppMenuItems',
              slot: getChatMenu(parentPlugin),
            },
          ]
        : [],
    }
  },
}

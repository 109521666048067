import React from 'react'
import { ViewStyle } from 'react-native'

import {
  useHasPluginWithAnyPermission,
  PluginEnum,
  useHasEnabledParentPlugin,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { isResit } from 'packages/plugins/features/resit'
import { PluginNameEnum } from 'packages/plugins/types'
import { Grade } from 'packages/sdks-next/vnas'

import { TipWrapper, Tip, Label, LabelBox } from './styles'

/**
 * 补考成绩插槽  - 提示标签
 *
 * @param param - Argument Object
 * @param param.grade - 成绩
 * @param param.style - 样式
 * @returns React.ReactNode
 */
export const ResitTagTip: React.FC<{
  grade?: Grade | null
  style?: ViewStyle
}> = ({ grade, style }) => {
  const resitPluginEnable = useHasPluginWithAnyPermission(PluginEnum.ExamResit)
  const szCustomResitRuleEnabled = useHasEnabledParentPlugin(
    PluginNameEnum.SZCustomResitRule,
  )

  // 未开启补考管理插件 || 不是补考 || 开启了深圳中学定制补考规则插件
  if (!resitPluginEnable || !isResit(grade) || szCustomResitRuleEnabled)
    return null

  return (
    <TipWrapper style={style}>
      <LabelBox>
        <Label>{$t('补考')}</Label>
      </LabelBox>
      <Tip>{$t('补考成绩不纳入成绩分析')}</Tip>
    </TipWrapper>
  )
}

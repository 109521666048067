import { camelizeKeys } from '@seiue/axios'
import { isIOS } from '@seiue/rn-util'
import { isFunction, omit, parseURL } from '@seiue/util'
import React from 'react'
import { Linking } from 'react-native'

import {
  isWebNavigatorRes,
  NativeNavigatorRes,
  WebNavigatorRes,
} from 'packages/features/messages/register'
import { useCurrentReflection } from 'packages/features/sessions'
import { Message } from 'packages/sdks-next/chalk'

import { getMessageNavigator } from '@go/features/messages/utils'
import { MainStackParams } from '@go/router'
import { filterAndReportError } from '@go/utils/error-handler'
import {
  addNotificationListener,
  TPushNotificationType,
} from '@go/utils/native-modules/tpush-native-module'
import { navigate } from '@go/utils/navigator'

/**
 *【Android】推送点击事件处理
 * Android 借助 Linking 的机制，也可以理解为「跨应用调用」，能够实现通过一个链接就能打开 App 或 App 中的某个页面。
 * 所以点击推送时，相当于通过一个 url 打开了我们的 App，推送参数就在 url 上，这里只要监听 url 就可以处理了。
 * 详细用法请见：https://facebook.github.io/react-native/docs/linking
 *
 * @param props - props
 * @param props.onProcess - 推送的处理事件
 * @returns 空内容
 */
const PushAndroid: React.FC<{
  onProcess: (customContent: string) => void
}> = ({ onProcess }) => {
  // 处理 url
  const handleUrl = ({ url }: { url: string | null }) => {
    if (!url) return

    const { url: parsedUrl, query } = parseURL(url)

    // notification 是和后端商定好的推送的路径，暂时只处理这一类
    if (!parsedUrl.includes('chalk://notification')) return

    onProcess((query?.['custom'] as string) || '')
  }

  // 监听 url
  React.useEffect(() => {
    Linking.getInitialURL().then(url => handleUrl({ url }))

    const subscription = Linking.addEventListener('url', handleUrl)

    return () => {
      subscription?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

/**
 * 【iOS】推送点击事件处理
 * iOS 分两种情况处理
 * 进程被杀死：点击推送时，推送参数被带入 iOS 的生命周期函数中，可以从 Main Component 获取
 * 进程未杀死：通过在 iOS 的推送回调中派发事件到 RN
 *
 * @param props - props
 * @param props.pushCustomContent - 推送的数据内容
 * @param props.onProcess - 推送的处理事件
 * @returns 空内容
 */
const PushIOS: React.FC<{
  pushCustomContent?: string
  onProcess: (customContent: string) => void
}> = ({ pushCustomContent, onProcess }) => {
  // 对应「进程被杀死」的情况
  React.useEffect(() => {
    if (pushCustomContent) {
      onProcess(pushCustomContent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushCustomContent])

  // 监听推送事件并存储数据，对应「进程未杀死」的情况
  React.useEffect(() => {
    const subscription = addNotificationListener(
      ({ customContent }: TPushNotificationType) => {
        onProcess(customContent)
      },
    )

    return () => {
      subscription?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

/**
 * 推送点击事件处理
 *
 * @param props - props
 * @param props.pushCustomContent - 推送的数据内容，数据格式为：{"type":"xxx","message_id":"xx",...各业务的自定义属性}
 * @returns component
 */
export const PushProcessor: React.FC<{
  // 推送的自定义参数
  pushCustomContent?: string
}> = ({ pushCustomContent }) => {
  const currentReflection = useCurrentReflection()

  const onProcess = async (customContent: string) => {
    try {
      const message = camelizeKeys(JSON.parse(customContent)) as unknown as {
        /**
         * 消息类型，同 Message.type
         */
        type?: string
        /**
         * 消息 id，同 Message.id
         */
        messageId: string
        [key: string]: any
      }

      if (!message.type) return

      // 「通知」跳转
      if (['article.created', 'message'].includes(message.type)) {
        navigate('InboxNoticeDetail', { id: message.messageId })
        return
      }

      // 3.0 新通知
      if (
        ['notification.received', 'notification.republished'].includes(
          message.type,
        )
      ) {
        navigate('InboxNoticeDetail', {
          id: message['id'],
          idType: 'conversationId',
        })

        return
      }

      if (['todo.reminded'].includes(message.type)) {
        navigate('Todos')

        return
      }

      const messageFieldCount = Object.keys(message).length

      // 如果推送参数只有 type 和 messageId，那我们就认为是未适配过的业务消息推送，因此跳转到消息列表页
      if (message.type && message.messageId && messageFieldCount === 2) {
        navigate('Messages')
        return
      }

      // 「系统消息」跳转
      const navigatorProps = getMessageNavigator(
        {
          type: message.type,
          attributes: omit(message, ['message_id', 'type']) as any,
        } as Message,
        currentReflection,
      )

      const handleNavigator = (
        navigatorResult: NativeNavigatorRes | WebNavigatorRes | null,
      ) => {
        if (!navigatorResult) {
          navigate('Messages')
          return
        }

        // 如果「系统消息」关联业务暂不支持 app，则先跳转到统一的提示页面
        if (isWebNavigatorRes(navigatorResult)) {
          navigate('MessageDetail', {
            id: message.messageId,
            webNavigator: JSON.stringify(navigatorResult),
          })

          return
        }

        const [routeName, routeParams] = navigatorResult
        navigate(routeName as keyof MainStackParams, routeParams)
      }

      if (isFunction(navigatorProps)) {
        const result = await navigatorProps()
        handleNavigator(result)
      } else {
        handleNavigator(navigatorProps)
      }
    } catch (err) {
      // 点击推送跳转允许静默失败
      filterAndReportError(err, {
        ExceptionType: 'PushNotificationNavigation',
      })
    }
  }

  return isIOS ? (
    <PushIOS pushCustomContent={pushCustomContent} onProcess={onProcess} />
  ) : (
    <PushAndroid onProcess={onProcess} />
  )
}

import React from 'react'

import {
  EnabledPlugin,
  PluginEnum,
  getPluginIcon,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'

import { LogoRender } from '@go/components/Logo/Render'
import { Menu } from '@go/features/applications/types'

/**
 * 获取教师考勤菜单
 *
 * @param plugin - 教师考勤插件
 * @returns 菜单
 */
export const getTeacherAttendanceMenu = (plugin: EnabledPlugin) => {
  return async (): Promise<Menu[] | null> => {
    return [
      {
        key: PluginEnum.TeacherAttendance,
        pluginName: PluginEnum.TeacherAttendance,
        icon: ({ size } = {}) => (
          <LogoRender size={size} logo={getPluginIcon(plugin)} />
        ),
        getTitle: () => $t('教师考勤'),
        screen: {
          name: 'Plugin.TeacherAttendance.Entry',
          params: undefined,
        },
      },
    ]
  }
}

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'Plugin.VisitorCenter',
    getHeaderTitle: () => $t('访客系统'),
    Component: lazy(() =>
      import('./screens/Entry').then(m => ({
        default: m.Entry,
      })),
    ),
  },
  {
    name: 'Plugin.VisitorCenter.CreateInvitation',
    getHeaderTitle: () => $t('创建邀请'),
    Component: lazy(() =>
      import('./screens/CreateInvitation').then(m => ({
        default: m.CreateInvitation,
      })),
    ),
  },
  {
    name: 'Plugin.VisitorCenter.InvitationHistory',
    getHeaderTitle: () => $t('邀请记录'),
    Component: lazy(() =>
      import('./screens/InvitationHistory').then(m => ({
        default: m.InvitationHistory,
      })),
    ),
  },
  {
    name: 'Plugin.VisitorCenter.InvitationQRCode',
    getHeaderTitle: () => $t('邀请二维码'),
    Component: lazy(() =>
      import('./screens/InvitationQRCode').then(m => ({
        default: m.InvitationQRCode,
      })),
    ),
  },
  {
    name: 'Plugin.VisitorCenter.InvitationDetail',
    getTitle: () => $t('访客详情'),
    Component: lazy(() =>
      import('./screens/InvitationVisitors').then(m => ({
        default: m.InvitationVisitors,
      })),
    ),
  },
  {
    name: 'Plugin.VisitorCenter.InviteeRecords',
    getHeaderTitle: () => $t('访客记录'),
    Component: lazy(() =>
      import('./screens/InviteeRecords').then(m => ({
        default: m.InviteeRecords,
      })),
    ),
  },
]

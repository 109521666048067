import { useAtom } from 'jotai'
import React from 'react'

import { useOnlineIncentiveShortcuts } from 'packages/feature-utils/certifications'
import { PluginEnum, useHasUsablePlugin } from 'packages/feature-utils/plugins'
import { useCurrentReflection } from 'packages/features/sessions'
import { useIsLoggedIn } from 'packages/features/sessions/hooks'
import { moralApi$queryShortcutItems } from 'packages/sdks-next/vnas'

import { showShortcutAtom } from './atoms'

/**
 * 是否显示底部导航栏的「快速创建」入口
 *
 * @param sessionCreated - session 是否创建完成
 * @returns 是否
 */
export const useShouldShowShortcut = (sessionCreated: boolean) => {
  const [showShortcut, setShowShortcut] = useAtom(showShortcutAtom)

  // 德育评价随手评
  const {
    data: moralShortcuts,
    loading: loadingMoralShortcuts,
    reload: reloadMoralShortcuts,
  } = moralApi$queryShortcutItems.useApi(
    {
      perPage: 1,
      fields: 'id',
    },
    { disable: !sessionCreated },
  )

  const isLoggedIn = useIsLoggedIn()
  const currentReflectionId = useCurrentReflection().id
  React.useEffect(() => {
    if (isLoggedIn) {
      reloadMoralShortcuts()
    }
  }, [currentReflectionId, isLoggedIn, reloadMoralShortcuts])

  // 认证随手评
  const certificationShortcuts = useOnlineIncentiveShortcuts()

  // 是否有教师考勤可见权限
  const hasTeacherAttendancePermission = useHasUsablePlugin(
    PluginEnum.TeacherAttendance,
  )

  const show =
    !!moralShortcuts?.length ||
    !!certificationShortcuts.length ||
    hasTeacherAttendancePermission

  React.useEffect(() => {
    setShowShortcut(show)
  }, [setShowShortcut, show])

  return {
    show: showShortcut,
    loading: loadingMoralShortcuts,
  }
}

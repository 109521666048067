import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'notices',
    name: 'Notices',
    getHeaderTitle: () => $t('通知'),
    Component: lazy(() =>
      import('./screens/List').then(m => ({ default: m.NoticeList })),
    ),
  },
  {
    path: 'notices/inbox/:id',
    name: 'InboxNoticeDetail',
    getHeaderTitle: () => $t('通知详情'),
    Component: lazy(() =>
      import('./screens/InboxDetail').then(m => ({
        default: m.InboxNoticeDetail,
      })),
    ),
  },
  {
    path: 'notices/outbox/:id',
    name: 'OutboxNoticeDetail',
    getHeaderTitle: () => $t('通知详情'),
    Component: lazy(() =>
      import('./screens/OutboxDetail').then(m => ({
        default: m.OutboxNoticeDetail,
      })),
    ),
  },
  {
    path: 'notices/share/:id/:title',
    name: 'ShareNotice',
    getHeaderTitle: () => $t('分享通知'),
    Component: lazy(() =>
      import('./screens/Share').then(m => ({
        default: m.ShareNoticeScreen,
      })),
    ),
  },
  {
    path: 'notices/sender-reminder/:id',
    name: 'NoticeSenderReminder',
    getHeaderTitle: () => $t('提醒'),
    Component: lazy(() =>
      import('./screens/UnreadReminder').then(m => ({
        default: m.UnreadSenderReminder,
      })),
    ),
  },
  {
    path: 'notices/cc-reminder/:id',
    name: 'NoticeCCReminder',
    getHeaderTitle: () => $t('提醒'),
    Component: lazy(() =>
      import('./screens/UnreadReminder').then(m => ({
        default: m.UnreadCCReminder,
      })),
    ),
  },
]

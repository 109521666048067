import { State } from '@seiue/rn-ui'
import { chunk } from '@seiue/util'
import React from 'react'

import { useOnlineIncentiveShortcuts } from 'packages/feature-utils/certifications'
import { $t } from 'packages/locale'
import { moralApi$queryShortcutItems } from 'packages/sdks-next/vnas'

import { useMainNavigation } from '@go/router/hooks'
import { Swiper } from '@go/uikit/Swiper'

import { ShortcutItem } from './Item'
import { ShortcutGroup, ShortcutGroupTitle } from './Styled'

/**
 * 随手评
 *
 * @param props - props
 * @param props.onDismiss - 关闭弹窗的回调
 * @returns component
 */
export const Shortcut: React.FC<{
  onDismiss: () => void
}> = ({ onDismiss }) => {
  // 德育随手评
  const { data: moralItems, loading: loadingMoralItems } =
    moralApi$queryShortcutItems.useApi({
      paginated: 0,
      tryExpand: ['assessment'],
    })

  // 认证随手评
  const certificationPlugins = useOnlineIncentiveShortcuts()

  const shortcutItems: {
    id: number
    title: string
    description?: string
    icon?: string
    iconBackground?: string
    onPress?: () => void
  }[] = [
    ...(moralItems?.map(moralItem => {
      return {
        id: moralItem.id,
        title: moralItem.name,
        description: moralItem.assessment?.name,
        onPress: () => {
          onDismiss()

          // 跳转到德育随手评录入页
          navigation.navigate('Plugin.MoralAssessmentShortcutEntry', {
            id: moralItem.assessmentId,
            itemId: moralItem.id,
          })
        },
      }
    }) || []),
    ...certificationPlugins.map(certPlugin => {
      return {
        id: certPlugin.id,
        title: certPlugin.label,
        icon: certPlugin.icon,
        iconBackground: '#F7585C',
        onPress: () => {
          onDismiss()

          // 跳转到认证随手评录入页
          navigation.navigate('Plugin.Certifications.ShortcutEntry', {
            certificationPluginId: certPlugin.id,
          })
        },
      }
    }),
  ]

  const chunkedItems = chunk(shortcutItems || [], 5)

  const navigation = useMainNavigation()

  return loadingMoralItems ? (
    <State.Loading />
  ) : !shortcutItems.length ? null : (
    <ShortcutGroup>
      <ShortcutGroupTitle>{$t('随手评')}</ShortcutGroupTitle>
      <Swiper>
        {chunkedItems.map((chunked, chunkedIdx) => {
          return (
            <Swiper.Item key={chunkedIdx}>
              {chunked.map((item, itemIdx) => {
                return (
                  <ShortcutItem
                    key={item.id}
                    itemIndex={chunkedIdx * 5 + itemIdx}
                    title={item.title}
                    description={item.description}
                    icon={item.icon}
                    iconBackground={item.iconBackground}
                    onPress={item.onPress}
                  />
                )
              })}
            </Swiper.Item>
          )
        })}
      </Swiper>
    </ShortcutGroup>
  )
}

import { InitPluginFeature } from '@go/plugins/types'

import { getClassinScheduleAction } from './slots/get-classin-schedule-action'
import { getClassinScheduleDetail } from './slots/get-classin-schedule-detail'
import { todos } from './todos'

export const classIn: InitPluginFeature = {
  name: 'classin',
  todos,
  register: () => ({
    slots: [
      {
        name: 'asyncOwnerScheduleDetail',
        slot: getClassinScheduleDetail,
      },
      {
        name: 'ownerScheduleEventCardAction',
        slot: getClassinScheduleAction,
      },
    ],
  }),
}

/**
 * @file 靠谱 ai 工具
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { getKaopuAiToolMenu } from './menu-slot'

export const kaopuAiTool: InitPluginFeature = {
  name: PluginNameEnum.KaopuAiTool,
  register: plugins => {
    const plugin = plugins[0]

    return {
      slots: [
        {
          name: 'asyncAppMenuItems',
          slot: getKaopuAiToolMenu(plugin),
        },
      ],
    }
  },
}

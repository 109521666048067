import { InitFeatureParams } from '../types'

import { gzAcademicCreditTodos } from './todo'

/**
 * 初始化教师赋分
 *
 * @param param0 - 参数
 */
export const initGzAcademicCredit = (param0: InitFeatureParams) => {
  const { registerTodos } = param0
  registerTodos(gzAcademicCreditTodos)
}

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'EvaluationsScreen',
    getTitle: () => $t('填写评教'),
    Component: lazy(() =>
      import('./screens/EvaluationsScreen').then(m => ({
        default: m.EvaluationsScreen,
      })),
    ),
  },
]

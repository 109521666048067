/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  NetdiskPermission,
  NetdiskPermissionInterface,
} from './extends/netdisk-permission'

const buildConfig = createConfigBuilder('chalk')

export const netdiskApi$queryPersonalNetdiskPermissions = {
  config(
    ownerId: number,
    fileIds: string,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/netdisk/personal/{ownerId}/permissions',
      {
        ownerId,
        fileIds,
      },
    )
  },

  /**
   * @summary 获取网盘权限信息
   * @param ownerId
   * @param fileIds
   * @param [options]
   * @return AxiosResponsePromise<NetdiskPermission[]>
   */

  api(
    ownerId: number,
    fileIds: string,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<NetdiskPermission[]> {
    return axios(
      netdiskApi$queryPersonalNetdiskPermissions.config(
        ownerId,
        fileIds,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data
          ? _data.map((d: any) => new NetdiskPermission(d))
          : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = NetdiskPermission[]>(
    {
      ownerId,
      fileIds,
    }: {
      ownerId: number
      fileIds: string
    },
    queryOptions?: QueryOptionsWithSelect<NetdiskPermission[], TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<NetdiskPermission[], TSelected>(
      netdiskApi$queryPersonalNetdiskPermissions.api,
      'Netdisk.queryPersonalNetdiskPermissions',
      queryOptions,
      ownerId,
      fileIds,
    )
  },
}

// @ts-ignore
netdiskApi$queryPersonalNetdiskPermissions.api._name_ = `Netdisk.queryPersonalNetdiskPermissions`

/**
 * @file 顶层路由页面
 */

import { createStackNavigator } from '@react-navigation/stack'
import { isReactNative } from '@seiue/rn-util'
import React, { Suspense, useRef, lazy } from 'react'
import { Platform } from 'react-native'

import { useIsLoggedIn, useIsNoLogged } from 'packages/features/sessions/hooks'
import { isPublicShareURL } from 'packages/features/shares/utils'
import { $t } from 'packages/locale'

import { ErrorBoundary } from '@go/components/ErrorBoundary'
import { SharesNavigator } from '@go/features/shares/SharesNavigator'

import { MainNavigator } from './MainNavigator'
import { RootStackParams } from './types'
import { useSessionNavigationTransfer } from './use-session-nav-transfer'
import { defaultHeaderOptions, getModalRoutes } from './utils'

const LoginScreenWithErrorBoundaryLazy = lazy(() =>
  import('@go/features/sessions/screens/Login').then(mod => ({
    default: mod.LoginScreenWithErrorBoundary,
  })),
)

const LoginScreenWithErrorBoundary = () => (
  <Suspense fallback={<></>}>
    <LoginScreenWithErrorBoundaryLazy />
  </Suspense>
)

const RootStack = createStackNavigator<RootStackParams>()

/**
 * 顶层路由组件
 *
 * @returns React.ReactElement
 */
export const RootNavigator: React.FC = () => {
  const isLoggedIn = useIsLoggedIn()
  const isNoLogged = useIsNoLogged()
  const isPublicShare = isPublicShareURL()

  useSessionNavigationTransfer()

  const isDisplayedRef = useRef(false)

  // 登录状态稳定后，再进行余下渲染，否则，系统会提前进入路由
  if (!isLoggedIn && !isNoLogged && !isDisplayedRef.current) return null

  isDisplayedRef.current = true

  // 如果非 RN 环境，且未登录，那么此时浏览器环境下，直接跳转到 passport，不需要余下渲染
  // 为什么这么做？因为渲染余下的路由将会改变当前的 URL，这样会导致浏览器环境下，重新登录后，无法回到原来的 URL
  if (!isReactNative && !isLoggedIn && !isPublicShare) {
    return null
  }

  return (
    <RootStack.Navigator screenOptions={defaultHeaderOptions}>
      {isLoggedIn ? (
        <RootStack.Screen
          name="Main"
          component={MainNavigator}
          options={{
            headerShown: false,
            gestureEnabled: false,
          }}
        />
      ) : (
        <RootStack.Screen
          name="LoginScreen"
          component={LoginScreenWithErrorBoundary}
          options={{
            headerShown: false,
            gestureEnabled: false,
            headerTitle: $t('登录'),
          }}
        />
      )}
      <RootStack.Screen name="Share">
        {props => <SharesNavigator {...props} />}
      </RootStack.Screen>
      {getModalRoutes().map(({ name, getTitle, getHeaderTitle, Component }) => (
        <RootStack.Screen
          key={name}
          name={name as keyof RootStackParams}
          options={{
            title: getTitle?.() ?? '',
            headerTitle: getHeaderTitle?.() ?? '',
            presentation: 'modal',
            // FIXME 升级 react-native-webview 后遇到一个 Android crash 问题，等待官方修正
            // https://github.com/react-native-webview/react-native-webview/issues/1915
            // https://github.com/react-native-webview/react-native-webview/pull/2874
            animationEnabled: Platform.select({
              ios: true,
              android: false,
            }),
          }}
        >
          {props => (
            <ErrorBoundary>
              <Suspense fallback={<></>}>
                <Component {...props} />
              </Suspense>
            </ErrorBoundary>
          )}
        </RootStack.Screen>
      ))}
    </RootStack.Navigator>
  )
}

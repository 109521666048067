import React from 'react'

import { eventApi$listInstances } from 'packages/sdks-next/chalk'

import { LogoRender } from '@go/components/Logo/Render'
import { Menu } from '@go/features/applications/types'

/**
 * 获取活动插件菜单
 *
 * @returns 活动菜单列表
 */
export const getEventMenu = async (): Promise<Menu[] | null> => {
  const { data: events } = await eventApi$listInstances.api({
    expand: ['schoolPlugin', ['schoolPlugin', 'adminReflections']] as const,
  })

  const enabledEvents = events?.filter(e => !!e.schoolPlugin.enabled)
  if (!enabledEvents?.length) return []

  return enabledEvents.map(plugin => ({
    key: `${plugin.schoolPlugin.id}`,
    pluginId: plugin.schoolPlugin.id,
    icon: ({ size } = {}) => (
      <LogoRender size={size} logo={plugin.schoolPlugin.icon} />
    ),
    getTitle: () => plugin.schoolPlugin.label,
    screen: {
      name: 'EventList' as const,
      params: { pid: plugin.schoolPlugin.bizId as number },
    },
  }))
}

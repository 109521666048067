import React from 'react'

import { RegisterWorkflowRenderType } from 'packages/features/workflows/register'

export const workflowRenders: RegisterWorkflowRenderType = {
  bizType: 'chat',
  render: {
    ShowFlowModal: React.lazy(() =>
      import('./ShowFlowModal').then(m => ({
        default: m.ShowPsyChatFlowModal,
      })),
    ),
    NewFlowModal: React.lazy(() =>
      import('./NewFlowModal').then(m => ({
        default: m.NewFlowWarningModal,
      })),
    ),
  },
}

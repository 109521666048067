/**
 * @file 问卷模块路由
 */
import { lazy } from 'react'

import { $t } from 'packages/locale/LocaleProvider'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'QuestionnaireSquare',
    getTitle: () => $t('问卷'),
    Component: lazy(() =>
      import('./screens/QuestionnaireSquare').then(m => ({
        default: m.QuestionnaireSquare,
      })),
    ),
  },
  {
    name: 'QuestionnaireSubmit',
    getTitle: () => $t('问卷填写'),
    Component: lazy(() =>
      import('./screens/QuestionnaireSubmit').then(m => ({
        default: m.QuestionnaireSubmit,
      })),
    ),
  },
]

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'VenuesCenter',
    Component: lazy(() =>
      import('./screens/Center').then(m => ({ default: m.VenuesCenter })),
    ),
    getHeaderTitle: () => $t('场地预约'),
  },
  {
    name: 'SearchVenues',
    Component: lazy(() =>
      import('./screens/SearchVenues').then(m => ({
        default: m.SearchVenues,
      })),
    ),
    getHeaderTitle: () => $t('搜索场地'),
  },
  {
    name: 'SearchOrders',
    Component: lazy(() =>
      import('./screens/SearchOrders').then(m => ({
        default: m.SearchOrders,
      })),
    ),
    getHeaderTitle: () => $t('搜索预约'),
  },
  {
    name: 'SearchApprovals',
    Component: lazy(() =>
      import('./screens/SearchApprovals').then(m => ({
        default: m.SearchApprovals,
      })),
    ),
    getHeaderTitle: () => $t('搜索预约'),
  },
  {
    name: 'OrderDetail',
    Component: lazy(() =>
      import('./screens/OrderDetail').then(m => ({
        default: m.OrderDetail,
      })),
    ),
    getHeaderTitle: () => $t('预约详情'),
  },
  {
    name: 'ApplyOrder',
    Component: lazy(() =>
      import('./screens/Apply').then(m => ({
        default: m.ApplyOrder,
      })),
    ),
    getHeaderTitle: () => $t('预约申请'),
  },
]

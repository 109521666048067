import { InitFeatureParams } from '../types'

import { semesterReviewTodos } from './todo'

/**
 * 初始化学期评语
 *
 * @param param0 - 参数
 */
export const initSemesterReview = (param0: InitFeatureParams) => {
  const { registerTodos } = param0
  registerTodos(semesterReviewTodos)
}

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { calendarEvents } from './calendar-events'
import { routes } from './routes'
import { todos } from './todos'

export const classReview: InitPluginFeature = {
  name: PluginNameEnum.ClassReview,
  todos,
  calendarEvents,
  register: () => {
    return {
      routes,
    }
  },
}

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'schedule/week',
    name: 'WeekSchedule',
    getTitle: () => $t('周日程'),
    Component: lazy(() =>
      import('./screens/WeekSchedule').then(m => ({ default: m.WeekSchedule })),
    ),
  },
  {
    path: 'schedule/owner/:id',
    name: 'OwnerScheduleDetail',
    getTitle: () => $t('日程详情'),
    Component: lazy(() =>
      import('./screens/OwnerScheduleDetail').then(m => ({
        default: m.OwnerScheduleDetail,
      })),
    ),
  },
  {
    path: 'schedule/owner/:id/members',
    name: 'OwnerScheduleMembers',
    getTitle: () => $t('参与人员'),
    Component: lazy(() =>
      import('./screens/OwnerScheduleMembers').then(m => ({
        default: m.OwnerScheduleMembers,
      })),
    ),
  },
  {
    path: 'schedule/owner/:id/reject',
    name: 'RejectOwnerSchedule',
    Component: lazy(() =>
      import('./screens/RejectOwnerSchedule').then(m => ({
        default: m.RejectOwnerSchedule,
      })),
    ),
  },
]

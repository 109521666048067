import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'ClassReviewScheduleDetail',
    getTitle: () => $t('日程详情'),
    Component: lazy(() =>
      import('./screens/ClassReviewScheduleDetail').then(m => ({
        default: m.ClassReviewScheduleDetail,
      })),
    ),
  },
]

/**
 * @file 注册全局状态
 */

import storage from '@react-native-async-storage/async-storage'
import createLoadingPlugin from '@rematch/loading'
import createRematchPersist from '@rematch/persist'
import { env } from '@seiue/env'
import { init, RematchRootState, RematchDispatch } from '@seiue/rematch-core'
import {
  useSelector as originUseSelector,
  useDispatch as originUseDispatch,
} from 'react-redux'

import { entities } from 'packages/entities-store'
import { setStore } from 'packages/feature-utils/store'
import {
  session,
  sessionPersistTransform,
} from 'packages/features/sessions/stores/sessions'
import { setReduxRootStore, todosStore } from 'packages/features/todos'
import { localeStore, localePersistTransform } from 'packages/locale/store'
import { LoadingState } from 'packages/rematch-loading-types'
import { dicts } from 'packages/shared-stores/dicts'
import { migratePersistedState } from 'packages/utils/redux'

import type { NetdiskDownloadPollModel } from '@go/plugins/features/netdisk/stores/netdisk-download-poll/types'
import type { NetdiskUploadPollModel } from '@go/plugins/features/netdisk/stores/netdisk-upload-poll'

import { app, appPersistTransform } from './app'
import { flipperDebugger } from './flipper-debugger'
import { push } from './push'
import { searchUpdates } from './search'
import { versionUpdates } from './version-updates'

const loading = createLoadingPlugin({})

const models = {
  session,
  entities,
  app,
  versionUpdates,
  push,
  dicts,
  locale: localeStore,
  todos: todosStore,
  searchUpdates,
}

const persist = createRematchPersist({
  /**
   * 1. 持久化 session 以记住用户
   * 2. dicts 为用户的一些自定义配置
   * 3. versionUpdated 决定什么时候给用户新版本提示框
   * 4. locale 为缓存在本地的多语言配置
   * 5. app 持久化一些全局 App 状态
   * 6. searchUpdates 搜索历史记录
   */
  whitelist: [
    'session',
    'dicts',
    'versionUpdates',
    'locale',
    'app',
    'searchUpdates',
  ],

  // 最多 1 秒存储一次
  throttle: 1000,

  // 升版本以让旧缓存失效
  version: 7,

  storage,

  transforms: [
    sessionPersistTransform,
    localePersistTransform,
    appPersistTransform,
  ],

  // 当前的迁移策略是不迁移... version 变更后废弃旧 version 的缓存
  migrate: migratePersistedState,
})

// FIXME 为了让每个 e2e test case 相互没有缓存影响，测试环境暂不开启 persist 插件
const plugins = [loading]
if (env('ENV') !== 'test') plugins.push(persist)

const middlewares = []

if (flipperDebugger && env('ENV') === 'development') {
  // Enable Redux Debugger for Flipper (only on native)
  middlewares.push(flipperDebugger as any)
}

export const store = init({
  models,
  plugins,
  redux: {
    middlewares,
  },
})

// 向待办注册一下根 store
setReduxRootStore(store)

type PluginModels = {
  netdiskUploadPoll: NetdiskUploadPollModel
  netdiskDownloadPoll: NetdiskDownloadPollModel
}

export type Store = typeof store
export type Models = typeof models & PluginModels
export type Dispatch = RematchDispatch<Models>
export type RootState = RematchRootState<Models> & LoadingState<Models>

/**
 * 访问 redux `dispatch` 函数的 hook
 *
 * @returns `dispatch` 函数
 */
export const useDispatch = () => originUseDispatch<Dispatch>()

/**
 * 访问 redux store 状态的函数
 *
 * @param selector - 选择器
 * @returns 状态
 */
export const useSelector = <T>(selector: (state: RootState) => T) =>
  originUseSelector<RootState, T>(selector)

setStore(store)

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { PlatformEnum } from './platform-enum'
import { NewestAppver, NewestAppverInterface } from './newest-appver'
import type { CheckNewestQuery } from './check-newest-query'

const buildConfig = createConfigBuilder('chalk')

export const appverApi$checkNewest = {
  config(
    platform: PlatformEnum,
    currentVersion: string,
    checkNewestQuery?: CheckNewestQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/appver/appvers/newest',
      {
        platform,
        currentVersion,
      },
      checkNewestQuery,
    )
  },

  /**
   * @summary Class CheckNewestAppverApi
   * @param platform
   * @param currentVersion
   * @param [checkNewestQuery]
   * @param [appverQuery.app]
   * @param [appverQuery.clusterName]
   * @param [appverQuery.releasedStoresContains]
   * @param [options]
   * @return AxiosResponsePromise<NewestAppver>
   */

  api(
    platform: PlatformEnum,
    currentVersion: string,
    checkNewestQuery?: CheckNewestQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<NewestAppver> {
    return axios(
      appverApi$checkNewest.config(
        platform,
        currentVersion,
        checkNewestQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new NewestAppver(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = NewestAppver>(
    {
      platform,
      currentVersion,
      query,
    }: {
      platform: PlatformEnum
      currentVersion: string
      query?: CheckNewestQuery
    },
    queryOptions?: QueryOptionsWithSelect<NewestAppver, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<NewestAppver, TSelected>(
      appverApi$checkNewest.api,
      'Appver.checkNewest',
      queryOptions,
      platform,
      currentVersion,
      query,
    )
  },
}

// @ts-ignore
appverApi$checkNewest.api._name_ = `Appver.checkNewest`

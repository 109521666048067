import { MessageDomainEnum } from 'packages/features/messages/types'

import { InitFeatureParams } from '../types'

import { calendarEvents } from './calendar-events'
import { routes } from './routes'
import { workflowRenders } from './workflow-renders'

import './navigators'

/**
 * 初始化课程班
 *
 * @param param0 - 参数
 */
export const initClasses = (param0: InitFeatureParams) => {
  const {
    addRoutes,
    registerCalendarEvents,
    registerMessageNavigator,
    registerWorkflowRenders,
  } = param0

  addRoutes(routes)
  registerCalendarEvents(calendarEvents)
  registerWorkflowRenders(workflowRenders)
  registerMessageNavigator(
    MessageDomainEnum.ClassAssessment,
    ({ attributes }) => {
      // @ts-expect-error 类型推断错误
      if (!attributes?.bizId) return null

      return [
        'ClassGroup',
        {
          // @ts-expect-error 类型推断错误
          id: attributes.bizId,
        },
      ] as const
    },
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'

const buildConfig = createConfigBuilder('chalk')

export const userApi$deleteUserDevice = {
  config(id: string, options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(options, 'delete', '/me/devices/{id}', {
      id,
    })
  },

  /**
   * @summary Class DeleteUserDevice
   * @param id
   * @param [options]
   * @return AxiosResponsePromise<null>
   */

  api(
    id: string,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<null> {
    return axios(userApi$deleteUserDevice.config(id, options)) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      (id: string) => userApi$deleteUserDevice.api(id),
      mutationOptions,
    )
  },
}

// @ts-ignore
userApi$deleteUserDevice.api._name_ = `User.deleteUserDevice`

import {
  ParamListBase,
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/core'
import { StackNavigationOptions } from '@react-navigation/stack'
import { isEmpty } from '@seiue/util'
import React, { useContext } from 'react'
import { StatusBarStyle } from 'react-native'

import { parseParams } from 'packages/route/utils'

import { ShareNavigationContext } from '@go/features/shares/context'
import { useDispatch } from '@go/stores'

import {
  MainStackNavigationProp,
  MainStackParams,
  MainStackRouteProp,
  RootStackNavigationProp,
  RootStackParams,
  RootStackRouteProp,
} from './types'

/**
 * 获取顶层路由的导航器
 *
 * @param options - 配置项
 * @param extraDeps - 额外要监听的依赖（用来触发配置项的变更）
 * @returns 导航器
 */
export const useRootNavigation = (
  options?: StackNavigationOptions,
  extraDeps?: any[],
) => {
  const navigation =
    useNavigation<RootStackNavigationProp<keyof RootStackParams>>()

  // setOptions 需要用 useEffect 包裹以解决一个 warning
  React.useEffect(() => {
    if (options) navigation.setOptions(options)
  }, [navigation, options, extraDeps])

  return navigation
}

/**
 * 获取业务路由的导航器
 *
 * @param options - 配置项
 * @param options.statusBarStyle - 状态栏颜色
 * @param extraDeps - 额外要监听的依赖（用来触发配置项的变更）
 * @returns 导航器
 */
export const useMainNavigation = (
  options?: StackNavigationOptions & {
    statusBarStyle?: StatusBarStyle
  },
  extraDeps?: any[],
) => {
  const navigation =
    useNavigation<MainStackNavigationProp<keyof MainStackParams>>()

  const dispatch = useDispatch()

  const { statusBarStyle, ...navOptions } = options || {}

  // setOptions 需要用 useEffect 包裹以解决一个 warning
  React.useEffect(() => {
    if (!isEmpty(navOptions)) navigation.setOptions(navOptions)
  }, [navigation, extraDeps, navOptions])

  const isFocused = useIsFocused()
  React.useEffect(() => {
    if (!statusBarStyle) return

    dispatch.app.setStatusBarStyle(isFocused ? statusBarStyle : 'dark-content')
  }, [dispatch.app, isFocused, statusBarStyle])

  return navigation
}

/**
 * 获取分享页的导航器
 *
 * @param options - 配置项
 * @param deps - 额外要监听的依赖（用来触发配置项的变更）
 */
export const useShareNavigation = (
  options: StackNavigationOptions | undefined,
  deps: any[],
) => {
  const { setOptions } = useContext(ShareNavigationContext)

  React.useEffect(() => {
    setOptions(options)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

/**
 * 在 go web 中刷新页面时, 所有 param 都会返回字符串. 和 chalk 一样做一下 parse.
 *
 * @returns 路由参数
 */
export const useParams = <
  T extends RouteProp<ParamListBase, string>,
>(): T['params'] => {
  const params = useRoute<T>().params || {}
  return parseParams(params as any)
}

/**
 * 获取顶层路由的参数
 *
 * @returns 路由参数
 */
export const useRootParams = <RouteName extends keyof RootStackParams>() =>
  useParams<RootStackRouteProp<RouteName>>()

/**
 * 获取业务路由的参数
 *
 * @returns 路由参数
 */
export const useMainParams = <RouteName extends keyof MainStackParams>() =>
  useParams<MainStackRouteProp<RouteName>>()

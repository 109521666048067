import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'plugins/visitor/qrcodes',
    name: 'PluginVisitorQRCodes',
    getHeaderTitle: () => $t('入校码'),
    Component: lazy(() =>
      import('./screens/MyVisitorQRCodes').then(m => ({
        default: m.PluginVisitorQRCodes,
      })),
    ),
  },
  {
    path: 'plugins/visitor/qrcodes-checker/:token',
    name: 'PluginVisitorQRCodeChecker',
    getHeaderTitle: () => $t('核验入校信息'),
    Component: lazy(() =>
      import('./screens/QRCodeChecker').then(m => ({
        default: m.QRCodeChecker,
      })),
    ),
  },
]

/**
 * @file 审批待办注册
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

const WorkflowTodoCard = lazy(() =>
  import('./WorkflowTodoCard').then(m => ({
    default: m.WorkflowTodoCard,
  })),
)

export const todos: TodoExecution[] = [
  {
    // 审批人待办
    type: TodoTypeEnum.WorkflowApprover,
    Render: WorkflowTodoCard,
  },
  {
    // 办理人待办
    type: TodoTypeEnum.WorkflowHandler,
    Render: WorkflowTodoCard,
  },
  {
    // 发起人待办
    type: TodoTypeEnum.WorkflowInitiator,
    Render: WorkflowTodoCard,
  },
]

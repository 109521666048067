/**
 * @file 需要严格在 app 初始化时运行的配置代码
 *
 * 注意！！！！
 * 以下代码有严格的引入顺序要求，需按序引入执行
 */

// public-path 需要始终在最前 @refer https://webpack.docschina.org/guides/public-path/#on-the-fly
import './public-path'
import './ua'
import './env'

/**
 * @file go router
 */

import { LinkingOptions } from '@react-navigation/native'
import { env } from '@seiue/env'
import { kebabCase } from '@seiue/util'

import { addSharesRoutes } from '@go/features/shares/SharesNavigator'

import { addMainRoutes, getMainRoutes, removeMainRoutes } from './MainNavigator'
import { sharedModalRoutes } from './shared-modal-routes'
import { tabRoutes } from './tab-routes'
import { AddRoutes, RemoveRoutes, Route, RootStackParams } from './types'
import { addModalRoutes, removeModalRoutes } from './utils'

export * from './types'
export * from './hooks'
export * from './utils'

const baseUrl = env('CLIENT_GO')

// Linking 机制用于指定 React Navigation 在 App Screen 和 Web URL Route 之间的名称
export const linking: {
  prefixes: LinkingOptions<RootStackParams>['prefixes']
  config: {
    screens: {
      [routeName: string]:
        | string
        | {
            screens: { [routeName: string]: string }
          }
      Main: {
        screens: { [routeName: string]: string }
      }
      Share: {
        screens: { [routeName: string]: string }
      }
    }
  }
} = {
  prefixes: [baseUrl],
  config: {
    screens: {
      Main: {
        screens: {},
      },
      Share: {
        screens: {},
      },
    },
  },
}

const updateLinking = (routes: Route[], stack: 'Main' | 'Root' | 'Share') => {
  const _stack =
    stack === 'Main'
      ? linking.config.screens.Main
      : stack === 'Share'
      ? linking.config.screens.Share
      : linking.config

  _stack.screens = routes.reduce(
    (rts, rt) => ({
      ...rts,
      [rt.name]: rt.path ?? kebabCase(rt.name),
    }),
    _stack.screens,
  )
}

const removeLinking = (routeNames: string[], stack: 'Main' | 'Root') => {
  const _stack = stack === 'Main' ? linking.config.screens.Main : linking.config
  _stack.screens = routeNames.reduce((rts, rtname) => {
    const nextRts = { ...rts }

    delete nextRts[rtname]

    return rts
  }, _stack.screens)
}

// tab routes 实际是放在 Tabs 中
updateLinking(
  tabRoutes.map(route => ({
    ...route,
    path: `Tabs/${route.name}`,
  })),
  'Main',
)

updateLinking(getMainRoutes(), 'Main')

/**
 * 注册路由
 *
 * @param routes - 要注册的路由列表
 */
export const addRoutes: AddRoutes = routes => {
  const mainRoutes = routes.filter(
    ({ isModal, isShare }) => !isModal && !isShare,
  )

  const modalRoutes = routes.filter(({ isModal }) => isModal)
  const shareRoutes = routes.filter(({ isShare }) => isShare)

  updateLinking(mainRoutes, 'Main')
  updateLinking(modalRoutes, 'Root')
  updateLinking(shareRoutes, 'Share')

  addMainRoutes(mainRoutes)
  addSharesRoutes(routes.filter(({ isModal }) => !isModal))
  addModalRoutes(modalRoutes)
}

// First of all let's add modal routes that are not in features
addRoutes(sharedModalRoutes)

/**
 * 移除路由
 *
 * @param routes - 要移除的路由列表
 */
export const removeRoutes: RemoveRoutes = routes => {
  const mainRouteNames = routes
    .filter(({ isModal }) => !isModal)
    .map(({ name }) => name)

  const modalRouteNames = routes
    .filter(({ isModal }) => isModal)
    .map(({ name }) => name)

  removeLinking(mainRouteNames, 'Main')

  removeLinking(modalRouteNames, 'Root')

  removeMainRoutes(mainRouteNames)

  removeModalRoutes(modalRouteNames)
}

/**
 * @file 注册审批渲染器
 */
import React from 'react'

import { RegisterWorkflowRenderType } from 'packages/features/workflows/register'

export const workflowRenders: RegisterWorkflowRenderType[] = [
  {
    bizType: 'absence',
    render: {
      FlowCard: React.lazy(() =>
        import('./FlowCard').then(m => ({ default: m.AbsenceFlowCard })),
      ),
      NewFlowModal: React.lazy(() =>
        import('./NewFlowModal').then(m => ({ default: m.NewAbsenceModal })),
      ),
      ShowFlowModal: React.lazy(() =>
        import('./ShowFlowModal').then(m => ({
          default: m.ShowAbsenceModal,
        })),
      ),
      TodoCard: React.lazy(() =>
        import('./TodoCard').then(m => ({
          default: m.AbsenceTodoCard,
        })),
      ),
    },
  },
  {
    // @ts-expect-error 特殊的请假销假审批流注册
    bizType: 'absence_verification',
    render: {
      TodoCard: React.lazy(() =>
        import('./TodoCard').then(m => ({
          default: m.AbsenceVerifictionTodoCard,
        })),
      ),
    },
  },
]

/**
 * @file 管理全局 App 状态
 * 部分字段被持久化，详见 appPersistTransform
 */

import Orientation from '@seiue/react-native-orientation-locker'
import { initSentry } from '@seiue/sentry'
import { isReactNative, pick } from '@seiue/util'
import { StatusBarStyle } from 'react-native'

import { School } from 'packages/sdks-next/chalk'
import { registerWeChat } from 'packages/wechat'

import { RootState } from '@go/stores'
import { register as registerUMeng } from '@go/utils/native-modules/umeng-native-module'

export interface AppState {
  // 登录用的手机号
  phone: string

  // 手机验证码对应的 id
  reminderId: string

  /*
   * 是否正在绑定账号
   * 该标志位用在 bindAccountModal，当其为 true 时，表示正在绑定手机和账号，此时阻止 bindPhoneModal 出现
   */
  bindingAccount: boolean

  /**
   * 状态栏样式
   */
  statusBarStyle: StatusBarStyle

  /**
   * 是否为首次进入 App
   */
  isFirstEntry: boolean

  /**
   * 是否已注册同意隐私政策时所注册的第三方服务
   */
  privacyPolicyThirdPartiesRegistered: boolean

  /**
   * 登录界面的学校的 id
   */
  loginSchool: School | null
}

const state: AppState = {
  phone: '',
  reminderId: '',
  statusBarStyle: 'light-content',
  bindingAccount: false,
  isFirstEntry: true,
  privacyPolicyThirdPartiesRegistered: false,
  loginSchool: null,
}

const reducers = {
  setPhone(appState: AppState, phone: string) {
    return { ...appState, phone }
  },

  setReminderId(appState: AppState, reminderId: string) {
    return { ...appState, reminderId }
  },

  setStatusBarStyle(appState: AppState, statusBarStyle: StatusBarStyle) {
    return { ...appState, statusBarStyle }
  },

  setBindingAccount(appState: AppState, status: boolean) {
    return { ...appState, bindingAccount: status }
  },

  setIsFirstEntry(appState: AppState, isFirstEntry: boolean) {
    return { ...appState, isFirstEntry }
  },

  setPrivacyPolicyThirdPartiesRegistered(
    appState: AppState,
    registered: boolean,
  ) {
    return { ...appState, privacyPolicyThirdPartiesRegistered: registered }
  },

  setLoginSchool(appState: AppState, loginSchool: School | null) {
    return {
      ...appState,
      loginSchool,
    }
  },
}

const effects = (dispatch: any) => ({
  /**
   * 登录后注册的第三方服务
   *
   * @param payload - payload
   * @param rootState - 全局状态
   * @param rootState.app - app 状态
   */
  registerThirdParties(payload: void, { app }: RootState) {
    if (isReactNative) {
      /**
       * 注册推送服务不阻塞主界面渲染，所以不需等待，
       * 而且该注册请求不稳定，有时会超时，需要重试，也不能等待。
       */
      dispatch.push.register()

      // 同意隐私政策有多处触发按钮，确保只注册一次
      if (!app.privacyPolicyThirdPartiesRegistered) {
        this.registerThirdPartiesWhenPrivacyPolicyAgreed()
      }
    }
  },

  /**
   * 同意隐私协议时注册的第三方服务
   *
   * @param payload - payload
   * @param rootState - 全局状态
   * @param rootState.app - app 状态
   */
  registerThirdPartiesWhenPrivacyPolicyAgreed(
    payload: void,
    { app }: RootState,
  ) {
    // 同意隐私政策有多处触发按钮，确保只注册一次
    if (isReactNative && !app.privacyPolicyThirdPartiesRegistered) {
      dispatch.app.setPrivacyPolicyThirdPartiesRegistered(true)

      // 初始化 sentry
      initSentry({
        dsn: 'https://eacee7d6972748dd89e03decfe30a921@sentry.seiue.com/44',
        // react-native sentry 不需要我们手动传版本号
        release: undefined,
      })

      // 注册友盟
      registerUMeng()

      // 注册微信分享
      registerWeChat()

      // 初始化 Orientation 屏幕旋转的方法，调用后才可以设置为横向
      Orientation.init()
    }
  },
})

export const app = {
  state,
  reducers,
  effects,
}

export const appPersistTransform = {
  in: (persistState: any, key: any) => {
    if (key === 'app') {
      // 只持久化部分字段
      return pick(persistState, ['isFirstEntry', 'loginSchoolId'])
    }

    return persistState
  },
  out: (val: any) => val,
}

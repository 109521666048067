import { Layout } from '@seiue/rn-ui'
import { isAndroid } from '@seiue/rn-util'
import React, { useRef } from 'react'

import {
  Webview,
  WebviewRef,
  WebviewProps,
} from '@go/components/webview/Webview'
import { WebviewModalHeaderLeft } from '@go/components/webview/WebviewModal/HeaderLeft'
import { RootStackComponent } from '@go/router'
import { useRootNavigation } from '@go/router/hooks'

export type WebviewModalParams = WebviewProps & {
  uri: string
  title: string
}

/**
 * Webview 路由弹窗
 *
 * @param props - props
 * @returns React.ReactElement
 */
export const WebviewModal: RootStackComponent<'WebviewModal'> = props => {
  const {
    route: {
      params: {
        title,
        uri,
        source,
        originWhitelist,
        allowFileAccess,
        injectedJavaScript,
        javaScriptEnabled,
        onMessage,
      },
    },
  } = props

  const [canGoBack, setCanGoBack] = React.useState(false)
  const ref = useRef<WebviewRef | null>(null)

  const navigation = useRootNavigation({
    headerTitle: title,
    headerStyle: { elevation: 1 },
    headerLeft: () => (
      <WebviewModalHeaderLeft
        onClose={navigation.goBack}
        onBack={ref.current?.goBack}
        canGoBack={canGoBack}
      />
    ),
    headerTitleAlign: 'center',
  })

  return (
    <Layout.Container>
      <Webview
        ref={ref}
        uri={uri}
        source={source}
        originWhitelist={originWhitelist}
        allowFileAccess={allowFileAccess}
        injectedJavaScript={injectedJavaScript}
        javaScriptEnabled={isAndroid || javaScriptEnabled}
        onMessage={onMessage}
        onNavigationStateChange={state => {
          setCanGoBack(state.canGoBack)
        }}
      />
    </Layout.Container>
  )
}

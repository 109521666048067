/**
 * @file 与 webview 配套或 webview 内部使用的一些 utils
 */

import { env } from '@seiue/env'
import { PhonePermission } from '@seiue/rn-ui'
import { isReactNative } from '@seiue/util'

import {
  createChalk2TokenAuthUrlQuery,
  createTokenAuthUrlQuery,
} from 'packages/features/sessions/utils/data'
import { isInGoWebview, sendDataToRnWebview } from 'packages/utils/rn-webview'

import type { MainStackParamsUnion } from '@go/router'
import { store } from '@go/stores'
import { navigate, navigationRef } from '@go/utils/navigator'

/**
 * 创建 webview 的 uri
 *
 * @param path - 路径
 * @returns webview uri
 */
export const createWebviewUri = (path: string) => {
  // create absolute uri
  const base = env('CLIENT_GO')
  const uri = path.startsWith('http') ? path : `${base}/${path}`

  const { oAuthToken, oAuthTokenFetchedAt, currentReflection, currentUser } =
    store.getState().session

  if (!oAuthToken) return uri

  // add chalk2 auth query if election url
  if (uri.startsWith(env('CLIENT_ELECTION'))) {
    if (!currentReflection) return uri

    const query = createChalk2TokenAuthUrlQuery({
      oAuthToken,
      currentUser,
      currentReflection,
    })

    return uri.includes('?') ? `${uri}&${query}` : `${uri}?${query}`
  }

  // return if not go or chalk url
  if (
    !uri.startsWith(env('CLIENT_GO')) &&
    !uri.startsWith(env('CLIENT_CHALK_3')) &&
    !uri.startsWith(env('CLIENT_PREVIEW'))
  ) {
    return uri
  }

  // add auth query if go or chalk url

  const query = createTokenAuthUrlQuery(
    oAuthToken,
    oAuthTokenFetchedAt,
    currentReflection?.id,
  )

  return uri.includes('?') ? `${uri}&${query}` : `${uri}?${query}`
}

/**
 * 在 go webview 中会发送 back 事件给 native，在 go web 中会直接 goBack()
 *
 * TODO: 可能漏考虑 webview 继续前进后需在 webview 中先后退的情况 (用 canGoBack 检查?)
 */
export const goBackHybrid = () => {
  if (isInGoWebview()) {
    sendDataToRnWebview('back', null)
  } else {
    navigationRef.current?.goBack()
  }
}

/**
 * 在 go webview 中会发送 navigate 事件给 native，在 go web 中会直接 navigate()
 *
 * @param screen - 要导航到的页面
 */
export const navigateHybrid = (screen: MainStackParamsUnion) => {
  if (isInGoWebview()) {
    sendDataToRnWebview('navigate', screen)
  } else {
    navigationRef.current?.navigate(screen.name, screen.params)
  }
}

/**
 * 常用的 injectedJavaScript
 */
export const injectedJavaScript = {
  /**
   * 设置 webview 页面缩放级别
   *
   * @param scale - 缩放级别
   * @returns 注入的 JavaScript 代码
   */
  setInitialScale(scale: number) {
    return `
      const meta = document.createElement('meta');
      meta.setAttribute('content', 'width=device-width, initial-scale=${scale}, user-scalable=1.0');
      meta.setAttribute('name', 'viewport');
      document.getElementsByTagName('head')[0].appendChild(meta);
    `
  },
}

/**
 * 以 WebviewModal 的方式打开 chalk3 链接
 *
 * @param params - 参数
 * @param params.uri - 链接
 * @param params.title - 标题
 * @param params.initialScale - 初始缩放级别
 */
export const openChalk3Url = async ({
  uri,
  title,
  initialScale = 0.5,
}: {
  uri: string
  title: string
  initialScale?: number | null
}) => {
  if (
    uri.startsWith(env('CLIENT_GO')) ||
    uri.startsWith(env('CLIENT_CHALK_3'))
  ) {
    // app webview 里面拍照（比如提交作业）需要提前获取权限
    await PhonePermission.request({ ios: 'ios.permission.CAMERA' })
  }

  if (isReactNative) {
    // app 跳转到 webview
    navigate('WebviewModal', {
      // Webview 内部会为 Chalk3 Chalk2 的地址添加 auth token query
      uri,
      title,
      injectedJavaScript: initialScale
        ? injectedJavaScript.setInitialScale(initialScale)
        : undefined,
    })
  }

  if (!isReactNative && typeof window !== 'undefined') {
    // go-web 使用 window.open
    window.open(createWebviewUri(uri))
  }
}

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'admin/adjustment',
    name: 'AdminAdjustmentsScreen',
    getTitle: () => $t('调代课管理'),
    getHeaderTitle: () => $t('调代课管理'),
    Component: lazy(() =>
      import('./screens/AdminAdjustmentScreen').then(m => ({
        default: m.AdminAdjustmentsScreen,
      })),
    ),
  },
  {
    path: 'admin/adjustment/new/:type',
    name: 'AdminNewAdjustmentScreen',
    getTitle: () => $t('创建调代课'),
    getHeaderTitle: () => $t('创建调代课'),
    Component: lazy(() =>
      import('./screens/New').then(m => ({
        default: m.NewAdjustmentScreen,
      })),
    ),
  },
  {
    path: 'admin/adjustment/detail/:type/:id',
    name: 'AdminAdjustmentDetailScreen',
    getTitle: () => $t('调代课详情'),
    getHeaderTitle: () => $t('调代课详情'),
    Component: lazy(() =>
      import('./screens/Detail').then(m => ({
        default: m.AdjustmentDetailScreen,
      })),
    ),
  },
  {
    path: 'adjustment',
    name: 'ClassAdjustmentsScreen',
    getTitle: () => $t('调代课'),
    getHeaderTitle: () => $t('调代课'),
    Component: lazy(() =>
      import('./screens/ClassAdjustmentsScreen').then(m => ({
        default: m.ClassAdjustmentsScreen,
      })),
    ),
  },
  {
    path: 'adjustment/new/:type',
    name: 'NewAdjustmentScreen',
    getTitle: () => $t('创建调代课'),
    getHeaderTitle: () => $t('创建调代课'),
    Component: lazy(() =>
      import('./screens/New').then(m => ({
        default: m.NewAdjustmentScreen,
      })),
    ),
  },
  {
    path: 'adjustment/detail/:type/:id',
    name: 'AdjustmentDetailScreen',
    getTitle: () => $t('调代课详情'),
    getHeaderTitle: () => $t('调代课详情'),
    Component: lazy(() =>
      import('./screens/Detail').then(m => ({
        default: m.AdjustmentDetailScreen,
      })),
    ),
  },
  {
    path: 'adjustment/middle/detail/:type/:id',
    name: 'AdjustmentDetailMiddlePage',
    getTitle: () => $t('调代课详情'),
    getHeaderTitle: () => $t('调代课详情'),
    Component: lazy(() =>
      import('./screens/Detail/MiddlePage').then(m => ({
        default: m.MiddlePage,
      })),
    ),
  },
]

/**
 * @file 北京市监测平台
 */

import { registerBjMonitorPlatform } from 'packages/plugins/features/bj-monitor-platform/utils'

import { InitPluginFeature } from '@go/plugins/types'

export const bjMonitorPlatform: InitPluginFeature = {
  name: 'bj-monitor-platform',
  register: plugins => {
    if (plugins.length) {
      registerBjMonitorPlatform(plugins[0].schoolId)
    }

    return {}
  },
}

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'StudentExamAnalysisReportModal',
    getHeaderTitle: () => $t('考试成绩单'),
    Component: lazy(() =>
      import('./screens/Report').then(m => ({
        default: m.StudentExamAnalysisReportModal,
      })),
    ),
  },
]

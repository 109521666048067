/**
 * @file 网盘模块路由
 */
import { lazy } from 'react'

import { $t } from 'packages/locale/LocaleProvider'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'Plugin.SmsHome',
    getTitle: () => $t('短信'),
    Component: lazy(() =>
      import('./screens/SmsHome').then(m => ({
        default: m.SmsHome,
      })),
    ),
  },
  {
    name: 'Plugin.SmsTaskSearch',
    getTitle: () => $t('搜索'),
    Component: lazy(() =>
      import('./screens/SmsTaskSearch').then(m => ({
        default: m.SmsTaskSearch,
      })),
    ),
  },
  {
    name: 'Plugin.SmsTaskDetail',
    getTitle: () => $t('任务详情'),
    Component: lazy(() =>
      import('./screens/SmsTaskDetail').then(m => ({
        default: m.SmsTaskDetail,
      })),
    ),
  },
  {
    name: 'Plugin.SmsTaskDetailContent',
    getTitle: () => $t('任务详情'),
    Component: lazy(() =>
      import('./screens/SmsTaskContent').then(m => ({
        default: m.SmsTaskContent,
      })),
    ),
  },
  {
    name: 'Plugin.NewSmsTask',
    getTitle: () => $t('创建短信任务'),
    Component: lazy(() =>
      import('./screens/NewSmsTask').then(m => ({
        default: m.NewSmsTask,
      })),
    ),
  },
]

/**
 * @file 网盘插件 - 预览相关
 */
import { env } from '@seiue/env'
import { userAgent } from '@seiue/ua'

import { NetdiskFile, netdiskApi$getFileUrl } from 'packages/sdks-next/chalk'

const CLIENT_PREVIEW = env('CLIENT_PREVIEW')

/**
 * 获取预览网盘文件的 Hooks
 *
 * 目前仅支持网页
 *
 * @returns {Function} 获取预览网盘文件的 Hooks
 */
export const usePreviewNetdiskFile = () => {
  return async (file: NetdiskFile, query?: { netdiskShareId: number }) => {
    const { data: url } = await netdiskApi$getFileUrl.api(`${file.id}`, {
      netdiskShareId: query?.netdiskShareId,
      download: false,
      redirect: false,
    })

    const finalUrl = `${CLIENT_PREVIEW}?furl=${encodeURIComponent(url)}`

    // 由于 Safari 的限制，iOS 下直接打开新窗口会被拦截，所以直接跳转
    if (
      userAgent.isSafari ||
      (userAgent.isIOS && (userAgent.isWeChat || userAgent.isWeWork))
    ) {
      window.location.href = finalUrl
      return
    }

    window.open(finalUrl, '_blank', 'noopener')
  }
}

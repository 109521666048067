/**
 * @file 日程「查看回放」&「进入直播」按钮
 */

import {
  faUndoAlt,
  faUsersClass,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons'

import { getIsEnded } from 'packages/features/schedules/utils/data'
import { $t } from 'packages/locale'
import { Reflection, ScheduleMemberStatusEnum } from 'packages/sdks-next/chalk'

import { DeviceType } from './types'
import { getClassinLessonLinkWithSso } from './utils'
import { getEnableClassInButton } from './views'

interface Props {
  reflection: Reflection
  endTime: string
  /**
   * 与 classIn 绑定的课程 id，没有返回数据则表示未绑定
   */
  classinCourseId?: number | null
  /**
   * 与 classIn 绑定的课节 id，没有返回数据则表示未绑定
   */
  classinLessonId?: number | null
  /**
   * classIn 直播回放链接
   */
  castUrl?: string
  /**
   * 人员状态
   */
  sectionMemberStatus?: ScheduleMemberStatusEnum
  /**
   * 是否启用单点登录
   */
  enableSsoLogin?: boolean
  /**
   * 点击状态
   */
  onClick: (classinLink: string, label: string) => void
}

/**
 * schedule classin actions
 *
 * @param props - Props
 * @returns actions
 */
export const scheduleClassInActions = (
  props: Props,
): {
  dataTestId?: string
  icon: IconDefinition
  label: string
  disabled?: boolean | string
  onClick: () => void
}[] => {
  const {
    reflection,
    endTime,
    classinCourseId,
    classinLessonId,
    castUrl,
    sectionMemberStatus,
    enableSsoLogin,
    onClick,
  } = props

  const isEnded = getIsEnded(endTime)
  const enableLive =
    castUrl ||
    getEnableClassInButton({
      reflection,
      classinCourseId,
      classinLessonId,
    })

  if (sectionMemberStatus !== ScheduleMemberStatusEnum.Approved) return []

  if (isEnded && castUrl) {
    return [
      {
        icon: faUndoAlt,
        label: $t('查看回放'),
        onClick: () => onClick(castUrl, $t('查看回放')),
      },
    ]
  }

  if (enableLive) {
    return [
      {
        icon: faUsersClass,
        label: $t('进入直播'),
        onClick: async () => {
          const classinLink = await getClassinLessonLinkWithSso({
            classinLessonId,
            classinCourseId,
            liveUrl: castUrl,
            reflection,
            deviceType: DeviceType.Web,
            enableSsoLogin,
          })

          onClick(classinLink, $t('进入直播'))
        },
      },
    ]
  }

  return []
}

/**
 * @file 课程班的消息导航注册
 */

import 'packages/features/messages/register/chalk-navigators'

import { registerMessageNavigator } from '@go/features/messages/utils'

/**
 * 教师接收总成绩被修改的消息
 */
registerMessageNavigator(
  [
    'class_assessment.assessment_published_for_teacher',
    'class_assessment.assessment_recalled_for_teacher',
    'class_assessment.assessment_rejected_for_teacher',
    'class_assessment.grade_updated_for_teacher',
  ],
  message => {
    const { scopeId } = (message.attributes as { scopeId: number }) ?? {}
    return ['ClassGradeEntry', { classId: scopeId }]
  },
)

/**
 * 教师接收评价项被修改的消息
 */
registerMessageNavigator(
  [
    'class_assessment.raw_score_addition_for_teacher',
    'class_assessment.raw_score_tag_for_teacher',
    'class_assessment.raw_score_special_for_teacher',
    'class_assessment.score_for_teacher',
    'class_assessment.item_operated_for_teacher',
  ],
  message => {
    const { scopeId, itemId } =
      (message.attributes as { scopeId: number; itemId: number }) ?? {}

    return ['ClassGradeEntry', { classId: scopeId, itemId }]
  },
)

registerMessageNavigator(
  [
    'class_assessment.grade_delayed_for_student',
    'class_assessment.grade_published_for_student',
    'class_assessment.grade_revoked_for_student',
    'class_assessment.grade_updated_for_student',
  ],
  message => [
    'ClassGradeAnalyses',
    { classId: (message as any).attributes?.scopeId },
  ],
)

registerMessageNavigator(
  [
    'class_assessment.stage_grade_delayed_for_student',
    'class_assessment.stage_grade_published_for_student',
    'class_assessment.stage_grade_revoked_for_student',
    'class_assessment.stage_grade_updated_for_student',
  ],
  message => {
    const { scopeId, stageId } =
      (message.attributes as { scopeId: number; stageId?: number }) ?? {}

    return ['ClassGradeAnalyses', { classId: scopeId, stageId }]
  },
)

registerMessageNavigator(
  [
    'class_assessment.raw_score_addition_for_student',
    'class_assessment.raw_score_tag_for_student',
    'class_assessment.raw_score_special_for_student',
    'class_assessment.score_for_student',
    'class_assessment.item_operated_for_student',
  ],
  message => {
    const attributes = message.attributes as any

    return [
      'ClassGradeAnalyses',
      { classId: attributes?.scopeId, itemId: attributes?.itemId },
    ]
  },
)

registerMessageNavigator(['class_assessment.daily_report'], message => {
  return ['DailyGrades', { id: message.id }]
})

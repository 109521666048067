import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'
import { getAppName } from '@go/utils/native-modules/device-info-module'

export const routes: Route[] = [
  {
    path: 'accounts/settings',
    name: 'Settings',
    getTitle: () => $t('设置'),
    getHeaderTitle: () => $t('设置'),
    Component: lazy(() =>
      import('./screens/Settings').then(m => ({ default: m.Settings })),
    ),
  },
  {
    path: 'accounts/about',
    name: 'About',
    getHeaderTitle: () =>
      $t('关于{appName}', {
        appName: getAppName(),
      }),
    Component: lazy(() =>
      import('./screens/About').then(m => ({ default: m.About })),
    ),
  },
  {
    path: 'accounts/language-setting',
    name: 'LanguageSetting',
    getTitle: () => $t('语言'),
    getHeaderTitle: () => $t('语言'),
    Component: lazy(() =>
      import('./screens/LanguageSetting').then(m => ({
        default: m.LanguageSetting,
      })),
    ),
  },
  {
    path: 'accounts/info',
    name: 'MyInfo',
    getTitle: () => $t('个人资料'),
    getHeaderTitle: () => $t('个人资料'),
    Component: lazy(() =>
      import('./screens/Info').then(m => ({ default: m.MyInfo })),
    ),
  },
  {
    path: 'accounts/account-manager',
    name: 'AccountManager',
    getTitle: () => $t('账号设置'),
    getHeaderTitle: () => $t('账号设置'),
    Component: lazy(() =>
      import('./screens/Account').then(m => ({ default: m.AccountManager })),
    ),
  },
  {
    path: 'accounts/account-manager/check-password',
    name: 'AccountManagerCheckPassword',
    getTitle: () => $t('校验密码'),
    Component: lazy(() =>
      import('./screens/Account/CheckPasswordScreen').then(m => ({
        default: m.CheckPasswordScreen,
      })),
    ),
  },
  {
    path: 'accounts/account-manager/bind-detail/:target',
    name: 'AccountManagerBindDetail',
    getTitle: () => $t('账号绑定详情'),
    Component: lazy(() =>
      import('./screens/Account/BindDetail').then(m => ({
        default: m.AccountBindDetail,
      })),
    ),
  },
  {
    path: 'accounts/account-manager/bind-phone',
    name: 'AccountManagerBindPhone',
    getTitle: () => $t('绑定手机'),
    Component: lazy(() =>
      import('./screens/Account/BindPhoneScreen').then(m => ({
        default: m.BindPhoneScreen,
      })),
    ),
  },
  {
    path: 'accounts/account-manager/bind-email',
    name: 'AccountManagerBindEmail',
    getTitle: () => $t('绑定邮箱'),
    Component: lazy(() =>
      import('./screens/Account/BindEmailScreen').then(m => ({
        default: m.BindEmailScreen,
      })),
    ),
  },
  {
    path: 'accounts/account-manager/bind-wechat',
    name: 'AccountManagerBindWeChat',
    getTitle: () => $t('绑定微信'),
    Component: lazy(() =>
      import('./screens/Account/BindWechatScreen').then(m => ({
        default: m.BindWeChatScreen,
      })),
    ),
  },
  {
    path: 'accounts/account-manager/unbind/:target',
    name: 'AccountManagerUnbind',
    getTitle: () => $t('解绑'),
    Component: lazy(() =>
      import('./screens/Account/UnbindScreen').then(m => ({
        default: m.AccountUnbindScreen,
      })),
    ),
  },
  {
    path: 'accounts/account-manager/unbind-sso',
    name: 'AccountManagerUnbindSSO',
    getTitle: () => $t('解绑第三方账号'),
    Component: lazy(() =>
      import('./screens/Account/UnbindSSOScreen').then(m => ({
        default: m.UnbindSSOScreen,
      })),
    ),
  },
  {
    path: 'accounts/account-manager/interrupt',
    name: 'AccountManagerInterrupt',
    getTitle: () => $t('手机绑定确认'),
    Component: lazy(() =>
      import('./screens/Account/InterruptScreen').then(m => ({
        default: m.AccountManagerInterruptScreen,
      })),
    ),
  },
  {
    path: 'accounts/account-manager/change-password',
    name: 'AccountManagerChangePassword',
    getTitle: () => $t('修改密码'),
    getHeaderTitle: () => $t('修改密码'),
    Component: lazy(() =>
      import('./screens/Account/ChangePasswordScreen').then(m => ({
        default: m.AccountManagerChangePasswordScreen,
      })),
    ),
  },
  {
    path: 'accounts/account-manager/select-wechat',
    name: 'AccountManagerSelectWechat',
    getTitle: () => $t('选择微信'),
    Component: lazy(() =>
      import('./screens/Account/SelectWechatScreen').then(m => ({
        default: m.SelectWechatScreen,
      })),
    ),
  },
  {
    path: 'accounts/account-manager/reflection-manager',
    name: 'AccountReflectionManager',
    getTitle: () => $t('身份管理'),
    Component: lazy(() =>
      import('./screens/Account/ReflectionManager').then(m => ({
        default: m.AccountReflectionManager,
      })),
    ),
  },
  {
    path: 'accounts/security-settings',
    name: 'AccountSecuritySettings',
    getTitle: () => $t('安全设置'),
    getHeaderTitle: () => $t('安全设置'),
    Component: lazy(() =>
      import('./screens/Account/SecuritySettings').then(m => ({
        default: m.AccountSecuritySettings,
      })),
    ),
  },
  {
    path: 'accounts/reflection-transfer/:rid',
    name: 'ReflectionTransfer',
    getTitle: () => $t('正在切换用户身份...'),
    getHeaderTitle: () => $t('正在切换用户身份...'),
    Component: lazy(() =>
      import('./screens/ReflectionTransferStation').then(m => ({
        default: m.ReflectionTransferStation,
      })),
    ),
  },
  {
    name: 'TicketLogin',
    getHeaderTitle: () => $t('授权登录确认'),
    Component: lazy(() =>
      import('./screens/TicketLogin').then(m => ({ default: m.TicketLogin })),
    ),
  },
]

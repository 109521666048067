import { AccountTypeEnum } from './account-type-enum'
import { AccountStatusEnum } from './account-status-enum'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface ClassinAccountInterface {
  id?: number
  schoolId?: number
  /**
   * 账户
   */
  account?: string
  /**
   * 账户类型
   */
  accountType?: AccountTypeEnum
  /**
   * 绑定状态
   */
  status?: AccountStatusEnum
  /**
   * 绑定classin的ID
   */
  bindUid?: string | null
  reflectionId?: number
  reflection?: SecuredReflectionInterface | null
}

export class ClassinAccount implements ClassinAccountInterface {
  id?: number
  schoolId?: number
  /**
   * 账户
   */
  account?: string
  /**
   * 账户类型
   */
  accountType?: AccountTypeEnum
  /**
   * 绑定状态
   */
  status?: AccountStatusEnum
  /**
   * 绑定classin的ID
   */
  bindUid?: string | null
  reflectionId?: number
  reflection?: SecuredReflection | null

  constructor(props: ClassinAccountInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      id: _id,
      schoolId: _schoolId,
      account: _account,
      accountType: _accountType,
      status: _status,
      bindUid: _bindUid,
      reflectionId: _reflectionId,
      reflection: _reflection,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.id = _id
    this.schoolId = _schoolId
    this.account = _account
    this.accountType = _accountType
    this.status = _status
    this.bindUid = _bindUid
    this.reflectionId = _reflectionId
    this.reflection = _reflection
      ? new SecuredReflection(_reflection)
      : _reflection
  }

  static propKeys = [
    'id',
    'schoolId',
    'account',
    'accountType',
    'status',
    'bindUid',
    'reflectionId',
    'reflection',
  ]
}

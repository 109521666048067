/**
 * @file 调代课待办注册
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.Declaration,
    Render: lazy(() =>
      import('./DeclarationTodo').then(m => ({ default: m.DeclarationTodo })),
    ),
  },
]

/**
 * @file 插件入口文件
 */

import { EnabledPlugin } from 'packages/feature-utils/plugins'

import {
  initPlugins as initPluginFeatures,
  registerPlugins as registerPluginFeatures,
} from './features'
import { InitPluginParams } from './types'

export const initPlugins = (params: InitPluginParams) => {
  initPluginFeatures({
    ...params,
  })
}

export const registerPlugins = (enabledPlugins: EnabledPlugin[]) => {
  registerPluginFeatures(enabledPlugins)
}

/**
 * @file 公开分享页顶部
 */

import { Button, ActionSheet } from '@seiue/rn-ui'
import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import styled from 'styled-components/native'

import { Avatar } from 'packages/components/Avatar'
import { useSchoolThemeColorsForShare } from 'packages/feature-utils/schools'
import { useCurrentSchoolName } from 'packages/features/schools/hooks'
import { useCurrentReflection } from 'packages/features/sessions/utils'
import { $t } from 'packages/locale'

import { useSelector } from '@go/stores'
import { useLogout } from '@go/utils/use-logout'

import { SiderLogo } from './SiderLogo'

/**
 * 公开分享页顶部
 *
 * @param param0 - 参数
 * @param param0.onPress - 点击 logo 的回调
 * @param param0.style - 样式
 * @param param0.isLoggedIn - 是否已登录
 * @returns 公开分享页顶部
 */
export const Header: React.FC<{
  onPress?: () => void
  style?: StyleProp<ViewStyle>
  isLoggedIn: boolean
}> = ({ style, onPress, isLoggedIn }) => {
  const logout = useLogout()
  const reflection = useCurrentReflection()
  const { navbarBackgroundColor, isDefault } = useSchoolThemeColorsForShare()

  const school = useSelector(state => state.session.currentSchool)
  const schoolName = useCurrentSchoolName()

  const displaySchoolName = schoolName && school?.id === 502

  return (
    <Wrapper
      style={[
        style,
        {
          backgroundColor: navbarBackgroundColor,
        },
      ]}
    >
      <View style={{ flex: 1 }}>
        <SiderLogo onPress={onPress} style={{ marginLeft: 8 }} />
      </View>
      <View style={{ flex: 3 }}>
        {displaySchoolName ? <SchoolName>{schoolName}</SchoolName> : null}
      </View>
      <Right>
        {isLoggedIn ? (
          <TouchableOpacity
            onPress={() => {
              ActionSheet.show({
                options: [
                  {
                    label: $t('退出登录'),
                    color: '#e03437',
                    onPress: () => {
                      logout()
                    },
                  },
                ],
              })
            }}
          >
            <Avatar reflection={reflection} size={24} />
          </TouchableOpacity>
        ) : (
          <Button
            style={{
              backgroundColor: isDefault ? '#515660' : navbarBackgroundColor,
              height: 28,
            }}
            textStyle={{ color: '#c6c9cf' }}
            onPress={onPress}
            label={$t('登录')}
          />
        )}
      </Right>
    </Wrapper>
  )
}

const Wrapper = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding-right: 12px;
  /* padding-left: 16px; */
  background-color: #3b404b;
`

const Right = styled(View)`
  flex-direction: row;
  align-items: center;
`

const SchoolName = styled.Text`
  color: ${props => props.theme.text._0};
`

import { StackNavigationOptions } from '@react-navigation/stack'
import { noop } from '@seiue/util'
import React, { createContext } from 'react'

/**
 * 分享导航 context
 */
export const ShareNavigationContext = createContext<{
  setOptions: React.Dispatch<
    React.SetStateAction<StackNavigationOptions | undefined>
  >
}>({
  setOptions: noop,
})

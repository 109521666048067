/**
 * @file 通用 modal routes, 不来自于 features/*, 因此需要专门注册
 */
import { lazy } from 'react'

import { Route } from './types'

export const sharedModalRoutes: Route[] = [
  {
    name: 'WebviewModal',
    Component: lazy(() =>
      import('@go/components/webview/WebviewModal').then(m => ({
        default: m.WebviewModal,
      })),
    ),
    isModal: true,
  },
  {
    name: 'FileViewerModal',
    Component: lazy(() =>
      import('@go/components/FileViewer/Modal').then(m => ({
        default: m.FileViewerModal,
      })),
    ),
    isModal: true,
  },
  {
    name: 'ImageViewerModal',
    Component: lazy(() =>
      import('@go/components/ImageViewer/Modal').then(m => ({
        default: m.ImageViewerModal,
      })),
    ),
    isModal: true,
  },
  {
    name: 'ImportantNoticesModal',
    Component: lazy(() =>
      import('@go/features/notices/screens/ImportantNoticesModal').then(m => ({
        default: m.ImportantNoticesModal,
      })),
    ),
  },
  {
    name: 'BindPhoneModal',
    Component: lazy(() =>
      import('@go/features/sessions/screens/BindPhone').then(m => ({
        default: m.BindPhoneModal,
      })),
    ),
    isModal: true,
  },
  {
    name: 'Camera',
    Component: lazy(() =>
      import('@go/components/Camera').then(m => ({
        default: m.Camera,
      })),
    ),
    isModal: true,
  },
  {
    name: 'Publisher',
    Component: lazy(() =>
      import('@go/uikit').then(m => ({
        default: m.Discussion.Publisher,
      })),
    ),
    isModal: true,
  },
  {
    name: 'ResetPasswordModal',
    Component: lazy(() =>
      import('@go/features/sessions/screens/ResetPassword').then(m => ({
        default: m.ResetPasswordModal,
      })),
    ),
    isModal: true,
  },
  {
    name: 'WebNavigator',
    Component: lazy(() =>
      import('@go/components/WebNavigator').then(m => ({
        default: m.WebNavigator,
      })),
    ),
  },
  {
    name: 'ForceEvaluationModal',
    Component: lazy(() =>
      import('@go/features/evaluations/screens/ForceEvaluationScreen').then(
        m => ({
          default: m.ForceEvaluationModal,
        }),
      ),
    ),
  },
]

import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'
import {
  NotificationScope,
  NotificationScopeInterface,
} from './notification-scope'
import {
  GuardianReadStatus,
  GuardianReadStatusInterface,
} from './guardian-read-status'

export interface NotificationReceiversReadStatusInterface {
  /**
   * 收件人信息
   */
  receiver?: SecuredReflectionInterface | null
  /**
   * 收件人角色
   * *冗余字段*
   */
  roleId?: string
  /**
   * 已读状态
   */
  readed: boolean
  /**
   * 收件人回执情况
   */
  receipted: boolean | null
  /**
   * 收件人回执时间
   */
  receiptedAt?: string | null
  /**
   * 已回执的签名图片 Hash
   */
  receiptedImage?: string | null
  /**
   * 通知变量
   */
  variables?: string
  /**
   * 发件人所属范围
   */
  scopes?: NotificationScopeInterface[]
  /**
   * 家长已读状态
   * expand 出来，收件人为学生才需要
   */
  guardianReadStatuses?: GuardianReadStatusInterface[]
}

export class NotificationReceiversReadStatus
  implements NotificationReceiversReadStatusInterface
{
  /**
   * 收件人信息
   */
  receiver?: SecuredReflection | null
  /**
   * 收件人角色
   * *冗余字段*
   */
  roleId?: string
  /**
   * 已读状态
   */
  readed: boolean
  /**
   * 收件人回执情况
   */
  receipted: boolean | null
  /**
   * 收件人回执时间
   */
  receiptedAt?: string | null
  /**
   * 已回执的签名图片 Hash
   */
  receiptedImage?: string | null
  /**
   * 通知变量
   */
  variables?: string
  /**
   * 发件人所属范围
   */
  scopes?: NotificationScope[]
  /**
   * 家长已读状态
   * expand 出来，收件人为学生才需要
   */
  guardianReadStatuses?: GuardianReadStatus[]

  constructor(props: NotificationReceiversReadStatusInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      receiver: _receiver,
      roleId: _roleId,
      readed: _readed,
      receipted: _receipted,
      receiptedAt: _receiptedAt,
      receiptedImage: _receiptedImage,
      variables: _variables,
      scopes: _scopes,
      guardianReadStatuses: _guardianReadStatuses,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.receiver = _receiver ? new SecuredReflection(_receiver) : _receiver
    this.roleId = _roleId
    this.readed = _readed
    this.receipted = _receipted
    this.receiptedAt = _receiptedAt
    this.receiptedImage = _receiptedImage
    this.variables = _variables
    this.scopes = _scopes ? _scopes.map(i => new NotificationScope(i)) : _scopes
    this.guardianReadStatuses = _guardianReadStatuses
      ? _guardianReadStatuses.map(i => new GuardianReadStatus(i))
      : _guardianReadStatuses
  }

  static propKeys = [
    'receiver',
    'roleId',
    'readed',
    'receipted',
    'receiptedAt',
    'receiptedImage',
    'variables',
    'scopes',
    'guardianReadStatuses',
  ]
}

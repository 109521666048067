/**
 * @module 行政班
 */

import { AttendanceTypes } from 'packages/features/admin-classes/message/message-type'
import { MessageDomainEnum } from 'packages/features/messages/types'

import { InitFeatureParams } from '../types'

import { routes } from './routes'
import { todos } from './todos/index'

/**
 * 初始化行政班模块
 *
 * @param props - 初始化参数
 */
export const initAdminClass = (props: InitFeatureParams) => {
  const { addRoutes, registerTodos, registerMessageNavigator } = props

  addRoutes(routes)
  registerTodos(todos)

  registerMessageNavigator(
    [`${MessageDomainEnum.AdminClass}_${AttendanceTypes.Guardian}`],
    () => null,
  )

  registerMessageNavigator(
    `${MessageDomainEnum.AdminClass}_${AttendanceTypes.Teacher}`,
    message => {
      const attributes = message.attributes as {
        bizType: string
        bizId: number
        bizName: string
        attendanceTimeId: number
        attendanceTimeStart: string
        attendanceTimeEnd: string
      }

      if (!attributes?.bizId || !attributes?.attendanceTimeId) return null

      return [
        'AdminClassInputAttendance',
        {
          adminClassId: attributes.bizId,
          startAt: attributes.attendanceTimeStart,
          endAt: attributes.attendanceTimeEnd,
          attendanceTimeId: attributes.attendanceTimeId,
        },
      ]
    },
  )
}

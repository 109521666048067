/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Organization, OrganizationInterface } from './organization'
import type { QueryOrganizationsQuery } from './query-organizations-query'

const buildConfig = createConfigBuilder('scms')

export const onlineClassApi$queryOrganizations = {
  config(
    queryOrganizationsQuery?: QueryOrganizationsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/online-class/organizations',
      {},
      queryOrganizationsQuery,
    )
  },

  /**
   * @summary 查看区域组织
   * @param [queryOrganizationsQuery]
   * @param [onlineClassQuery.expand]
   * @param [onlineClassQuery.paginated] 是否分页，默认 1
   * @param [onlineClassQuery.page] 显示的数据页，默认 1
   * @param [onlineClassQuery.perPage] 每页显示数量，默认 20
   * @param [onlineClassQuery.sort]
   * @param [onlineClassQuery.nameLike]
   * @param [onlineClassQuery.nameIn]
   * @param [onlineClassQuery.number]
   * @param [onlineClassQuery.numberIn]
   * @param [onlineClassQuery.memberIdsOverlaps]
   * @param [onlineClassQuery.createdAtElt]
   * @param [onlineClassQuery.createdAtEgt]
   * @param [options]
   * @return AxiosResponsePromise<Organization[]>
   */

  api<const TQuery extends QueryOrganizationsQuery>(
    queryOrganizationsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Organization, TQuery['expand']>[]> {
    return axios(
      onlineClassApi$queryOrganizations.config(
        queryOrganizationsQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Organization(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryOrganizationsQuery,
    TSelected = Expand<Organization, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<Organization, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Organization, TQuery['expand']>[], TSelected>(
      onlineClassApi$queryOrganizations.api,
      'OnlineClass.queryOrganizations',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
onlineClassApi$queryOrganizations.api._name_ = `OnlineClass.queryOrganizations`

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { NetdiskOwner, NetdiskOwnerInterface } from './netdisk-owner'
import type { QueryNetdiskOwnersQuery } from './query-netdisk-owners-query'

const buildConfig = createConfigBuilder('chalk')

export const netdiskApi$queryNetdiskOwners = {
  config(
    queryNetdiskOwnersQuery?: QueryNetdiskOwnersQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/netdisk/owners',
      {},
      queryNetdiskOwnersQuery,
    )
  },

  /**
   * @summary 查询网盘成员(app)
   * @param [queryNetdiskOwnersQuery]
   * @param [netdiskQuery.ownerId]
   * @param [netdiskQuery.type] type = shared 意为查询共享网盘的owner
   * @param [netdiskQuery.fileNameLike] 文件名称 需要和 file_parent_id 一起使用
   * @param [netdiskQuery.fileParentId]
   * @param [netdiskQuery.expand]
   * @param [netdiskQuery.paginated] 是否分页，默认 1
   * @param [netdiskQuery.page] 显示的数据页，默认 1
   * @param [netdiskQuery.perPage] 每页显示数量，默认 20
   * @param [netdiskQuery.totalCapacityEgt]
   * @param [netdiskQuery.totalCapacityElt]
   * @param [netdiskQuery.usedCapacityEgt]
   * @param [netdiskQuery.usedCapacityElt]
   * @param [netdiskQuery.usedRateElt]
   * @param [netdiskQuery.usedRateEgt]
   * @param [netdiskQuery.typeIn]
   * @param [netdiskQuery.idIn]
   * @param [options]
   * @return AxiosResponsePromise<NetdiskOwner[]>
   */

  api<const TQuery extends QueryNetdiskOwnersQuery>(
    queryNetdiskOwnersQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<NetdiskOwner, TQuery['expand']>[]> {
    return axios(
      netdiskApi$queryNetdiskOwners.config(queryNetdiskOwnersQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new NetdiskOwner(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryNetdiskOwnersQuery,
    TSelected = Expand<NetdiskOwner, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<NetdiskOwner, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<NetdiskOwner, TQuery['expand']>[], TSelected>(
      netdiskApi$queryNetdiskOwners.api,
      'Netdisk.queryNetdiskOwners',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
netdiskApi$queryNetdiskOwners.api._name_ = `Netdisk.queryNetdiskOwners`

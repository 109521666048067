/**
 * @file 自定义日程
 */

import { lazy } from 'react'

import { CalendarEventType } from 'packages/features/calendars/utils/types'
import { $t } from 'packages/locale'

export const calendarEvents = [
  {
    type: CalendarEventType.Event,
    Component: lazy(() =>
      import('./ScheduleEvent').then(m => ({
        default: m.ScheduleEvent,
      })),
    ),
    getEventTag: () => $t('活动'),
  },
]

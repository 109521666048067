import { useOnAppState } from '@seiue/rn-util'
import React from 'react'

import { useDispatch } from '@go/stores'

/**
 * 返回应用时，刷新用户信息
 */
export const useRefreshUser = () => {
  const dispatch = useDispatch()

  useOnAppState(
    'active',
    React.useCallback(() => {
      dispatch.session.getCurrentUser()
    }, [dispatch.session]),
  )
}

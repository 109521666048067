/**
 * @file 获取 ClassIn 的访问地址
 */

import { isAxiosError } from '@seiue/axios'
import { isSeiueCluster } from '@seiue/env'
import { filterAndReportError, ToastMetaError } from '@seiue/error-handler'
import { flatten, split, uniqBy } from '@seiue/util'
import React from 'react'

import { useCurrentReflection } from 'packages/features/sessions/utils'
import { DeviceType } from 'packages/plugins/features/class-in/types'
import {
  AccountTypeEnum,
  ClassinAccount,
  Reflection,
  RoleEnum,
  classinApi$getAccount as chalkClassinApi$getAccount,
} from 'packages/sdks-next/chalk'
import {
  ChannelEnum,
  OnlineClassLessonReq,
  School,
  classinApi$getClassInLoginLink,
  classApi$sendUnbindAccountNotify,
  onlineClassApi$batchPatchOnlineLessons,
  onlineClassApi$queryOrganizations,
} from 'packages/sdks-next/scms'

import { baseClassinUrl, getClassinSsoUrl } from './data'

/**
 * 获取 ClassIn 的访问地址
 *
 * @param param0 - 参数
 * @param param0.role - 身份
 * @param param0.schoolId - 学校 ID
 * @param param0.token - 登录 token
 * @param param0.enableSsoLogin - 是否启用单点登录
 * @param param0.catchError - 自定义捕捉错误的处理方法
 * @returns ClassIn 的访问地址
 */
export const getClassInUrl = async ({
  role,
  schoolId,
  token,
  enableSsoLogin,
  catchError,
}: {
  role: RoleEnum
  schoolId: number
  token?: string
  enableSsoLogin?: boolean
  catchError?: (error: any) => void
}) => {
  let url = ''
  let accountType: AccountTypeEnum | undefined

  try {
    const { data: accountInfo } = await chalkClassinApi$getAccount.api(
      role,
      // 这个参数现在不重要了，后端暂时不会用
      AccountTypeEnum.Phone,
    )

    accountType = accountInfo.accountType

    if (enableSsoLogin && isSeiueCluster()) {
      url = getClassinSsoUrl(schoolId, token)
    } else {
      const { account, bindPassword } = accountInfo as ClassinAccount & {
        account: string
        bindPassword: string
      }

      url = `${baseClassinUrl}?telephone=${account}`

      if (accountType === AccountTypeEnum.Account && !!bindPassword) {
        url += `&password=${bindPassword}`
      }
    }

    return url
  } catch (e) {
    if (catchError) {
      catchError(e)
      return ''
    }

    if (isAxiosError(e, 404)) {
      if (e.response.data.message) {
        throw new ToastMetaError({
          title: e.response.data.message,
          report: false,
        })
      } else {
        throw e
      }
    } else {
      throw e
    }
  }

  return ''
}

/**
 * 获取 ClassIn 课节链接，若启用单点登录则返回单点登录地址，否则返回课节链接
 *
 * @param param0 - 参数
 * @param param0.reflection - 用户
 * @param param0.enableSsoLogin - 是否启用单点登录
 * @param param0.classinCourseId - ClassIn 课程 ID
 * @param param0.classinLessonId - ClassIn 节次 ID
 * @param param0.liveUrl - 直播链接
 * @param param0.deviceType - 设备类型
 * @param param0.token - 登录 token, 仅开启单点登录且 app 端通过 webview 打开时需要传入
 * @returns ClassIn 的访问地址
 */
export const getClassinLessonLinkWithSso = ({
  reflection,
  enableSsoLogin,
  classinCourseId,
  classinLessonId,
  liveUrl,
  deviceType,
  token,
}: {
  reflection: Reflection
  enableSsoLogin?: boolean
  classinCourseId?: number | null
  classinLessonId?: number | null
  liveUrl?: string | null
  deviceType: DeviceType | null
  token?: string
}) => {
  if (enableSsoLogin && isSeiueCluster()) {
    const backupUrl = liveUrl || baseClassinUrl

    try {
      return getClassInUrl({
        role: reflection.role,
        schoolId: reflection.schoolId,
        // 因为 app 端是通过 webview 打开的，passport 拿不到 cookie 信息，所以需要手动传入 token
        token: deviceType !== DeviceType.Web ? token : undefined,
        enableSsoLogin,
      })
    } catch (e) {
      return backupUrl
    }
  }

  return getClassinLessonLink({
    reflectionId: reflection.id,
    classinCourseId,
    classinLessonId,
    liveUrl,
    deviceType,
  })
}

/**
 * 获取 classin 唤醒链接并直接进入课堂，若获取不到则返回直播链接（liveUrl），直播链接也获取不到返回 baseClassinUrl
 *
 * @param props - 组件属性
 * @param props.reflectionId - 用户 ID
 * @param props.classinCourseId - ClassIn 课程 ID
 * @param props.classinLessonId - ClassIn 节次 ID
 * @param props.liveUrl - 直播链接
 * @param props.deviceType - 设备类型
 * @returns ClassIn 的访问地址
 */
export const getClassinLessonLink = ({
  reflectionId,
  classinCourseId,
  classinLessonId,
  liveUrl,
  deviceType,
}: {
  reflectionId?: number
  classinCourseId?: number | null
  classinLessonId?: number | null
  liveUrl?: string | null
  deviceType: DeviceType | null
}): Promise<string> =>
  new Promise(resolve => {
    const backupUrl = liveUrl || baseClassinUrl

    if (!classinCourseId || !classinLessonId || !reflectionId || !deviceType) {
      resolve(backupUrl)
      return
    }

    classinApi$getClassInLoginLink
      .api(reflectionId, classinCourseId, classinLessonId, {
        deviceType,
      })
      .then(({ data: loginLink }) => {
        if (!loginLink?.link) {
          // 获取不到链接的时候保底
          resolve(backupUrl)
          return
        }

        if (deviceType !== DeviceType.Web) {
          resolve(loginLink.link)
          return
        }

        // go-web / web 端走中间页唤醒
        resolve(`${baseClassinUrl}?${split(loginLink.link, '?')[1]}`)
      })
      // classin 的报错静默处理
      .catch(e => {
        filterAndReportError(e, { ExceptionType: 'classin' })

        // 获取不到链接的时候保底
        resolve(backupUrl)
      })
  })

/**
 * 获取提醒用户绑定 classin 账号的方法
 *
 * @returns 提醒用户绑定 classin 账号的方法
 */
export const useNotifyUnboundAccounts = () => {
  const { api: notice } = classApi$sendUnbindAccountNotify.useApi()

  return async ({ rids }: { rids: string }) => {
    await notice({
      channel: ChannelEnum.Classin,
      rids,
    })
  }
}

/**
 * 获取更新线上课节次的方法
 *
 * @param classId - 线上课 ID
 * @returns 更新线上课节次的方法
 */
export const useUpdateOnlineLessons = (classId: number) => {
  const { api: update } = onlineClassApi$batchPatchOnlineLessons.useApi()

  return async ({
    data,
    lessonIds,
  }: {
    data: Omit<OnlineClassLessonReq, 'id' | 'channel'>
    lessonIds: number[]
  }) => {
    await update({
      channel: ChannelEnum.Classin,
      onlineClassId: classId,
      payload: lessonIds.map(lessonId => ({
        ...data,
        id: lessonId,
      })),
    })
  }
}

/**
 * 根据当前用户查询所属组织的学校列表
 *
 * @param props - 组件属性
 * @param props.disable - 是否禁用
 * @returns 所属组织的学校列表
 */
export const useOrgSchoolsByCurrentReflection = ({
  disable,
}: { disable?: boolean } = {}) => {
  const { schoolId } = useCurrentReflection()
  const { data: organizationsRaw, loading } =
    onlineClassApi$queryOrganizations.useApi(
      {
        memberIdsOverlaps: `${schoolId}`,
        expand: ['members'] as const,
        paginated: 0,
      },
      { disable },
    )

  const schools = React.useMemo(
    () =>
      uniqBy(
        flatten(organizationsRaw?.map(item => item.members)),
        'id',
      ) as School[],
    [organizationsRaw],
  )

  return {
    data: schools,
    loading,
    organizations: organizationsRaw,
  }
}

/**
 * @file 新开普订单系统
 */

import { PluginEnum } from 'packages/feature-utils/plugins'

import { InitPluginFeature } from '@go/plugins/types'

import { getNewcapecOrderMenu } from './menu-slot'

export const newcapecOrder: InitPluginFeature = {
  name: PluginEnum.NewcapecOrder,
  register: () => ({
    slots: [
      {
        name: 'asyncAppMenuItems',
        slot: getNewcapecOrderMenu,
      },
    ],
  }),
}

import { StackNavigationOptions } from '@react-navigation/stack'
import { Layout } from '@seiue/rn-ui'
import { isWeb } from '@seiue/rn-util'
import { isEmpty, kebabCase, stringifyURLQuery } from '@seiue/util'
import React from 'react'
import { ViewStyle } from 'react-native'

import { formatParams } from 'packages/route/utils'
import { TextColor } from 'packages/theme'
import { isInGoWebview } from 'packages/utils/rn-webview'

import { MainStackParams, MainStackRouteProp, Route } from './types'

export const defaultHeaderStyle: ViewStyle = {
  shadowColor: 'transparent',
  elevation: 0,
  height: isWeb ? 44 : undefined,
  borderBottomColor: '#ffffff',
}

export const defaultHeaderOptions: StackNavigationOptions = {
  headerShown: true,
  headerTitleAlign: 'center',
  headerStyle: defaultHeaderStyle,
  headerBackTitleVisible: false,
  headerLeft: props => (
    <Layout.HeaderLeft
      {...props}
      onPress={async () => {
        // web 刷新页面后点击后退按钮无效，直接跳转到主页
        if (isWeb && !isInGoWebview() && !props.canGoBack) {
          window.location.href = '/Tabs/Home'
          return
        }

        await import('@go/components/webview/utils').then(mod => {
          // 兼容处理正常后退和 webview 后退至 native 的情况
          mod.goBackHybrid()
        })
      }}
    />
  ),
  headerTitleStyle: {
    fontWeight: 'bold',
    fontSize: 18,
    color: TextColor._1,
    marginHorizontal: 28,
    ...(isWeb
      ? {
          userSelect: 'none',
        }
      : {}),
  },
  headerTitleContainerStyle: {
    flexShrink: 1,
  },
}

/**
 * 获取 ScreenName 所对应的 GoWeb Uri
 *
 * @param name - 路由 Screen 名称
 * @param params - 路由参数
 * @returns uri
 */
export const getPath = <TName extends keyof MainStackParams>(
  name: TName,
  params: MainStackRouteProp<TName>['params'],
) => {
  const path = kebabCase(name)

  if (!params || isEmpty(params)) return path

  return `${path}?${stringifyURLQuery(formatParams(params as any))}`
}

/**
 * 将待添加的路由添加到已有路由列表
 *
 * @param prevRoutes - 已有路由列表
 * @param addedRoutes - 待添加的路由列表
 */
export const addRoutes = (prevRoutes: Route[], addedRoutes: Route[]) => {
  const prevRouteNames = prevRoutes.map(route => route.name)

  prevRoutes.push(
    ...addedRoutes.filter(
      addedRoute => !prevRouteNames.includes(addedRoute.name),
    ),
  )
}

let modalRoutes: Route[] = []

/**
 * 获取弹窗路由
 *
 * @returns 弹窗路由列表
 */
export const getModalRoutes = () => modalRoutes

/**
 * 添加弹窗路由
 *
 * @param rts - 路由列表
 */
export const addModalRoutes = (rts: Route[]) => {
  addRoutes(modalRoutes, rts)
}

/**
 * 移除弹窗路由
 *
 * @param rtsName - 要移除的路由列表
 */
export const removeModalRoutes = (rtsName: string[]) => {
  modalRoutes = modalRoutes.filter(
    modalRoute => !rtsName.includes(modalRoute.name),
  )
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { Item, ItemInterface } from './extends/item'
import type { QueryShortcutItemsQuery } from './query-shortcut-items-query'

const buildConfig = createConfigBuilder('vnas')

export const moralApi$queryShortcutItems = {
  config(
    queryShortcutItemsQuery?: QueryShortcutItemsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/moral/shortcut-items',
      {},
      queryShortcutItemsQuery,
    )
  },

  /**
   * @summary 查询随手评的评价项
   * @param [queryShortcutItemsQuery]
   * @param [moralQuery.expand] - assessment
   * @param [moralQuery.paginated] 是否分页，默认 1
   * @param [moralQuery.page] 显示的数据页，默认 1
   * @param [moralQuery.perPage] 每页显示数量，默认 20
   * @param [options]
   * @return AxiosResponsePromise<Item[]>
   */

  api<const TQuery extends QueryShortcutItemsQuery>(
    queryShortcutItemsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<Item, TQuery['expand']>[]> {
    return axios(
      moralApi$queryShortcutItems.config(queryShortcutItemsQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new Item(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends QueryShortcutItemsQuery,
    TSelected = Expand<Item, TQuery['expand']>[],
  >(
    query?: TQuery,
    queryOptions?: QueryOptionsWithSelect<
      Expand<Item, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<Item, TQuery['expand']>[], TSelected>(
      moralApi$queryShortcutItems.api,
      'Moral.queryShortcutItems',
      queryOptions,
      query,
    )
  },
}

// @ts-ignore
moralApi$queryShortcutItems.api._name_ = `Moral.queryShortcutItems`

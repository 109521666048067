/**
 * @file 图标、颜色配置
 */

import {
  faUserClock,
  faUserEdit,
  faMedal,
  faBullseyeArrow,
  faBed,
  faUserFriends,
  faChalkboardTeacher,
  faBookReader,
  faUsersClass,
  faFlagAlt,
  faCheck,
  faArchive,
  faBell,
  faClock,
  faChartBar,
  faBookOpen,
  faCode,
  faSchool,
  faFileCertificate,
  faUsers,
  faDatabase,
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faDoorOpen,
  faClock as faRegularClock,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faGavel,
  faTools,
  faStamp,
  faBoxesAlt,
  faFolderTree,
  faAddressCard,
  faPaperPlane,
  faPencilRuler,
  faStar,
} from '@fortawesome/pro-solid-svg-icons'
import {
  ActivitySolid,
  ApplicationSymbolSolid,
  BallotCheck,
  CertificationSolid,
  CheckWorkAttendanceSolid,
  CourseClassLeave,
  DormSolid,
  Trophy,
  LocationDotSolid,
} from '@seiue/rn-icons'

import medal1 from './assets/medal-1.png'
import medal2 from './assets/medal-2.png'
import medal3 from './assets/medal-3.png'
import medal4 from './assets/medal-4.png'
import { LogoIcon, LogoColor } from './utils'

export const images: string[] = [medal1, medal2, medal3, medal4]

/**
 * 图标编辑器可供选择的内置图标
 * 需要同时配置 config.ts 中的 defaultIcons
 */
export const defaultIcons: LogoIcon[] = [
  {
    icon: faUserClock,
    name: 'userClock',
  },
  {
    icon: faMedal,
    name: 'medal',
  },
  {
    icon: faUserEdit,
    name: 'userEdit',
  },
  {
    icon: faBullseyeArrow,
    name: 'bullseyeArrow',
  },
  {
    icon: faBed,
    name: 'bed',
  },
  {
    icon: faUserFriends,
    name: 'userFriends',
  },
  {
    icon: faChalkboardTeacher,
    name: 'chalkboardTeacher',
  },

  {
    icon: faBookReader,
    name: 'bookReader',
  },
  {
    icon: faUsersClass,
    name: 'usersClass',
  },
  {
    icon: faFlagAlt,
    name: 'flagAlt',
  },
  {
    icon: faCheck,
    name: 'check',
  },
  {
    icon: faArchive,
    name: 'archive',
  },
  {
    icon: faBell,
    name: 'bell',
  },
  {
    icon: faClock,
    name: 'clock',
  },
  {
    icon: faChartBar,
    name: 'chartBar',
  },
  {
    icon: faBookOpen,
    name: 'bookOpen',
  },
  {
    icon: faCode,
    name: 'code',
  },
  {
    icon: faSchool,
    name: 'school',
  },
  {
    icon: faFileCertificate,
    name: 'fileCertificate',
  },
  {
    icon: faUsers,
    name: 'group',
  },
  {
    icon: faDatabase,
    name: 'database',
  },
  {
    icon: CertificationSolid,
    name: 'CertificationSolid',
  },
  {
    icon: BallotCheck,
    name: 'BallotCheck',
  },
  {
    icon: ActivitySolid,
    name: 'ActivitySolid',
  },
  {
    icon: CheckWorkAttendanceSolid,
    name: 'CheckWorkAttendanceSolid',
  },
  {
    icon: Trophy,
    name: 'Trophy',
  },
  {
    icon: ApplicationSymbolSolid,
    name: 'ApplicationSymbolSolid',
  },
  {
    icon: LocationDotSolid,
    name: 'LocationDotSolid',
  },
]

export const icons = [
  ...defaultIcons,
  {
    icon: CourseClassLeave,
    name: 'CourseClassLeave',
  },
  {
    icon: faRegularClock,
    name: 'regularClock',
  },
  {
    icon: faGavel,
    name: 'gavel',
  },
  {
    icon: faTools,
    name: 'tools',
  },
  {
    icon: faStamp,
    name: 'stamp',
  },
  {
    icon: faBoxesAlt,
    name: 'boxesAlt',
  },
  {
    icon: faFolderTree,
    name: 'folderTree',
  },
  {
    icon: faAddressCard,
    name: 'addressCard',
  },
  {
    icon: faPaperPlane,
    name: 'paperPlane',
  },
  {
    icon: faPencilRuler,
    name: 'pencilRuler',
  },
  {
    icon: faStar,
    name: 'star',
  },
  {
    icon: faDoorOpen,
    name: 'DoorOpen',
  },
  {
    icon: DormSolid,
    name: 'DormSolid',
  },
]

export const defaultColors: LogoColor[] = [
  {
    color: '#669FFF',
    name: '669FFF',
  },
  {
    color: '#15B8B1',
    name: '15B8B1',
  },
  {
    color: '#E99D0A',
    name: 'E99D0A',
  },
  {
    color: '#D45586',
    name: 'D45586',
  },
  {
    color: '#8E6BF1',
    name: '8E6BF1',
  },
  {
    color: '#5975BA',
    name: '5975BA',
  },
  {
    color: '#60C381',
    name: '60C381',
  },
  {
    color: '#8E6796',
    name: '8E6796',
  },
  {
    color: '#EA7B25',
    name: 'EA7B25',
  },
  {
    color: '#757AA6',
    name: '757AA6',
  },
  {
    color: '#17A0DE',
    name: '17A0DE',
  },
  {
    color: '#829B9E',
    name: '829B9E',
  },
  {
    color: '#C85BE0',
    name: 'C85BE0',
  },
  {
    color: '#4A9995',
    name: '4A9995',
  },
  {
    color: '#c6c9cf',
    name: 'c6c9cf',
  },
  {
    color: '#477cff',
    name: '477cff',
  },
  {
    color: '#cc6666',
    name: 'cc6666',
  },
  {
    color: '#F7585C',
    name: 'F7585C',
  },
  {
    color: '#16B8B1',
    name: '16B8B1',
  },
]

export const colors = [
  ...defaultColors,
  {
    color: '#fca700',
    name: 'FCA700',
  },
  {
    color: '#e4524b',
    name: 'E4524B',
  },
]

import { faFileAlt } from '@fortawesome/pro-regular-svg-icons'
import React from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'

import { LogoRender } from '@go/components/Logo/Render'
import { Menu } from '@go/features/applications/types'

/**
 * 获取问卷菜单
 *
 * @returns 菜单
 */
export const getQuestionnaireMenu = async (): Promise<Menu[] | null> => [
  {
    key: PluginEnum.Questionnaire,
    pluginName: PluginEnum.Questionnaire,
    icon: ({ size } = {}) => (
      <LogoRender
        size={size}
        logo={'faFileAlt+4A9995'}
        icons={[
          {
            name: 'faFileAlt',
            icon: faFileAlt,
          },
        ]}
      />
    ),
    getTitle: () => $t('问卷'),
    screen: {
      name: 'QuestionnaireSquare' as const,
      params: undefined,
    },
  },
]

import { ChangeTypeEnum } from './change-type-enum'

export interface ChangeInterface {
  type: ChangeTypeEnum
  content: string
}

export class Change implements ChangeInterface {
  type: ChangeTypeEnum
  content: string

  constructor(props: ChangeInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      type: _type,
      content: _content,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.type = _type
    this.content = _content
  }

  static propKeys = ['type', 'content']
}

/**
 * @file 活动待办注册
 */
import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'
import { RegisterWorkflowRenderType } from 'packages/features/workflows/register'
import { WorkflowBizType } from 'packages/features/workflows/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.EventMemberApproval,
    Render: lazy(() =>
      import('./EventMemberApprovalTodo').then(m => ({
        default: m.EventMemberApprovalTodo,
      })),
    ),
  },
  {
    type: TodoTypeEnum.EventApproval,
    Render: lazy(() =>
      import('./EventApprovalTodo').then(m => ({
        default: m.EventApprovalTodo,
      })),
    ),
  },
  {
    type: TodoTypeEnum.EventAttendanceDailyNotice,
    Render: lazy(() =>
      import('./EventAttendanceTodo').then(m => ({
        default: m.EventAttendanceTodo,
      })),
    ),
  },
]

/**
 * 活动审批流待办注册
 */
export const workflowRenders: RegisterWorkflowRenderType[] = [
  {
    bizType: 'event' as WorkflowBizType,
    render: {
      TodoCard: lazy(() =>
        import('./EventWorkflowTodo').then(m => ({
          default: m.EventWorkflowTodo,
        })),
      ),
    },
  },
]

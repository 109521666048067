/**
 * @file 底部导航栏的图标
 */

import React from 'react'
import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import { useTheme } from 'styled-components/native'

const IconWrapper: React.FC = ({ children }) => {
  return (
    <View
      style={[
        {
          width: 24,
          height: 24,
        },
      ]}
    >
      {children}
    </View>
  )
}

const HomeNormal: React.FC = () => {
  return (
    <Svg fill="none" viewBox="0 0 16 16">
      <Path
        fill="#333"
        d="M5.759 2.516c.61-.111 1.288.32 1.288 1.04l-.004 8.887c0 .724-.684 1.156-1.298 1.039l-2.866-.546c-.477-.091-.879-.5-.879-1.04V4.078c0-.543.407-.953.888-1.04l2.87-.521.001-.001Zm-.046 1.363-2.38.432v7.354l2.377.453.003-8.24v.001Zm3.24-2.14c0-.708.66-1.178 1.302-1.051l2.87.565c.491.096.875.522.875 1.051v11.392c0 .529-.384.955-.875 1.051l-2.87.565c-.642.127-1.302-.343-1.302-1.051V1.739Zm1.334.314 2.38.468V13.48l-2.38.468V2.053Z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </Svg>
  )
}

const HomeActive: React.FC = () => {
  const theme = useTheme()
  return (
    <Svg fill="none" viewBox="0 0 16 16">
      <Path
        fill={theme.brand._1}
        d="M8.953 1.739c0-.708.66-1.178 1.302-1.051l2.87.565c.491.096.875.522.875 1.051v11.392c0 .529-.384.955-.875 1.051l-2.87.565c-.642.127-1.302-.343-1.302-1.051V1.739Zm-3.194.777c.61-.111 1.288.32 1.288 1.04l-.004 8.887c0 .724-.684 1.156-1.298 1.039l-2.866-.546c-.477-.091-.879-.5-.879-1.04V4.078c0-.543.407-.953.888-1.04l2.87-.521.001-.001Z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </Svg>
  )
}

const CalendarNormal: React.FC = () => {
  return (
    <Svg fill="none" viewBox="0 0 16 16">
      <Path
        fill="#333"
        d="M10.665 1.667a.666.666 0 1 0-1.332 0v2a.667.667 0 1 0 1.332 0v-2ZM6.666 1.667a.667.667 0 0 0-1.333 0v2a.667.667 0 1 0 1.333 0v-2ZM9.334 10.667a.667.667 0 0 0 0 1.333h2a.668.668 0 0 0 0-1.333h-2Z"
      />
      <Path
        fill="#333"
        d="M1 3c0-.736.597-1.333 1.333-1.333h1a.667.667 0 1 1 0 1.333h-1v2.667h11.334V3h-1a.667.667 0 1 1 0-1.333h1C14.403 1.667 15 2.264 15 3v10.333c0 .737-.597 1.334-1.333 1.334H2.333A1.332 1.332 0 0 1 1 13.333V3Zm1.333 4h11.334v6.333H2.333V7Z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </Svg>
  )
}

const CalendarActive: React.FC = () => {
  const theme = useTheme()
  return (
    <Svg fill="none" viewBox="0 0 16 16">
      <Path
        fill={theme.brand._1}
        d="M9.333 1.667H6.667v2a.667.667 0 1 1-1.334 0v-2h-3C1.597 1.667 1 2.264 1 3v2.667h14V3c0-.736-.597-1.333-1.333-1.333h-3v2a.667.667 0 0 1-1.334 0v-2ZM15 7H1v6.333a1.333 1.333 0 0 0 1.333 1.334h11.334c.736 0 1.333-.597 1.333-1.334V7Zm-5.93 3.71a.667.667 0 0 1 .263-.043h2a.667.667 0 0 1 0 1.333h-2a.667.667 0 0 1-.263-1.29Z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </Svg>
  )
}

const ApplicationNormal: React.FC = () => {
  return (
    <Svg fill="none" viewBox="0 0 16 16">
      <Path
        fill="#333"
        d="M1.333 2.667c0-.737.597-1.334 1.333-1.334H6c.736 0 1.333.597 1.333 1.334V6c0 .736-.597 1.333-1.333 1.333H2.666A1.333 1.333 0 0 1 1.333 6V2.667Zm4.667 0H2.666V6H6V2.667ZM1.333 10c0-.736.597-1.333 1.333-1.333H6c.736 0 1.333.597 1.333 1.333v3.333c0 .737-.597 1.334-1.333 1.334H2.666a1.332 1.332 0 0 1-1.333-1.334V10ZM6 10H2.666v3.333H6V10Zm2.666-7.333c0-.737.597-1.334 1.334-1.334h3.333c.736 0 1.333.597 1.333 1.334V6c0 .736-.597 1.333-1.333 1.333H10A1.333 1.333 0 0 1 8.666 6V2.667Zm4.667 0H10V6h3.333V2.667ZM8.666 10c0-.736.597-1.333 1.334-1.333h3.333c.736 0 1.333.597 1.333 1.333v3.333c0 .737-.597 1.334-1.333 1.334H10a1.334 1.334 0 0 1-1.334-1.334V10Zm4.667 0H10v3.333h3.333V10Z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </Svg>
  )
}

const ApplicationActive: React.FC = () => {
  const theme = useTheme()
  return (
    <Svg fill="none" viewBox="0 0 16 16">
      <Path
        fill={theme.brand._1}
        d="M1.333 2.667c0-.737.597-1.334 1.333-1.334H6c.736 0 1.333.597 1.333 1.334V6c0 .736-.597 1.333-1.333 1.333H2.666A1.333 1.333 0 0 1 1.333 6V2.667Zm7.333 0c0-.737.597-1.334 1.334-1.334h3.333c.736 0 1.333.597 1.333 1.334V6c0 .736-.597 1.333-1.333 1.333H10A1.333 1.333 0 0 1 8.666 6V2.667ZM1.333 10c0-.736.597-1.333 1.333-1.333H6c.736 0 1.333.597 1.333 1.333v3.333c0 .737-.597 1.334-1.333 1.334H2.666a1.332 1.332 0 0 1-1.333-1.334V10Zm7.333 0c0-.736.597-1.333 1.334-1.333h3.333c.736 0 1.333.597 1.333 1.333v3.333c0 .737-.597 1.334-1.333 1.334H10a1.334 1.334 0 0 1-1.334-1.334V10Z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </Svg>
  )
}

const MineNormal: React.FC = () => {
  return (
    <Svg fill="none" viewBox="0 0 16 16">
      <Path
        fill="#333"
        d="M4.5 4.167a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM8 2a2.167 2.167 0 1 0-.097 4.333A2.167 2.167 0 0 0 8 2ZM6.238 9h3.524c.722 0 1.305 0 1.777.039.486.04.912.123 1.307.324.627.32 1.137.83 1.457 1.457.201.395.285.822.325 1.308.038.472.038 1.054.038 1.777v.428A.667.667 0 0 1 14 15H2a.667.667 0 0 1-.667-.667v-.428c0-.723 0-1.305.039-1.777.04-.486.123-.913.324-1.308.32-.627.83-1.137 1.457-1.457.395-.2.822-.285 1.308-.324C4.933 9 5.515 9 6.238 9Zm-2.48 1.551a2 2 0 0 0-.874.874c-.09.176-.15.408-.184.811-.03.361-.033.813-.034 1.43h10.667c0-.617-.005-1.069-.034-1.43-.033-.403-.095-.635-.184-.81a2 2 0 0 0-.874-.875c-.176-.09-.407-.15-.81-.184-.412-.033-.94-.034-1.698-.034H6.266c-.757 0-1.286 0-1.697.034-.403.033-.635.095-.81.184h-.001Z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </Svg>
  )
}

const MineActive: React.FC = () => {
  const theme = useTheme()
  return (
    <Svg fill="none" viewBox="0 0 16 16">
      <Path
        fill={theme.brand._1}
        d="M4.5 4.167a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM6.238 9h3.524c.722 0 1.305 0 1.777.039.486.04.912.123 1.307.324.627.32 1.137.83 1.457 1.457.201.395.285.822.325 1.308.038.472.038 1.054.038 1.777v.428A.667.667 0 0 1 14 15H2a.667.667 0 0 1-.667-.667v-.428c0-.723 0-1.305.039-1.777.04-.486.123-.913.324-1.308.32-.627.83-1.137 1.457-1.457.395-.2.822-.285 1.308-.324C4.933 9 5.515 9 6.238 9Z"
        clipRule="evenodd"
        fillRule="evenodd"
      />
    </Svg>
  )
}

/**
 * 快速创建入口 Icon
 *
 * @returns component
 */
export const ShortcutIcon: React.FC<{ focused: boolean }> = () => {
  return (
    <Svg width={16} height={16} fill="none" viewBox="0 0 16 16">
      <Path
        fill="#fff"
        d="M9.29 1.29a1.29 1.29 0 1 0-2.58 0v5.42H1.29a1.29 1.29 0 1 0 0 2.58h5.42v5.42a1.29 1.29 0 0 0 2.58 0V9.29h5.42a1.29 1.29 0 0 0 0-2.58H9.29V1.29Z"
        data-follow-fill="#fff"
      />
    </Svg>
  )
}

/**
 * 首页 Icon
 *
 * @param props - props
 * @param props.focused - 是否聚焦
 * @returns component
 */
export const HomeIcon: React.FC<{ focused: boolean }> = ({ focused }) => {
  return <IconWrapper>{focused ? <HomeActive /> : <HomeNormal />}</IconWrapper>
}

/**
 * 日程 Icon
 *
 * @param props - props
 * @param props.focused - 是否聚焦
 * @returns component
 */
export const CalendarIcon: React.FC<{ focused: boolean }> = ({ focused }) => {
  return (
    <IconWrapper>
      {focused ? <CalendarActive /> : <CalendarNormal />}
    </IconWrapper>
  )
}

/**
 * 应用 Icon
 *
 * @param props - props
 * @param props.focused - 是否聚焦
 * @returns component
 */
export const ApplicationIcon: React.FC<{ focused: boolean }> = ({
  focused,
}) => {
  return (
    <IconWrapper>
      {focused ? <ApplicationActive /> : <ApplicationNormal />}
    </IconWrapper>
  )
}

/**
 * 我的 Icon
 *
 * @param props - props
 * @param props.focused - 是否聚焦
 * @returns component
 */
export const MineIcon: React.FC<{ focused: boolean }> = ({ focused }) => {
  return <IconWrapper>{focused ? <MineActive /> : <MineNormal />}</IconWrapper>
}

/**
 * @file 自定义日程卡片操作按钮 slot
 */

import { EnabledPlugin } from 'packages/feature-utils/plugins'
import { CustomScheduleEventSource } from 'packages/features/schedules/types'
import { getIsEnded } from 'packages/features/schedules/utils/data'
import { $t } from 'packages/locale'
import { getEnableClassInButton } from 'packages/plugins/features/class-in/views'
import { Reflection, ScheduleMemberStatusEnum } from 'packages/sdks-next/chalk'

import { onPressLiveButton } from '../utils'

/**
 * 自定义日程卡片操作按钮 slot
 *
 * @param param0 - 参数
 * @param param0.reflection - 用户
 * @param param0.source - 日程
 * @param param0.classInPlugin - classin 插件
 * @param param0.accessToken - accessToken
 * @returns 按钮
 */
export const getClassinScheduleAction = ({
  reflection,
  source,
  classInPlugin,
  accessToken,
}: {
  reflection: Reflection
  source: CustomScheduleEventSource
  classInPlugin?: EnabledPlugin
  accessToken?: string
}): {
  label: string
  onPress: () => Promise<void> | void
}[] => {
  if (source.custom.sectionMemberStatus !== ScheduleMemberStatusEnum.Approved)
    return []

  const isEnded = getIsEnded(source.endTime)

  const enable =
    !!source.custom.castUrl ||
    getEnableClassInButton({
      reflection,
      classinLessonId: source.custom.classinLessonId,
      classinCourseId: source.custom.classinCourseId,
    })

  return !isEnded && enable
    ? [
        {
          label: $t('进入直播间'),
          onPress: async () => {
            await onPressLiveButton({
              classinCourseId: source.custom.classinCourseId,
              classinLessonId: source.custom.classinLessonId,
              liveUrl: source.custom.castUrl,
              reflection,
              classInPlugin,
              accessToken,
            })
          },
        },
      ]
    : []
}

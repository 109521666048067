import { InitFeatureParams } from '../types'

import { szHomeworkSurveyTodos } from './todo'

/**
 * 初始化作业调查
 *
 * @param param0 - 参数
 */
export const initSzHomeworkSurvey = (param0: InitFeatureParams) => {
  const { registerTodos } = param0

  registerTodos(szHomeworkSurveyTodos)
}

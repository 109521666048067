/**
 * @file 自定义日程视频信息 slot
 */

import { faVideo } from '@fortawesome/pro-regular-svg-icons'
import { Button, CollapsibleText } from '@seiue/rn-ui'
import { getWindowWidth } from '@seiue/rn-util'
import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'

import { $t } from 'packages/locale'
import { getEnableClassInButton } from 'packages/plugins/features/class-in/views'
import {
  ScheduleMemberStatusEnum,
  classinApi$queryClassinReflections,
} from 'packages/sdks-next/chalk'

import { getShowErrorUI } from '@go/App/error-handler'
import { LabelText } from '@go/components/Schedule/ScheduleDetail'
import type { AsyncSlotTypes } from '@go/plugins/slots'
import { handleError } from '@go/utils/error-handler'

import { onPressLiveButton } from '../utils'

/**
 * 自定义日程视频信息 slot
 *
 * @param props - 组件属性
 * @returns 自定义日程视频信息 slot
 */
export const getClassinScheduleDetail: AsyncSlotTypes['asyncOwnerScheduleDetail'] =
  async props => {
    const showErrorUI = getShowErrorUI(props.dispatch)

    let classinRegisteredIds: number[] = []

    try {
      if (props.members?.length) {
        const { data: classinReflections } =
          await classinApi$queryClassinReflections.api({
            registered: true,
            idIn: props.members?.map(member => member.reflectionId).join(','),
          })

        classinRegisteredIds = classinReflections.map(r => r.id)
      }
    } catch (e) {
      // 在这里单独处理 Error，以不阻塞页面渲染
      handleError(e, showErrorUI)
    }

    const unBoundPhoneMembers = props.members?.filter(
      m => !classinRegisteredIds?.includes(m.reflectionId),
    )

    const { status: compereStatus, reflection: compereReflection } =
      props.members?.find(
        m => m.reflection?.id === props.schedule?.compereId,
      ) || {}

    const { status: currentRefStatus } =
      props.members?.find(
        m => m.reflection?.id === props.currentReflection?.id,
      ) || {}

    const enable =
      !!props.playbackUrl ||
      getEnableClassInButton({
        reflection: props.currentReflection,
        classinLessonId: props.classinLessonId,
        classinCourseId: props.classinCourseId,
      })

    const getVideoDisplay = () => {
      if (!compereStatus || !currentRefStatus) return false
      return !(
        compereStatus === ScheduleMemberStatusEnum.Approved &&
        currentRefStatus === ScheduleMemberStatusEnum.Approved &&
        !enable
      )
    }

    return !compereStatus
      ? []
      : [
          {
            icon: faVideo,
            title: $t('视频'),
            display: getVideoDisplay(),
            value: (
              <View>
                {compereStatus === ScheduleMemberStatusEnum.Approved &&
                currentRefStatus === ScheduleMemberStatusEnum.Approved &&
                enable ? (
                  <ButtonWrapper>
                    <Button
                      size="xs"
                      type="text-primary"
                      label={$t('打开 ClassIn')}
                      onPress={async () => {
                        await onPressLiveButton({
                          classinLessonId: props.classinLessonId,
                          classinCourseId: props.classinCourseId,
                          liveUrl: props.playbackUrl,
                          reflection: props.currentReflection,
                          classInPlugin: props.classInPlugin,
                          accessToken: props.accessToken,
                        })
                      }}
                    />
                  </ButtonWrapper>
                ) : null}

                {compereStatus === ScheduleMemberStatusEnum.Pending ? (
                  <LabelText style={{ color: '#AD5B00' }}>
                    {$t('主持人「{compereName}」接受后可以进入直播间', {
                      compereName: compereReflection?.name,
                    })}
                  </LabelText>
                ) : null}

                {compereStatus === ScheduleMemberStatusEnum.Rejected ? (
                  <LabelText style={{ color: '#E4524B' }}>
                    {$t('主持人拒绝，直播创建失败')}
                  </LabelText>
                ) : null}
              </View>
            ),
          },
          {
            title: '',
            icon: faVideo,
            iconColor: 'transparent',
            display:
              !!unBoundPhoneMembers?.length &&
              compereStatus === ScheduleMemberStatusEnum.Approved &&
              props.currentReflection?.id === props.schedule?.compereId,
            value: (
              <View style={{ marginRight: 32 }}>
                <UnBoundMessages style={{ maxWidth: getWindowWidth() - 82 }}>
                  <CollapsibleText
                    maxLines={2}
                    wrapperStyle={{ marginBottom: 0 }}
                    textStyle={{
                      color: '#AD5B00',
                      fontSize: 14,
                    }}
                    expandTextStyle={{
                      color: '#AD5B00',
                      fontWeight: 'bold',
                    }}
                    collapseStyle={{ marginLeft: 0 }}
                    expandIconStyle={{ color: '#AD5B00' }}
                    backgroundColor="#FFF5E3"
                    content={$t(
                      '{name}等 {num} 人未绑定 ClassIn 账号，需绑定后才能查看直播人员：{names}',
                      {
                        name: unBoundPhoneMembers?.[0]?.reflection?.name,
                        num: unBoundPhoneMembers?.length,
                        names: unBoundPhoneMembers
                          ?.map(
                            m => `${m.reflection?.name}/${m.reflection?.usin}`,
                          )
                          .join('；'),
                      },
                    )}
                  />
                </UnBoundMessages>
              </View>
            ),
          },
        ]
  }

const UnBoundMessages = styled(View)`
  width: 100%;
  padding: 12px 16px;
  background: #fff5e3;
  border-radius: 6px;
`

const ButtonWrapper = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  margin-left: 10px;
  padding: 2px 8px;
  background: #ecf4ff;
  border-radius: 4px;
`

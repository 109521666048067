/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { ChannelEnum } from './channel-enum'

const buildConfig = createConfigBuilder('scms')

export const classApi$sendUnbindAccountNotify = {
  config(
    channel: ChannelEnum,
    rids: string,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(options, 'post', '/online_class/accounts/notify', {
      channel,
      rids,
    })
  },

  /**
   * @summary 给未绑定账户的人发送待办
   * @param channel 渠道
   * @param rids
   * @param [options]
   * @return AxiosResponsePromise<object>
   */

  api(
    channel: ChannelEnum,
    rids: string,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<object> {
    return axios(
      classApi$sendUnbindAccountNotify.config(channel, rids, options),
    ) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({ channel, rids }: { channel: ChannelEnum; rids: string }) =>
        classApi$sendUnbindAccountNotify.api(channel, rids),
      mutationOptions,
    )
  },
}

// @ts-ignore
classApi$sendUnbindAccountNotify.api._name_ = `Class.sendUnbindAccountNotify`

import { PluginEnum } from 'packages/feature-utils/plugins'

import { InitPluginFeature } from '@go/plugins/types'

import { calendarEvents } from './calendar-events'
import { routes } from './routes'

export const examRooms: InitPluginFeature = {
  name: PluginEnum.ExamRooms,
  calendarEvents,
  register: () => {
    return {
      routes,
    }
  },
  messageNavigators: [
    {
      type: 'exam.schedule_result_for_examinee',
      navigator: ({ attributes }) => {
        const examId = (attributes as any)?.examId
        if (!examId) return null

        return [
          'Plugin.ExamRooms.PreviewExaminee',
          {
            examId,
          },
        ]
      },
    },
    {
      type: 'exam.schedule_result_for_examiner',
      navigator: ({ attributes }) => {
        const examId = (attributes as any)?.examId
        if (!examId) return null

        return [
          'Plugin.ExamRooms.PreviewExaminer',
          {
            examId,
          },
        ]
      },
    },
  ],
}

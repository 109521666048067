import { InitFeatureParams } from '../types'

import { routes } from './routes'
import { todos } from './todos'

/**
 * 初始化通知
 *
 * @param param0 - 参数
 */
export const initNotices = (param0: InitFeatureParams) => {
  const { addRoutes, registerMessageNavigator, registerTodos } = param0
  addRoutes(routes)
  registerTodos(todos)

  registerMessageNavigator(
    ['notification.received', 'notification.discussion_message_replied'],
    message => [
      'InboxNoticeDetail',
      {
        id: (message.attributes as any)?.notificationId,
        idType: 'conversationId',
      },
    ],
  )

  registerMessageNavigator(['notification.withdrawn'], msg => [
    'OutboxNoticeModal',
    {
      id: (msg.attributes as any)?.notificationId,
    },
  ])
}

/**
 * @file classIn 公用视图 helpers
 */

import { faPlay } from '@fortawesome/pro-solid-svg-icons'
import { ToastMetaError } from '@seiue/error-handler'
import { compact } from '@seiue/util'

import { EnabledPlugin } from 'packages/feature-utils/plugins'
import { ClassEventSource } from 'packages/features/classes/calendar-events/types'
import { getIsEnded } from 'packages/features/schedules/utils/data'
import { $t } from 'packages/locale'
import { DeviceType } from 'packages/plugins/features/class-in/types'
import { getClassinLessonLinkWithSso } from 'packages/plugins/features/class-in/utils/apis'
import { Reflection } from 'packages/sdks-next/chalk'

import { getClassInSettions } from './utils'

/**
 * 是否需要渲染「云课堂」按钮
 *
 * @param args - args
 * @param args.reflection - 当前登录用户
 * @param args.classinCourseId - 线上课 id
 * @param args.classinLessonId - 线上课节 id
 * @returns 是否
 */
export const getEnableClassInButton = ({
  reflection,
  classinCourseId,
  classinLessonId,
}: {
  reflection?: Reflection
  classinCourseId?: number | null
  classinLessonId?: number | null
}) => reflection?.id && classinCourseId && classinLessonId

/**
 * 获取线上课的操作
 *
 * @param args - args
 * @param args.currentReflection - 当前登录用户
 * @param args.source - 共享课信息
 * @param args.classInPlugin - ClassIn 插件
 * @param args.onClick - 点击事件
 * @returns 操作
 */
export const getLocalClassActions = ({
  currentReflection,
  source,
  classInPlugin,
  onClick,
}: {
  currentReflection?: Reflection
  source: ClassEventSource
  classInPlugin?: EnabledPlugin
  onClick: (classinLink: string) => void
}) => {
  const enable = getEnableClassInButton({
    reflection: currentReflection,
    classinLessonId: source.custom.classinLessonId,
    classinCourseId: source.custom.classinCourseId,
  })

  if (!enable || !currentReflection) return []

  const isEnded = getIsEnded(source.endTime)
  const classInSettings = getClassInSettions(classInPlugin)

  return compact([
    enable && isEnded && source.custom.lessonReplayUrl
      ? {
          icon: 'LeftCurvedArrow' as const,
          label: $t('查看回放'),
          onClick: () => onClick(source.custom.lessonReplayUrl || ''),
        }
      : null,
    enable && !isEnded
      ? {
          icon: 'ClassSolid' as const,
          label: $t('云课堂'),
          onClick: async () => {
            const classinLink = await getClassinLessonLinkWithSso({
              reflection: currentReflection,
              classinLessonId: source.custom.classinLessonId,
              classinCourseId: source.custom.classinCourseId,
              deviceType: DeviceType.Web,
              enableSsoLogin: classInSettings?.enableSsoLogin,
            })

            onClick(classinLink)
          },
        }
      : null,
  ])
}

/**
 * 获取共享课的操作
 *
 * @param args - args
 * @param args.currentReflection - 当前登录用户
 * @param args.source - 共享课信息
 * @param args.classInPlugin - ClassIn 插件
 * @param args.onClick - 点击事件
 * @returns 操作
 */
export const getSharedClassActions = ({
  currentReflection,
  source,
  classInPlugin,
  onClick,
}: {
  currentReflection?: Reflection
  source: ClassEventSource
  classInPlugin?: EnabledPlugin
  onClick: (classinLink: string) => void
}) => {
  const { classinLessonId, classinCourseId, lessonReplayUrl } = source.custom
  const isEnded = getIsEnded(source.endTime)
  const sourceExists = classinLessonId && classinCourseId
  const classInSettings = getClassInSettions(classInPlugin)

  return [
    {
      icon: sourceExists && faPlay,
      label: sourceExists ? $t('进入云课堂') : $t('云课堂资源已失效'),
      disabled: !sourceExists,
      onClick: async () => {
        let classinLink = lessonReplayUrl

        if (
          !isEnded &&
          classinLessonId &&
          classinCourseId &&
          currentReflection
        ) {
          classinLink = await getClassinLessonLinkWithSso({
            reflection: currentReflection,
            classinLessonId,
            classinCourseId,
            liveUrl: lessonReplayUrl,
            deviceType: DeviceType.Web,
            enableSsoLogin: classInSettings?.enableSsoLogin,
          })
        }

        if (!classinLink) {
          throw new ToastMetaError({
            title: $t('未找到云课堂资源地址'),
            report: false,
          })
        }

        onClick(classinLink)
      },
    },
  ].filter(item => item)
}

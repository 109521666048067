import {
  NativeModules,
  NativeEventEmitter,
  EmitterSubscription,
} from 'react-native'

export interface TPushNotificationType {
  // 推送的自定义参数，是一个 json string
  customContent: string
}

const { TPushNativeModule } = NativeModules
const eventEmitter = new NativeEventEmitter(TPushNativeModule)

/**
 * 设置 Debug 模式
 * @param enableDebug - 是否开启 Debug
 */
export function setDebugMode(enableDebug: boolean) {
  TPushNativeModule.setDebugMode(enableDebug)
}

/**
 * 注册腾讯移动推送
 */
export function register() {
  return TPushNativeModule.register()
}

/**
 * 移除注册
 */
export function unregister() {
  return TPushNativeModule.unregister()
}

/**
 * 绑定账号
 * @param accountId - reflection id
 */
export function bindAccount(accountId: string) {
  return TPushNativeModule.bindAccount(accountId)
}

/**
 * 获取 TPNs 生成的 Token
 */
export function getTPNsToken() {
  return TPushNativeModule.getTPNsToken()
}

/**
 * 【iOS】获取 APNs 生成的 DeviceToken
 */
export function getAPNsDeviceToken() {
  return TPushNativeModule.getAPNsDeviceToken()
}

export function addNotificationListener(
  listener: (result: TPushNotificationType) => void,
): EmitterSubscription {
  return eventEmitter.addListener('notification-event', listener)
}

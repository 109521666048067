import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'school-calendar',
    name: 'ShowSchoolCalendar',
    getTitle: () => $t('校历'),
    Component: lazy(() =>
      import('./screens/Show').then(m => ({ default: m.Show })),
    ),
  },
  {
    path: 'school-calendar/event',
    name: 'SchoolCalendarEvent',
    Component: lazy(() =>
      import('./screens/Event').then(m => ({ default: m.EventDetail })),
    ),
  },
]

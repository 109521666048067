/**
 * @file 红色徽章
 */

import { Text } from '@seiue/rn-ui'
import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import styled, { css } from 'styled-components/native'

export const Badge: React.FC<{
  count?: number
  dot?: boolean
  style?: StyleProp<ViewStyle>
  fontSize?: number
}> = ({ count, style, dot = false, fontSize }) =>
  count || dot ? (
    <BadgeEl style={style} dot={!!dot}>
      {!dot && count ? (
        <BadgeText style={{ fontSize }}>{count > 99 ? '99+' : count}</BadgeText>
      ) : null}
    </BadgeEl>
  ) : null

const BadgeEl = styled(View)<{ dot: boolean }>`
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: ${props => props.theme.status.danger.background};
  padding: 0 4px;
  height: 16px;
  min-width: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  ${({ dot }) =>
    dot &&
    css`
      top: 6px;
      right: 6px;
      width: 8px;
      min-width: 8px;
      height: 8px;
      padding: 0;
    `}
`

const BadgeText = styled(Text)`
  color: ${props => props.theme.text._0};
  font-weight: bold;
  text-align: center;
`

import { reportToEngineer } from '@seiue/sentry'
import React from 'react'

import { EnabledPlugin, PluginEnum } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { kaopuApi$getToolLoginUrl } from 'packages/sdks-next/chalk'

import { LogoRender } from '@go/components/Logo/Render'
import { Menu } from '@go/features/applications/types'

/**
 * 获取靠谱 ai 菜单
 *
 * @param plugin - 插件
 * @returns 菜单
 */
export const getKaopuAiToolMenu =
  (plugin: EnabledPlugin) => async (): Promise<Menu[] | null> => {
    let uri = ''

    try {
      const { data } = await kaopuApi$getToolLoginUrl.api()

      uri = data
    } catch (e) {
      reportToEngineer(e)
    }

    if (!uri) return null

    const title = $t('靠谱 AI')

    return [
      {
        key: PluginEnum.KaopuAiTool,
        pluginName: PluginEnum.KaopuAiTool,
        icon: ({ size } = {}) => <LogoRender size={size} logo={plugin.icon} />,
        getTitle: () => $t('靠谱'),
        screen: {
          name: 'WebviewModal' as const,
          params: {
            uri,
            title,
          },
        },
        openInExternalBrowserInAndroid: true,
      },
    ]
  }

/**
 * @file 听评课-待办注册
 */

import { lazy } from 'react'

import { TodoExecution, TodoTypeEnum } from 'packages/features/todos/types'

export const todos: TodoExecution[] = [
  {
    type: TodoTypeEnum.ClassReviewInvited,
    Render: lazy(() =>
      import('./ClassReviewInvited').then(m => ({
        default: m.ClassReviewInvited,
      })),
    ),
  },
]

/**
 * @file 自定义日程
 */

import { lazy } from 'react'

import { CalendarEventType } from 'packages/features/calendars/utils/types'
import { $t } from 'packages/locale'

export const calendarEvents = [
  {
    // 咨询和约谈共用渲染
    type: CalendarEventType.Chat,
    Component: lazy(() =>
      import('@go/plugins/features/chat/calendar-events/ScheduleChat').then(
        m => ({
          default: m.ScheduleChat,
        }),
      ),
    ),
    getEventTag: () => $t('咨询'),
  },
]

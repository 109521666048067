import { InitFeatureParams } from '../types'

import { routes } from './routes'

/**
 * 初始化分享
 *
 * @param param0 - 参数
 */
export const initShares = (param0: InitFeatureParams) => {
  const { addRoutes } = param0

  addRoutes(routes)
}

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const teacherRoutes: Route[] = [
  {
    name: 'Plugin.CertificationCredit.ApplyPrize',
    getHeaderTitle: () => '',
    // 这里在实际上显示时，需要使用校园币的名称
    getTitle: () => $t('校园币兑换'),
    Component: lazy(() =>
      import('./screens/ApplyPrize').then(m => ({
        default: m.ApplyCertificationCreditPrize,
      })),
    ),
  },
]

export const studentRoutes: Route[] = [
  {
    name: 'Plugin.CertificationCredit.MyCredit',
    getHeaderTitle: () => '',
    // 这里在实际上显示时，需要使用校园币的名称
    getTitle: () => $t('我的校园币'),
    Component: lazy(() =>
      import('./screens/MyCredit').then(m => ({
        default: m.MyCertificationCredit,
      })),
    ),
  },
  {
    name: 'Plugin.CertificationCredit.MyCreditLogs',
    getHeaderTitle: () => $t('详情'),
    Component: lazy(() =>
      import('./screens/MyCreditLogs').then(m => ({
        default: m.MyCreditLogs,
      })),
    ),
  },
  {
    name: 'Plugin.CertificationCredit.TeacherCreditLogs',
    getHeaderTitle: () => $t('兑换记录'),
    Component: lazy(() =>
      import('./screens/TeacherCreditLogs').then(m => ({
        default: m.TeacherCreditLogs,
      })),
    ),
  },
]

import { Text } from '@seiue/rn-ui'
import { View } from 'react-native'
import styled from 'styled-components/native'

export const ShortcutGroup = styled(View)``

export const ShortcutGroupTitle = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color: ${p => p.theme.text._2};
  margin-bottom: 2px;
`

export const ShortcutPlaceHolder = styled(View)`
  width: 100%;
`

/**
 * @file 教师档案
 */

import { InitPluginFeature } from '@go/plugins/types'

import { todos } from './todos'

export const teacherArchives: InitPluginFeature = {
  name: 'teacher_profile',
  todos,
}

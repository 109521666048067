import React from 'react'

import { PluginNameEnum } from 'packages/plugins/types'
import { RoleEnum } from 'packages/sdks-next/chalk'
import { AdjustmentTypeEnum } from 'packages/sdks-next/scms'

import { InitPluginFeature } from '@go/plugins/types'

import { calendarEvents } from './calendar-events'
import { routes } from './routes'
import { todos } from './todos'

/**
 * 调代课
 */
export const adjustment: InitPluginFeature = {
  name: PluginNameEnum.Adjustment,
  todos,
  calendarEvents,
  workflowRenders: {
    bizType: PluginNameEnum.Adjustment,
    render: {
      TodoCard: React.lazy(() =>
        import('./todos/ApproveTodo').then(m => ({
          default: m.AdjustmentFlowTodoCard,
        })),
      ),
    },
  },
  register: () => ({
    routes,
  }),
  messageNavigators: [
    {
      type: 'class_adjustment',
      navigator: (message, reflection) => {
        const attrs = message.attributes as {
          id: number
          type: AdjustmentTypeEnum
          disabledOpen: boolean
        }

        // 产品确定，学生无需看到教师发起的调代课详情（会看到一些隐私信息），消息中即可看到所需要的信息
        if (
          !attrs.id ||
          !attrs.type ||
          reflection.role === RoleEnum.Student ||
          attrs.disabledOpen
        )
          return null

        return [
          'AdjustmentDetailMiddlePage',
          {
            type: attrs.type,
            id: attrs.id,
          },
        ]
      },
    },
  ],
}

import { ExamTypeEnum } from 'packages/sdks-next/vnas'

import { InitFeatureParams } from '../types'

import { routes } from './routes'

/**
 * 初始化考试
 *
 * @param param - 初始化参数
 */
export const initExams = (param: InitFeatureParams) => {
  param.addRoutes(routes)
  param.registerMessageNavigator(
    ['exam.published_for_examinee'],
    ({ attributes, owner }, currentReflection) => {
      const {
        exam: { id: examId, examType },
      } = attributes as {
        exam: { id: number; examType: ExamTypeEnum }
      }

      const rid = currentReflection.pupilId || owner?.id

      if (!examId) return null

      return [
        'StudentExamAnalysisReportModal',
        {
          examineeId: rid,
          examId,
          examType,
        },
      ]
    },
  )
}

/**
 * @file 活动任务日程
 */

import { lazy } from 'react'

import { CalendarEventType } from 'packages/features/calendars/utils/types'
import { $t } from 'packages/locale'

export const calendarEvents = [
  {
    type: CalendarEventType.Task,
    Component: lazy(() =>
      import('./ScheduleTaskCard').then(m => ({ default: m.ScheduleTaskCard })),
    ),
    getEventTag: () => $t('活动任务'),
  },
]

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'Plugin.Certifications.GrantByCode',
    getHeaderTitle: () => '',
    getTitle: () => $t('扫码授予'),
    Component: lazy(() =>
      import('./screens/GrantByCode').then(m => ({
        default: m.CertificationGrantByCode,
      })),
    ),
  },
  {
    name: 'Plugin.Certifications.ShortcutEntry',
    getHeaderTitle: () => $t('随手评'),
    Component: lazy(() =>
      import('./screens/ShortcutEntry').then(m => ({
        default: m.CertificationShortcutEntry,
      })),
    ),
  },
  {
    name: 'Plugin.Certifications.Wall',
    getHeaderTitle: () => $t('徽章墙'),
    Component: lazy(() =>
      import('./screens/Wall').then(m => ({
        default: m.CertificationWall,
      })),
    ),
  },
  {
    name: 'Plugin.Certifications.ApplicationLogs',
    getHeaderTitle: () => $t('全部获得记录'),
    Component: lazy(() =>
      import('./screens/ApplicationLogs').then(m => ({
        default: m.CertificationApplicationLogs,
      })),
    ),
  },
  {
    name: 'Plugin.Certifications.Certified',
    getHeaderTitle: () => $t('获得详情'),
    Component: lazy(() =>
      import('./screens/Certified').then(m => ({
        default: m.CertificationCertified,
      })),
    ),
  },
  {
    name: 'Plugin.Certifications.GrantedLogs',
    getHeaderTitle: () => $t('评价记录'),
    Component: lazy(() =>
      import('./screens/GrantedLogs').then(m => ({
        default: m.GrantedLogs,
      })),
    ),
  },
  {
    name: 'Plugin.Certifications.EditGrantedReview',
    getHeaderTitle: () => $t('编辑评语'),
    Component: lazy(() =>
      import('./screens/EditGrantedReview').then(m => ({
        default: m.EditGrantedReview,
      })),
    ),
  },
]

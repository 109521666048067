import { Image, SuperEllipse, Icon as IconRN, FAIcon } from '@seiue/rn-ui'
import { isObject, isString } from '@seiue/util'
import React from 'react'
import styled from 'styled-components'

import {
  colors as defaultColors,
  icons as defaultIcons,
} from 'packages/components/Logo/config.native'
import {
  getLogoConfig,
  IconType,
  isFontAwesomeIcon,
  LogoSize,
  LogoType,
  sizeToIconSize,
  sizeToPx,
} from 'packages/components/Logo/utils'

import { LogoRenderNativeProps } from './types'

/**
 * Logo Render
 *
 * @param props - props
 * @returns component
 */
export const LogoRender: React.FC<LogoRenderNativeProps> = props => {
  const {
    size = LogoSize.S,
    iconSize,
    style,
    logo = '',
    icons = defaultIcons,
    colors = defaultColors,
    iconType = IconType.Icon,
    logoType: propLogoType,
  } = props

  const { type, src, icon, color, px } = getLogoConfig({
    logo,
    icons,
    colors,
    size,
  })

  const logoType = propLogoType ?? type

  if (logoType === LogoType.Comb && icon && iconType === IconType.Icon) {
    return (
      <Wrapper
        style={style}
        color={isObject(color) ? color.color : color}
        size={size}
      >
        {isString(icon) ||
        isString(icon.icon) ||
        isFontAwesomeIcon(icon.icon) ? (
          <IconRN
            icon={(icon as any)?.icon as FAIcon}
            color="#fff"
            size={iconSize ?? sizeToIconSize(size)}
            secondaryColor="rgba(255, 255, 255, 0.8)"
            secondaryOpacity={1}
          />
        ) : (
          <icon.icon color="#fff" size={iconSize ?? sizeToIconSize(size)} />
        )}
      </Wrapper>
    )
  }

  if (logoType === LogoType.Comb && iconType === IconType.Image) {
    return (
      <Wrapper style={style} size={size}>
        <Image
          style={{
            width: px[0],
            height: px[1],
          }}
          source={{
            uri: logo,
            method: 'GET',
          }}
        />
      </Wrapper>
    )
  }

  return (
    <Wrapper style={style} size={size}>
      {src ? (
        <Image
          style={{
            width: px[0],
            height: px[1],
          }}
          source={{
            uri: src,
            method: 'GET',
          }}
        />
      ) : (
        <Empty />
      )}
    </Wrapper>
  )
}

const Wrapper = styled(SuperEllipse)<{
  size?: LogoSize
  color?: string
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: ${props => sizeToPx(props.size || LogoSize.S)}px;
  height: ${props => sizeToPx(props.size || LogoSize.S)}px;

  ${props => {
    const size = props.size || LogoSize.S
    if (size === LogoSize.L) {
      return `
        font-size: 80px;
      `
    }

    if (size === LogoSize.M) {
      return `
        font-size: 32px;
      `
    }

    return `
        font-size: 24px;
      `
  }}

  ${props =>
    props.color
      ? `
    background-color: ${props.color};
    color: #ffffff;
  `
      : ''}
`

const Empty = styled.div`
  width: 100%;
  height: 100%;
  background: #c6c9cf;
`

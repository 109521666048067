import { isIOS } from '@seiue/rn-util'
import React from 'react'
import {
  LayoutChangeEvent,
  ScrollView,
  ScrollViewProps,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native'
import styled from 'styled-components/native'

import { SwiperRef } from './types'
import { useSwiperScrollEnd } from './use-scroll-end'

/**
 * 走马灯（轮播展示的区域）
 *
 * @param props - props
 * @param props.children - 子组件
 * @param props.style - 样式
 * @param props.showIndicators - 是否展示指示器，默认显示
 * @param props.defaultIndex - 默认选中项下标，默认 0
 * @param props.onIndexChange - 当前选中项下标变化时回调
 * @returns JSX.Element
 */
export const Swiper = React.forwardRef<
  SwiperRef,
  {
    children?: React.ReactNode
    style?: StyleProp<ViewStyle>
    showIndicators?: boolean
    defaultIndex?: number
    onIndexChange?: (index: number) => void
  }
>(
  (
    { children, style, showIndicators = true, defaultIndex = 0, onIndexChange },
    ref,
  ) => {
    const [swiperItemLayout, setSwiperItemLayout] = React.useState<{
      width?: number
      height?: number
    } | null>(null)

    // 冗余一份宽度提供给 handleScroll 使用
    const widthRef = React.useRef(0)

    const itemCount = React.Children.count(children)

    const [activeItemIndex, setActiveItemIndex] = React.useState(defaultIndex)

    const onItemLayout = React.useCallback(
      ({ nativeEvent }: LayoutChangeEvent) => {
        setSwiperItemLayout(prevLayout => {
          return {
            ...prevLayout,
            height: nativeEvent.layout.height,
          }
        })
      },
      [],
    )

    // Native 端类型是 ScrollView；Go web 为 HTMLDivElement
    const scrollViewRef = React.useRef<any>(null)

    const momentumScrollEndTimer = React.useRef<NodeJS.Timeout | null>(null)

    const scrollToIndex = (index: number, itemWidth: number) => {
      scrollViewRef.current.scrollTo({
        x: index * itemWidth,
        animated: false,
      })
    }

    const handleIndexChange = React.useCallback(
      (index: number) => {
        setActiveItemIndex(index)
        onIndexChange?.(index)
      },
      [onIndexChange],
    )

    const handleMomentumScrollEnd: Required<ScrollViewProps>['onMomentumScrollEnd'] =
      ({ nativeEvent }) => {
        if (widthRef.current) {
          if (momentumScrollEndTimer.current) {
            clearTimeout(momentumScrollEndTimer.current)
          }

          const offsetX = nativeEvent.contentOffset.x
          const index = Math.floor(offsetX / widthRef.current)

          // iOS 滚动结束事件只会触发一次，所以直接设置最新的下标即可
          if (isIOS) {
            handleIndexChange(index)
          }

          // 而 Android 下会触发多次，为了保证滚动结束事件只执行一次，所以使用 setTimeout 来判断
          else {
            momentumScrollEndTimer.current = setTimeout(() => {
              handleIndexChange(index)
            }, 60)
          }
        }
      }

    React.useImperativeHandle(ref, () => ({
      swipeTo: index => {
        setActiveItemIndex(index)

        if (swiperItemLayout?.width) {
          scrollToIndex(index, swiperItemLayout.width)
        }
      },
    }))

    // go-web 监听 ScrollView 的滚动，当停止滚动时，执行 handleIndexChange 方法
    useSwiperScrollEnd({
      scrollViewRef,
      widthRef,
      handleIndexChange,
    })

    return (
      <Wrapper style={style}>
        <ScrollView
          ref={scrollViewRef}
          horizontal={true}
          pagingEnabled={true}
          showsHorizontalScrollIndicator={false}
          style={{
            width: swiperItemLayout?.width ? swiperItemLayout.width : undefined,
          }}
          onLayout={event => {
            const itemWidth = event.nativeEvent.layout.width

            widthRef.current = itemWidth
            setSwiperItemLayout({
              ...swiperItemLayout,
              width: itemWidth,
            })

            scrollToIndex(activeItemIndex, itemWidth)
          }}
          // Native 监听 ScrollView 结束滚动事件
          onMomentumScrollEnd={handleMomentumScrollEnd}
        >
          {React.Children.map(children, child => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                ...child.props,
                style: {
                  ...child.props.style,
                  width: swiperItemLayout?.width,
                },
                onItemLayout,
              })
            }

            return child
          })}
        </ScrollView>
        {itemCount > 1 && showIndicators ? (
          <Indicators>
            {[...Array(itemCount)].map((item, itemIdx) => {
              return (
                <Indicator key={itemIdx} active={itemIdx === activeItemIndex} />
              )
            })}
          </Indicators>
        ) : null}
      </Wrapper>
    )
  },
)

const Wrapper = styled(View)``

const Indicators = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  flex-shrink: 0;
`

const Indicator = styled(View)<{ active: boolean }>`
  width: ${p => (p.active ? 14 : 6)}px;
  height: 6px;
  background-color: ${p => (p.active ? p.theme.brand._1 : '#d3d5db')};
  border-radius: 20px;
  margin-horizontal: 3px;
`

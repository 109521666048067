/**
 * @file 网盘 hooks
 */

import { uniqBy } from '@seiue/util'
import { useMemo } from 'react'

import {
  PluginEnum,
  useFindEnabledParentPlugin,
} from 'packages/feature-utils/plugins'
import { useCurrentReflection } from 'packages/features/sessions'
import { SHARED_NET_DISK } from 'packages/plugins/features/net-disk/const'
import { NetDiskUploadLimit } from 'packages/plugins/features/net-disk/types'
import { NetDiskRange, PluginNameEnum } from 'packages/plugins/types'
import {
  GetPersonalNetdiskOwnersQuery,
  netdiskApi$getPersonalNetdiskOwners,
  netdiskApi$queryNetdiskOwners,
  netdiskApi$queryPersonalNetdiskPermissions,
  NetdiskOwner,
  NetdiskPermissionPermissionsEnum,
  UploadLimitSetting,
} from 'packages/sdks-next/chalk'

/**
 * 获取网盘的 settings 字段，用以判断是否需要展示对应 tab 页
 *
 * @returns 网盘的 settings 字段
 */
export const useNetDiskPluginSetting = () => {
  const plugin = useFindEnabledParentPlugin(PluginEnum.NetDisk)
  if (!plugin) {
    return [undefined, false] as const
  }

  return [plugin.settings as NetDiskRange, false] as const
}

/**
 * 查询所有网盘
 * 网盘管理员在 getPersonalNetdiskOwners 时，没有共享网盘类型的 owners 返回
 * 所以在这里需要再次查询共享网盘类型的 owners
 *
 * @param props - 参数
 * @param props.query - 查询条件
 * @param props.isInSharedType - 是否是共享网盘类型
 * @param props.isInAdmin - 是否是在管理端
 * @returns 所有网盘
 */
export const useQueryAllOwners = ({
  query,
  isInSharedType = true,
  isInAdmin = false,
}: {
  query?: GetPersonalNetdiskOwnersQuery
  isInSharedType?: boolean
  isInAdmin?: boolean
}) => {
  const { id: crid } = useCurrentReflection()

  const { data, loading, reload } = netdiskApi$getPersonalNetdiskOwners.useApi({
    reflectionId: crid,
    query: {
      ...query,
      expand: ['ownerName', 'ownerManagers'],
      tryExpand: ['topFile'],
      paginated: 0,
    },
  })

  const {
    data: sharedOwners,
    loading: loadingSharedOwners,
    reload: reloadSharedOwners,
  } = netdiskApi$queryNetdiskOwners.useApi(
    {
      ...query,
      type: SHARED_NET_DISK,
      tryExpand: ['topFile'],
      paginated: 0,
    },
    {
      disable: !isInAdmin || !isInSharedType,
    },
  )

  const finalData = useMemo(() => {
    return uniqBy(
      [...(data || []), ...(!isInAdmin ? [] : sharedOwners || [])],
      'id',
    )
  }, [data, isInAdmin, sharedOwners])

  return {
    data: finalData,
    loading: loadingSharedOwners || loading,
    reload: () => {
      reloadSharedOwners()
      reload()
    },
  }
}

/**
 * 获取当前用户对文件的权限
 *
 * @param props - 参数
 * @param props.fileId - 文件 id
 * @param props.isAdmin - 是否在管理端
 * @returns 当前用户对文件的权限
 */
export const useFilePermissionFlag = ({
  fileId,
  isAdmin = false,
}: {
  fileId?: number
  isAdmin?: boolean
}) => {
  const me = useCurrentReflection()

  const {
    data: selfPermissions,
    loading,
    reload,
  } = netdiskApi$queryPersonalNetdiskPermissions.useApi(
    {
      ownerId: me.id,
      fileIds: `${fileId}`,
    },
    {
      disable: !fileId,
    },
  )

  const permissionFlag = isAdmin
    ? NetdiskPermissionPermissionsEnum.Manage
    : selfPermissions?.find(item => item.fileId === fileId)?.permissionFlag ||
      NetdiskPermissionPermissionsEnum.Read

  return {
    data: permissionFlag,
    loading,
    reload,
  }
}

/**
 * 判断是否可以管理某文件
 *
 * @param props - 参数
 * @param props.fileId - 文件 id
 * @param props.isAdmin - 是否在管理端
 * @returns 是否可以管理某文件
 */
export const useCanManageFile = ({
  fileId,
  isAdmin = false,
}: {
  fileId?: number
  isAdmin?: boolean
}) => {
  const {
    data: permissionFlag,
    loading,
    reload,
  } = useFilePermissionFlag({
    fileId,
    isAdmin,
  })

  return {
    data: permissionFlag === NetdiskPermissionPermissionsEnum.Manage,
    loading,
    reload,
  }
}

/**
 * 将文件扩展名字符串按逗号分割成数组
 *
 * @param allowedExtensions - 允许的扩展名字符串，如 "jpg,png，jpeg"
 * @returns 处理后的扩展名数组，如 [".jpg", ".png", ".jpeg"]
 */
const splitAllowedExtensions = (allowedExtensions?: string) => {
  if (!allowedExtensions) return undefined

  return allowedExtensions.split(/[,，]/).map(item => `.${item.trim()}`)
}

/**
 * 获取网盘接受的文件扩展名
 *
 * @param diskType - 网盘类型, 可以是 GroupType 或者 role
 * @returns 网盘接受的文件扩展名
 */
export const useNetdiskAllowedExtensions = (diskType?: string) => {
  const netDiskPlugin = useFindEnabledParentPlugin(PluginNameEnum.NetDisk)

  const settings = netDiskPlugin?.settings as {
    uploadLimitEnum: NetDiskUploadLimit
    uploadLimitSettings: UploadLimitSetting[]
  } | null

  const { uploadLimitEnum, uploadLimitSettings } = settings || {}

  if (diskType && uploadLimitEnum === NetDiskUploadLimit.Part) {
    const uploadLimitSetting = uploadLimitSettings?.find(
      item => item.scope === diskType,
    )

    return splitAllowedExtensions(uploadLimitSetting?.allowed)
  }

  return undefined
}

/**
 * 获取共享网盘接受的文件扩展名
 *
 * @param netdiskOwner - 网盘所有者
 * @returns 网盘接受的文件扩展名
 */
export const useSharedNetdiskAllowedExtensions = (
  netdiskOwner?: NetdiskOwner | null,
) => {
  const { uploadLimit, uploadLimitSetting } = netdiskOwner?.settings || {}

  if (uploadLimit === NetDiskUploadLimit.Part) {
    return splitAllowedExtensions(uploadLimitSetting?.allowed)
  }

  return undefined
}

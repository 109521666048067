import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'

import { useFindUsablePlugins } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'

import { useMainNavigation } from '@go/router/hooks'

import { ShortcutItem } from './Item'
import {
  ShortcutGroup,
  ShortcutGroupTitle,
  ShortcutPlaceHolder,
} from './Styled'

/**
 * 快速发起
 *
 * @param props - props
 * @param props.onDismiss - 关闭弹窗
 * @returns JSX.Element
 */
export const QuickStart: React.FC<{
  onDismiss: () => void
}> = ({ onDismiss }) => {
  const [teacherAttendancePlugin] = useFindUsablePlugins(
    PluginNameEnum.TeacherAttendance,
  )

  const navigation = useMainNavigation()

  if (!teacherAttendancePlugin) return null

  const [, color] = teacherAttendancePlugin.icon.split('+')

  return (
    <QuickStartGroup style={{ marginTop: 32 }}>
      <ShortcutGroupTitle>{$t('快速发起')}</ShortcutGroupTitle>
      <QuickStartRow>
        <ShortcutItem
          title={$t('考勤打卡')}
          icon={teacherAttendancePlugin.icon}
          iconBackground={`#${color}`}
          itemIndex={0}
          onPress={() => {
            onDismiss()
            navigation.navigate('Plugin.TeacherAttendance.Entry')
          }}
        />
        <ShortcutPlaceHolder />
      </QuickStartRow>
    </QuickStartGroup>
  )
}

const QuickStartGroup = styled(ShortcutGroup)`
  margin-top: 32px;
`

const QuickStartRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

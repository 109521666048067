/**
 * @file 注册审批渲染器
 */
import React from 'react'

import { RegisterWorkflowRenderType } from 'packages/features/workflows/register'

export const workflowRenders: RegisterWorkflowRenderType = {
  // 退课审批，后续可能会将 bizType 扩展为数组，因为一个领域下可能会有不同的审批流业务
  bizType: 'class_members',
  render: {
    FlowCard: React.lazy(() =>
      import('./FlowCard').then(m => ({ default: m.LeaveClassFlowCard })),
    ),
    NewFlowModal: React.lazy(() =>
      import('./NewFlowModal').then(m => ({ default: m.NewLeaveClassModal })),
    ),
    ShowFlowModal: React.lazy(() =>
      import('./ShowFlowModal').then(m => ({
        default: m.ShowLeaveClassFlowModal,
      })),
    ),
  },
}

import { InitFeatureParams } from '../types'

import { routes } from './routes'

/**
 * 初始化插件
 *
 * @param param0 - 参数
 */
export const initPlugins = (param0: InitFeatureParams) => {
  const { addRoutes } = param0
  addRoutes(routes)
}

import { lazy } from 'react'

import { $t } from 'packages/locale/LocaleProvider'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'grade/classes',
    name: 'GradeClasses',
    getTitle: () => $t('成绩班级列表'),
    getHeaderTitle: () => $t('成绩'),
    Component: lazy(() =>
      import('./screens/ClassGradeList').then(m => ({
        default: m.ClassGradeList,
      })),
    ),
  },
  {
    name: 'GradeClassesStudentAssessmentsSelf',
    Component: lazy(() =>
      import('./screens/ClassGradeStudentAssessmentSelf').then(m => ({
        default: m.GradeClassesStudentAssessmentsSelf,
      })),
    ),
  },
]

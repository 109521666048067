/**
 * @file 认证积分
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@go/plugins/types'

import { studentRoutes, teacherRoutes } from './routes'

export const certificationCredit: InitPluginFeature = {
  name: PluginNameEnum.CertificationCredit,
  register: () => {
    return {
      routes: [...studentRoutes, ...teacherRoutes],
    }
  },
}

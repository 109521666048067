/**
 * @file 通知通用 Hooks
 */

import { keyBy } from '@seiue/util'
import { useMemo } from 'react'

import {
  Message,
  Notification,
  GetReceivedNotificationsQuery,
  notificationApi$getReceivedNotifications,
} from 'packages/sdks-next/chalk'

import { convertMessageToNotification } from '../utils'

export * from './use-important-notice-modal-settings'

/**
 * 通过留言转换为通知
 *
 * @param message - 留言
 * @returns 通知
 */
export const useNoticeByMessage = (message?: Message | null) =>
  useMemo(() => {
    if (!message) return null

    return convertMessageToNotification(message)
  }, [message])

/**
 * 通过通知获取读取状态列表
 *
 * @param props - 组件属性
 * @param props.noticeId - 通知 ID
 * @param props.notice - 通知
 * @param props.query - 查询条件
 * @returns 读取状态列表
 */
export const useReadStatusListByNotice = ({
  noticeId,
  notice,
  query,
}: {
  noticeId: string
  notice: Notification | null
  query?: GetReceivedNotificationsQuery
}) => {
  const { data: receivedNotices, loading } =
    notificationApi$getReceivedNotifications.useApi({
      idIn: noticeId,
      query: {
        actingAsSender: true,
        // @ts-expect-error expand is undefined
        expand: 'receiver,receiver.user,receiver.pupil',
        ...query,
      },
    })

  const readStatuses = useMemo(() => {
    if (!notice || !receivedNotices) return []
    const receiverMap = keyBy(notice.receivers, 'id')

    const _readStatuses = receivedNotices[0]?.readStatuses || []

    // 因为是发件人，用 receiver 里的 scopes 填充 readStatus 里的 scopes
    return _readStatuses
      .filter(v => v.receiver?.id)
      .map(v => ({
        ...v,
        scopes: receiverMap[v.receiver?.id || '']?.scopes || [],
      }))
  }, [receivedNotices, notice])

  return [readStatuses, loading] as const
}

/**
 * 通过留言获取读取状态列表
 *
 * @param message - 留言
 * @returns 读取状态列表
 */
export const useReadStatusListByMessage = (message?: Message | null) => {
  const { data: ccNoticeList, loading } =
    notificationApi$getReceivedNotifications.useApi(
      {
        idIn: message?.conversationId || '',
        query: {
          // @ts-expect-error expand is undefined
          expand: ['receiver'],
        },
      },
      {
        disable: !message?.isCc || !message?.conversationId,
      },
    )

  const { content: template, readStatuses } = ccNoticeList?.[0] || {}

  return {
    template,
    readStatuses: readStatuses || [],
    loading,
  }
}

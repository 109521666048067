import { getStudentTaskNavigation } from 'packages/feature-utils/tasks'
import { TaskMessageAttributes } from 'packages/features/tasks/types/message'
import { EnhancerEnum, TaskEnhancerEnum } from 'packages/sdks-next/chalk'

import { InitFeatureParams } from '../types'

import { calendarEvents } from './calendar-events'
import { routes } from './routes'
import { todos } from './todos'

type TaskDiscussionMsgAttributes = {
  discussionId: number
  /**
   * 任务 id
   */
  id: number
  messageId: number
  parentMessageId: number
  receivedByManager: boolean
  senderId: number
  topicId: number
}

const getStudentChalkNavigation = (attributes: TaskMessageAttributes) => {
  const { id, type, parentTaskId, relatedHandoutId, questionnaireId } =
    attributes

  if (
    !type ||
    (type === EnhancerEnum.ClassQuestionnaireTask && !questionnaireId) ||
    (type === EnhancerEnum.ClassQuizTask && !relatedHandoutId)
  ) {
    // 之前的消息，没有带上 type，或者测验/问卷任务没有相关的 id，则不跳转
    return null
  }

  const [modalName, options] = getStudentTaskNavigation({
    task: {
      id: id ?? 0,
      labels: { type: type as EnhancerEnum },
      parentTaskId,
      customFields: { questionnaireId, relatedHandoutId },
    },
  })

  return {
    modalName,
    options,
  }
}

/**
 * 初始化任务
 *
 * @param param0 - 参数
 */
export const initTasks = (param0: InitFeatureParams) => {
  const {
    addRoutes,
    registerTodos,
    registerCalendarEvents,
    registerMessageNavigator,
  } = param0

  addRoutes(routes)
  registerTodos(todos)
  registerCalendarEvents(calendarEvents)

  registerMessageNavigator(['task.discussion_replied'], msg => {
    const msgAttributes = msg.attributes as TaskDiscussionMsgAttributes
    const isTeacher = msgAttributes.receivedByManager

    return [
      'TaskPrivateDiscussion',
      {
        taskId: msgAttributes.id,
        isTeacher,
        teacherId: isTeacher ? msg.owner?.id : msgAttributes.senderId,
        assigneeId: isTeacher ? msgAttributes.senderId : msg.owner?.id,
        discussionId: msgAttributes.discussionId,
        messageId: msgAttributes.messageId,
      },
    ] as const
  })

  registerMessageNavigator(
    ['task.task_updated', 'task.submission_notified'],
    msg => {
      const { id, type } = msg.attributes as TaskMessageAttributes

      // 作业任务消息跳转
      if (type === TaskEnhancerEnum.ClassHomeworkTask) {
        return ['StudentTaskView', { id }]
      }

      return getStudentChalkNavigation(msg.attributes as TaskMessageAttributes)
    },
  )

  registerMessageNavigator(['task.approved', 'task.mark_excellent'], msg => {
    const { id, type } = msg.attributes as TaskMessageAttributes

    // 作业任务消息跳转移动端已有页面
    if (type === TaskEnhancerEnum.ClassHomeworkTask) {
      return [
        'StudentSubmissionView',
        { taskId: id, assigneeId: msg.owner?.id },
      ]
    }

    return getStudentChalkNavigation(msg.attributes as TaskMessageAttributes)
  })

  registerMessageNavigator(
    [
      'task.deleted',
      'task.canceled',
      'task.discussion_topic_archived',
      'task.discussion_comment_archived',
      'task.discussion_reply_archived',
    ],
    () => null,
  )
}

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    name: 'Plugin.Dorm',
    getTitle: () => $t('宿舍'),
    getHeaderTitle: () => $t('宿舍'),
    Component: lazy(() =>
      import('./screens/DormEntry').then(m => ({
        default: m.DormEntry,
      })),
    ),
  },
  {
    name: 'Plugin.Dorm.AssessmentSearch',
    getTitle: () => $t('搜索宿舍评价项'),
    Component: lazy(() =>
      import('./screens/DormAssessmentSearch').then(m => ({
        default: m.DormAssessmentSearch,
      })),
    ),
  },
  {
    name: 'Plugin.Dorm.AssessmentStructure',
    Component: lazy(() =>
      import('./screens/DormAssessmentStructure').then(m => ({
        default: m.DormAssessmentStructure,
      })),
    ),
  },
  {
    name: 'Plugin.Dorm.AssessmentItemInput',
    getTitle: () => $t('评价项录入'),
    Component: lazy(() =>
      import('./screens/DormAssessmentItemInput').then(m => ({
        default: m.DormAssessmentItemInput,
      })),
    ),
  },
]

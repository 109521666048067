import React from 'react'
import styled from 'styled-components'

/**
 * 模糊背景 for web
 *
 * @returns component
 */
export const BlurView: React.FC<any> = () => {
  return <Wrapper />
}

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(80px);
`

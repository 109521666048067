import { userAgent } from '@seiue/ua'
import { useEffect } from 'react'

import { CounterSubjectEnum } from 'packages/features/counter'
import {
  useImportantNoticeModalSettings,
  useLoadImportantNotice,
} from 'packages/features/notices/hooks'
import { useIsApolloLogin } from 'packages/features/sessions/hooks'

import { RootStackNavigationProp } from '@go/router'

/**
 * Go 检测重要通知
 *
 * @param navigation - navigation
 */
export const useCheckImportantNotice = (
  navigation: RootStackNavigationProp<'Main'>,
) => {
  const { isDone, loading, currentConversationId, skip } =
    useImportantNoticeModalSettings()

  const { notice, loading: loadingNotice } = useLoadImportantNotice(
    currentConversationId,
  )

  const isApolloLogin = useIsApolloLogin()

  const openImportantNotice =
    !isDone &&
    !loading &&
    !loadingNotice &&
    !isApolloLogin &&
    !userAgent.isC3AppWebView

  useEffect(() => {
    if (notice?.receipted) {
      skip(CounterSubjectEnum.ImportantNotifications, currentConversationId)

      return
    }

    if (openImportantNotice) {
      navigation.replace('ImportantNoticesModal')
    }
  }, [
    openImportantNotice,
    navigation,
    notice?.receipted,
    skip,
    currentConversationId,
  ])
}

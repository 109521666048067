import { lazy } from 'react'

import { $t } from 'packages/locale'

import { Route } from '@go/router'

export const routes: Route[] = [
  {
    path: 'todos',
    name: 'Todos',
    getTitle: () => $t('我的待办'),
    Component: lazy(() =>
      import('./pages/Todos').then(m => ({ default: m.Todos })),
    ),
  },
]

import { Text } from '@seiue/rn-ui'
import { getPaletteColorByIndex } from '@seiue/theme'
import React from 'react'
import { TouchableHighlight, View } from 'react-native'
import styled from 'styled-components/native'

import { LogoSize } from 'packages/components/Logo/utils'

import { LogoRender } from '@go/components/Logo/Render'

/**
 * 快速创建小卡片
 *
 * @param props - props
 * @param props.itemIndex - 下标
 * @param props.title - 卡片标题
 * @param props.description - 卡片描述
 * @param props.icon - 图标
 * @param props.iconBackground - 图标背景颜色
 * @param props.onPress - 点击事件
 * @returns component
 */
export const ShortcutItem: React.FC<{
  itemIndex: number
  title: string
  description?: string
  icon?: string
  iconBackground?: string
  onPress?: () => void
}> = ({
  itemIndex,
  title,
  description,
  icon = 'star+D45586',
  iconBackground,
  onPress,
}) => {
  return (
    <Wrapper underlayColor="#E4E6EB" onPress={onPress}>
      <>
        <LogoRender
          size={LogoSize.XXS}
          logo={icon}
          iconSize={14}
          style={{
            flexShrink: 0,
            backgroundColor:
              iconBackground || getPaletteColorByIndex(itemIndex).background,
          }}
        />
        <Content>
          <Title numberOfLines={1}>{title}</Title>
          {!description ? null : (
            <Description numberOfLines={1}>{description}</Description>
          )}
        </Content>
      </>
    </Wrapper>
  )
}

const Wrapper = styled(TouchableHighlight)`
  border-radius: 8px;
  background-color: ${p => p.theme.background._3};
  padding: 0 16px;
  height: 66px;
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const Content = styled(View)`
  flex-shrink: 1;
  padding-left: 10px;
`

const Title = styled(Text)`
  font-size: 15px;
  color: ${p => p.theme.text._1};
`

const Description = styled(Text)`
  font-size: 12px;
  line-height: 18px;
  color: ${p => p.theme.text._3};
  margin-top: 3px;
`

/**
 * @file 学业目标管理
 */

import { InitPluginFeature } from '@go/plugins/types'

import { todos } from './todos'

export const academicGoals: InitPluginFeature = {
  name: 'academic-goal',
  todos,
}

import { ConfigProvider as ConfigProviderNative } from '@seiue/rn-ui'
import { ConfigProvider as ConfigProviderDesktop } from '@seiue/ui'
import React, { FC } from 'react'

import { useThemeColors } from 'packages/features/system-settings/utils/data'
import { useLocale } from 'packages/locale'

/**
 * ConfigProvider
 * 目前 go 还在使用一些 @seiue/ui 组件, 所以需要它的 ConfigProvider
 *
 * @param root0 - 参数
 * @param root0.children - 子组件
 * @returns Provider
 */
export const ConfigProvider: FC = ({ children }) => {
  const locale = useLocale()
  const { themeColor } = useThemeColors()

  return (
    <ConfigProviderDesktop locale={locale} themeColor={themeColor}>
      <ConfigProviderNative locale={locale} themeColor={themeColor}>
        {children}
      </ConfigProviderNative>
    </ConfigProviderDesktop>
  )
}

import { InitPluginFeature } from '@go/plugins/types'

import { calendarEvents } from './calendar-events'
import { getEventMenu } from './menu-slot'
import { routes } from './routes'
import { todos, workflowRenders } from './todos'

export const event: InitPluginFeature = {
  name: 'event',
  todos,
  calendarEvents,
  workflowRenders,
  register: () => ({
    routes,
    slots: [
      {
        name: 'asyncAppMenuItems',
        slot: getEventMenu,
      },
    ],
  }),
}

/**
 * @file 注册审批渲染器
 */
import React from 'react'

import { RegisterWorkflowRenderType } from 'packages/features/workflows/register'

export const workflowRenders: RegisterWorkflowRenderType = {
  bizType: 'visitor',
  render: {
    FlowCard: React.lazy(() =>
      import('./FlowCard').then(m => ({ default: m.VisitorFlowCard })),
    ),
    NewFlowModal: React.lazy(() =>
      import('./NewFlowModal').then(m => ({ default: m.NewVisitorModal })),
    ),
    ShowFlowModal: React.lazy(() =>
      import('./ShowFlowModal').then(m => ({
        default: m.ShowVisitorModal,
      })),
    ),
  },
}

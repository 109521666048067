import React from 'react'
import { LayoutChangeEvent, StyleProp, View, ViewStyle } from 'react-native'

/**
 * 走马灯的轮播区域项
 *
 * @param props - props
 * @param props.onItemLayout - 组件挂载完成后调用
 * @param props.style - 样式
 * @param props.children - 子元素
 * @returns component
 */
export const SwiperItem: React.FC<{
  onItemLayout?: (event: LayoutChangeEvent) => void
  style?: StyleProp<ViewStyle>
}> = ({ onItemLayout, children, style }) => {
  return (
    <View style={style} onLayout={onItemLayout}>
      {children}
    </View>
  )
}

import { BorderColor } from './variables/common'
import {
  TextFontSize,
  TitleFontSize,
  Color,
  BorderWidth,
} from './variables/native'

export * from './variables/native'

const getTitleStyle = (size: number) => `
    font-size: ${size};
    font-weight: bold;
    line-height: ${1.5 * size};
    color: ${Color.Black};
  `

const getTextStyle = (size: number) => `
    font-size: ${size};
    line-height: ${1.7 * size};
    color: ${Color.Black};
  `

export const XLargeTitle = getTitleStyle(TitleFontSize.XLarge)
export const LargeTitle = getTitleStyle(TitleFontSize.Large)
export const Title = getTitleStyle(TitleFontSize.Regular)
export const SmallTitle = getTitleStyle(TitleFontSize.Small)

export const Article = getTextStyle(TextFontSize.Article)
export const Text = getTextStyle(TextFontSize.Regular)
export const Comment = `
  ${getTextStyle(TextFontSize.Comment)}

  color: ${Color.DeepGray};
`
export const Label = `
  ${getTextStyle(TextFontSize.Label)}

  color: ${Color.DeepGray};
  font-weight: bold;
`
export const Tag = `
  ${getTextStyle(TextFontSize.Tag)}

  color: ${Color.DeepGray};
`

export const TopBorder = `
  border-top-color: ${BorderColor.Regular};
  border-top-width: ${BorderWidth.Regular};
`

export const BottomBorder = `
  border-bottom-color: ${BorderColor.Regular};
  border-bottom-width: ${BorderWidth.Regular};
`

/**
 * @file 德育评价
 */

import { NativeNavigatorRes } from 'packages/features/messages/register'
import { PluginNameEnum } from 'packages/plugins/types'
import { RoleEnum } from 'packages/sdks-next/chalk'

import { CenterTabKeyEnum } from '@go/plugins/features/moral-assessments/types'
import { InitPluginFeature } from '@go/plugins/types'

import { routes } from './routes'

export const moralAssessment: InitPluginFeature = {
  name: PluginNameEnum.MoralAssessment,
  register: () => ({
    routes,
  }),
  messageNavigators: [
    {
      type: [
        'moral_assessment.assessment_published_for_evaluated', // 评价已结算
        'moral_assessment.assessment_revoked_for_evaluated', // 评价撤销结算
      ],
      navigator: ({ attributes }) => {
        const attrs = attributes as {
          assessmentId: number
        }

        return [
          'Plugin.MoralAssessment.AnalysisStructures',
          {
            id: attrs.assessmentId,
          },
        ]
      },
    },
    {
      // 未评价提醒
      type: 'moral_assessment.assessment_incomplete_for_evaluator',
      navigator: ({ attributes }, currentReflection) => {
        const attrs = attributes as {
          assessmentId: number
        }

        let params: NativeNavigatorRes
        if (
          currentReflection.role === RoleEnum.Student ||
          currentReflection.role === RoleEnum.Guardian
        ) {
          params = [
            'Plugin.SingleEvaluateTargetGradeEntry',
            {
              id: attrs.assessmentId,
            },
          ]
        } else {
          params = [
            'Plugin.MoralAssessmentEntryStructures',
            {
              id: attrs.assessmentId,
              from: CenterTabKeyEnum.Evaluated,
            },
          ]
        }

        return params
      },
    },
    // 评价项发布、修改提醒
    {
      type: [
        'moral_assessment.score_for_evaluated', // 分数评价项
        'moral_assessment.raw_score_addition_for_evaluated', // 加减分评价项
        'moral_assessment.raw_score_tag_for_evaluated', // 标签评价项
        'moral_assessment.raw_score_special_for_evaluated', // 个性化评价项
      ],
      navigator: ({ attributes, owner }) => {
        const attrs = attributes as {
          assessmentId: number
          itemId: number
        }

        return [
          'Plugin.MoralAssessment.Analysis',
          {
            id: attrs.assessmentId,
            rid: owner?.id,
            itemId: attrs.itemId,
          },
        ]
      },
    },
    // 评价项发布、修改提醒
    {
      type: [
        'moral_assessment.score_for_evaluated_to_mentor', // 分数评价项
        'moral_assessment.raw_score_addition_for_evaluated_to_mentor', // 加减分评价项
        'moral_assessment.raw_score_tag_for_evaluated_to_mentor', // 标签评价项
        'moral_assessment.raw_score_special_for_evaluated_to_mentor', // 个性化评价项
      ],
      navigator: ({ attributes }) => {
        const attrs = attributes as {
          assessmentId: number
          itemId: number
          studentId: number
          studentName: string
        }

        return [
          'Plugin.MoralAssessment.Analysis',
          {
            id: attrs.assessmentId,
            rid: attrs.studentId,
            itemId: attrs.itemId,
            showName: attrs.studentName,
          },
        ]
      },
    },
  ],
}

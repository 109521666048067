import { Modal, Toast } from '@seiue/rn-ui'
import { useOnMount } from '@seiue/util'

import { $t } from 'packages/locale'

import { Dispatch, useDispatch } from '@go/stores'
import {
  ErrorUI,
  initGlobalErrorHandlers,
  ShowErrorUI,
} from '@go/utils/error-handler'
import { navigate } from '@go/utils/navigator'

/**
 * 显示错误信息对应的 UI 组件
 *
 * @param dispatch - store dispatch
 * @returns UI
 */
export const getShowErrorUI =
  (dispatch: Dispatch): ShowErrorUI =>
  meta => {
    if (meta.action === ErrorUI.Report) {
      return Modal.alert({
        title: meta.title,
        message: meta.content.join('\n'),
      })
    }

    if (meta.action === ErrorUI.Alert) {
      return Modal.alert({
        title: meta.title,
        message: meta.content,
        okText: $t('我知道了'),
        onOk: meta.onConfirm,
      })
    }

    if (meta.action === ErrorUI.AlertThenLogout) {
      return Modal.alert({
        title: meta.title,
        message: meta.content,
        okText: $t('好的'),
        onOk: () => dispatch.session.destroy(),
      })
    }

    if (meta.action === ErrorUI.Toast) {
      return Toast.show(meta.title)
    }

    return null
  }

/**
 * 注册全局错误处理
 */
export const useRegisterErrorHandlers = () => {
  const dispatch = useDispatch()

  const showErrorUI = getShowErrorUI(dispatch)

  useOnMount(() => {
    initGlobalErrorHandlers(showErrorUI, error => {
      /**
       * 目前 react-navigation 在 web 上有一个已知问题是,
       * 当用户刷新一个子页面后用浏览器后退按钮往一级页面退时,
       * 会无法后退且报如下的错误.
       * 在此 (用并不健壮的方法) 捕捉后回退到首页, 以免用户卡在子页面.
       *
       * react-navigation@6.0.0-next-* 版本也有该问题,
       * 可能与一级页面是 tab screens 有关.
       */
      if (
        error?.message?.includes("Cannot read property 'routes' of undefined")
      ) {
        navigate('Tabs')
        return {
          action: ErrorUI.None,
          report: false,
        }
      }

      return null
    })
  })
}

export interface FilePolicyInterface {
  /**
   * 如果是 MinIO 本地部署，则 key 为 X-Amz-Credential
   */
  accessKeyId: string
  /**
   * 如果是 MinIO 本地部署，则 key 为 X-Amz-Date
   */
  expire: string
  /**
   * 本地部署时有值，key 为 success_action_redirect
   */
  callbackUrl?: string
  callback?: string
  /**
   * 目标上传文件地址
   */
  host: string
  /**
   * 文件对象的 key 值
   */
  objectKey: string
  /**
   * OSS 驱动时为 policy 。如果是 MinIO 本地部署，则 key 为 Policy
   */
  policy: string
  /**
   * OSS 驱动时为 signature 。如果是 MinIO 本地部署，则 key 为 X-Amz-Signature
   */
  signature: string
}

export class FilePolicy implements FilePolicyInterface {
  /**
   * 如果是 MinIO 本地部署，则 key 为 X-Amz-Credential
   */
  accessKeyId: string
  /**
   * 如果是 MinIO 本地部署，则 key 为 X-Amz-Date
   */
  expire: string
  /**
   * 本地部署时有值，key 为 success_action_redirect
   */
  callbackUrl?: string
  callback?: string
  /**
   * 目标上传文件地址
   */
  host: string
  /**
   * 文件对象的 key 值
   */
  objectKey: string
  /**
   * OSS 驱动时为 policy 。如果是 MinIO 本地部署，则 key 为 Policy
   */
  policy: string
  /**
   * OSS 驱动时为 signature 。如果是 MinIO 本地部署，则 key 为 X-Amz-Signature
   */
  signature: string

  constructor(props: FilePolicyInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      accessKeyId: _accessKeyId,
      expire: _expire,
      callbackUrl: _callbackUrl,
      callback: _callback,
      host: _host,
      objectKey: _objectKey,
      policy: _policy,
      signature: _signature,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.accessKeyId = _accessKeyId
    this.expire = _expire
    this.callbackUrl = _callbackUrl
    this.callback = _callback
    this.host = _host
    this.objectKey = _objectKey
    this.policy = _policy
    this.signature = _signature
  }

  static propKeys = [
    'accessKeyId',
    'expire',
    'callbackUrl',
    'callback',
    'host',
    'objectKey',
    'policy',
    'signature',
  ]
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { NetdiskFileReq, NetdiskFileReqInterface } from './netdisk-file-req'
import { NetdiskFile, NetdiskFileInterface } from './netdisk-file'
import type { CreateFileQuery } from './create-file-query'

const buildConfig = createConfigBuilder('chalk')

export const netdiskApi$createFile = {
  config(
    netdiskFileReq: NetdiskFileReqInterface,
    createFileQuery?: CreateFileQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'post',
      '/netdisk/files',
      {},
      createFileQuery,
      netdiskFileReq,
      {
        format: 'json',
        isArray: false,
        defaults: {
          parentId: (v: any) => v || 0,
          isDir: (v: any) => v || false,
          path: (v: any) => v || '',
          type: (v: any) => v || null,
          mime: (v: any) => v || '',
          size: (v: any) => v || 0,
          hash: (v: any) => v || '',
          status: (v: any) => v || null,
        },
      },
    )
  },

  /**
   * @summary 创建文件 meta 信息
   * @param netdiskFileReq
   * @param [createFileQuery]
   * @param [netdiskQuery.bizSigHash] 业务签名 hash（短时效，可覆盖并手动控制，场景：移动端扫码上传图片）
   * @param [netdiskQuery.bizSig] 业务签名（长失效，有效期前不可手动控制，场景：匿名上传文件，解析行程码）
   * @param [options]
   * @return AxiosResponsePromise<NetdiskFile>
   */

  api(
    netdiskFileReq: NetdiskFileReqInterface,
    createFileQuery?: CreateFileQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<NetdiskFile> {
    return axios(
      netdiskApi$createFile.config(netdiskFileReq, createFileQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new NetdiskFile(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi(mutationOptions?: MutationOptions) {
    return useMutationApi(
      ({
        payload,
        query,
      }: {
        payload: NetdiskFileReqInterface
        query?: CreateFileQuery
      }) => netdiskApi$createFile.api(payload, query),
      mutationOptions,
    )
  },
}

// @ts-ignore
netdiskApi$createFile.api._name_ = `Netdisk.createFile`

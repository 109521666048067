/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'

const buildConfig = createConfigBuilder('chalk')

export const newcapecApi$getSsoUrl = {
  config(options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(options, 'get', '/newcapec', {})
  },

  /**
   * @summary Class NewcapecApi
   * @param [options]
   * @return AxiosResponsePromise<string>
   */

  api(options?: AxiosRequestExtraConfig): AxiosResponsePromise<string> {
    return axios(newcapecApi$getSsoUrl.config(options)) as any
  },

  useApi<TSelected = string>(
    queryOptions?: QueryOptionsWithSelect<string, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<string, TSelected>(
      newcapecApi$getSsoUrl.api,
      'Newcapec.getSsoUrl',
      queryOptions,
    )
  },
}

// @ts-ignore
newcapecApi$getSsoUrl.api._name_ = `Newcapec.getSsoUrl`

import { seiueFiles, getCompressImageUrl, ZoomMode } from '@seiue/file'
import React from 'react'

import { SeIconProp } from 'packages/components/Icon'

import { images } from './config'

export interface LogoRenderProps {
  className?: string
  style?: React.CSSProperties
  cursor?: React.CSSProperties['cursor']
  /**
   * Logo 大小
   */
  size?: LogoSize
  /**
   * Logo 值，可以是资源的 hash 值，也可以是图标名称
   */
  logo?: string
  /**
   * 图标列表
   */
  icons?: LogoIcon[]
  /**
   * 颜色列表
   */
  colors?: LogoColor[]
  /**
   * 图标类型，图标/图片
   */
  iconType?: IconType
  /**
   * Logo 上传类型，图标库选择/自定义上传，默认会根据 `logo` 值判断
   */
  logoType?: LogoType
  /**
   * 是否使用圆形图标，默认 false
   */
  useRoundLogo?: boolean
}

export interface LogoIcon {
  icon:
    | string
    | SeIconProp
    | React.FC<{
        size?: string | number
        color?: string
      }>
  name: string
}

export type LogoImage =
  | {
      image: string
      name: string
    }
  | string

export interface LogoColor {
  color: string
  name: string
}

export const LOGO_SPLIT = '+'

export enum LogoSize {
  XXXS = '24px',
  XXS = '28px',
  XS = '32px',
  SS = '40px',
  S = '48px',
  M = '64px',
  L = '160px',
}

/**
 * 获取 Logo 容器大小
 *
 * @param size - 大小
 * @returns 大小
 */
export const sizeToPx = (size: LogoSize): number =>
  ({
    [LogoSize.XXXS]: 24,
    [LogoSize.XXS]: 28,
    [LogoSize.XS]: 32,
    [LogoSize.SS]: 40,
    [LogoSize.S]: 48,
    [LogoSize.M]: 64,
    [LogoSize.L]: 160,
  }[size])

/**
 * 获取 Logo 大小
 *
 * @param size - 大小
 * @returns 大小
 */
export const sizeToIconSize = (size: LogoSize): number =>
  ({
    [LogoSize.XXXS]: 12,
    [LogoSize.XXS]: 12,
    [LogoSize.XS]: 20,
    [LogoSize.SS]: 20,
    [LogoSize.S]: 26,
    [LogoSize.M]: 35,
    [LogoSize.L]: 87,
  }[size])

export enum LogoType {
  Comb,
  Upload,
}

export enum IconType {
  Icon = 'icon',
  Image = 'image',
}

/**
 * 获取 Logo 类型
 *
 * @param logo - logo 值
 * @returns 类型
 */
export const getLogoType = (logo: string) => {
  if (images.includes(logo) || (logo && logo.includes(LOGO_SPLIT))) {
    return LogoType.Comb
  }

  return LogoType.Upload
}

/**
 * 获取 Logo 配置项
 *
 * @param args - args
 * @param args.logo - logo 值
 * @param args.icons - 图标列表
 * @param args.colors - 颜色列表
 * @param args.size - 大小
 * @returns 配置项
 */
export const getLogoConfig = ({
  logo,
  icons,
  colors,
  size,
}: {
  logo: string
  icons: LogoIcon[]
  colors: LogoColor[]
  size?: LogoSize
}): {
  type: LogoType
  src?: string
  icon?: LogoIcon | string
  color?: LogoColor | string
  px: [number | undefined, number | undefined]
} => {
  const type = getLogoType(logo)
  const px = size ? sizeToPx(size) : undefined
  if (type === LogoType.Upload) {
    const src = logo ? seiueFiles.hashToUrl(logo) : logo
    return {
      type,
      src:
        px && src
          ? getCompressImageUrl(src, {
              zoom: [ZoomMode.Fill, px, px],
            })
          : src,
      px: [px, px],
    }
  }

  const [iconName, colorName] = logo.split(LOGO_SPLIT)
  const icon =
    icons.find(
      ({ name }) => name.toLocaleLowerCase() === iconName?.toLocaleLowerCase(),
    ) || iconName

  const color =
    colors.find(
      ({ name }) => name.toLocaleLowerCase() === colorName?.toLocaleLowerCase(),
    ) || colorName
      ? `#${colorName}`
      : ''

  return {
    type,
    icon,
    color,
    px: [px, px],
  }
}

/**
 * 是否为 FontAwesome 图标
 *
 * @param icon - 图标
 * @returns 是否
 */
export const isFontAwesomeIcon = (icon: any): icon is SeIconProp =>
  icon?.prefix && icon?.iconName

/**
 * @file classin 插件公用方法
 */

import { Modal } from '@seiue/rn-ui'
import { isAndroid, isIOS } from '@seiue/rn-util'
import { Linking } from 'react-native'

import { EnabledPlugin } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { getClassInSettions } from 'packages/plugins/features/class-in'
import { DeviceType } from 'packages/plugins/features/class-in/types'
import { getClassinLessonLinkWithSso } from 'packages/plugins/features/class-in/utils/apis'
import { Reflection } from 'packages/sdks-next/chalk'

/**
 * 点击进入直播间
 *
 * @param param0 - 参数
 * @param param0.classinCourseId - classin 班级 id
 * @param param0.classinLessonId - classin 课节 id
 * @param param0.liveUrl - 直播链接
 * @param param0.reflection - Reflection
 * @param param0.classInPlugin - classin 插件
 * @param param0.accessToken - 登录的 token
 */
export const onPressLiveButton = async ({
  classinCourseId,
  classinLessonId,
  liveUrl,
  reflection,
  classInPlugin,
  accessToken,
}: {
  classinLessonId?: number | null
  classinCourseId?: number | null
  liveUrl?: string | null
  reflection: Reflection
  classInPlugin?: EnabledPlugin
  accessToken?: string
}) => {
  const classInSettings = getClassInSettions(classInPlugin)
  const deviceType = isIOS
    ? DeviceType.IOS
    : isAndroid
    ? DeviceType.ANDROID
    : DeviceType.Web

  const classinLink = await getClassinLessonLinkWithSso({
    classinLessonId,
    classinCourseId,
    liveUrl,
    deviceType,
    reflection,
    enableSsoLogin: classInSettings?.enableSsoLogin,
    token: accessToken,
  })

  // go-web 中直接打开链接
  if (!isIOS && !isAndroid) {
    window.location.href = classinLink
    return
  }

  const supported = await Linking.canOpenURL(classinLink)

  if (supported) {
    await Linking.openURL(classinLink)
    return
  }

  Modal.alert({
    title: $t('系统不支持自动打开浏览器'),
    message: $t('请你手动打开手机浏览器访问以下网址：{classinLink}', {
      classinLink,
    }),
  })
}

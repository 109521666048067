/**
 * @file 日程详情容器
 */

import { Text, Layout } from '@seiue/rn-ui'
import React from 'react'
import { ScrollView, View } from 'react-native'
import styled from 'styled-components/native'

import { getEventTag } from 'packages/features/calendars/utils/register'
import { CalendarEventType } from 'packages/features/calendars/utils/types'
import {
  TitleFontSize,
  BorderColor,
  Color as ThemeColor,
} from 'packages/themed/native'

import { EventTag } from './EventComponents'

export const ScheduleDetail: React.FC<{
  title: string
  content: React.ReactNode
  deepText: string
  lightBackground: string
  footer?: React.ReactNode
  subTitle?: string
  eventType?: CalendarEventType
  tag?: string
}> = ({
  title,
  subTitle,
  eventType,
  content,
  footer,
  deepText,
  lightBackground,
  tag,
}) => {
  const eventTag = tag ?? getEventTag(eventType)

  return (
    <Layout.Container>
      <Scroll>
        <TopView>
          <TitleView>
            <Title>{title}</Title>
            {eventTag ? (
              <EventTag color={deepText} backgroundColor={lightBackground}>
                {eventTag}
              </EventTag>
            ) : null}
          </TitleView>
          {subTitle ? <SubTitle>{subTitle}</SubTitle> : null}
        </TopView>
        <ContentWrapper hasFooter={!!footer}>{content}</ContentWrapper>
      </Scroll>
      {footer ? (
        <Layout.Footer slotStyle={{ flex: 1 }}>{footer}</Layout.Footer>
      ) : null}
    </Layout.Container>
  )
}

const Scroll = styled(ScrollView)`
  position: relative;
  flex: 1;
  background-color: #fff;
`

const TopView = styled(View)`
  padding-horizontal: 20px;
  padding-vertical: 15px;
  border-bottom-color: ${BorderColor.Light};
  border-bottom-width: 1px;
`

const TitleView = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

const Title = styled(Text)`
  flex: auto;
  padding-right: 5px;
  font-weight: bold;
  font-size: ${TitleFontSize.XLarge}px;
  line-height: 36px;
`

const SubTitle = styled(Text)`
  font-weight: 400;
  font-size: ${TitleFontSize.Regular}px;
  line-height: 22px;
`

const ContentWrapper = styled(View)<{ hasFooter: boolean }>`
  padding-horizontal: 20px;
  padding-vertical: 15px;
  margin-bottom: ${props => (props.hasFooter ? 120 : 0)}px;
`

export const LabelItem = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-vertical: 6px;
`

export const LabelTitle = styled(Text)`
  color: ${ThemeColor.Gray};
  font-size: ${TitleFontSize.Regular}px;
  line-height: 27px;
`

export const LabelText = styled(Text)`
  margin-left: 10px;
  color: ${ThemeColor.Black};
  font-size: ${TitleFontSize.Regular}px;
  line-height: 27px;
`

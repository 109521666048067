import { InitFeatureParams } from '../types'

import { routes } from './routes'
import { todos } from './todos'

/**
 * 初始化成绩
 *
 * @param param0 - 参数
 */
export const initGrades = (param0: InitFeatureParams) => {
  const { addRoutes, registerTodos } = param0
  addRoutes(routes)
  registerTodos(todos)
}

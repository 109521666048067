import { InitFeatureParams } from '@go/features/types'

import { routes } from './routes'
import { todos } from './todos'

/**
 * 初始化选科
 *
 * @param param0 - 参数
 */
export const initSubjectSelection = (param0: InitFeatureParams) => {
  const { registerTodos, addRoutes } = param0

  registerTodos(todos)
  addRoutes(routes)
}

import React, { useEffect, useState } from 'react'

import { useEnabledPlugins } from 'packages/feature-utils/plugins'
import { useIsLoggedIn } from 'packages/features/sessions/hooks'

import { registerPlugins } from '@go/plugins'

/**
 * 插件注册
 *
 * @param sessionCreated - 插件是否注册完成
 * @returns 是否
 */
export const usePluginRegister = (sessionCreated: boolean) => {
  const [enabledPlugins, inited, reloadPlugins] = useEnabledPlugins({
    disable: !sessionCreated,
  })

  const isLoggedIn = useIsLoggedIn()
  React.useEffect(() => {
    if (isLoggedIn) {
      reloadPlugins()
    }
  }, [isLoggedIn, reloadPlugins])

  const [registered, setRegistered] = useState(false)

  useEffect(() => {
    if (inited) {
      registerPlugins(enabledPlugins)

      setRegistered(true)
    }
  }, [inited, enabledPlugins])

  return registered
}
